import React, { Component } from "react";
import styles from "./Appbar-styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Hidden,
  Paper,
  Avatar,
  CardHeader,
  IconButton,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  Badge,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import {
  MoreVertOutlined,
  Search as SearchIcon,
  AccountCircle,
  NotificationImportantRounded,
  Notifications,
} from "@material-ui/icons";
import { getAuth } from "firebase/auth";
import Authentication from "controller/Authentication";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsAppbar from "components/Notifications/NotificationsAppbar";

class AdminAppbar extends Component {
  state = {
    accountSettingsAnchor: null,
    notificationsAnchor: null,
    user: Authentication.emptyUser,
    notificationsCount: 0,
  };

  constructor(props) {
    super(props);
    this.renderAvatar = this.renderAvatar.bind(this);
  }

  handleUserChange = (user) => {
    this.setState({ user: user ?? Authentication.emptyUser });
  };

  componentDidMount() {
    Authentication.instance.subscribeToUserChange(this.handleUserChange);
  }

  componentWillUnmount() {
    Authentication.instance.unsubscribeFromUserChange(this.handleUserChange);
  }

  handleNotificationCount = (count) => {
    this.setState({ notificationsCount: count });
  };

  renderAvatar(props) {
    if (this.state.user.photoURL == null) {
      return <AccountCircle />;
    } else {
      return (
        <Avatar
          alt="Account"
          src={this.state.user.photoURL}
          className={props.classes.avatar}
        />
      );
    }
  }

  handleOpenAccountSettings = (e) => {
    this.setState({ accountSettingsAnchor: e.currentTarget });
  };
  handleCloseAccountSettings = () => {
    this.setState({ accountSettingsAnchor: null });
  };

  handleOpenNotifications = (e) => {
    this.setState({ notificationsAnchor: e.currentTarget });
  };
  handleCloseNotifications = () => {
    this.setState({ notificationsAnchor: null });
  };

  renderMenu(props) {
    return (
      <Menu
        elevation={3}
        id="account-settings"
        anchorEl={this.state.accountSettingsAnchor}
        open={Boolean(this.state.accountSettingsAnchor)}
        onClose={this.handleCloseAccountSettings}
        style={{ width: "500px" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        getContentAnchorEl={null}
        className={props.classes.menuAccountSettings}
      >
        <div className={props.classes.menuAccountSettingsItem}>
          <IconButton>
            <Avatar
              alt="Account"
              src={this.state.user.photoURL}
              className={props.classes.menuAvatar}
            />
          </IconButton>
        </div>
        <div className={props.classes.menuAccountSettingsItem}>
          <Typography variant={"body1"} style={{ fontWeight: 600 }}>
            {this.state.user.displayName}
          </Typography>
        </div>
        {Authentication.instance.section && (
          <div className={props.classes.menuAccountSettingsItem}>
            <Typography variant={"body2"}>
              {Authentication.instance.section.name}
            </Typography>
          </div>
        )}
        <div className={props.classes.menuAccountSettingsItem}>
          <Typography variant={"body2"}>{this.state.user.email}</Typography>
        </div>

        <br />
        <MenuItem
          className={props.classes.menuAccountSettingsItem}
          onClick={() => {
            if (props.history.location.pathname != "/settings/account")
              props.history.push("/settings/account");
          }}
        >
          <Typography variant={"button"}>Configurar Cuenta</Typography>
        </MenuItem>
        <Divider />
        <br />
        <div className={props.classes.menuAccountSettingsItem}>
          <Button
            variant="outlined"
            color="primary"
            onClick={async () => {
              await getAuth().signOut();
              props.history.push("/authenticate");
            }}
          >
            Cerrar Sesión
          </Button>
        </div>
        <br />
      </Menu>
    );
  }

  render() {
    const { classes, handleDrawerOpen } = this.props;

    return (
      <React.Fragment>
        <AppBar
          className={classes.appbar}
          elevation={0}
          //color="transparent"
          position="static"
          style={{
            // boxShadow: "none",
            zIndex: 1000,
          }}
        >
          <Toolbar syle={{ zIndex: 1000 }}>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              color="default"
              // className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Hidden smDown>
              <Button
                className={classes.appbarTitle}
                color="default"
                style={{ fontWeight: "600" }}
                onClick={() => this.props.history.push("/authenticate")}
              >
                Bachillerato Experimental
              </Button>
            </Hidden>
            <div className={classes.grow} />

            <IconButton onClick={this.handleOpenNotifications}>
              <Badge
                badgeContent={this.state.notificationsCount}
                color="primary"
              >
                <Notifications />
              </Badge>
            </IconButton>

            <IconButton onClick={this.handleOpenAccountSettings}>
              {this.renderAvatar(this.props)}
            </IconButton>
          </Toolbar>
        </AppBar>
        {this.renderMenu(this.props)}
        <NotificationsAppbar
          onGetCount={this.handleNotificationCount}
          history={this.props.history}
          anchorEl={this.state.notificationsAnchor}
          open={Boolean(this.state.notificationsAnchor)}
          onClose={this.handleCloseNotifications}
        />
        <Divider />
      </React.Fragment>
    );
  }
}

AdminAppbar.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(AdminAppbar);
