import {
  Menu,
  Typography,
  withStyles,
  MenuItem,
  Avatar,
  IconButton,
  Divider,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { MoreHoriz, TimerSharp } from "@material-ui/icons";
import React, { Component } from "react";
import NotificationItem from "./NotificationItem";
import styles from "./NotificationsStyles";
import Controller from "./NotificationsController";
import axios from "axios";
import { FetchDataComponent } from "components/Fetching/FetchDataComponent";
import {
  loadingCards,
  textDataRender,
} from "components/Fetching/fetchingLoaders";
import { Skeleton } from "@material-ui/lab";

const controller = new Controller();

class NotificationsMenu extends Component {
  state = {
    notifications: { loading: true, data: [], error: null },
    count: 0,
    opening: false,
    menuAnchor: null,
  };

  componentDidMount() {
    this.getNotificationCount();
  }

  handleMenuOpen = (el) => {
    this.setState({ menuAnchor: el });
  };
  handleMenuClose = () => {
    this.setState({ menuAnchor: null });
  };

  renderMenu = () => {
    return (
      <Menu
        elevation={3}
        id="notification-menu"
        anchorEl={this.state.menuAnchor}
        open={Boolean(this.state.menuAnchor)}
        onClose={this.handleMenuClose}
        style={{ width: "500px" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        getContentAnchorEl={null}
      >
        <MenuItem
          disabled={this.state.count === 0}
          onClick={() => {
            this.handleMenuClose();
            this.markAllAsRead();
          }}
        >
          Marcar como leídas
        </MenuItem>
      </Menu>
    );
  };

  getNotifications = async () => {
    let notifications = { ...this.state.notifications };
    if (notifications.data.length !== 0) return;
    notifications.loading = true;
    notifications.error = null;
    this.setState({ notifications });
    try {
      let data = await controller.getNotifications();
      notifications.data = data;
    } catch (error) {
      if (axios.isCancel(error)) return;
      notifications.error = error;
      console.log(error);
    } finally {
      notifications.loading = false;
      this.setState({ notifications });
    }
  };

  getNotificationCount = async () => {
    let count = await controller.getUnread();
    this.setState({ count });
    this.props.onGetCount(count);
  };

  decreaseCount = async () => {
    let count = this.state.count;
    count = count - 1;
    if (count <= 0) count = 0;
    this.setState({ count: count });
    this.props.onGetCount(count);
  };

  resetCount = async () => {
    let notifications = { ...this.state.notifications };
    notifications.data = notifications.data.map((el) => {
      return { ...el, readStatus: true };
    });
    this.setState({ count: 0, notifications });
    this.props.onGetCount(0);
  };

  markAllAsRead = async () => {
    if (this.state.count === 0) return;
    this.setState({ opening: true });
    await controller.readAll();
    this.resetCount();
    this.setState({ opening: false });
  };

  handleNotificationClick = async (notification) => {
    if (this.state.opening) return;
    if (this.props.onClose) this.props.onClose();
    try {
      this.setState({ opening: true });
      if (!notification.readStatus) {
        await controller.read(notification.id);
        this.decreaseCount();
      }
    } catch (error) {
    } finally {
      this.setState({ opening: false });
    }
  };

  componentWillUnmount() {
    controller.cancelAllTokens();
  }
  render() {
    const props = this.props;
    const { anchorEl, open, onClose } = this.props;
    return (
      <React.Fragment>
        <Menu
          elevation={3}
          id="notification-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={onClose}
          style={{ width: "500px" }}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
          getContentAnchorEl={null}
          className={props.classes.menuAccountSettings}
          onEnter={this.getNotifications}
        >
          {this.state.opening && <LinearProgress />}
          <div className={props.classes.menuAccountSettingsItem}>
            <Typography
              variant={"subtitle2"}
              style={{ fontWeight: 600, marginTop: 20 }}
              align="center"
            >
              Notificaciones
            </Typography>
            <div className={props.classes.grow} />
            <IconButton
              onClick={(e) => {
                this.handleMenuOpen(e.target);
              }}
            >
              <MoreHoriz />
            </IconButton>
          </div>
          <br />
          <Divider />
          <FetchDataComponent
            loading={this.state.notifications.loading}
            data={this.state.notifications.data}
            error={this.state.notifications.error}
            customNoDataRenderer={() =>
              textDataRender("No tienes notificaciones")
            }
            customErrorRenderer={() =>
              textDataRender("Error al traer las notificaciones")
            }
            loadingRender={() => (
              <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Skeleton width={"100%"} height={50} />
                <Skeleton width={"100%"} height={50} />
                <Skeleton width={"100%"} height={50} />
              </div>
            )}
          >
            {this.state.notifications.data.map((notification) => {
              return (
                <NotificationItem
                  history={this.props.history}
                  notification={notification}
                  onClick={this.handleNotificationClick}
                />
              );
            })}
          </FetchDataComponent>
          <Divider />
          <br />
          <div className={props.classes.menuAccountSettingsItem}>
            <Button
              disabled={this.state.count === 0}
              variant="outlined"
              color="primary"
              onClick={this.markAllAsRead}
            >
              Marcar como leídas
            </Button>
          </div>
          <br />
        </Menu>
        {this.renderMenu()}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(NotificationsMenu);
