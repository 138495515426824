import axios from "utils/API";
import Axios from "axios";
import dayjs from "dayjs";

export default class StudentImproveController {
  constructor() {
    this.getSource = Axios.CancelToken.source();
    this.postSource = Axios.CancelToken.source();
  }

  getPlan = async (idAssignment) => {
    if (this.getSource) this.getSource.cancel();
    this.getSource = Axios.CancelToken.source();
    const response = await axios.get(
      `api/student/improve/assignments/${idAssignment}`,
      { cancelToken: this.getSource.token }
    );

    return response.data;
  };

  getPlans = async (idClassroom) => {
    if (this.getSource) this.getSource.cancel();
    this.getSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/student/improve/classrooms/${idClassroom}`,
      { cancelToken: this.getSource.token }
    );

    let data = response.data.map((el, index) => {
      return { ...el };
    });
    return data;
  };

  upload = async (idAssignment, filesURL) => {
    if (this.postSource) this.postSource.cancel();
    this.postSource = Axios.CancelToken.source();

    const response = await axios.post(
      `api/student/improve/assignments/${idAssignment}`,
      { filesURL },
      { cancelToken: this.postSource.token }
    );

    return response.data;
  };

  cancelAllTokens() {
    if (this.getSource) this.getSource.cancel();
    if (this.postSource) this.postSource.cancel();
  }
}
