import axios from "utils/API";
import Axios from "axios";

export default class TestController {
  constructor() {
    this.createSource = Axios.CancelToken.source();
  }

  create = async (idClassroom, info) => {
    if (this.createSource) this.createSource.cancel();
    this.createSource = Axios.CancelToken.source();

    const response = await axios.post(
      `api/teacher/classrooms/${idClassroom}/tests/`,
      { info },
      { cancelToken: this.createSource.token }
    );

    return response.data;
  };
  cancelAllTokens() {
    if (this.createSource) this.createSource.cancel();
  }
}
