import axios from "utils/API";
import Axios from "axios";

export default class UploadController {
  constructor() {
    this.cancelSource = Axios.CancelToken.source();
  }

  startUpload = async (
    href,
    files,
    filesName,
    onUploadProgress,
    onUploadFinish
  ) => {
    if (href === null || files === null || files.length === 0) return;

    if (this.cancelSource) this.cancelSource.cancel();
    this.cancelSource = Axios.CancelToken.source();

    let formData = new FormData();
    files.forEach((file) => {
      formData.append(filesName, file);
    });

    const response = await axios({
      method: "POST",
      url: href,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      onUploadProgress: onUploadProgress,
      cancelToken: this.cancelSource.token,
    });
    onUploadFinish(response.data);
    return response.data;
  };
  cancelAllTokens() {
    if (this.cancelSource) this.cancelSource.cancel();
  }
}
