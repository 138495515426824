import Authentication from "controller/Authentication";
import React, { Component } from "react";
import AdminDashboard from "./AdminDashboard";
import EmptyDashboard from "./EmptyDashboard";
import SecretaryDashboard from "./SecretaryDashboard";
import StudentDashboard from "./StudentDashboard";
import SystemDashboard from "./SystemDashboard";
import TeacherDashboard from "./TeacherDashboard";

class Dashboard extends Component {
  state = { role: "undefined" };

  handleUserChange = (user) => {
    if (user == null) return;
    this.setState({ role: user.idRole });
  };

  componentDidMount() {
    Authentication.instance.subscribeToUserChange(this.handleUserChange);
  }

  componentWillUnmount() {
    Authentication.instance.unsubscribeFromUserChange(this.handleUserChange);
  }

  render() {
    if (this.state.role === "system")
      return <SystemDashboard {...this.props} />;
    if (this.state.role === "admin") return <AdminDashboard {...this.props} />;
    if (this.state.role === "teacher")
      return <TeacherDashboard {...this.props} />;
    if (this.state.role === "student")
      return <StudentDashboard {...this.props} />;
    if (this.state.role === "secretary")
      return <SecretaryDashboard {...this.props} />;
    return <EmptyDashboard {...this.props} />;
  }
}

export default Dashboard;
