import {
  AppBar,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { Component } from "react";
import styles from "./FilePreviewStyles";
import ModalImage from "react-modal-image";

// import "./ImagePreview.css";

import { Download } from "react-bootstrap-icons";
import Lightbox from "react-modal-image/lib/Lightbox";

class ImagePreview extends Component {
  state = {};

  download = () => {
    var anchor = document.createElement("a");
    anchor.href = this.props.file;
    anchor.target = "_blank";
    anchor.download = this.props.title;

    anchor.click();
  };

  url = (file) => {
    if (typeof file === "string") {
      return file;
    }

    return this.imageFileToBlob(file);
  };

  imageFileToBlob(imageFile) {
    return URL.createObjectURL(imageFile);
  }

  render() {
    const { classes } = this.props;
    const { image, open = true, onClose, title = "Image" } = this.props;

    return (
      <React.Fragment>
        {open && (
          <Lightbox
            // small={image}
            large={this.url(image)}
            alt={title}
            onClose={onClose}
            hideDownload
            hideZoom
          />
        )}
      </React.Fragment>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default withStyles(styles)(ImagePreview);
