import {
  Button,
  Card,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import DownloadButton from "components/DownloadButton/DownloadButton";
import UploadController from "components/DownloadButton/UploadController";
import { DropzoneArea } from "material-ui-dropzone";
import React, { Component } from "react";
import { Download } from "react-bootstrap-icons";

const upload = new UploadController();

class UploadTemaryStep extends Component {
  state = {
    files: [],
    fileURL: null,
    upload: {
      uploading: false,
      progress: 0,
    },
  };

  handleDropzoneChange = async (files) => {
    this.setState({ files: files });
  };

  componentDidMount() {
    let fileURL = this.props.oldFileURL;
    this.setState({ fileURL });
  }

  componentWillUnmount() {
    upload.cancelAllTokens();
  }

  getTemaryUploadURL = () => {
    let { id } = this.props.match.params;
    return `files/v2/classrooms/${id}/assignments/temary`;
  };

  removeTemary = () => {
    this.setState({ fileURL: null });
    this.props.onUpload(null);
  };

  uploadTemary = async () => {
    if (this.state.files.length === 0) return;

    this.setState({ upload: { progress: 0, uploading: true } });
    let f = await upload.startUpload(
      this.getTemaryUploadURL(),
      this.state.files,
      "file",
      this.onUploadProgress,
      this.onUploadFinish
    );
  };

  onUploadProgress = (progressEvent) => {
    let progress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    let { upload } = this.state;
    upload.progress = progress;
    this.setState({ upload });
  };

  onUploadFinish = (data) => {
    let { upload } = this.state;
    upload.progress = 0;
    upload.uploading = false;
    let filesURL = data.uri;

    this.setState({ upload, filesURL }, () => this.props.onUpload?.(filesURL));
  };

  onUploadCancel = () => {
    let { upload } = this.state;
    upload.progress = 0;
    upload.uploading = false;
    this.setState({ upload });
  };

  render() {
    const { fileURL } = this.state;
    return (
      <React.Fragment>
        {this.state.fileURL !== null && (
          <React.Fragment>
            <div style={{ display: "flex" }}>
              <Typography variant="subtitle2">Temario Subido</Typography>
              <div style={{ flexGrow: 1 }} />

              <DownloadButton
                fileURL={fileURL}
                color="primary"
                variant="text"
                endIcon={<Download />}
                label="Descargar"
              />
              <Divider flexItem variant="middle" orientation="vertical" />
              <IconButton onClick={this.removeTemary}>
                <Delete />
              </IconButton>
            </div>
            <br />
            <Divider />

            <br />
          </React.Fragment>
        )}
        <DropzoneArea
          onChange={this.handleDropzoneChange}
          dropzoneText={
            fileURL == null
              ? "Arrastra archivos o da click"
              : "Remplazar temario"
          }
          maxFileSize={1024 * 1024 * 100}
          filesLimit={1}
          showAlerts={false}
          useChipsForPreview
        />
        <br />
        {this.state.upload.uploading && (
          <LinearProgress
            variant="determinate"
            value={this.state.upload.progress}
          />
        )}
      </React.Fragment>
    );
  }
}

export default UploadTemaryStep;
