import axios from "utils/API";
import Axios from "axios";

export default class StudentCalendarController {
  constructor() {
    this.getAllSource = Axios.CancelToken.source();
    this.getAllImproveSource = Axios.CancelToken.source();
  }

  getAll = async () => {
    const [assignments] = await Promise.all([
      this.getAssignments(),
      // this.getImprove(),
    ]);

    return [...assignments];
  };

  getImprove = async () => {
    if (this.getAllImproveSource) this.getAllImproveSource.cancel();
    this.getAllImproveSource = Axios.CancelToken.source();

    const response = await axios.get(`api/student/improve/assignments/`, {
      cancelToken: this.getAllImproveSource.token,
    });

    let { data } = response;
    data = data.map((el) => {
      return { ...el, type: "improve" };
    });

    return data;
  };

  getAssignments = async () => {
    if (this.getAllSource) this.getAllSource.cancel();
    this.getAllSource = Axios.CancelToken.source();

    const response = await axios.get(`api/student/assignments/`, {
      cancelToken: this.getAllSource.token,
    });

    let { data } = response;
    data = data.map((el) => {
      return { ...el };
    });

    return data;
  };

  cancelAllTokens() {
    if (this.getAllSource) this.getAllSource.cancel();
    if (this.getAllImproveSource) this.getAllImproveSource.cancel();
  }
}
