import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { Component } from "react";

class CreateCriteriaStep extends Component {
  state = { criteria: [], currentScore: 0 };

  handleChange = () => {
    this.props.onChange(this.state.criteria);
  };

  addCriteria = () => {
    let criteria = [...this.state.criteria];
    criteria.push({ name: "", percentage: 0 });
    const index = criteria.length - 1;

    this.setState({ criteria }, () => {
      document.getElementById(`criteria-name-${index}`).focus();
      this.handleChange();
    });
  };

  removeCriteria = (index) => {
    let criteria = [...this.state.criteria];
    criteria.splice(index, 1);
    this.setState({ criteria }, () => {
      document.getElementById(`add-btn`).focus();
      this.updateCriteria();
    });
  };

  updateCriteria = (e, name, index) => {
    let criteria = [...this.state.criteria];
    if (e) {
      criteria[index][name] = e.target.value;
    }
    let currentScore = criteria.reduce((acum, value) => {
      let val = acum + (parseFloat(value.percentage) || 0);
      return parseFloat(val.toFixed(10));
    }, 0);
    this.setState({ criteria, currentScore }, () => {
      this.handleChange();
    });
  };

  componentDidMount() {
    let { oldCriteria = [] } = this.props;
    oldCriteria = oldCriteria.map((el) => {
      return { ...el };
    });
    this.setState({ criteria: [...oldCriteria] }, () => {
      this.updateCriteria();
      if (oldCriteria.length > 0)
        document.getElementById(`criteria-name-${0}`).focus();
    });
  }

  render() {
    let { criteria = [] } = this.state;
    let { oldScore } = this.props;

    return (
      <React.Fragment>
        <Typography variant="h5" color="textSecondary" align="right">
          {`${this.state.currentScore ?? 0} de ${oldScore} puntos`}
        </Typography>
        <br />
        <Grid container spacing={2}>
          {criteria.map((el, index, arr) => {
            return (
              <React.Fragment>
                <Grid item xs={8}>
                  <TextField
                    label="Nombre"
                    variant="outlined"
                    margin="dense"
                    value={el.name}
                    fullWidth
                    id={`criteria-name-${index}`}
                    onChange={(e) => {
                      this.updateCriteria(e, "name", index);
                    }}
                  />
                </Grid>
                <Grid item xs={arr.length > 1 ? 3 : 4}>
                  <TextField
                    label="Punteo"
                    variant="outlined"
                    margin="dense"
                    type="number"
                    value={el.percentage}
                    onChange={(e) => {
                      this.updateCriteria(e, "percentage", index);
                    }}
                    fullWidth
                  />
                </Grid>
                {arr.length > 1 && (
                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => {
                        this.removeCriteria(index);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            id="add-btn"
            color="primary"
            onClick={() => {
              this.addCriteria();
            }}
          >
            Agregar Criterio
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default CreateCriteriaStep;
