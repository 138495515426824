import React, { Component } from "react";
import styles from "./AccountSettings-styles";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  Button,
  Container,
  Divider,
  Grid,
  Hidden,
  Paper,
  FormControl,
  TextField,
  Typography,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import { TabPanel, TabContext } from "@material-ui/lab";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import MuiPhoneNumber from "material-ui-phone-number";
import AdminAppbar from "components/Appbar/AdminAppbar";
import MaskedInput from "react-text-mask";
import Authentication from "controller/Authentication";
import axios from "utils/API";
import Axios from "axios";
import Dashboard from "components/DashboardContainer/Dashboard";
import { DropzoneArea } from "material-ui-dropzone";
import { columnsTotalWidthSelector } from "@material-ui/data-grid";
import { toast } from "material-react-toastify";
import validator from "validator";
import { TransferWithinAStationSharp } from "@material-ui/icons";

const reader = new FileReader();

class AccountSettings extends Component {
  state = {
    originalUser: Authentication.emptyUser,
    user: Authentication.emptyUser,
    files: [],
    dropzoneOpen: false,
    uploadingPicture: false,
    loading: false,
    previewImage: null,
    pictureToUpload: null,
    nameHelperText: "",
    lastNameHelperText: "",
    phoneExtHelperText: "",
    phoneHelperText: "",
    addressHelperText: "",
  };

  handleChangeDropzone = async (files) => {
    this.setState({ files: files });
    reader.abort();

    if (files && files.length > 0) {
      reader.readAsDataURL(files[0]);
      this.setState({ dropzoneOpen: false, pictureToUpload: files[0] });
    }
  };

  uploadProfilePicture = async () => {
    let formData = new FormData();
    formData.append("profilePicture", this.state.pictureToUpload);
    const response = await axios({
      method: "POST",
      url: "/files/account-management/me/profile-picture",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return response.data;
  };

  constructor(props) {
    super(props);
    this.updateAccountData = this.updateAccountData.bind(this);

    this.nameInput = React.createRef();
    this.lastNameInput = React.createRef();
    this.phoneExtInput = React.createRef();
    this.phoneInput = React.createRef();
    this.addressInput = React.createRef();
  }

  /**
   * Acciones de validación
   * @param {*} valid
   * @param {*} message
   * @param {*} ref
   * @param {*} stateName
   */
  validateInput(valid, message, ref, stateName) {
    if (!valid) {
      ref.current.focus();
      this.setState({ [stateName]: message });
      return;
    }
    this.setState({ [stateName]: "" });
  }

  /**
   *
   * @param {String} name
   * @param {String} lastName
   * @param {String} phoneExt
   * @param {String} phone
   * @param {String} address
   */
  validateForm(name, lastName, phoneExt, phone, address) {
    if (name == null) name = "";
    if (lastName == null) lastName = "";
    if (phoneExt == null) phoneExt = "";
    if (phone == null) phone = "";
    if (address == null) address = "";
    //Validar Nombre
    let isInvalid = name.length < 1 || name.length > 150;

    this.validateInput(
      !isInvalid,
      "Nombre no puede estar vacío",
      this.nameInput,
      "nameHelperText"
    );

    if (isInvalid) return false;

    isInvalid = lastName.length < 1 || lastName.length > 150;

    this.validateInput(
      !isInvalid,
      "Apellido no puede estar vacío",
      this.lastNameInput,
      "lastNameHelperText"
    );

    if (isInvalid) return false;

    //Phone Ext

    isInvalid = !validator.isInt(phoneExt);

    this.validateInput(
      !isInvalid,
      "Extensión inválida",
      this.phoneExtInput,
      "phoneExtHelperText"
    );

    if (isInvalid) return false;

    //Phone
    isInvalid = !validator.isMobilePhone(phone);

    this.validateInput(
      !isInvalid,
      "Teléfono Inválido",
      this.phoneInput,
      "phoneHelperText"
    );

    if (isInvalid) return false;

    //Address

    isInvalid = address.length < 1 || address.length > 255;

    this.validateInput(
      !isInvalid,
      "Dirección no puede estar vacía",
      this.addressInput,
      "addressHelperText"
    );

    if (isInvalid) return false;

    return true;
  }

  async updateAccountData() {
    let user = { ...this.state.user };
    if (
      !this.validateForm(
        user.name,
        user.lastName,
        user.phoneExt,
        user.phone,
        user.address
      )
    )
      return false;
    this.setState({ loading: true });
    try {
      if (this.state.pictureToUpload) {
        let picture = await this.uploadProfilePicture();
        user.photoURL = picture.uri;
      }
      await axios.put("/api/account-management/me/account-data", user);
      this.setState({ loading: false });
      toast("Datos de perfil actualizados correctamente", { type: "success" });
      return true;
    } catch (error) {
      this.setState({ loading: false });
      toast("Error al actualizar los datos del perfil", { type: "error" });
    }
  }

  handleTextChange = (e, name) => {
    const { value } = e.target;
    let user = this.state.user;
    user[name] = value;
    this.setState({ user, [name + "HelperText"]: "" }, () => {});
  };

  handleUserChange = (user) => {
    let u = user || Authentication.emptyUser;
    this.setState({
      user: u,
      originalUser: { ...u },
      previewImage: null,
      pictureToUpload: null,
    });
  };

  hasChanges = () => {
    return (
      JSON.stringify(this.state.user) !=
        JSON.stringify(this.state.originalUser) ||
      this.state.pictureToUpload != null
    );
  };

  discardChanges = () => {
    if (this.state.loading) return;

    reader.abort();

    this.setState((old) => {
      return {
        user: { ...old.originalUser },
        previewImage: null,
        pictureToUpload: null,
        nameHelperText: "",
        lastNameHelperText: "",
        phoneExtHelperText: "",
        addressHelperText: "",
        phoneHelperText: "",
      };
    });
  };

  componentDidMount() {
    Authentication.instance.subscribeToUserChange(this.handleUserChange);
    reader.onload = () => {
      this.setState({ previewImage: reader.result });
    };
    reader.onerror = (error) => {
      this.setState({ previewImage: null, pictureToUpload: null });
    };
  }

  componentWillUnmount() {
    Authentication.instance.unsubscribeFromUserChange(this.handleUserChange);
    reader.abort();
  }

  render() {
    const { classes } = this.props;

    return (
      <Dashboard history={this.props.history}>
        <div className={classes.root}>
          <Paper className={classes.accountSettingsContainer}>
            <Typography variant={"h4"} align="left">
              Cuenta
            </Typography>
            <br />
            <div className={classes.section}>
              <div className={classes.sectionHeader}>
                <Typography variant={"h6"} align="left">
                  Imagen de perfil
                </Typography>
              </div>
              <div className={classes.contentRow}>
                <Avatar
                  align="left"
                  className={classes.menuAvatar}
                  src={this.state.previewImage || this.state.user.photoURL}
                />
                <Button
                  color="primary"
                  onClick={() => {
                    this.setState({ dropzoneOpen: true });
                  }}
                >
                  Subir imagen
                </Button>
              </div>
            </div>
            <Divider />
            <div className={classes.section}>
              <div className={classes.sectionHeader}>
                <Typography variant={"h6"} align="left">
                  Nombre
                </Typography>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    value={this.state.user.name ?? ""}
                    onChange={(e) => {
                      this.handleTextChange(e, "name");
                    }}
                    inputRef={this.nameInput}
                    variant="outlined"
                    label="Nombres"
                    margin="dense"
                    autoComplete="given-name"
                    fullWidth
                    helperText={this.state.nameHelperText}
                    error={this.state.nameHelperText !== ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Apellidos"
                    margin="dense"
                    fullWidth
                    autoComplete="family-name"
                    value={this.state.user.lastName ?? ""}
                    onChange={(e) => {
                      this.handleTextChange(e, "lastName");
                    }}
                    inputRef={this.lastNameInput}
                    helperText={this.state.lastNameHelperText}
                    error={this.state.lastNameHelperText !== ""}
                  />
                </Grid>
              </Grid>
            </div>
            <Divider />

            <div className={classes.section}>
              <div className={classes.sectionHeader}>
                <Typography variant={"h6"} align="left">
                  Información de contacto
                </Typography>
              </div>
              <TextField
                variant="outlined"
                label="Email"
                margin="dense"
                disabled
                value={this.state.user.email ?? ""}
              />
              <div style={{ display: "flex" }}>
                <TextField
                  variant="outlined"
                  label="Extensión"
                  margin="dense"
                  type="number"
                  autoComplete="tel-country-code"
                  value={this.state.user.phoneExt ?? "502"}
                  onChange={(e) => {
                    this.handleTextChange(e, "phoneExt");
                  }}
                  style={{ marginRight: "10px" }}
                  inputRef={this.phoneExtInput}
                  helperText={this.state.phoneExtHelperText}
                  error={this.state.phoneExtHelperText !== ""}
                />
                <TextField
                  variant="outlined"
                  label="Número de Teléfono"
                  type="tel"
                  margin="dense"
                  fullWidth
                  autoComplete="tel-national"
                  value={this.state.user.phone ?? ""}
                  onChange={(e) => {
                    this.handleTextChange(e, "phone");
                  }}
                  helperText={this.state.phoneHelperText}
                  error={this.state.phoneHelperText !== ""}
                  inputRef={this.phoneInput}
                />
              </div>
            </div>
            <Divider />

            <div className={classes.section}>
              <div className={classes.sectionHeader}>
                <Typography variant={"h6"} align="left">
                  Dirección
                </Typography>
              </div>
              <TextField
                autoComplete="street-address"
                variant="outlined"
                label="Dirección"
                multiline
                margin="dense"
                value={this.state.user.address ?? ""}
                onChange={(e) => {
                  this.handleTextChange(e, "address");
                }}
                inputRef={this.addressInput}
                helperText={this.state.addressHelperText}
                error={this.state.addressHelperText !== ""}
              />
            </div>
            <Divider />

            <div className={classes.section}>
              <TextField
                variant="outlined"
                label="Rol"
                margin="dense"
                disabled
                value={this.state.user.role}
              />
            </div>

            {Authentication.instance.section && (
              <div className={classes.section}>
                <TextField
                  variant="outlined"
                  label="Sección"
                  margin="dense"
                  disabled
                  value={Authentication.instance.section.name}
                />
              </div>
            )}
            <Divider />

            <div className={classes.section}>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  const val = await this.updateAccountData();
                  if (!val) return;
                  Authentication.instance.refreshUserData();
                }}
                disabled={this.state.loading || !this.hasChanges()}
              >
                {this.state.loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                    color="primary"
                  />
                )}
                Guardar Cambios
              </Button>

              {this.hasChanges() && (
                <React.Fragment>
                  <div style={{ marginTop: "10px" }} />
                  <Button
                    variant="text"
                    color="primary"
                    onClick={this.discardChanges}
                    size="small"
                    disabled={this.state.loading}
                  >
                    Descartar cambios
                  </Button>
                </React.Fragment>
              )}

              <Button
                size="small"
                onClick={() => {
                  this.resetPassword();
                }}
              >
                Restablecer Contraseña
              </Button>
            </div>
          </Paper>
        </div>
        <Dialog
          open={this.state.dropzoneOpen || this.state.uploadingPicture}
          onClose={() => {
            this.setState({ dropzoneOpen: false });
          }}
          fullWidth
        >
          <DialogTitle>Sube una imagen</DialogTitle>
          <DialogContent>
            {this.state.uploadingPicture ? (
              <div
                style={{
                  width: "100%",
                  height: "250px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <DropzoneArea
                onChange={(files) => {
                  this.handleChangeDropzone(files);
                }}
                filesLimit={1}
                dropzoneText={"Arrastra la imagen aqui o da click"}
                acceptedFiles={["image/jpeg", "image/jpg", "image/png"]}
                showAlerts={false}
                maxFileSize={1024 * 1024 * 15}
              />
            )}
            <br />
          </DialogContent>
        </Dialog>
      </Dashboard>
    );
  }

  resetPassword = async () => {
    try {
      await sendPasswordResetEmail(getAuth(), this.state.user.email);

      toast(
        "Se te ha enviado un correo electrónico para que restablezcas tu contraseña",
        { type: "success" }
      );
    } catch (error) {
      toast("Error al enviar el correo de restablecimiento", {
        type: "error",
      });
    }
  };
}

AccountSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(AccountSettings);
