import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  LinearProgress,
  MobileStepper,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import GetData from "components/Fetching/GetData";
import dayjs from "dayjs";
import React, { Component } from "react";
import AssignmentController from "../Assignments/AssignmentController";
import ClassroomManagementController from "../ClassroomController";
import ClipLoader from "react-spinners/PulseLoader";
import Controller from "./ImprovePlanController";
import PostData from "components/Fetching/PostData";
import Authentication from "controller/Authentication";
import routes from "Pages/routes";
import { generatePath } from "react-router";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {
  Delete,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import UploadTemaryStep from "./CreateSteps/UploadTemaryStep";
import CreateCriteriaStep from "./CreateSteps/CreateCriteriasStep";
import { toast } from "material-react-toastify";
import AssignmentErrorMessages from "../Assignments/AssignmentErrorMessages";
import DefaultErrorMessages from "utils/DefaultErrorMessages";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const assignment = new AssignmentController();
const classroomController = new ClassroomManagementController();
const controller = new Controller();

const steps = [
  "Agrega información básica",
  "Sube el temario",
  "Agrega los criterios",
];

class CreateImprovePlan extends Component {
  state = {
    students: [],
    activeStep: 0,
    currentStudent: [],
    studentsLoading: false,
    assignmentsLoading: false,
    currentAssignment: null,
    assignments: [],
    comments: "",
    dueTime: dayjs(new Date(new Date().setHours(23, 59, 0, 0))),
    dueTimeStr: "23:59",
    dueDateStr: dayjs(new Date().setHours(23, 59, 0, 0)).format("YYYY-MM-DD"),
    queryStudent: "",
    queryAssignment: "",
    message: "",
    loading: false,
    criteria: [],
    currentScore: 0,
    files: [],
    filesURL: null,
    executing: false,
  };

  constructor(props) {
    super(props);
    this.createRef = React.createRef();
    this.temaryRef = React.createRef();
    this.criteriaRef = React.createRef();
  }

  setDate = (e) => {
    let date = e.target.value;
    if (date == "") date = dayjs(this.state.dueTime).format("YYYY-MM-DD");
    this.setDueTime(date, this.state.dueTimeStr);
  };

  setTime = (e) => {
    let time = e.target.value;
    if (time == "") time = dayjs(this.state.dueTime).format("HH:mm");
    this.setDueTime(this.state.dueDateStr, time);
  };

  setDueTime = (date, time) => {
    let fullDateStr = `${date} ${time}`;
    let d = dayjs(fullDateStr, "YYYY-MM-DD HH:mm");
    this.setState({ dueTime: d, dueTimeStr: time, dueDateStr: date });
  };

  setCurrent = (name, val, cb) => {
    this.setState({ [name]: val }, cb);
  };

  onAssignmentChange = () => {
    let { currentAssignment } = this.state;
    let criteria = currentAssignment ? currentAssignment.criteria : [];
    this.setState({
      filesURL: currentAssignment.filesURL,
      message: currentAssignment.description,
      criteria: [...criteria],
      currentScore: currentAssignment.score,
    });
  };

  getAssignments = async () => {
    const result = await assignment.getAssignments(this.props.match.params.id);
    return result;
  };

  onLoading = (name, val) => {
    this.setState({ [name]: val });
  };

  onData = (name, data) => {
    this.setState({ [name]: data });
    if (name === "assignments") this.setDefaultAssignment();
  };

  /**
   * Tarea obtenida desde el URL
   */
  setDefaultAssignment = () => {
    let idAssignment = this.state.queryAssignment;
    let assignment = this.state.assignments[0];
    if (idAssignment) {
      assignment = this.state.assignments.find((e) => e.id === idAssignment);
    }
    this.setCurrent("currentAssignment", assignment, this.onAssignmentChange);
  };

  componentDidMount() {
    let params = new URLSearchParams(this.props.location.search);
    let queryStudent = params.get("uid");
    let queryAssignment = parseInt(params.get("idAssignment"));
    this.setState({ queryStudent, queryAssignment });
  }

  onClick = () => {
    this.createRef.current.post();
  };

  componentWillUnmount() {
    classroomController.cancelAllTokens();
    assignment.cancelAllTokens();
    controller.cancelAllTokens();
  }

  createPlan = async () => {
    const result = await controller.createPlan(
      {
        filesURL: this.state.filesURL || "",
        criteria: this.state.criteria,
        description: this.state.message,
        dueTime: this.state.dueTime,
      },
      this.state.currentAssignment
    );
    return result;
  };

  onPost = () => {
    const { id } = this.props.match.params;
    this.props.history.push(
      generatePath(routes.teacher.classroomAssignments, {
        id: id,
        idAssignment: this.state.currentAssignment.id,
        // idStudent: this.state.currentStudent.uid,
      })
    );
  };

  handleError = (error) => {
    let message =
      AssignmentErrorMessages(error) ||
      DefaultErrorMessages(error, "Error desconocido al crear plan de mejora");

    toast.error(message);
  };

  uploadTemary = async () => {
    this.setState({ executing: true });
    await this.temaryRef.current.uploadTemary();
    this.setState({ executing: false });
  };

  nextStep = async () => {
    if (this.state.executing) return;
    let { activeStep } = this.state;

    if (activeStep === 1) {
      await this.uploadTemary();
    }

    if (activeStep + 1 < steps.length) {
      this.setState({ activeStep: activeStep + 1 });
    }
  };

  previousStep = () => {
    let { activeStep } = this.state;

    if (activeStep - 1 >= 0) {
      this.setState({ activeStep: activeStep - 1 });
    }
  };

  render() {
    const { criteria = [], currentAssignment, activeStep } = this.state;
    let oldScore = currentAssignment ? currentAssignment.score : 0;

    const { executing } = this.state;

    return (
      <div
        style={{
          marginTop: "40px",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "left",
        }}
      >
        <GetData
          request={this.getAssignments}
          onData={(val) => this.onData("assignments", val)}
          onLoading={(val) => this.onLoading("assignmentsLoading", val)}
          errorMessage="Error al obtener las tareas"
          disableSuccessNotification
        />

        <PostData
          request={this.createPlan}
          onData={(val) => this.onPost()}
          onLoading={(val) => this.onLoading("loading", val)}
          onError={this.handleError}
          // errorMessage="Error al crear plan de mejora"
          successMessage="Plan de mejora ha sido creado"
          ref={this.createRef}
          disableErrorNotification
        />

        <Container maxWidth="md">
          <Paper
            elevation={0}
            style={{
              padding: 20,
              marginBottom: 20,
            }}
          >
            <Typography variant="subtitle2">Plan de Mejora</Typography>
          </Paper>
          <Hidden smDown>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              style={{ width: "100%" }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Hidden>

          <Hidden mdUp>
            <Paper
              elevation={0}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: 20,
              }}
            >
              <Typography
                variant="body1"
                style={{ fontWeight: 600, textTransform: "uppercase" }}
              >
                {steps[activeStep]}
              </Typography>
            </Paper>
            <MobileStepper
              position="bottom"
              variant="dots"
              steps={steps.length}
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={this.nextStep}
                  disabled={activeStep === steps.length - 1 || executing}
                >
                  Siguiente
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={this.previousStep}
                  disabled={activeStep === 0 || executing}
                >
                  <KeyboardArrowLeft />
                  Anterior
                </Button>
              }
            ></MobileStepper>
          </Hidden>

          <div style={{ marginTop: 20 }} />
          <Paper
            elevation={0}
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <form
              style={{
                width: "100%",
                maxWidth: 800,
                padding: 20,
                paddingTop: 50,
              }}
            >
              {activeStep === 0 && (
                <React.Fragment>
                  <Autocomplete
                    id="combo-box-assignment"
                    value={this.state.currentAssignment}
                    options={this.state.assignments}
                    getOptionLabel={(option) => option.name}
                    // disabled={this.state.assignmentsLoading}
                    loading={this.state.assignmentsLoading}
                    onChange={(e, val) => {
                      this.setCurrent(
                        "currentAssignment",
                        val,
                        this.onAssignmentChange
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        autoFocus
                        fullWidth
                        {...params}
                        label="Tarea"
                        variant="outlined"
                        // margin="dense"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.assignmentsLoading ? (
                                <ClipLoader
                                  color={"#4C7AF1"}
                                  loading={true}
                                  size={6}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  <div style={{ marginTop: 20 }} />
                  {/* <Typography gutterBottom></Typography> */}
                  <TextField
                    fullWidth
                    label="Descripción"
                    variant="outlined"
                    multiline
                    autoComplete="off"
                    // margin="dense"
                    value={this.state.message}
                    onChange={(e) => this.setState({ message: e.target.value })}
                  />

                  <div style={{ marginTop: 20 }} />
                  <Typography gutterBottom>Fecha de Entrega</Typography>
                  <TextField
                    fullWidth
                    label="Fecha"
                    variant="outlined"
                    type="date"
                    autoComplete="off"
                    margin="dense"
                    value={this.state.dueDateStr}
                    onChange={(e) => {
                      this.setDate(e);
                    }}
                  />
                  <div style={{ marginTop: 20 }} />
                  <TextField
                    fullWidth
                    label="Fecha"
                    variant="outlined"
                    type="time"
                    autoComplete="off"
                    margin="dense"
                    value={this.state.dueTimeStr}
                    onChange={(e) => {
                      this.setTime(e);
                    }}
                  />
                </React.Fragment>
              )}

              {activeStep === 1 && (
                <React.Fragment>
                  <UploadTemaryStep
                    ref={this.temaryRef}
                    oldFileURL={this.state.filesURL}
                    history={this.props.history}
                    match={this.props.match}
                    onUpload={(filesURL) => {
                      this.setState({ filesURL });
                    }}
                  />
                </React.Fragment>
              )}

              {activeStep === 2 && (
                <CreateCriteriaStep
                  ref={this.criteriaRef}
                  oldScore={oldScore}
                  oldCriteria={criteria}
                  onChange={(criteria) => {
                    this.setState({ criteria });
                  }}
                />
              )}

              <div style={{ marginTop: 20 }} />
              <Hidden smDown>
                {activeStep !== steps.length - 1 && (
                  <React.Fragment>
                    {activeStep !== 0 && (
                      <Button
                        color="primary"
                        onClick={this.previousStep}
                        disabled={executing}
                      >
                        Anterior
                      </Button>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={this.nextStep}
                      disabled={executing}
                    >
                      Siguiente
                    </Button>
                  </React.Fragment>
                )}
              </Hidden>
              {activeStep === steps.length - 1 && (
                <React.Fragment>
                  <Hidden smDown>
                    <Button
                      color="primary"
                      onClick={this.previousStep}
                      disabled={executing}
                    >
                      Anterior
                    </Button>
                  </Hidden>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.onClick}
                    disabled={this.state.loading || executing}
                    endIcon={
                      this.state.loading && (
                        <ClipLoader color={"#4C7AF1"} loading={true} size={6} />
                      )
                    }
                  >
                    Crear Plan de Mejora
                  </Button>
                </React.Fragment>
              )}

              <div style={{ marginTop: 40 }} />
            </form>
          </Paper>
        </Container>
      </div>
    );
  }
}

export default CreateImprovePlan;
