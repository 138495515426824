import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
  },
  grow: {
    flexGrow: 1,
  },
  container: {
    margin: 50,
  },
  appbarTransparent: {
    background: "transparent",
    boxShadow: "none",
    zIndex: 1000,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  avatar: { margin: 0, width: 36, height: 36 },
  menuAvatar: { margin: 20, width: 120, height: 120 },
  accountSettingsContainer: {
    marginTop: 30,
    marginBottom: 30,
    justifyContent: "flex-start",
    maxWidth: "100%",
    width: "600px",
    padding: "20px",
  },
  section: {
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    marginBottom: 10,
  },
  sectionHeader: {
    marginBottom: 10,
  },
  contentRow: {
    display: "flex",
    alignItems: "center",
  },
});
