import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import styles from "./StudentClassroomStyles";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  GridList,
  GridListTile,
  IconButton,
  Button,
  Select,
  MenuItem,
  Tabs,
  Tab,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

import Skeleton from "@material-ui/lab/Skeleton";
import {
  WebAssetOutlined,
  AdbOutlined,
  DeleteOutline,
  Add as AddIcon,
} from "@material-ui/icons";

import Dashboard from "components/DashboardContainer/Dashboard";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Controller from "./StudentClassroomController";
import { toast } from "material-react-toastify";
import Axios from "axios";
import Authentication from "controller/Authentication";
//import AssignmentsTable from "./Assignments/AssignmentsTable";
import { isMobile } from "react-device-detect";
//import Assignment from "./Assignments/Assignment";
import routes from "Pages/routes";
import {
  generatePath,
  Link,
  matchPath,
  Route,
  withRouter,
} from "react-router-dom";
import { ProtectedRoute } from "components/ProtectedRoutes/ProtectedRoute";
import StudentAssignmentsTable from "./Assignments/StudentAssignmentsTable";
import StudentAssingment from "./Assignments/StudentAssingment";
import Posts from "./StudentPosts/StudentPosts";
import StudentScores from "./Scores/StudentScores";
import StudentImprovePlan from "./Improve/StudentImprovePlan";
import { getPeriodName, periods, MAX_PERIODS } from "utils/Settings";
import PeriodSummary from "Pages/Teacher/Classroom/Classroom/Summary/PeriodSummary";
import StudentPeriodSummary from "./Summary/StudentPeriodSummary";

const controller = new Controller();

class StudentClassroom extends Component {
  state = {
    tabIndex: 0,
    loading: false,
    classroom: {
      id: null,
      name: "",
      imageURL: "",
      sectionName: "",
      currentPeriod: 0,
    },
    error: null,
    currentPeriod: 0,
  };

  constructor(props) {
    super(props);
    this.assignmetCreation = React.createRef();
    this.summaryRef = React.createRef();
  }

  matchUrl = (location, path, exact) => {
    return matchPath(location, {
      exact,
      path,
      strict: false,
    });
  };

  setTabIndex = (l) => {
    const tabIndex = this.getTabIndex(l);
    this.setState({ tabIndex });
  };
  getTabIndex = (l) => {
    let rt = routes.student;
    if (this.matchUrl(l, rt.classroom, true)) return 0;
    if (this.matchUrl(l, rt.posts, true)) return 0;
    if (this.matchUrl(l, rt.classroomAssignments, false)) return 1;
    if (this.matchUrl(l, rt.improvePlan, false)) return 1;
    if (this.matchUrl(l, rt.scores, false)) return 2;
    return 0;
  };

  handleUserChange = (user) => {
    if (user == null) return;
    this.requestClassroom();
  };

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.setTabIndex(location.pathname);
    });
    this.setTabIndex(this.props.location.pathname);
    Authentication.instance.subscribeToUserChange(this.handleUserChange);
  }

  componentWillUnmount() {
    Authentication.instance.unsubscribeFromUserChange(this.handleUserChange);
    controller.cancelAllTokens();
    this.unlisten();
  }

  changePeriod = (e) => {
    const newVal = e.target.value;
    const currentPeriod = this.state.classroom.currentPeriod ?? 0;

    if (newVal > currentPeriod) {
      toast.info(`Esta unidad es mayor a la unidad actual`);
      return;
    }
    let old = this.state.currentPeriod;
    this.setState({ currentPeriod: newVal }, () => {
      if (newVal === currentPeriod) return;
      if (old === newVal || old === currentPeriod) return;

      this.summaryRef.current.refresh();
    });
  };

  requestClassroom = async () => {
    try {
      this.setState({ loading: true });
      let response = await controller.getClassroom(this.props.match.params.id);
      if (response) {
        let period = response.currentPeriod;
        if (period >= MAX_PERIODS) period = MAX_PERIODS - 1;
        this.setState({
          classroom: response,
          loading: false,
          currentPeriod: period,
        });
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
      if (Axios.isCancel(error)) return;
      this.props.history.replace(routes.errors.classroomNotFound);
      // toast("Error al obtener el salón", { type: "error" });
    }
  };

  parseLink = (link) => {
    return generatePath(link, { ...this.props.match.params });
  };

  render() {
    const { classes } = this.props;
    const { classroom, loading } = this.state;
    return (
      <Dashboard history={this.props.history}>
        <Container
          className={isMobile ? classes.containerMobile : classes.container}
        >
          <main>
            <div
              className={
                isMobile
                  ? classes.mainFeaturedPostMobile
                  : classes.mainFeaturedPost
              }
            >
              {/* Increase the priority of the hero background image */}

              {!loading && (
                <img
                  className={classes.coverPage}
                  src={this.state.classroom.imageURL}
                  alt="background"
                />
              )}
              <div className={classes.overlay} />

              <div className={classes.mainFeaturedPostContent}>
                {loading && (
                  <div className={classes.loadingTextContainer}>
                    <Skeleton
                      width={350}
                      height={80}
                      animation="wave"
                      style={{ maxWidth: "90%" }}
                    />
                    <div style={{ height: "10px" }} />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width={250}
                      height={30}
                      style={{ maxWidth: "90%" }}
                    />
                  </div>
                )}
                <Typography
                  variant={"h3"}
                  color="inherit"
                  gutterBottom
                  unselectable={"on"}
                >
                  {this.state.classroom.name}
                </Typography>

                <Typography
                  variant={"subtitule1"}
                  color="inherit"
                  gutterBottom
                  unselectable={"on"}
                >
                  {this.state.classroom.teacherName}
                </Typography>

                <div className={classes.period}>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    onChange={this.changePeriod}
                    // value={this.state.classroom.currentPeriod}
                    value={this.state.currentPeriod}
                    className={classes.select}
                    style={{ height: "40px", borderColor: "white" }}
                    classes={{
                      root: classes.whiteColor,
                      icon: classes.whiteColor,
                    }}
                    disableUnderline
                  >
                    {periods().map((el) => {
                      return (
                        <MenuItem value={el}>{getPeriodName(el)}</MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>
          </main>
        </Container>

        {this.state.currentPeriod === this.state.classroom.currentPeriod ? (
          <Container
            className={isMobile ? classes.containerMobile : classes.container}
          >
            <Tabs
              scrollButtons="on"
              value={this.state.tabIndex}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              aria-label="disabled tabs example"
            >
              <Tab
                label="Inicio"
                to={this.parseLink(routes.student.classroom)}
                component={Link}
              />
              <Tab
                label="Tareas"
                to={this.parseLink(routes.student.classroomAssignments)}
                component={Link}
              />
              <Tab
                label="Calificaciones"
                to={this.parseLink(routes.student.scores)}
                component={Link}
              />
              {/* <Tab label="Evaluaciones" />
              <Tab label="Alumnos" /> */}
            </Tabs>
            <ProtectedRoute
              idRole="student"
              exact
              path={routes.student.classroom}
              component={Posts}
            />

            <ProtectedRoute
              idRole="student"
              exact
              path={routes.student.posts}
              component={Posts}
            />

            <ProtectedRoute
              idRole="student"
              exact
              path={routes.student.classroomAssignments}
              component={StudentAssignmentsTable}
            />

            <ProtectedRoute
              idRole="student"
              exact
              path={routes.student.assignment}
              component={StudentAssingment}
            />

            <ProtectedRoute
              idRole="student"
              exact
              path={routes.student.scores}
              component={StudentScores}
            />

            <ProtectedRoute
              idRole="student"
              exact
              path={routes.student.improvePlan}
              component={StudentImprovePlan}
            />
          </Container>
        ) : (
          <StudentPeriodSummary
            innerRef={this.summaryRef}
            idClassroom={this.props.match.params.id}
            period={this.state.currentPeriod}
          />
        )}
      </Dashboard>
    );
  }
  openDeleteDialog = () => {
    this.setState({ deleteOpen: true });
  };
  handleDeleteDialogClose = () => {
    this.setState({ deleteOpen: false });
  };
}

StudentClassroom.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(StudentClassroom));
