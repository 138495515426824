import { Divider } from "@material-ui/core";
import { Button, Container, Typography, TextField } from "@material-ui/core";
import { TextFields } from "@material-ui/icons";
import GetData from "components/Fetching/GetData";
import SearchInput from "components/SearchInput/SearchInput";
import React, { Component } from "react";
import SecretaryClassroomController from "../SecretaryClassroomController";
import Authentication from "controller/Authentication";
import { Card } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { CardHeader } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { CardActions } from "@material-ui/core";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { Skeleton, Autocomplete } from "@material-ui/lab";
import DownloadButton from "components/DownloadButton/DownloadButton";
import { textDataRender } from "components/Fetching/fetchingLoaders";
import { FetchDataComponent } from "components/Fetching/FetchDataComponent";
import School from "controller/School";

const controller = new SecretaryClassroomController();

function loadingCards(prefix) {
  let style = {
    width: "100%",
    height: "100%",
    minHeight: 150,
    borderRadius: 20,
  };
  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={6} lg={4} key={prefix + "_01"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={4} key={prefix + "_02"}>
        <Skeleton variant="rect" style={style} />
      </Grid>{" "}
      <Grid item xs={12} sm={12} md={6} lg={4} key={prefix + "_03"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
    </React.Fragment>
  );
}

class StudentNotes extends Component {
  state = {
    sections: [],
    currentSection: 0,
    students: [],
    studentsLoading: true,
    textFilter: "",
    currentPage: 0,
    studentsError: null,
  };

  constructor(props) {
    super(props);
    this.studentsRequestRef = React.createRef();
  }

  startStudentsRequest() {
    this.studentsRequestRef.current.get();
  }

  requestStudents = async () => {
    let students = await controller.getStudents(
      this.state.textFilter,
      this.state.currentSection.idSection,
      this.state.currentPage
    );
    return students;
  };

  async componentDidMount() {
    await this.requestSections();
    // this.startStudentsRequest();
  }

  componentWillUnmount() {
    controller.cancelAllTokens();
  }

  async requestSections() {
    const sections = await School.getSections();

    if (sections.length > 0) {
      this.setState({ sections, currentSection: sections[0] }, () =>
        this.onChange()
      );
    } else {
      this.setState({ sections: [] });
    }
  }

  onChange = () => {
    this.startStudentsRequest();
  };

  handleSearch = async (text) => {
    this.setState({ textFilter: text }, () => {
      this.startStudentsRequest();
    });
  };

  render() {
    return (
      <React.Fragment>
        <GetData
          ref={this.studentsRequestRef}
          request={this.requestStudents}
          onData={(data) => this.setState({ students: data })}
          onLoading={(val) => this.setState({ studentsLoading: val })}
          onError={(err) => this.setState({ studentsError: err })}
          dontExecuteOnStart
          disableErrorNotification
          disableSuccessNotification
        />

        <Container maxWidth={"lg"} style={{ justifyContent: "flex-start" }}>
          <div style={{ marginTop: 30, marginBottom: 30, display: "flex" }}>
            <Autocomplete
              id="section-selection"
              options={this.state.sections}
              getOptionLabel={(option) => option.name}
              style={{ marginTop: "20px", width: "100%", maxWidth: "400px" }}
              onChange={(e, newVal) => {
                this.setState({ currentSection: newVal }, () => {
                  if (newVal != null) {
                    this.onChange();
                  }
                });
              }}
              value={this.state.currentSection}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sección"
                  variant="outlined"
                  autoFocus={!isMobile}
                />
              )}
            />
          </div>

          <div style={{ marginTop: 30, marginBottom: 30, display: "flex" }}>
            <SearchInput
              style={{ background: "#fff", width: 400, maxWidth: "100%" }}
              variant="outlined"
              size="small"
              margin="dense"
              autoFocus={!isMobile}
              onSearch={this.handleSearch}
            />
          </div>
          <Divider />

          <br />
          <Grid container spacing={4}>
            <FetchDataComponent
              loading={this.state.studentsLoading}
              data={this.state.students}
              error={this.state.studentsError}
              customNoDataRenderer={() =>
                textDataRender("No se encontraron estudiantes")
              }
              customErrorRenderer={() =>
                textDataRender(
                  "Ocurrio un error al momento de traer la información"
                )
              }
              loadingRender={() => loadingCards("classrooms")}
            >
              {this.state.students.map((student) => {
                return (
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Card variant="outlined" style={{ height: "100%" }}>
                      <CardHeader
                        title={
                          <Typography align="left" variant="subtitle2">
                            {Authentication.formatName(
                              student.name,
                              student.lastName
                            )}
                          </Typography>
                        }
                        avatar={
                          <Avatar
                            style={{ width: 60, height: 60 }}
                            src={student.photoURL}
                          />
                        }
                      />
                      <Divider />
                      <CardActions>
                        <DownloadButton
                          fileURL={`${process.env.REACT_APP_API_URL}api/secretary/notes/${student.uid}/xlsx`}
                          color="primary"
                          variant="text"
                          label="Descargar Boleta"
                        />
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
            </FetchDataComponent>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

export default StudentNotes;
