import {
  Badge,
  Breadcrumbs,
  Button,
  Chip,
  Typography,
  withStyles,
  withWidth,
} from "@material-ui/core";
import { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import { DataGrid } from "@material-ui/data-grid";
import React, { Component } from "react";
import styles from "./StudentAssignmentStyles";
import { Add as AddIcon, Check, CheckCircleOutline } from "@material-ui/icons";
import Controller from "./StudentAssignmentController";
import Axios from "axios";
import { toast } from "material-react-toastify";
import dayjs from "dayjs";
import { generatePath } from "react-router-dom";
import routes from "Pages/routes";
import DataTable from "components/Data/Table/DataTable";
import GetData from "components/Fetching/GetData";
import StudentImproveController from "../Improve/StudentImproveController";

const improveController = new StudentImproveController();
const controller = new Controller();

/**
 *
 * @param {import("@material-ui/data-grid").ValueGetterParams} params
 */

function parseDate(params) {
  return dayjs(params.getValue("dueTime")).format("DD/MM/YYYY");
}

function formatImprove(params) {
  if (params.getValue("hasImprove"))
    return <Chip label="PM" variant="outlined" color="primary" />;
}

function formatDelivered(params) {
  if (params.getValue("delivered"))
    return <CheckCircleOutline color="primary" />;
}

function formatDeliveredImprove(params) {
  if (params.getValue("filesURL"))
    return <CheckCircleOutline color="primary" />;
}

class StudentAssignmentsTable extends Component {
  state = {
    colums: {},
    improve: [],
    loadingImprove: true,
    creatingAssignment: false,
    assignments: {
      loading: true,
      data: [],
      error: null,
    },
    addingNew: false,
  };

  constructor() {
    super();
    this.dataRef = React.createRef();
  }

  handleLoading = (name, val) => {
    this.setState({ [name]: val });
  };

  getImprovePlans = async () => {
    const { id } = this.props.match.params;
    const data = await improveController.getPlans(id);

    return data;
  };

  getAssignments = async (id) => {
    try {
      this.setState({ assignments: { data: [], loading: true, error: null } });
      const data = await controller.getAssignments(id);
      let assignments = { loading: false, data, error: null };
      this.setState({ assignments }, () => {});
    } catch (error) {
      if (Axios.isCancel(error)) return;
      let assignments = { loading: false, data: [], error: error };
      this.setState({ assignments }, () => {});
      toast("Error al obtener las tareas", { type: "error" });
    }
  };

  componentDidMount() {
    this.handleClassroomId(this.props.match.params.id);
  }

  componentWillUnmount() {
    controller.cancelAllTokens();
    improveController.cancelAllTokens();
  }

  getImproveColumns = () => {
    return [
      {
        field: "name",
        headerName: "Nombre",
        width: 200,
      },
      {
        field: "filesURL",
        headerName: "Entregada",
        width: 120,
        renderCell: formatDeliveredImprove,
      },
      { field: "description", headerName: "Descripción", width: 600, flex: 1 },
      {
        field: "dueTime",
        headerName: "Fecha",
        width: 200,
        valueFormatter: parseDate,
      },
    ];
  };

  getColumns = () => {
    if (isWidthDown("sm", this.props.width)) {
      return [
        { field: "name", headerName: "Nombre", width: 200 },
        {
          field: "delivered",
          headerName: "Entregada",
          width: 120,
          renderCell: formatDelivered,
        },
        {
          field: "dueTime",
          headerName: "Fecha",
          width: 120,
          valueFormatter: parseDate,
        },
      ];
    }

    return [
      { field: "name", headerName: "Nombre", width: 200 },
      {
        field: "delivered",
        headerName: "Entregada",

        renderCell: formatDelivered,
      },
      {
        field: "hasImprove",
        headerName: "Plan Mejora",
        renderCell: formatImprove,
      },
      { field: "description", headerName: "Descripción", width: 600, flex: 1 },
      {
        field: "dueTime",
        headerName: "Fecha",
        width: 200,
        valueFormatter: parseDate,
      },
    ];
  };

  handleClassroomId = (id) => {
    this.getAssignments(id);
  };

  render() {
    const { assignments } = this.state;
    return (
      <React.Fragment>
        <GetData
          request={this.getImprovePlans}
          onData={(d) => this.setState({ improve: d })}
          onLoading={(val) => this.handleLoading("loadingImprove", val)}
          onError={(err) => console.log(err)}
          errorMessage={`Error al obtener los planes de mejora`}
          disableSuccessNotification
        />
        <React.Fragment>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "50px",
            }}
          ></div>
          <Typography align="left" color="textSecondary" variant="subtitle2">
            Tareas
          </Typography>
          <div
            style={{
              height: 400,
              width: "100%",
              marginTop: "30px",
              textAlign: "left",
            }}
          >
            <DataTable
              noRowsLabel="No hay ninguna tarea"
              loading={assignments.loading}
              rows={assignments.data}
              columns={this.getColumns()}
              pageSize={5}
              disableMultipleSelection
              disableSelectionOnClick
              disableColumnSelector
              sortModel={[{ field: "dueTime", sort: "desc" }]}
              onRowClick={(target) => {
                this.props.history.push(
                  generatePath(routes.student.assignment, {
                    id: this.props.match.params.id,
                    idAssignment: target.row.id,
                  })
                );
              }}
            />
          </div>
        </React.Fragment>

        <br />
        <Typography align="left" color="textSecondary" variant="subtitle2">
          Planes de mejora
        </Typography>
        <div
          style={{
            height: 400,
            width: "100%",
            marginTop: "30px",
            textAlign: "left",
          }}
        >
          <DataTable
            noRowsLabel="No hay ningún plan de mejora"
            loading={this.state.loadingImprove}
            rows={this.state.improve}
            columns={this.getImproveColumns()}
            pageSize={5}
            disableMultipleSelection
            disableSelectionOnClick
            disableColumnSelector
            sortModel={[{ field: "dueTime", sort: "desc" }]}
            onRowClick={(target) => {
              // alert(JSON.stringify(target.row));

              const url = generatePath(routes.student.improvePlan, {
                id: target.row.idClassroom,
                idStudent: target.row.uid,
                idAssignment: target.row.id,
              });
              this.props.history.push(url);
            }}
          />
        </div>
        <br />
      </React.Fragment>
    );
  }
}

export default withWidth()(withStyles(styles)(StudentAssignmentsTable));
