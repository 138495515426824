import axios from "utils/API";
import Axios from "axios";
import dayjs from "dayjs";

export default class ImprovePlanController {
  constructor() {
    this.createSource = Axios.CancelToken.source();
    this.getSource = Axios.CancelToken.source();
    this.deleteSource = Axios.CancelToken.source();
    this.getPlansSource = Axios.CancelToken.source();
  }

  check = (info, oldAssignment) => {
    if (!dayjs(new Date()).isBefore(info.dueTime))
      throw new Error("before_date");

    let currentScore = info.criteria.reduce((acum, value) => {
      let val = acum + (parseFloat(value.percentage) || 0);
      return parseFloat(val.toFixed(10));
    }, 0);

    if (currentScore !== oldAssignment.score) throw new Error("invalid_score");
  };

  createPlan = async (info, oldAssignment) => {
    if (this.createSource) this.createSource.cancel();
    this.createSource = Axios.CancelToken.source();

    this.check(info, oldAssignment);

    const response = await axios.post(
      `api/teacher/improve/${oldAssignment.id}/`,
      { info },
      { cancelToken: this.createSource.token }
    );
    return response.data;
  };

  editAssignment = async (info, oldAssignment) => {
    if (this.createSource) this.createSource.cancel();
    this.createSource = Axios.CancelToken.source();

    if (!dayjs(new Date()).isBefore(info.dueTime))
      throw new Error("before_date");

    let editInfo = { ...oldAssignment, ...info };

    const response = await axios.put(
      `api/teacher/improve/assignments/${oldAssignment.id}/`,
      { info: editInfo },
      { cancelToken: this.createSource.token }
    );
    return response.data;
  };

  getNotDelivered = async (idAssignment) => {
    if (this.getPlansSource) this.getPlansSource.cancel();
    this.getPlansSource = Axios.CancelToken.source();
    const response = await axios.get(
      `api/teacher/improve/assignments/${idAssignment}/not-delivered`,
      { cancelToken: this.getPlansSource.token }
    );

    return response.data;
  };

  getPlans = async (idAssignment) => {
    if (this.getPlansSource) this.getPlansSource.cancel();
    this.getPlansSource = Axios.CancelToken.source();
    const response = await axios.get(
      `api/teacher/improve/assignments/${idAssignment}/delivered`,
      { cancelToken: this.getPlansSource.token }
    );

    return response.data;
  };

  getUserPlan = async (idAssignment, idUser) => {
    if (this.getSource) this.getSource.cancel();
    this.getSource = Axios.CancelToken.source();
    const response = await axios.get(
      `api/teacher/improve/assignments/${idAssignment}/delivered/${idUser}`,
      { cancelToken: this.getSource.token }
    );

    return response.data;
  };

  getPlan = async (idAssignment) => {
    if (this.getSource) this.getSource.cancel();
    this.getSource = Axios.CancelToken.source();
    const response = await axios.get(
      `api/teacher/improve/assignments/${idAssignment}`,
      { cancelToken: this.getSource.token }
    );

    return response.data;
  };

  deletePlan = async (idAssignment, idStudent) => {
    if (this.deleteSource) this.deleteSource.cancel();
    this.deleteSource = Axios.CancelToken.source();
    const response = await axios.delete(
      `api/teacher/improve/assignments/${idAssignment}`,
      { cancelToken: this.deleteSource.token }
    );

    return response.data;
  };

  getImproveAssignments = async (idClassroom) => {
    if (this.getSource) this.createSource.cancel();
    this.getSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/teacher/improve/classrooms/${idClassroom}`,
      { cancelToken: this.getSource.token }
    );

    return response.data;
  };

  getImproveAssignmentsToScore = async (idClassroom) => {
    if (this.getSource) this.createSource.cancel();
    this.getSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/teacher/improve/classrooms/${idClassroom}/to-score`,
      { cancelToken: this.getSource.token }
    );

    return response.data;
  };

  uploadUserAssignment = async (idAssignment, idUser) => {
    if (this.createSource) this.createSource.cancel();
    this.createSource = Axios.CancelToken.source();

    const response = await axios.post(
      `api/teacher/improve/${idAssignment}/${idUser}/create-assignment`,
      { cancelToken: this.createSource.token }
    );

    return response.data;
  };

  cancelAllTokens() {
    if (this.createSource) this.createSource.cancel();
    if (this.getSource) this.getSource.cancel();
    if (this.deleteSource) this.deleteSource.cancel();
    if (this.getPlansSource) this.getPlansSource.cancel();
  }
}
