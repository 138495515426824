import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { FetchDataComponent } from "components/Fetching/FetchDataComponent";
import React, { Component } from "react";
import ClassroomManagementController from "../Management/ClassroomManagementController";

const controller = new ClassroomManagementController();

class CopyDialog extends Component {
  state = {
    loading: false,
    classrooms: [],
    loadingClassrooms: true,
    selectedClassrooms: [],
  };

  handleCopy = async () => {
    try {
      this.setState({ loading: true });
      let classrooms = this.state.selectedClassrooms.filter((el) => el.checked);
      classrooms = classrooms.map((el) => el.idClassroom);
      await this.props.onCopy(classrooms, this.props.idClassroom);
      this.setupSelected();
      this.setState({ loading: false }, this.handleClose);
    } catch (error) {
    } finally {
      this.setState({ loading: false });
    }
  };

  async componentDidMount() {
    this.setState({ loadingClassrooms: true });
    let classrooms = await controller.getClassrooms();
    classrooms = classrooms.filter((el) => el.id !== this.props.idClassroom);
    this.setState({ classrooms, loadingClassrooms: false }, this.setupSelected);
  }

  setupSelected = () => {
    const selectedClassrooms = this.state.classrooms.map((el) => {
      return { idClassroom: el.id, checked: false };
    });

    this.setState({ selectedClassrooms });
  };

  getCheckedValue = (idClassroom) => {
    let val = this.state.selectedClassrooms.find(
      (el) => el.idClassroom === idClassroom
    );
    if (val == null) return false;
    return val.checked;
  };

  setCheckedValue = (idClassroom, value) => {
    let selectedClassrooms = [...this.state.selectedClassrooms];
    let index = selectedClassrooms.findIndex(
      (el) => el.idClassroom === idClassroom
    );
    if (index !== -1) selectedClassrooms[index].checked = value;
    this.setState({ selectedClassrooms });
  };

  handleClose = () => {
    if (this.state.loading) return;
    if (this.props.onClose) this.props.onClose();
  };

  render() {
    const { open = false } = this.props;
    return (
      <Dialog fullWidth open={open} onClose={this.handleClose}>
        {this.state.loading && <LinearProgress />}
        <DialogTitle>{this.props.title || `Copiar`}</DialogTitle>

        <DialogContent>
          <DialogContentText>
            {this.props.content || `Elige los salones a los que deseas copiar`}
          </DialogContentText>

          <FetchDataComponent
            loading={this.state.loadingClassrooms}
            data={this.state.classrooms}
            error={null}
            loadingRender={() => {
              return (
                <React.Fragment>
                  <Skeleton width={"100%"} />
                  <Skeleton width={"100%"} />
                  <Skeleton width={"100%"} />
                </React.Fragment>
              );
            }}
          >
            {this.state.classrooms.map((el) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={this.getCheckedValue(el.id)}
                    onChange={(e) =>
                      this.setCheckedValue(el.id, e.target.checked)
                    }
                  />
                  <Typography style={{ marginLeft: 10 }}>{el.name}</Typography>
                  <div style={{ flexGrow: 1 }} />
                  <Typography>{el.sectionName}</Typography>
                </div>
              );
            })}
          </FetchDataComponent>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this.handleClose}
            disabled={this.state.loading}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={this.handleCopy}
            disabled={this.state.loading}
          >
            Copiar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CopyDialog;
