import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./SectionManagementStyles";
import AdminDashboard from "components/DashboardContainer/AdminDashboard";
import {
  AppBar,
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  LinearProgress,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import School from "controller/School";
import SectionManagementController from "./SectionManagementController";
import Axios from "axios";
import { toast } from "material-react-toastify";
import { FetchDataComponent } from "components/Fetching/FetchDataComponent";
import Authentication from "controller/Authentication";
import { Add } from "@material-ui/icons";
import { CloseIcon } from "@material-ui/data-grid";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import SearchInput from "components/SearchInput/SearchInput";

const Controller = new SectionManagementController();

function loadingCards(prefix) {
  let style = { width: "100%", height: "100%", minHeight: 110 };
  return (
    <React.Fragment>
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_01"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_02"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_03"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_04"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
    </React.Fragment>
  );
}

class SectionManagement extends Component {
  state = {
    sections: [],
    currentSection: null,
    currentStudents: { data: [], loading: true, error: null },
    potentialStudents: { data: [], loading: false, error: null },
    selectedPotentialStudents: [],
    textFilter: "",
    addUsersOpen: false,
    savingUsers: false,
  };

  handleTextChange = (e, name, callback) => {
    const { value } = e.target;
    this.setState({ [name]: value }, callback);
  };

  handlePotentialUserSearch = async (text) => {
    this.setState({ textFilter: text }, () => {
      this.requestNonStudents(
        this.state.currentSection ? this.state.currentSection.idSection : -1
      );
    });
  };

  handleStudentsSearch = async (text) => {
    await this.requestStudents(
      this.state.currentSection ? this.state.currentSection.idSection : -1,
      text
    );
  };

  componentDidMount() {
    this.requestSections();
    this.requestNonStudents(1);
  }
  componentWillUnmount() {
    Controller.cancelAllTokens();
  }

  assignUsersToSection = async () => {
    this.setState({ savingUsers: true });
    try {
      await Controller.assignToSection(
        this.state.selectedPotentialStudents,
        this.state.currentSection.idSection
      );

      this.setState({ savingUsers: false, selectedPotentialStudents: [] });
      toast(`Sección asignada correctamente`, { type: "success" });
      this.requestNonStudents(this.state.currentSection.idSection);
      this.requestStudents(this.state.currentSection.idSection);
    } catch (err) {
      if (Axios.isCancel(err)) return;
      this.setState({ savingUsers: false });
      toast(`Error al intentar asignarle sección a uno o más estudiantes`, {
        type: "error",
      });
    }
  };

  requestStudents = async (idSection, textFilter = null) => {
    this.setState((old) => ({
      currentStudents: { ...old.currentStudents, loading: true },
    }));

    try {
      const students = await Controller.getUsers(idSection, textFilter, 0);
      this.setState({
        currentStudents: { data: students, loading: false, error: null },
      });
    } catch (err) {
      if (Axios.isCancel(err)) return;
      toast(`Error al obtener los estudiantes de esta sección`, {
        type: "error",
      });
      this.setState({
        currentStudents: { data: [], error: err, loading: false },
      });
    }
  };

  requestNonStudents = async (idSection, studentsOnly = false) => {
    if (idSection < 0) return;
    this.setState((old) => ({
      selectedPotentialStudents: [],
      potentialStudents: { ...old.currentStudents, loading: true },
    }));

    try {
      const students = await Controller.getNonUsers(
        idSection,
        this.state.textFilter,
        0,
        studentsOnly
      );
      this.setState({
        potentialStudents: { data: students, loading: false, error: null },
      });
    } catch (err) {
      if (Axios.isCancel(err)) return;
      toast(`Error al obtener los estudiantes`, { type: "error" });
      this.setState({
        potentialStudents: { data: [], error: err, loading: false },
      });
    }
  };

  requestSections = async () => {
    const sections = await School.getSections();
    this.setState({ sections });
    if (sections.length > 0) {
      this.requestStudents(sections[0].idSection);
      this.setState({ currentSection: sections[0] });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <AdminDashboard history={this.props.history}>
        <main
          className={isMobile ? classes.containerMobile : classes.container}
        >
          <div className={classes.header}>
            <Typography variant="h4" align="left">
              {"Secciones"}
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Autocomplete
              id="section-selection"
              options={this.state.sections}
              getOptionLabel={(option) => option.name}
              style={{ marginTop: "20px", width: "100%", maxWidth: "400px" }}
              onChange={(e, newVal) => {
                this.setState({ currentSection: newVal });
                if (newVal != null) {
                  this.requestStudents(newVal.idSection);
                  this.requestNonStudents(newVal.idSection);
                }
              }}
              value={this.state.currentSection}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sección"
                  variant="outlined"
                  autoFocus={!isMobile}
                />
              )}
            />
          </div>
          <br />

          <div style={{ display: "flex", alignItems: "center" }}></div>

          <br />
          <Grid container spacing={2}>
            <Grid item md={3} sm={12}>
              <SearchInput
                fullWidth
                placeholder={"Buscar estudiantes"}
                onSearch={this.handleStudentsSearch}
              />
            </Grid>
            <Grid item md={4} sm={12}>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <Button
                  style={{ marginTop: 8 }}
                  variant="outlined"
                  color="primary"
                  endIcon={<Add />}
                  disabled={!this.state.currentSection}
                  onClick={() => this.setState({ addUsersOpen: true })}
                >
                  Agregar estudiantes
                </Button>
              </div>
            </Grid>
          </Grid>

          <br />
          <Grid container spacing={2} className={classes.heroContainer}>
            <FetchDataComponent
              loading={this.state.currentStudents.loading}
              data={this.state.currentStudents.data}
              error={this.state.currentStudents.error}
              customNoDataRenderer={() =>
                textDataRender("No se encontraron estudiantes")
              }
              loadingRender={() => loadingCards("old_")}
            >
              {this.state.currentStudents.data.map((el) => {
                return (
                  <Grid
                    item
                    xl={3}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    key={el.uid}
                    className={classes.heroContent}
                  >
                    {studentRenderer(this.props, el)}
                  </Grid>
                );
              })}
            </FetchDataComponent>
          </Grid>

          <Dialog
            fullScreen
            open={this.state.addUsersOpen || this.state.savingUsers}
            onClose={() => this.setState({ addUsersOpen: false })}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  onClick={() => this.setState({ addUsersOpen: false })}
                  edge="start"
                  color="inherit"
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Agregar estudiantes
                </Typography>
              </Toolbar>
              {this.state.savingUsers && <LinearProgress color="secondary" />}
            </AppBar>

            <DialogTitle>
              <br />
              <Typography
                variant="h5"
                className={classes.title}
                color="textSecondary"
              >
                {this.state.currentSection && this.state.currentSection.name}
              </Typography>

              <SearchInput
                className={classes.searchBar}
                autoFocus={!isMobile}
                onSearch={this.handlePotentialUserSearch}
              />

              <Button
                variant="contained"
                color="primary"
                className={classes.saveButton}
                onClick={this.assignUsersToSection}
                disabled={this.state.savingUsers}
              >
                Guardar
              </Button>
              <Divider />

              {this.renderChips()}

              <br />
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} className={classes.heroContainer}>
                <FetchDataComponent
                  loading={this.state.potentialStudents.loading}
                  data={this.state.potentialStudents.data}
                  error={this.state.potentialStudents.error}
                  customNoDataRenderer={() =>
                    textDataRender("No se encontraron estudiantes")
                  }
                  loadingRender={() => loadingCards("new_")}
                >
                  {this.state.potentialStudents.data.map((el) => {
                    return (
                      <Grid
                        item
                        xl={3}
                        lg={4}
                        md={6}
                        sm={12}
                        xs={12}
                        key={el.uid}
                        className={classes.heroContent}
                      >
                        {this.renderNewUsers(el)}
                      </Grid>
                    );
                  })}
                </FetchDataComponent>
              </Grid>
            </DialogContent>
          </Dialog>
        </main>
      </AdminDashboard>
    );
  }

  renderChips = () => {
    if (this.state.potentialStudents.length == 0) return <React.Fragment />;
    return (
      <React.Fragment>
        {this.state.selectedPotentialStudents.map((el, index, array) => {
          if (index > 5) return <React.Fragment />;
          return (
            <Chip
              style={{ margin: 5 }}
              avatar={<Avatar src={el.photoURL} />}
              label={
                index < 5
                  ? Authentication.formatName(el.name, el.lastName)
                  : index == 5 && `${array.length - 5} más`
              }
              onDelete={() => {
                this.removeSelected(el);
              }}
            />
          );
        })}
      </React.Fragment>
    );
  };

  pushSelected = (user) => {
    if (this.state.savingUsers) return;
    this.setState((old) => ({
      selectedPotentialStudents: [...old.selectedPotentialStudents, user],
    }));
  };

  removeSelected = (user) => {
    if (this.state.savingUsers) return;
    this.setState((old) => {
      let arr = [...old.selectedPotentialStudents];
      arr.splice(
        arr.findIndex((e) => e.uid === user.uid),
        1
      );

      return { selectedPotentialStudents: arr };
    });
  };

  renderNewUsers = (user) => {
    const { classes } = this.props;

    const { selectedPotentialStudents } = this.state;
    let sU = selectedPotentialStudents.find((el) => el.uid == user.uid);
    let selected = sU ? true : false;

    return (
      <Card
        className={selected ? classes.selectedCard : classes.card}
        style={{ height: "100%" }}
      >
        <CardActionArea
          style={{ height: "100%" }}
          onClick={() => {
            if (selected) this.removeSelected(user);
            else this.pushSelected(user);
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                src={user.photoURL}
                style={{
                  marginRight: 10,
                  width: 60,
                  height: 60,
                }}
              />
            }
            title={
              <Typography variant={"subtitle2"} align="left">
                {Authentication.formatName(user.name, user.lastName)}
              </Typography>
            }
            subheader={
              <React.Fragment>
                <Hidden smDown>
                  <Typography
                    variant={"body1"}
                    align="left"
                    color="textSecondary"
                  >
                    {user.email}
                  </Typography>
                </Hidden>
              </React.Fragment>
            }
          />
        </CardActionArea>
      </Card>
    );
  };
}

/**
 *
 * @param {import("utils/typedefs").AccountData} user
 */
function studentRenderer(props, user, selected = false) {
  const { classes } = props;

  return (
    <Card
      style={{ height: "100%" }}
      className={selected ? classes.selectedCard : classes.card}
    >
      <CardHeader
        avatar={<Avatar src={user.photoURL} />}
        title={
          <Typography variant={"subtitle2"} align="left">
            {Authentication.formatName(user.name, user.lastName)}
          </Typography>
        }
        subheader={
          <Hidden smDown>
            <Typography variant={"body1"} align="left" color="textSecondary">
              {user.email}
            </Typography>
          </Hidden>
        }
      />
    </Card>
  );
}

function textDataRender(text) {
  return (
    <Typography
      variant="caption"
      color="textSecondary"
      align="left"
      style={{
        fontWeight: "600",
        marginTop: "35px",
        fontSize: "24px",
        marginLeft: "10px",
        height: "75px",
        opacity: "0.5",
        width: "100%",
      }}
    >
      {text}
    </Typography>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default withStyles(styles)(SectionManagement);
