import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  isWidthDown,
  Typography,
  withStyles,
  withWidth,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";
import routes from "Pages/routes";
import React, { Component } from "react";
import { generatePath } from "react-router-dom";
import styles from "../Assignments/DeliveredStyles";
import AssignmentController from "Pages/Teacher/Classroom/Classroom/Assignments/AssignmentController";
import axios from "axios";
import { toast } from "material-react-toastify";
import { ArrowBack } from "@material-ui/icons";
import { FetchDataComponent } from "components/Fetching/FetchDataComponent";
import {
  loadingCards,
  textDataRender,
} from "components/Fetching/fetchingLoaders";
import GradeAssignment from "./GradeImprovePlan";
import DeliveredCard from "../Assignments/DeliveredCard";
import ImprovePlanController from "../../ImprovePlan/ImprovePlanController";
import GetData from "components/Fetching/GetData";
import NotDeliveredCard from "../Assignments/NotDeliveredCard";

let ac = new ImprovePlanController();

function parseDate(params) {
  return dayjs(params.getValue("dueTime")).format("DD/MM/YYYY");
}

class ImprovePlanScores extends Component {
  state = {
    loadingDelivered: true,
    pending: [],
    scored: [],
    notDelivered: [],
    gradingDialog: {
      data: [],
      editing: false,
      open: false,
      loading: false,
    },
    assignment: {},
  };

  constructor(props) {
    super(props);
    this.gradeRef = React.createRef();
    this.getDeliveredRef = React.createRef();
  }

  push = (link) => {
    this.props.history.push(generatePath(link, { ...this.props.match.params }));
  };

  componentWillUnmount() {
    ac.cancelAllTokens();
  }

  /**
   *
   * Obtiene la tarea

   */
  getAssignment = async () => {
    const { idAssignment } = this.props.match.params;
    let data = await ac.getPlan(idAssignment);
    return data;
  };

  startGetDelivered = () => {
    this.getDeliveredRef.current.get();
  };

  /**
   *
   * Obtiene las tareas entregadas
   */

  getDelivered = async () => {
    const { idAssignment } = this.props.match.params;
    const plans = await ac.getPlans(idAssignment);
    const notDelivered = await ac.getNotDelivered(idAssignment);
    let data = { scored: [], pending: [], notDelivered };
    plans.forEach((el) => {
      if (el.lastScoredURL === null) data.pending.push({ ...el });
      else data.scored.push({ ...el });
    });

    return data;
  };

  /**
   * Abre el dialogo de las calificaciones
   * @param {Boolean} val
   * @param {*} toEdit
   */
  openGradingDialog = (val, toEdit = null) => {
    let list = [];
    if (toEdit === null) list = [...this.state.pending];
    else list = [{ ...toEdit }];

    this.setState(
      {
        gradingDialog: {
          open: val,
          editing: toEdit !== null,
          data: list,
        },
      },
      () => {
        if (toEdit !== null) {
          this.gradeRef.current.getOldCriterias();
        }
      }
    );
  };

  /**
   *
   * @param {*} current
   * @param {*} score
   */
  handleGrade = (current, score) => {
    let gradingDialog = { ...this.state.gradingDialog };
    gradingDialog.data.splice(0, 1);
    if (gradingDialog.data.length === 0) gradingDialog.open = false;

    let pending = [...this.state.pending];
    let index = pending.findIndex((el) => el.uid === current.uid);
    let scored = [...this.state.scored];

    if (index === -1) {
      index = scored.findIndex((el) => el.uid === current.uid);
      scored[index].score = score;
    } else {
      scored.push({ ...pending[index], score });
      pending.splice(index, 1);
    }

    this.setState({ gradingDialog, pending, scored });
  };

  render() {
    return (
      <React.Fragment>
        <GetData
          onData={(data) => this.setState({ assignment: data })}
          request={this.getAssignment}
          errorMessage={`Error desconocido al obtener el plan de mejora`}
          disableSuccessNotification
        />

        <GetData
          onData={({ scored, pending, notDelivered }) =>
            this.setState({ scored, pending, notDelivered })
          }
          ref={this.getDeliveredRef}
          request={this.getDelivered}
          onError={(err) => console.log(err)}
          onLoading={(val) => this.setState({ loadingDelivered: val })}
          errorMessage={`Error desconocido al obtener planes de mejora entregados`}
          disableSuccessNotification
        />
        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => this.push(routes.teacher.scores)}>
            <ArrowBack />
          </IconButton>
          <Typography
            style={{ marginLeft: "20px" }}
            variant={"h5"}
            align="left"
          >
            Calificaciones
          </Typography>
        </div>
        <br />
        <Divider />
        <br />
        <div
          style={{
            height: 400,
            width: "100%",
            marginTop: "30px",
            textAlign: "left",
          }}
        >
          <Typography variant="body1" align="left" color="textSecondary">
            Entregados
          </Typography>

          <div style={{ marginTop: 20 }} />
          {this.state.pending.length > 0 && (
            <React.Fragment>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  this.openGradingDialog(true);
                }}
              >
                Calificar tareas
              </Button>
              <div style={{ marginTop: 20 }} />
            </React.Fragment>
          )}
          <Grid container spacing={2}>
            <FetchDataComponent
              loading={this.state.loadingDelivered}
              data={this.state.pending}
              error={null}
              customNoDataRenderer={() =>
                textDataRender("No hay tareas pendientes de calificar")
              }
              loadingRender={() => loadingCards("pending-")}
            >
              <React.Fragment>
                {this.state.pending.map((el) => {
                  return (
                    <Grid
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      key={el.uid}
                      //   className={classes.heroContent}
                    >
                      <DeliveredCard
                        deliver={el}
                        handleEdit={(el) => {
                          this.openGradingDialog(true, el);
                        }}
                      />
                    </Grid>
                  );
                })}

                <br />
              </React.Fragment>
            </FetchDataComponent>
          </Grid>

          <div style={{ marginTop: 20 }} />
          <Typography variant="body1" align="left" color="textSecondary">
            Calificados
          </Typography>

          <div style={{ marginTop: 20 }} />
          <Grid container spacing={2}>
            <FetchDataComponent
              loading={this.state.loadingDelivered}
              data={this.state.scored}
              error={null}
              customNoDataRenderer={() =>
                textDataRender("No hay tareas calificadas")
              }
              loadingRender={() => loadingCards("scored-")}
            >
              <React.Fragment>
                {this.state.scored.map((el) => {
                  return (
                    <Grid
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      key={el.uid}
                      //   className={classes.heroContent}
                    >
                      <DeliveredCard
                        deliver={el}
                        handleEdit={(el) => {
                          this.openGradingDialog(true, el);
                        }}
                      />
                    </Grid>
                  );
                })}

                <br />
              </React.Fragment>
            </FetchDataComponent>
          </Grid>

          <br />

          <div style={{ marginTop: 20 }} />
          <Typography variant="body1" align="left" color="textSecondary">
            Sin Entregar
          </Typography>

          <div style={{ marginTop: 20 }} />
          <div style={{ marginTop: 20 }} />
          <Grid container spacing={2}>
            <FetchDataComponent
              loading={this.state.loadingDelivered}
              data={this.state.notDelivered}
              error={null}
              customNoDataRenderer={() =>
                textDataRender("No hay tareas sin entregar")
              }
              loadingRender={() => loadingCards("not-delivered-")}
            >
              <React.Fragment>
                {this.state.notDelivered.map((el) => {
                  return (
                    <Grid
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      key={el.uid}
                      //   className={classes.heroContent}
                    >
                      <NotDeliveredCard
                        user={el}
                        idAssignment={this.props.match.params.idAssignment}
                        request={async (uid) => {
                          const { idAssignment } = this.props.match.params;
                          const data = await ac.uploadUserAssignment(
                            idAssignment,
                            uid
                          );
                          return data;
                        }}
                        onData={(data) => {
                          this.startGetDelivered();
                        }}
                      />
                    </Grid>
                  );
                })}

                <br />
              </React.Fragment>
            </FetchDataComponent>
          </Grid>
        </div>

        <div style={{ height: 50, marginTop: 20 }} />

        <GradeAssignment
          ref={this.gradeRef}
          open={this.state.gradingDialog.open}
          onClose={() => {
            this.openGradingDialog(false);
          }}
          data={this.state.gradingDialog.data}
          editing={this.state.gradingDialog.editing}
          assignment={this.state.assignment}
          onGrade={this.handleGrade}
          idAssignment={this.props.match.params.idAssignment}
          idClassroom={this.props.match.params.id}
        />
      </React.Fragment>
    );
  }
}

export default withWidth()(withStyles(styles)(ImprovePlanScores));
