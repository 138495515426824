import React, { Component } from "react";
import styles from "./Dashboard-styles";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  AccountCircle,
  Group,
  Book,
  Settings,
  BookOutlined,
  ClassRounded,
  SchoolRounded,
  BookRounded,
  ArchiveRounded,
  CalendarViewDayRounded,
} from "@material-ui/icons";
import { Authentication } from "Pages/Login/Authentication/authentication";
import AdminAppbar from "components/Appbar/AdminAppbar";
import DynamicNavigation from "components/DynamicNavigation/DynamicNavigation";

import {
  PeopleFill,
  CollectionFill,
  BookFill,
  Calendar2,
  Calendar,
  Calendar2DateFill,
} from "react-bootstrap-icons";
import routes from "Pages/routes";
const menuList = [
  {
    name: "Mis salones",
    icon: <BookRounded size={18} />,
    location: routes.student.classroomManagement,
  },
  {
    name: "Calendario",
    icon: <Calendar2DateFill size={18} />,
    location: routes.student.calendar,
  },
];

class StudentDashboard extends Component {
  state = {
    accountSettingsAnchor: null,
    drawerOpen: false,
  };

  constructor(props) {
    super(props);
  }

  handleUserChange = (user) => {
    if (user == null) return;
    this.setState({ photoURL: user.photoURL });
  };

  componentDidMount() {
    Authentication.instance.subuscribeToAuthStateChanged(this.handleUserChange);
  }

  componentWillUnmount() {
    Authentication.instance.unsubscribeFromAuthStateChanged(
      this.handleUserChange
    );
  }

  toggleDrawer = (overrideValue = null) => {
    let value = overrideValue !== null ? overrideValue : !this.state.drawerOpen;
    this.setState({ drawerOpen: value });
  };

  render() {
    return (
      <React.Fragment>
        <AdminAppbar
          history={this.props.history}
          handleDrawerOpen={(e) => this.toggleDrawer()}
        />
        {this.props.children}
        <DynamicNavigation
          toggle={this.toggleDrawer}
          open={this.state.drawerOpen}
          menuList={menuList}
          history={this.props.history}
        />
      </React.Fragment>
    );
  }
}

StudentDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(StudentDashboard);
