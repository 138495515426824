import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme) => ({
  bottomNavigation: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
  list: {
    width: 300,
  },

  fullList: {
    width: "auto",
  },
});
