import { createMuiTheme } from "@material-ui/core/styles";
//import Rubik from "typeface-rubik";
import color from "@material-ui/core/colors/amber";
import { esES } from "@material-ui/core/locale";

/**
 * #F1F2FA
 * #4C7AF1
 * #42CDAD
 * #F6BC41
 * **/

export const theme = createMuiTheme(
  {
    palette: {
      type: "light",
      primary: {
        light: "#82a2f5",
        main: "#4C7AF1",
        dark: "#3555a9",
        contrastText: "#fff",
      },
      secondary: {
        light: "#7bdcc6",
        main: "#42cdad",
        dark: "#2e9079",
        contrastText: "#000",
      },
      text: {
        primary: "rgba(0,0,0,0.87)",
        secondary: "rgba(0,0,0,0.54)",
        disabled: "rgba(0,0,0,0.38)",
        hint: "rgba(0,0,0,0.38)",
      },
      error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: "#fff",
      },
      divider: "rgba(0,0,0,0.12)",
      background: { default: "#fff", paper: "#fff" },
    },
    typography: {
      fontFamily: "Hind Siliguri",
      h1: {
        fontFamily: "Oswald",
        fontWeight: 400,
        fontSize: "4.5rem",
      },
      h2: {
        fontFamily: "Oswald",
        fontSize: "3.75rem",
      },
      h3: {
        fontFamily: "Oswald",
      },
      h4: {
        fontFamily: "Oswald",
      },
      h5: {
        fontFamily: "Oswald",
      },
      h6: {
        fontFamily: "Oswald",
      },
      button: {
        fontFamily: "Hind Siliguri",
        fontWeight: 600,
        letterSpacing: "0.06071em",
        fontSize: "1rem",
      },
      subtitle1: {
        fontFamily: "Hind Siliguri",
        fontSize: "1.5rem",
        fontWeight: 400,
      },
      subtitle2: {
        fontFamily: "Hind Siliguri",
        fontSize: "1.25rem",
        fontWeight: 300,
      },
      body1: {
        fontFamily: "Hind Siliguri",
        fontSize: "1rem",
        fontWeight: 300,
      },
      body2: {
        fontFamily: "Hind Siliguri",
        fontSize: "0.85rem",
        fontWeight: 300,
      },
    },
    shape: { borderRadius: 12 },
    shadows: [
      "none",
      "rgba(0, 0, 0, 0.06) 0px 2px 4px, rgba(0, 0, 0, 0.05) 0px 0.5px 1px",
      "rgba(0, 0, 0, 0.18) 0px 2px 4px, rgba(0, 0, 0, 0.15) 0px 0.5px 1px",
      "rgba(0, 0, 0, 0.06) 0px 19px 43px, rgba(0, 0, 0, 0.15) 0px 4px 11px",
      "rgba(0, 0, 0, 0.06) 0px 19px 43px, rgba(0, 0, 0, 0.15) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
      "rgba(0, 0, 0, 0.22) 0px 19px 43px, rgba(0, 0, 0, 0.18) 0px 4px 11px",
    ],
  },
  esES
);
