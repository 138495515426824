import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme) => ({
  container: {
    padding: 50,
  },
  containerMobile: { padding: 20 },
  header: {
    display: "flex",
    justifyContent: "start",
  },
  searchBar: {
    marginTop: "15px",
    marginBottom: "15px",
    background: "#fff",
    width: 400,
    maxWidth: "100%",
    marginRight: "15px",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  card: {},
  selectedCard: {
    background: theme.palette.action.focus,
  },
  saveButton: {
    marginTop: "15px",
    marginBottom: "15px",
  },
});
