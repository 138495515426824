import Axios from "axios";

export default (error, defaultMessage = "Error desconocido") => {
  let errorMessage = error.message;

  let unknown = defaultMessage;

  if (unknown == null) {
    if (Axios.isAxiosError(error)) {
      unknown = error.response.data.message;
    } else unknown = error.message;
  }
  switch (errorMessage) {
    default:
      return unknown;
  }
};
