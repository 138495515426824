import axios from "utils/API";

export default class RoleManagement {
  constructor() { }

  static async getRoles() {
    const res = await axios.get("/api/roles", {});
    return res.data;
  }

  static async approve(uid, role, sessionToken) { }
}
