import React, { Component } from "react";
import styles from "./Dashboard-styles";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  AccountCircle,
  Group,
  Book,
  Settings,
  BookOutlined,
  ClassRounded,
  SchoolRounded,
  NotificationImportant,
} from "@material-ui/icons";

import { Authentication } from "Pages/Login/Authentication/authentication";
import AdminAppbar from "components/Appbar/AdminAppbar";
import DynamicNavigation from "components/DynamicNavigation/DynamicNavigation";

import { PeopleFill, CollectionFill } from "react-bootstrap-icons";
import routes from "Pages/routes";
const menuList = [
  {
    name: "Notificaciones",
    icon: <NotificationImportant size={18} />,
    location: routes.system.notify,
  },
  {
    name: "Tareas",
    icon: <SchoolRounded size={18} />,
    location: routes.system.createAssignment,
  },
  // { name: "Secciones", icon: <CollectionFill />, location: "/" },
];
class AdminDashboard extends Component {
  state = {
    accountSettingsAnchor: null,
    drawerOpen: false,
  };

  constructor(props) {
    super(props);
  }

  handleUserChange = (user) => {
    if (user == null) return;
    this.setState({ photoURL: user.photoURL });
  };

  componentDidMount() {
    Authentication.instance.subuscribeToAuthStateChanged(this.handleUserChange);
  }

  componentWillUnmount() {
    Authentication.instance.unsubscribeFromAuthStateChanged(
      this.handleUserChange
    );
  }

  toggleDrawer = (overrideValue = null) => {
    let value = overrideValue !== null ? overrideValue : !this.state.drawerOpen;
    this.setState({ drawerOpen: value });
  };

  render() {
    return (
      <React.Fragment>
        <AdminAppbar
          history={this.props.history}
          handleDrawerOpen={(e) => this.toggleDrawer()}
        />
        {this.props.children}
        <DynamicNavigation
          toggle={this.toggleDrawer}
          open={this.state.drawerOpen}
          menuList={menuList}
          history={this.props.history}
        />
      </React.Fragment>
    );
  }
}

AdminDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(AdminDashboard);
