import React, { Component } from "react";
import ImagePreview from "./ImagePreview";
import PDFPreview from "./PDFPreview";

class FilePreview extends Component {
  state = {};

  /**
   *
   * @param {String} type
   */

  renderType = (type) => {
    const { file, name = "", open, onClose } = this.props;

    if (file === null) return <React.Fragment />;
    if (type === "image" || type.endsWith(".png") || type.endsWith(".jpg")) {
      return (
        <ImagePreview image={file} open={open} title={name} onClose={onClose} />
      );
    }
    if (type === "pdf" || type === "application/pdf" || type.endsWith(".pdf")) {
      return (
        <PDFPreview open={open} file={file} title={name} onClose={onClose} />
      );
    }

    return <React.Fragment />;
  };

  render() {
    let { type } = this.props;
    return this.renderType(type);
  }
}

export default FilePreview;
