import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { theme } from "./themes/lightTheme";
import PublicHome from "./Pages/PublicHome/PublicHome";
import Login from "./Pages/Login/Login";
import Authenticate from "Pages/Login/Authentication/Authenticate";
import UserManagement from "Pages/UserManagment/UserManagement";
import AccountSettings from "Pages/Settings/Account/AccountSettings";
import ClassroomsManagement from "Pages/Teacher/Classroom/Management/ClassroomManagement";
import Classroom from "Pages/Teacher/Classroom/Classroom/Classroom";
import "material-react-toastify/dist/ReactToastify.css";

/****FONTS****/
import "fontsource-hind-siliguri";
import "fontsource-oswald";

/****FIREBASE****/

import Authentication from "controller/Authentication";
import { initializeApp } from "firebase/app";
import { ProtectedRoute } from "components/ProtectedRoutes/ProtectedRoute";
import { ToastContainer } from "material-react-toastify";
import Routes from "Pages/routes";
import SectionManagement from "Pages/SectionsManagement/SectionManagement";
import routes from "Pages/routes";
import AssignmentsTable from "Pages/Teacher/Classroom/Classroom/Assignments/AssignmentsTable";
import Assignment from "Pages/Teacher/Classroom/Classroom/Assignments/Assignment";
import StudentClassroomManagement from "Pages/Student/Classroom/Management/StudentClassroomManagement";
import StudentClassroom from "Pages/Student/Classroom/Classroom/StudentClassroom";
import VerifyYourEmail from "Pages/Login/Authentication/VerifyYourEmail";
import NotFound from "Pages/NotFound/NotFound";
import StudentCalendar from "Pages/Student/Calendar/StudentCalendar";
import SystemNotifications from "Pages/System/Notifications/SystemNotifications";
import ClassroomNotFound from "Pages/Login/Errors/ClassroomNotFound";
import AssignmentNotFound from "Pages/Login/Errors/AssignmentNotFound";
import dayjs from "dayjs";
import dayjses from "dayjs/locale/es";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import ImproveNotFound from "Pages/Login/Errors/ImproveNotFound";
import SystemCreateAssignment from "Pages/System/TeacherAccess/CreateAssignment";
import SecretaryRoutes from "Pages/Secretary/SecretaryRoutes";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.locale("es");

const prodConfig = {
  apiKey: "AIzaSyDZEEYRYtyihtW0GosbYz9laIRzZyUFg-w",
  authDomain: "campus-ex.firebaseapp.com",
  databaseURL: "https://campus-ex.firebaseio.com",
  projectId: "campus-ex",
  storageBucket: "campus-ex.appspot.com",
  messagingSenderId: "858712719315",
  appId: "1:858712719315:web:2314fcd80ff8e3b24cb926",
  measurementId: "G-QK4ELPX3FN",
};

const devConfig = {
  apiKey: "AIzaSyBBxoiBTKNGsm5lyFJZ5rra_fvhU-M9pUU",
  authDomain: "campus-ex-dev.firebaseapp.com",
  projectId: "campus-ex-dev",
  storageBucket: "campus-ex-dev.appspot.com",
  messagingSenderId: "995813732466",
  appId: "1:995813732466:web:79edbb5120ded41b0d52eb",
};

const config =
  process.env.REACT_APP_USE_DEV === "true" ? devConfig : prodConfig;

initializeApp(config);
Authentication.initializeApp();

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/" exact component={PublicHome} />
            <Route path="/sign-in" exact component={Login} />
            <Route path="/verify" exact component={VerifyYourEmail} />
            <ProtectedRoute
              idRole="system"
              path={Routes.system.notify}
              exact
              component={SystemNotifications}
            />

            <ProtectedRoute
              idRole="system"
              exact
              path={routes.system.createAssignment}
              component={SystemCreateAssignment}
            />
            <ProtectedRoute
              idRole="admin"
              path={Routes.admin.userManagement}
              exact
              component={UserManagement}
            />
            <ProtectedRoute
              path="/settings/account"
              exact
              component={AccountSettings}
            />
            <ProtectedRoute
              path={Routes.admin.sectionsManagement}
              idRole="admin"
              exact
              component={SectionManagement}
            />
            <Route path="/authenticate" exact component={Authenticate} />
            <ProtectedRoute
              idRole="teacher"
              path={Routes.teacher.classroom}
              component={Classroom}
            />
            <ProtectedRoute
              idRole="teacher"
              path={Routes.teacher.classroomManagement}
              exact
              component={ClassroomsManagement}
            />
            <ProtectedRoute
              idRole="student"
              exact
              path={Routes.student.classroomManagement}
              component={StudentClassroomManagement}
            />
            <ProtectedRoute
              idRole="student"
              path={Routes.student.classroom}
              component={StudentClassroom}
            />
            <ProtectedRoute
              idRole="student"
              path={Routes.student.calendar}
              component={StudentCalendar}
            />
            <ProtectedRoute
              path={routes.errors.classroomNotFound}
              component={ClassroomNotFound}
            />
            <ProtectedRoute
              path={routes.errors.assignmentNotFound}
              component={AssignmentNotFound}
            />
            <ProtectedRoute
              path={routes.errors.improveNotFound}
              component={ImproveNotFound}
            />

            <ProtectedRoute
              idRole="secretary"
              path={Routes.secretary.index}
              component={SecretaryRoutes}
            />

            <Route path="*" component={NotFound} />
          </Switch>
        </Router>

        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    </div>
  );
}

export default App;
