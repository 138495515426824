import axios from "utils/API";
import Axios from "axios";
import dayjs from "dayjs";

export default class AssignmentController {
  constructor() {
    this.createAssignmentSource = Axios.CancelToken.source();
    this.totalScoreSource = Axios.CancelToken.source();
    this.totalStudentsSource = Axios.CancelToken.source();
    this.getAssignmetsSource = Axios.CancelToken.source();
    this.getAssignmentSource = Axios.CancelToken.source();
    this.editAssignmentSource = Axios.CancelToken.source();

    this.editCriteriasSource = Axios.CancelToken.source();
    this.deleteAssignmentSource = Axios.CancelToken.source();
    this.copyAssignmentSource = Axios.CancelToken.source();
  }

  getTotalStudents = async (classroomId) => {
    if (this.totalStudentsSource) this.totalStudentsSource.cancel();
    this.totalStudentsSource = Axios.CancelToken.source();
    const response = await axios.get(
      `api/teacher/classrooms/${classroomId}/total-students`,
      { cancelToken: this.totalStudentsSource.token }
    );
    return response.data;
  };

  getTotalScore = async (classroomId) => {
    if (this.totalScoreSource) this.totalScoreSource.cancel();
    this.totalScoreSource = Axios.CancelToken.source();
    const response = await axios.get(
      `api/teacher/classrooms/${classroomId}/total-score`,
      {
        cancelToken: this.totalScoreSource.token,
      }
    );
    return response.data;
  };

  copyAssignment = async (classroomId, idAssignment, classrooms) => {
    if (this.copyAssignmentSource) this.copyAssignmentSource.cancel();
    this.copyAssignmentSource = Axios.CancelToken.source();
    const response = await axios.post(
      `api/teacher/classrooms/${classroomId}/assignments/${idAssignment}/copy`,
      { classrooms },
      { cancelToken: this.copyAssignmentSource.token }
    );
    return response.data;
  };

  getDeliveredAssignments = async (idClassroom, idAssignment) => {
    if (this.totalScoreSource) this.totalScoreSource.cancel();
    this.totalScoreSource = Axios.CancelToken.source();
    const response = await axios.get(
      `api/teacher/classrooms/${idClassroom}/assignments/${idAssignment}/delivered`,
      {
        cancelToken: this.totalScoreSource.token,
      }
    );

    const data = { scored: [], pending: [] };

    response.data.forEach((el) => {
      if (el.score === null) data.pending.push({ ...el });
      else data.scored.push({ ...el });
    });
    return data;
  };

  createUserAssignment = async (idClassroom, idAssignment, idUser) => {
    if (this.createAssignmentSource) this.createAssignmentSource.cancel();
    this.createAssignmentSource = Axios.CancelToken.source();
    const response = await axios.post(
      `api/teacher/classrooms/${idClassroom}/assignments/${idAssignment}/create-assignment/${idUser}`,
      {
        cancelToken: this.createAssignmentSource.token,
      }
    );

    return response.data;
  };

  getNonDeliveredAssignments = async (idClassroom, idAssignment) => {
    if (this.getAssignmetsSource) this.getAssignmetsSource.cancel();
    this.getAssignmetsSource = Axios.CancelToken.source();
    const response = await axios.get(
      `api/teacher/classrooms/${idClassroom}/assignments/${idAssignment}/not-delivered`,
      {
        cancelToken: this.getAssignmetsSource.token,
      }
    );

    return response.data;
  };

  checkAssignment = async (classroomId, assignment) => {
    //Comprobar fecha de entrega
    if (!dayjs(new Date()).isBefore(assignment.dueTime))
      throw new Error("before_date");

    //Comprobar porcentaje total de los criterios
    // let criteriaTotal = assignment.criteria.reduce(
    //   (acum, value) => acum + parseInt(value.percentage),
    //   0
    // );
    // if (criteriaTotal != 100) throw new Error("criteria_total_score");

    //Comprobar score de todos los assignments
    // let totalScore = await this.getTotalScore(classroomId);

    // if (assignment.score > totalScore.remainingScore)
    // throw new Error(`assignment_total_score [${totalScore.remainingScore}]`);
  };

  createAssignment = async (classroomId, assignment) => {
    await this.checkAssignment(classroomId, assignment);
    assignment.dueTime = assignment.dueTime.toDate(); //Convertir a fecha de JS

    if (this.createAssignmentSource) this.createAssignmentSource.cancel();
    this.createAssignmentSource = Axios.CancelToken.source();

    const response = await axios.post(
      `api/teacher/classrooms/${classroomId}/assignments`,
      { assignment },
      { cancelToken: this.createAssignmentSource.token }
    );

    return response.data;
  };

  getAssignments = async (classroomId) => {
    if (this.getAssignmetsSource) this.getAssignmetsSource.cancel();
    this.getAssignmetsSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/teacher/classrooms/${classroomId}/assignments`,
      { cancelToken: this.getAssignmetsSource.token }
    );

    return response.data;
  };

  getGradeAssignments = async (classroomId) => {
    if (this.getAssignmetsSource) this.getAssignmetsSource.cancel();
    this.getAssignmetsSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/teacher/classrooms/${classroomId}/grade-assignments`,
      { cancelToken: this.getAssignmetsSource.token }
    );

    let { data } = response;
    // let data = [];
    data.sort((a, b) => {
      let diffA = a.delivered - a.scored;
      let diffB = b.delivered - b.scored;
      return diffB - diffA;
    });

    return data;
  };

  /**
   *
   * @param {String} classroomId
   * @param {Number} assignmentId
   */
  getAssignment = async (classroomId, assignmentId) => {
    if (this.getAssignmetSource) this.getAssignmetSource.cancel();
    this.getAssignmetSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/teacher/classrooms/${classroomId}/assignments/${assignmentId}`,
      { cancelToken: this.getAssignmetSource.token }
    );
    return response.data;
  };

  /**
   *
   * @param {*} classroomId
   * @param {*} assignmentId
   * @param {*} editValues
   */
  editAssignment = async (classroomId, assignmentId, editValues) => {
    if (editValues.dueTime) editValues.dueTime = editValues.dueTime.toDate(); //Convertir a fecha de JS

    if (this.editAssignmentSource) this.editAssignmentSource.cancel();
    this.editAssignmentSource = Axios.CancelToken.source();

    const response = await axios.put(
      `api/teacher/classrooms/${classroomId}/assignments/${assignmentId}`,
      { editValues },
      { cancelToken: this.editAssignmentSource.token }
    );
    return response.data;
  };

  editAssignmentCriterias = async (idClassroom, idAssignment, criterias) => {
    if (this.editCriteriasSource) this.editCriteriasSource.cancel();
    this.editCriteriasSource = Axios.CancelToken.source();

    const response = await axios.put(
      `api/teacher/classrooms/${idClassroom}/assignments/${idAssignment}/criterias`,
      { criterias },
      { cancelToken: this.editCriteriasSource.token }
    );

    return response.data;
  };

  /**
   *
   * @param {String} classroomId
   * @param {String} assignmentId
   */
  deleteAssignment = async (classroomId, assignmentId) => {
    if (this.deleteAssignmentSource) this.deleteAssignmentSource.cancel();
    this.deleteAssignmentSource = Axios.CancelToken.source();

    const response = await axios.delete(
      `api/teacher/classrooms/${classroomId}/assignments/${assignmentId}`,
      { cancelToken: this.deleteAssignmentSource.token }
    );

    return response.data;
  };

  cancelAllTokens() {
    if (this.createAssignmentSource) this.createAssignmentSource.cancel();
    if (this.totalScoreSource) this.totalScoreSource.cancel();
    if (this.getAssignmetsSource) this.getAssignmetsSource.cancel();
    if (this.getAssignmetSource) this.getAssignmetSource.cancel();
    if (this.editAssignmentSource) this.editAssignmentSource.cancel();
    if (this.deleteAssignmentSource) this.deleteAssignmentSource.cancel();
    if (this.totalStudentsSource) this.totalStudentsSource.cancel();
    if (this.editCriteriasSource) this.editCriteriasSource.cancel();
    if (this.copyAssignmentSource) this.copyAssignmentSource.cancel();
  }
}
