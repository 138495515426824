import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import { Typography, Grid, Box, Button, Hidden } from "@material-ui/core";
import Dashboard from "components/DashboardContainer/Dashboard";
import { Help, SearchRounded } from "@material-ui/icons";
import { Question, Search } from "react-bootstrap-icons";
import Authentication from "controller/Authentication";
import { generatePath } from "react-router";
import routes from "Pages/routes";

const styles = (theme) => ({
  content: {
    //backgroundColor: theme.palette.background.paper,
    position: "absolute",
    zIndex: -10000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
});

class AssignmentNotFound extends Component {
  state = {};
  render() {
    const { classes } = this.props;
    return (
      <Dashboard history={this.props.history}>
        <div className={classes.content}>
          <Box
            style={{
              padding: 30,
              margin: 0,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
            height="100%"
          >
            <Hidden smDown>
              <SearchRounded
                color="disabled"
                style={{ width: 200, height: 200 }}
              />
            </Hidden>
            <Typography
              variant="h1"
              color="textSecondary"
              style={{ fontSize: 90 }}
            >
              <Typography
                variant="h3"
                color="textSecondary"
                style={{ marginBottom: 20 }}
              >
                Tarea no encontrada
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Puede que la tarea haya sido eliminada o no tengas acceso a este
                salón
              </Typography>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={() => {
                  let params = new URLSearchParams(this.props.location.search);
                  let id = params.get("idClassroom");

                  if (id == null) {
                    this.props.history.push("/authenticate");
                    return;
                  }

                  if (Authentication.instance.user.idRole === "student")
                    this.props.history.push(
                      generatePath(routes.student.classroomAssignments, { id })
                    );
                  if (Authentication.instance.user.idRole === "teacher")
                    this.props.history.push(
                      generatePath(routes.teacher.classroomAssignments, { id })
                    );
                }}
              >
                Volver
              </Button>
            </Typography>

            <br />
          </Box>
        </div>
      </Dashboard>
    );
  }
}

export default withStyles(styles)(AssignmentNotFound);
