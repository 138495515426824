import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  isWidthDown,
  Typography,
  withStyles,
  withWidth,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";
import routes from "Pages/routes";
import React, { Component } from "react";
import { generatePath } from "react-router-dom";
import styles from "./DeliveredStyles";
import AssignmentController from "Pages/Teacher/Classroom/Classroom/Assignments/AssignmentController";
import axios from "axios";
import { toast } from "material-react-toastify";
import { ArrowBack } from "@material-ui/icons";
import { FetchDataComponent } from "components/Fetching/FetchDataComponent";
import {
  loadingCards,
  textDataRender,
} from "components/Fetching/fetchingLoaders";
import Authentication from "controller/Authentication";
import DeliveredCard from "./DeliveredCard";
import GradeAssignment from "../../Assignments/GradeAssignment/GradeAssignment";
import { Controller, TrophyFill } from "react-bootstrap-icons";
import NotDeliveredCard from "./NotDeliveredCard";

let ac = new AssignmentController();

function parseDate(params) {
  return dayjs(params.getValue("dueTime")).format("DD/MM/YYYY");
}

class Scores extends Component {
  state = {
    delivered: {
      loading: true,
      error: null,
    },
    pending: [],
    scored: [],
    notDelivered: [],
    gradingDialog: {
      data: [],
      editing: false,
      open: false,
      loading: false,
    },
    assignment: {},
  };

  constructor(props) {
    super(props);
    this.gradeRef = React.createRef();
  }

  push = (link) => {
    this.props.history.push(generatePath(link, { ...this.props.match.params }));
  };

  async componentDidMount() {
    const { id, idAssignment } = this.props.match.params;
    await this.getAssignment(id, idAssignment);
    this.getDelivered(id, idAssignment);
  }

  componentWillUnmount() {
    ac.cancelAllTokens();
  }

  /**
   *
   * Obtiene la tarea
   * @param {String} id
   * @param {String} idAssignment

   */
  getAssignment = async (id, idAssignment) => {
    try {
      let data = await ac.getAssignment(id, idAssignment);
      this.setState({ assignment: data });
    } catch (error) {
      if (axios.isCancel(error)) return;
      toast(`Error al momento de obtener la tarea`);
    }
  };

  /**
   *
   * Obtiene las tareas entregadas
   * @param {String} id
   * @param {String} idAssignment
   */

  getDelivered = async (id, idAssignment) => {
    try {
      let delivered = { loading: true, error: null };
      this.setState({ delivered, pending: [], scored: [] });
      const data = await ac.getDeliveredAssignments(id, idAssignment);
      const notDelivered = await ac.getNonDeliveredAssignments(
        id,
        idAssignment
      );
      let { pending, scored } = data;
      delivered.loading = false;
      this.setState({ delivered, pending, notDelivered, scored }, () => {});
    } catch (error) {
      if (axios.isCancel(error)) return;
      let delivered = { loading: false, error };
      this.setState({ delivered, pending: [], scored: [] });
      toast("Error al obtener las tareas", { type: "error" });
    }
  };

  /**
   * Abre el dialogo de las calificaciones
   * @param {Boolean} val
   * @param {*} toEdit
   */
  openGradingDialog = (val, toEdit = null) => {
    let list = [];
    if (toEdit === null) list = [...this.state.pending];
    else list = [{ ...toEdit }];

    this.setState(
      {
        gradingDialog: {
          open: val,
          editing: toEdit !== null,
          data: list,
        },
      },
      () => {
        if (toEdit !== null) {
          this.gradeRef.current.getOldCriterias();
        }
      }
    );
  };

  /**
   *
   * @param {*} current
   * @param {*} score
   */
  handleGrade = (current, score) => {
    let gradingDialog = { ...this.state.gradingDialog };
    gradingDialog.data.splice(0, 1);
    if (gradingDialog.data.length === 0) gradingDialog.open = false;

    let pending = [...this.state.pending];
    let index = pending.findIndex((el) => el.id === current.id);
    let scored = [...this.state.scored];

    if (index === -1) {
      index = scored.findIndex((el) => el.id === current.id);
      scored[index].score = score;
    } else {
      scored.push({ ...pending[index], score });
      pending.splice(index, 1);
    }

    this.setState({ gradingDialog, pending, scored });
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => this.push(routes.teacher.scores)}>
            <ArrowBack />
          </IconButton>
          <Typography
            style={{ marginLeft: "20px" }}
            variant={"h5"}
            align="left"
          >
            Calificaciones
          </Typography>
        </div>
        {/* 
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button color="primary" variant="outlined">
            Agregar Entrega
          </Button>
        </div> */}
        <br />
        <Divider />
        <br />
        <div
          style={{
            height: 400,
            width: "100%",
            marginTop: "30px",
            textAlign: "left",
          }}
        >
          <Typography variant="body1" align="left" color="textSecondary">
            Entregados
          </Typography>

          <div style={{ marginTop: 20 }} />
          {this.state.pending.length > 0 && (
            <React.Fragment>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  this.openGradingDialog(true);
                }}
              >
                Calificar tareas
              </Button>
              <div style={{ marginTop: 20 }} />
            </React.Fragment>
          )}
          <Grid container spacing={2}>
            <FetchDataComponent
              loading={this.state.delivered.loading}
              data={this.state.pending}
              error={this.state.delivered.error}
              customNoDataRenderer={() =>
                textDataRender("No hay tareas pendientes de calificar")
              }
              loadingRender={() => loadingCards("pending-")}
            >
              <React.Fragment>
                {this.state.pending.map((el) => {
                  return (
                    <Grid
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      key={el.uid}
                      //   className={classes.heroContent}
                    >
                      <DeliveredCard
                        deliver={el}
                        handleEdit={(el) => {
                          this.openGradingDialog(true, el);
                        }}
                      />
                    </Grid>
                  );
                })}

                <br />
              </React.Fragment>
            </FetchDataComponent>
          </Grid>

          <div style={{ marginTop: 20 }} />
          <Typography variant="body1" align="left" color="textSecondary">
            Calificados
          </Typography>

          <div style={{ marginTop: 20 }} />
          <Grid container spacing={2}>
            <FetchDataComponent
              loading={this.state.delivered.loading}
              data={this.state.scored}
              error={this.state.delivered.error}
              customNoDataRenderer={() =>
                textDataRender("No hay tareas calificadas")
              }
              loadingRender={() => loadingCards("scored-")}
            >
              <React.Fragment>
                {this.state.scored.map((el) => {
                  return (
                    <Grid
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      key={el.uid}
                      //   className={classes.heroContent}
                    >
                      <DeliveredCard
                        deliver={el}
                        handleEdit={(el) => {
                          this.openGradingDialog(true, el);
                        }}
                      />
                    </Grid>
                  );
                })}

                <br />
              </React.Fragment>
            </FetchDataComponent>
          </Grid>

          <br />

          <div style={{ marginTop: 20 }} />
          <Typography variant="body1" align="left" color="textSecondary">
            Sin Entregar
          </Typography>

          <div style={{ marginTop: 20 }} />
          <div style={{ marginTop: 20 }} />
          <Grid container spacing={2}>
            <FetchDataComponent
              loading={this.state.delivered.loading}
              data={this.state.notDelivered}
              error={this.state.delivered.error}
              customNoDataRenderer={() =>
                textDataRender("No hay tareas sin entregar")
              }
              loadingRender={() => loadingCards("not-delivered-")}
            >
              <React.Fragment>
                {this.state.notDelivered.map((el) => {
                  return (
                    <Grid
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      key={el.uid}
                      //   className={classes.heroContent}
                    >
                      <NotDeliveredCard
                        user={el}
                        idClassroom={this.props.match.params.id}
                        idAssignment={this.props.match.params.idAssignment}
                        request={async (uid) => {
                          const { id, idAssignment } = this.props.match.params;

                          const data = await ac.createUserAssignment(
                            id,
                            idAssignment,
                            uid
                          );
                          return data;
                        }}
                        onData={(data) => {
                          const { id, idAssignment } = this.props.match.params;
                          this.getDelivered(id, idAssignment);
                        }}
                      />
                    </Grid>
                  );
                })}

                <br />
              </React.Fragment>
            </FetchDataComponent>
          </Grid>
        </div>
        <div style={{ height: 50, marginTop: 20 }} />

        <GradeAssignment
          ref={this.gradeRef}
          open={this.state.gradingDialog.open}
          onClose={() => {
            this.openGradingDialog(false);
          }}
          data={this.state.gradingDialog.data}
          editing={this.state.gradingDialog.editing}
          assignment={this.state.assignment}
          onGrade={this.handleGrade}
          idAssignment={this.props.match.params.idAssignment}
          idClassroom={this.props.match.params.id}
        />
      </React.Fragment>
    );
  }
}

export default withWidth()(withStyles(styles)(Scores));
