export const MAX_PERIODS = 4;

export const getPeriodName = (id) => {
  switch (id) {
    case 0:
      return "1era. Unidad";
    case 1:
      return "2da. Unidad";
    case 2:
      return "3era. Unidad";
    case 3:
      return "4ta. Unidad";
    case 4:
      return "5ta. Unidad";
  }
};

/**
 *
 * @returns {Array<Number>}
 */
export const periods = () => {
  let p = [];
  for (let index = 0; index < MAX_PERIODS; index++) {
    p.push(index);
  }

  return p;
};
