import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  Bookmark,
  MoreVertOutlined,
  PinDrop,
  Videocam,
} from "@material-ui/icons";
import axios from "axios";
import DownloadButton from "components/DownloadButton/DownloadButton";
import { toast } from "material-react-toastify";
import React, { Component } from "react";
import { Download } from "react-bootstrap-icons";
import ReactPlayer from "react-player";
import { Pi } from "react-bootstrap-icons";

import Controller from "./PostsController";
import { IOSView } from "react-device-detect";
import ImagePreview from "components/FilePreview/ImagePreview";

let controller = new Controller();

class PostCard extends Component {
  state = { menuAnchor: null, transaction: false, openPreviewImage: false };

  removePost = async () => {
    try {
      this.setState({ transaction: true });
      await controller.removePost(this.props.post.id);
      toast(`Publicación eliminada`, { type: "success" });
      if (this.props.onDelete) this.props.onDelete(this.props.post);
    } catch (error) {
      if (axios.isCancel(error)) return;
      toast(`Error al eliminar publicación`, { type: "error" });
    } finally {
      this.setState({ transaction: false });
    }
  };

  pinPost = async (val) => {
    try {
      this.setState({ transaction: true });
      await controller.pin(
        "idClassroom",
        this.props.post.id,
        !this.props.post.pinned
      );
      let message = val ? `Publicación Fijada` : `Publicación Desfijada`;
      toast(message, { type: "success" });
      if (this.props.onPin) this.props.onPin(this.props.post, val);
    } catch (error) {
      if (axios.isCancel(error)) return;
      toast(`Error al fijar publicación`, { type: "error" });
    } finally {
      this.setState({ transaction: false });
    }
  };

  renderReunion = (url) => {
    return (
      <div>
        <Button
          color="primary"
          variant="text"
          endIcon={<Videocam />}
          href={url}
          target="_blank"
        >
          Unirse a la Reunión
        </Button>
      </div>
    );
  };

  renderVideo = (url) => {
    return (
      <div style={{ padding: 10, marginTop: 30 }}>
        <ReactPlayer url={url} width="100%" />
      </div>
    );
  };

  closePreviewImage = () => {
    this.setState({ openPreviewImage: false });
  };
  openPreviewImage = () => {
    this.setState({ openPreviewImage: true });
  };

  renderImage = (url) => {
    return (
      <React.Fragment>
        <div style={{ padding: 10, marginTop: 30 }}>
          <img
            onClick={this.openPreviewImage}
            style={{ borderRadius: 20, maxWidth: 800, cursor: "pointer" }}
            src={url}
            width="100%"
          />
        </div>
        <ImagePreview
          image={url}
          open={this.state.openPreviewImage}
          title={this.props.post.title}
          onClose={this.closePreviewImage}
        />
      </React.Fragment>
    );
  };

  renderDownload = (url) => {
    return (
      <div>
        <DownloadButton
          color="primary"
          variant="text"
          endIcon={<Download />}
          fileURL={url}
          label={"Ver Documento"}
        />
      </div>
    );
  };

  handleOpenMenu = (e) => {
    if (this.state.transaction) return;
    this.setState({ menuAnchor: e.currentTarget });
  };
  handleCloseMenu = () => {
    this.setState({ menuAnchor: null });
  };

  renderMenu = () => {
    return (
      <Menu
        elevation={3}
        id="settings"
        anchorEl={this.state.menuAnchor}
        open={Boolean(this.state.menuAnchor)}
        onClose={this.handleCloseMenu}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        getContentAnchorEl={null}
      >
        <MenuItem
          onClick={() => {
            this.pinPost(!this.props.post.pinned);
            this.handleCloseMenu();
          }}
        >
          <Typography variant={"body1"}>
            {this.props.post.pinned
              ? `Desfijar Publicación`
              : `Fijar Publicación`}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.props.onCopy(this.props.post.id);
            this.handleCloseMenu();
          }}
        >
          <Typography variant={"body1"}>Copiar a otros salones</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.removePost();
            this.handleCloseMenu();
          }}
        >
          <Typography variant={"body1"}>Eliminar</Typography>
        </MenuItem>
      </Menu>
    );
  };

  renderHeader = (title, pinned) => {
    if (pinned)
      return (
        <React.Fragment>
          <div style={{ display: "flex", width: "100%", marginBottom: 10 }}>
            <Bookmark color="primary" />
          </div>
          <Typography variant="subtitle2" align="left">
            {title}
          </Typography>
        </React.Fragment>
      );
    return (
      <Typography variant="subtitle2" align="left">
        {title}
      </Typography>
    );
  };

  render() {
    let {
      type = "text",
      title = "Titulo",
      content = "Lorem ipsum indolorem ada ked aldkae ak dkal",
      creationTime,
      url,
      period,
      pinned,
    } = this.props.post;

    return (
      <div style={{ maxWidth: 800, width: "100%" }}>
        <Card variant="outlined">
          {this.state.transaction && <LinearProgress />}
          <CardHeader
            title={this.renderHeader(title, pinned)}
            action={
              <IconButton aria-label="settings" onClick={this.handleOpenMenu}>
                <MoreVertOutlined />
              </IconButton>
            }
          />
          <CardContent>
            {content && (
              <Typography variant="body1" align="left">
                {content}
              </Typography>
            )}

            {type === "image" && this.renderImage(url)}
            {type === "video" && this.renderVideo(url)}
          </CardContent>
          <CardActions>
            {" "}
            {type === "meeting" && this.renderReunion(url)}
            {type === "files" && this.renderDownload(url)}
          </CardActions>
        </Card>
        {this.renderMenu()}
      </div>
    );
  }
}

export default PostCard;
