import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme) => ({
  searchBar: {
    marginTop: "15px",
    marginBottom: "15px",
    background: "#fff",
    width: 400,
    maxWidth: "100%",
    marginRight: "15px",
  },
});
