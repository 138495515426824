import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { Bookmark, MoreVertOutlined, Videocam } from "@material-ui/icons";
import axios from "axios";
import DownloadButton from "components/DownloadButton/DownloadButton";
import ImagePreview from "components/FilePreview/ImagePreview";
import { toast } from "material-react-toastify";
import React, { Component } from "react";
import { Download } from "react-bootstrap-icons";
import ReactPlayer from "react-player";

import Controller from "./StudentPostController";

class PostCard extends Component {
  state = {
    openPreviewImage: false,
  };

  closePreviewImage = () => {
    this.setState({ openPreviewImage: false });
  };
  openPreviewImage = () => {
    this.setState({ openPreviewImage: true });
  };
  renderReunion = (url) => {
    return (
      <div>
        <Button
          color="primary"
          variant="text"
          endIcon={<Videocam />}
          href={url}
          target="_blank"
        >
          Unirse a la Reunión
        </Button>
      </div>
    );
  };

  renderVideo = (url) => {
    return (
      <div style={{ padding: 10, marginTop: 30 }}>
        <ReactPlayer url={url} width="100%" />
      </div>
    );
  };

  renderImage = (url) => {
    return (
      <React.Fragment>
        <div style={{ padding: 10, marginTop: 30 }}>
          <img
            onClick={this.openPreviewImage}
            style={{ borderRadius: 20, maxWidth: 800, cursor: "pointer" }}
            src={url}
            width="100%"
          />
        </div>
        <ImagePreview
          title={this.props.post.title}
          image={url}
          open={this.state.openPreviewImage}
          onClose={this.closePreviewImage}
        />
      </React.Fragment>
    );
  };

  renderDownload = (url) => {
    return (
      <div>
        <DownloadButton
          color="primary"
          variant="text"
          endIcon={<Download />}
          fileURL={url}
          label={"Ver Documento"}
        />
      </div>
    );
  };

  renderHeader = (title, pinned) => {
    if (pinned)
      return (
        <React.Fragment>
          <div style={{ display: "flex", width: "100%", marginBottom: 10 }}>
            <Bookmark color="primary" />
          </div>
          <Typography variant="subtitle2" align="left">
            {title}
          </Typography>
        </React.Fragment>
      );
    return (
      <Typography variant="subtitle2" align="left">
        {title}
      </Typography>
    );
  };

  render() {
    let {
      type = "text",
      title = "Titulo",
      content = "Lorem ipsum indolorem ada ked aldkae ak dkal",
      creationTime,
      url,
      period,
      pinned,
    } = this.props.post;

    return (
      <div style={{ maxWidth: 800, width: "100%" }}>
        <Card variant="outlined">
          <CardHeader title={this.renderHeader(title, pinned)} />
          <CardContent>
            <Typography variant="body1" align="left">
              {content}
            </Typography>

            {type === "image" && this.renderImage(url)}
            {type === "video" && this.renderVideo(url)}
          </CardContent>
          <CardActions>
            {type === "meeting" && this.renderReunion(url)}
            {type === "files" && this.renderDownload(url)}
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default PostCard;
