import axios from "utils/API";

export default class School {
  constructor() { }

  static async getSections(searchName) {
    let filter = "";
    if (searchName != null) filter = `searchName=${searchName}`;
    const sections = await axios.get(`api/sections?${filter}`);
    return sections.data;
  }
}
