import React, { Component } from "react";
import styles from "./UserManagement-styles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  TextField,
  InputAdornment,
  Dialog,
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  LinearProgress,
  Avatar,
  Divider,
} from "@material-ui/core";
import {
  Search as SearchIcon,
  TransferWithinAStationRounded,
} from "@material-ui/icons";
import AdminDashboard from "components/DashboardContainer/AdminDashboard";
import axios from "utils/API";
import UserManagementCard from "./UserManagementCard";
import Authentication from "controller/Authentication";
import Axios from "axios";
import RoleManagement from "controller/Roles";
import School from "controller/School";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { toast } from "material-react-toastify";
import UserManagementController from "./UserManagementController";
import { FetchDataComponent } from "components/Fetching/FetchDataComponent";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import SearchInput from "components/SearchInput/SearchInput";

const Controller = new UserManagementController();

function textDataRender(text) {
  return (
    <Typography
      variant="caption"
      color="textSecondary"
      style={{
        fontWeight: "600",
        marginTop: "35px",
        fontSize: "24px",
        marginLeft: "30px",
        height: "75px",
        opacity: "0.5",
      }}
    >
      {text}
    </Typography>
  );
}

function loadingCards(prefix) {
  let style = { width: "100%", height: "100%", minHeight: 110 };
  return (
    <React.Fragment>
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_01"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_02"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
    </React.Fragment>
  );
}

class UserManagement extends Component {
  state = {
    user: Authentication.emptyUser,

    approved: {
      data: [],
      loading: false,
      error: null,
      currentPage: 0,
      reachedMax: false,
    },
    pending: {
      data: [],
      loading: false,
      error: null,
      currentPage: 0,
      reachedMax: false,
    },
    roles: [],
    sections: [],
    textFilter: "",
    rolesDialogOpen: false,
    currentOpenUser: {},
    studentEditDialogOpen: false,
    currentSection: {},
    currentSectionLoading: false,
    transactions: [],
  };

  constructor() {
    super();
    this.requestRoles = this.requestRoles.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
  }

  pushTransaction = (uid) => {
    this.setState((old) => ({ transactions: [...old.transactions, uid] }));
  };

  removeTransaction = (uid) => {
    this.setState((old) => {
      let arr = [...old.transactions];
      arr.splice(
        arr.findIndex((e) => e === uid),
        1
      );

      return { transactions: arr };
    });
  };

  handleOpenApproveMenu = (user) => {
    this.setState({ currentOpenUser: user });
    this.handleDialogOpen("rolesDialogOpen", true);
  };

  handleOpenStudentMenu = async (user) => {
    this.setState({ currentOpenUser: user });
    this.handleDialogOpen("studentEditDialogOpen", true);
    this.getSection(user);
  };

  handleDialogOpen = (name, open, clear = false) => {
    this.setState({ [name]: open });
    if (clear) this.setState({ currentOpenUser: {}, currentSection: null });
  };

  handleSearch = async (text) => {
    this.setState({ textFilter: text }, this.requestUsers);
  };

  handleTextChange = (e, name, callback) => {
    const { value } = e.target;
    this.setState({ [name]: value }, callback);
  };

  handleUserChange = (user) => {
    this.setState({ user });
    if (user == null) return;
    this.requestUsers();
    this.requestRoles();
    this.requestSections();
  };

  componentDidMount() {
    Authentication.instance.subscribeToUserChange(this.handleUserChange);
  }

  componentWillUnmount() {
    Authentication.instance.unsubscribeFromUserChange(this.handleUserChange);
    Controller.cancelAllTokens();
  }

  async requestRoles() {
    let roles = await RoleManagement.getRoles();
    roles = roles.filter((el) => el.id !== "system");
    this.setState({ roles });
  }

  async requestSections() {
    const sections = await School.getSections();
    this.setState({ sections });
  }

  requestUsers = () => {
    const { textFilter, approved, pending } = this.state;
    this.setState({
      approved: { ...approved, loading: true },
      pending: { ...pending, loading: true },
    });

    Controller.requestAllUsers(textFilter)
      .then((data) => {
        let d = {
          loading: false,
          error: null,
          currentPage: 0,
        };

        this.setState({
          approved: {
            data: data.users,
            ...d,
            reachedMax: data.users.length < 10,
          },
          pending: {
            data: data.pending,
            ...d,
            reachedMax: data.pending.length < 10,
          },
        });
      })
      .catch((error) => {
        if (Axios.isCancel(error)) return;
        toast("Error al obtener los usuarios", { type: "error" });
        this.setState({
          approved: { loading: false, data: [], error: error, currentPage: 0 },
          pending: { loading: false, data: [], error: error, currentPage: 0 },
        });
      });
  };

  requestMore = async (pending) => {
    const { textFilter } = this.state;
    let info = pending ? { ...this.state.pending } : { ...this.state.approved };
    let page = info.currentPage;

    try {
      let data = await Controller.getUsers(textFilter, page + 1, pending);
      let reachedMax = data.length < 10;

      let newState = {
        loading: false,
        error: null,
        currentPage: page + 1,
        data: [...info.data, ...data],
        reachedMax,
      };
      let state = pending ? { pending: newState } : { approved: newState };
      this.setState(state);
    } catch (error) {}
  };

  assignSection = async (section) => {
    let user = this.state.currentOpenUser;
    this.pushTransaction(user.uid);
    this.handleDialogOpen("studentEditDialogOpen", false, true);
    const response = await axios.put(
      `api/admin/user-management/users/${user.uid}/section`,
      {
        idSection: section.idSection,
      }
    );

    if (response.status === 200 || response.status === 201) {
      toast(
        `Se ha establecido ${section.name} para 
        ${Authentication.formatName(user.name, user.lastName)} `,
        { type: "success" }
      );
    }

    this.removeTransaction(user.uid);
  };

  getSection = async (user) => {
    this.setState({ currentSectionLoading: true });
    const response = await axios.get(
      `api/admin/user-management/users/${user.uid}/section`
    );

    this.setState({
      currentSection: response.data,
      currentSectionLoading: false,
    });
  };

  deleteUser = async (user) => {
    try {
      await axios.delete(`api/admin/user-management/users/${user.uid}`);

      let users = [...this.state.pending.data];
      let index = users.findIndex((e) => e.uid === user.uid);
      if (index !== -1) {
        users.splice(index, 1);
        let pending = this.state.pending;
        pending.data = users;
        this.setState({ pending });
      }

      toast(`Usuario eliminado`, { type: "success" });
    } catch (error) {
      toast(`Error al eliminar el usuario`, { type: "error" });
    }
  };

  async handleApprove(user, idRole) {
    this.pushTransaction(user.uid);
    this.handleDialogOpen("rolesDialogOpen", false, true);

    await axios.put(`api/admin/user-management/users/${user.uid}/approve`, {
      idRole: idRole,
    });

    let roleName = this.state.roles.find((role) => role.id === idRole).text;

    toast(
      `Se ha concedido rol ${roleName} a
      ${Authentication.formatName(user.name, user.lastName)} `,
      { type: "success" }
    );

    if (user.approved == 0) this.requestUsers();
    else {
      let users = [...this.state.approved.data];
      let index = users.findIndex((e) => e.uid == user.uid);
      if (index != -1) {
        let u = { ...users[index] };
        u.role = roleName;
        u.idRole = idRole;
        users[index] = u;
        this.setState((old) => ({
          approved: { ...old.approved, data: users },
        }));
      }
    }

    this.setState({ currentOpenUser: {} });
    this.removeTransaction(user.uid);
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <AdminDashboard history={this.props.history}>
          <main
            className={isMobile ? classes.containerMobile : classes.container}
          >
            <div className={classes.header}>
              <Typography variant="h4" align="left">
                Usuarios
              </Typography>
              <br />
              <SearchInput
                className={classes.searchBar}
                variant="outlined"
                size="small"
                margin="dense"
                placeholder="Buscar usuarios"
                autoFocus={!isMobile}
                onSearch={this.handleSearch}
              />
            </div>
            <br />
            <Typography variant="body1" align="left" color="textSecondary">
              Aprobados
            </Typography>
            <br />
            <Grid container spacing={2} className={classes.heroContainer}>
              <FetchDataComponent
                loading={this.state.approved.loading}
                data={this.state.approved.data}
                error={this.state.approved.error}
                customNoDataRenderer={() =>
                  textDataRender("No se encontraron usuarios")
                }
                loadingRender={() => loadingCards("approved")}
              >
                <React.Fragment>
                  {this.state.approved.data.map((el) => {
                    if (
                      this.state.user == null ||
                      this.state.user.uid == null ||
                      el.uid === this.state.user.uid
                    )
                      return;
                    return (
                      <Grid
                        item
                        xl={3}
                        lg={4}
                        md={6}
                        sm={12}
                        xs={12}
                        key={el.uid}
                        className={classes.heroContent}
                      >
                        <UserManagementCard
                          user={el}
                          hasTransaction={this.state.transactions.includes(
                            el.uid
                          )}
                          handleDelete={this.deleteUser}
                          handleApprove={this.handleApprove}
                          handleOpenApproveMenu={this.handleOpenApproveMenu}
                          handleOpenStudentMenu={this.handleOpenStudentMenu}
                        />
                      </Grid>
                    );
                  })}
                </React.Fragment>
              </FetchDataComponent>
            </Grid>

            <div style={{ display: "flex", marginTop: "20px" }}>
              {this.state.approved.data.length > 0 &&
                !this.state.approved.reachedMax && (
                  <Button
                    color="primary"
                    onClick={() => this.requestMore(false)}
                  >
                    Ver más
                  </Button>
                )}
            </div>

            <br />

            {/* <Pagination count={10} color="primary" />
          <br /> */}
            <Typography variant="body1" align="left" color="textSecondary">
              Pendientes de Aprobación
            </Typography>
            <br />

            <Grid container spacing={2} className={classes.heroContainer}>
              <FetchDataComponent
                loading={this.state.pending.loading}
                data={this.state.pending.data}
                error={this.state.pending.error}
                customNoDataRenderer={() =>
                  textDataRender("No hay usuarios pendientes de aprobación")
                }
                loadingRender={() => loadingCards("approved")}
              >
                <React.Fragment>
                  {this.state.pending.data.map((el) => {
                    return (
                      <Grid
                        item
                        xl={3}
                        lg={4}
                        md={6}
                        sm={12}
                        xs={12}
                        key={el.uid}
                        className={classes.heroContent}
                      >
                        <UserManagementCard
                          user={el}
                          handleDelete={this.deleteUser}
                          handleApprove={this.handleApprove}
                          handleOpenApproveMenu={this.handleOpenApproveMenu}
                          handleOpenStudentMenu={this.handleOpenStudentMenu}
                        />
                      </Grid>
                    );
                  })}

                  <br />
                </React.Fragment>
              </FetchDataComponent>
            </Grid>
            <div style={{ display: "flex", marginTop: "20px" }}>
              {this.state.pending.data.length > 0 &&
                !this.state.pending.reachedMax && (
                  <Button
                    color="primary"
                    onClick={() => this.requestMore(true)}
                  >
                    Ver más
                  </Button>
                )}
            </div>
            {/* <Pagination count={10} color="primary" /> */}
          </main>

          <Dialog
            open={this.state.rolesDialogOpen}
            maxWidth={"sm"}
            fullWidth
            onClose={() =>
              this.handleDialogOpen("rolesDialogOpen", false, true)
            }
          >
            <DialogContent>
              {this.renderUser(this.props, this.state.currentOpenUser)}
              <Divider />
              <br />
              <List>
                {this.state.roles.map((role) => (
                  <ListItem
                    key={role.id}
                    button
                    onClick={() =>
                      this.handleApprove(this.state.currentOpenUser, role.id)
                    }
                  >
                    <ListItemText primary={role.text} />
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.handleDialogOpen("rolesDialogOpen", false);
                }}
                color={"primary"}
              >
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.studentEditDialogOpen}
            maxWidth={"sm"}
            fullWidth
            onClose={() =>
              this.handleDialogOpen("studentEditDialogOpen", false, true)
            }
          >
            <DialogContent>
              {this.renderUser(this.props, this.state.currentOpenUser)}
              <Divider />
              <br />
              <div style={{ display: "flex", alignItems: "center" }}>
                <Autocomplete
                  fullWidth
                  disabled={this.state.currentSectionLoading}
                  loading={this.state.currentSectionLoading}
                  id="combo-box-section"
                  options={this.state.sections}
                  getOptionLabel={(option) => option.name}
                  style={{ marginTop: "10px" }}
                  onChange={(e, newVal) => {
                    this.setState({ currentSection: newVal });
                  }}
                  value={this.state.currentSection}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sección"
                      variant="outlined"
                      autoFocus={!isMobile}
                    />
                  )}
                />

                {this.state.currentSectionLoading && (
                  <CircularProgress style={{ marginLeft: "20px" }} />
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.handleDialogOpen("studentEditDialogOpen", false);
                }}
                color={"primary"}
              >
                Cancelar
              </Button>
              <Button
                onClick={() => this.assignSection(this.state.currentSection)}
                color={"primary"}
                disabled={
                  this.state.currentSection == null ||
                  this.state.currentSection == {} ||
                  this.state.currentSection.idSection == null
                }
              >
                Guardar
              </Button>
            </DialogActions>
          </Dialog>
        </AdminDashboard>
      </React.Fragment>
    );
  }

  /**
   *
   * @param {*} props
   * @param {import("utils/typedefs").AccountData} user
   */
  renderUser = (props, user) => {
    const { classes } = props;
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <Avatar
            src={user.photoURL}
            style={{
              margin: 10,
              width: 96,
              height: 96,
            }}
          />
          <div style={{ margin: 10 }}>
            <Typography variant="subtitle1">
              {Authentication.formatName(user.name, user.lastName)}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {user.role}
            </Typography>
          </div>
        </div>
      </React.Fragment>
    );
  };
}

UserManagement.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(UserManagement);
