import { Button, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { toast } from "material-react-toastify";
import Authentication from "controller/Authentication";

class VerifyYourEmail extends Component {
  state = {
    sendingEmail: false,
  };

  handleUserChange = (user) => {
    if (!user) {
      this.props.history.push("/sign-in");
      return;
    }
    if (user.isVerified) {
      this.verified();
      return;
    }
  };

  componentDidMount() {
    Authentication.instance.subscribeToUserChange(this.handleUserChange);
  }
  componentWillUnmount() {
    Authentication.instance.unsubscribeFromUserChange(this.handleUserChange);
  }

  sendEmail = async () => {
    try {
      await sendEmailVerification(getAuth().currentUser);
      toast(
        "Se te ha enviado un correo electrónico con un link de verificación",
        { type: "success" }
      );
    } catch (error) {
      toast("Error al enviar correo de verificación", { type: "error" });
    }
  };

  verified = async () => {
    //if (firebase.auth().currentUser.emailVerified)
    this.props.history.push("/authenticate");
    //    else toast("Email no ha sido verificado", { type: "error" });
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: 30,
            paddingRight: 30,
            position: "absolute",
            top: 0,
            height: "100%",
            width: "100%",
          }}
        >
          <Typography variant="subtitle2">
            Debes verificar tu correo electrónico antes de ingresar a la
            plataforma
          </Typography>
          <br />
          <Button
            onClick={this.sendEmail}
            color="primary"
            variant="contained"
            disabled={this.state.sendingEmail}
          >
            Enviar verificación
          </Button>
          <br />

          <Button
            onClick={() => {
              this.verified();
            }}
            color="primary"
            variant="outlined"
            disabled={this.state.sendingEmail}
          >
            Ya lo he verificado! ingresar
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default VerifyYourEmail;
