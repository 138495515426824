import {
  Button,
  Divider,
  Typography,
  withStyles,
  withWidth,
} from "@material-ui/core";
import { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import { DataGrid } from "@material-ui/data-grid";
import React, { Component } from "react";
import styles from "./AssignmentsTableStyles";
import { Add as AddIcon, ShowChart } from "@material-ui/icons";
import Controller from "./AssignmentController";
import Axios from "axios";
import { toast } from "material-react-toastify";
import dayjs from "dayjs";
import Classroom from "../Classroom";
import { generatePath } from "react-router-dom";
import routes from "Pages/routes";
import AssignmentCreation from "./AssignmentCreation";
import ImproveController from "../ImprovePlan/ImprovePlanController";
import GetData from "components/Fetching/GetData";
import DataTable from "components/Data/Table/DataTable";

const controller = new Controller();
const improveController = new ImproveController();

/**
 *
 * @param {import("@material-ui/data-grid").ValueGetterParams} params
 */

function parseDate(params) {
  return dayjs(params.getValue("dueTime")).format("DD/MM/YYYY");
}

class AssignmentsTable extends Component {
  state = {
    colums: {},
    creatingAssignment: false,
    //currentWidth: "",
    assignments: {
      loading: true,
      data: [],
      error: null,
    },

    loadingImprove: true,
    improve: [],
    addingNew: false,
  };

  constructor() {
    super();
    this.dataRef = React.createRef();
  }

  getAssignments = async (id) => {
    try {
      this.setState({ assignments: { data: [], loading: true, error: null } });
      const data = await controller.getAssignments(id);
      let assignments = { loading: false, data, error: null };
      this.setState({ assignments }, () => {});
    } catch (error) {
      if (Axios.isCancel(error)) return;
      let assignments = { loading: false, data: [], error: error };
      this.setState({ assignments }, () => {});
      toast("Error al obtener las tareas", { type: "error" });
    }
  };

  handleLoading = (name, val) => {
    this.setState({ [name]: val });
  };

  getImprovePlans = async () => {
    const { id } = this.props.match.params;
    const data = await improveController.getImproveAssignments(id);
    return data;
  };

  componentDidMount() {
    this.handleClassroomId(this.props.match.params.id);
    //this.props.onSetTabIndex(1);
  }

  componentWillUnmount() {
    improveController.cancelAllTokens();
    controller.cancelAllTokens();
  }

  getImproveColumns = () => {
    return [
      // { field: "userName", headerName: "Estudiante", width: 200 },
      {
        field: "name",
        headerName: "Tarea",
        width: 600,
        flex: 1,
      },
      {
        field: "dueTime",
        headerName: "Fecha",
        width: 200,
        valueFormatter: parseDate,
      },
    ];
  };

  getColumns = () => {
    if (isWidthDown("md", this.props.width)) {
      return [
        { field: "name", headerName: "Nombre", width: 200, flex: 1 },
        {
          field: "dueTime",
          headerName: "Fecha",
          width: 200,
          valueFormatter: parseDate,
        },
      ];
    }

    return [
      { field: "name", headerName: "Nombre", width: 200 },
      { field: "description", headerName: "Descripción", width: 600, flex: 1 },
      {
        field: "dueTime",
        headerName: "Fecha",
        width: 200,
        valueFormatter: parseDate,
      },
    ];
  };

  handleClassroomId = (id) => {
    this.getAssignments(id);
  };

  startAddNew = () => {
    this.setState({ addingNew: true });
  };

  stopAddNew = () => {
    this.setState({ addingNew: false });
  };

  render() {
    const { assignments } = this.state;
    return (
      // <Classroom
      //   tabIndex={1}
      //   match={this.props.match}
      //   history={this.props.history}
      //   onGetClassroomId={this.handleClassroomId}
      // >
      <React.Fragment>
        <GetData
          request={this.getImprovePlans}
          onData={(d) => this.setState({ improve: d })}
          onLoading={(val) => this.handleLoading("loadingImprove", val)}
          errorMessage={`Error al obtener los planes de mejora`}
          disableSuccessNotification
        />

        {this.state.addingNew && (
          <AssignmentCreation
            onAdd={() => this.getAssignments(this.props.match.params.id)}
            match={this.props.match}
            onBack={this.stopAddNew}
          />
        )}
        {!this.state.addingNew && (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "50px",
              }}
            >
              <Button
                onClick={this.startAddNew}
                color="primary"
                variant="outlined"
                endIcon={<AddIcon />}
              >
                Crear Nueva Tarea
              </Button>
            </div>
            <div
              style={{
                height: 400,
                width: "100%",
                marginTop: "30px",
                textAlign: "left",
              }}
            >
              <DataTable
                //state={this.state}

                noRowsLabel="No hay ninguna tarea"
                loading={assignments.loading}
                rows={assignments.data}
                columns={this.getColumns()}
                pageSize={5}
                disableMultipleSelection
                disableSelectionOnClick
                disableColumnSelector
                sortModel={[{ field: "dueTime", sort: "desc" }]}
                onRowClick={(target) => {
                  this.props.history.push(
                    generatePath(routes.teacher.assignment, {
                      id: this.props.match.params.id,
                      idAssignment: target.row.id,
                    })
                  );
                }}
              />
            </div>
            <br />
            <Divider />

            <br />

            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                onClick={() => {
                  this.props.history.push(
                    generatePath(routes.teacher.createImprovePlan, {
                      ...this.props.match.params,
                    })
                  );
                }}
                disabled={this.state.assignments.data.length === 0}
                color="primary"
                variant="outlined"
                endIcon={<ShowChart />}
              >
                Crear Plan de Mejora
              </Button>
            </div>
            <div
              style={{
                height: 400,
                width: "100%",
                marginTop: "30px",
                textAlign: "left",
              }}
            >
              <DataTable
                noRowsLabel="No hay ningún plan de mejora"
                loading={this.state.loadingImprove}
                rows={this.state.improve}
                columns={this.getImproveColumns()}
                pageSize={5}
                disableMultipleSelection
                disableSelectionOnClick
                disableColumnSelector
                sortModel={[{ field: "dueTime", sort: "desc" }]}
                onRowClick={(target) => {
                  // alert(JSON.stringify(target.row));
                  const url = generatePath(routes.teacher.improvePlans, {
                    id: target.row.idClassroom,
                    // idStudent: target.row.uid,
                    idAssignment: target.row.id,
                  });
                  this.props.history.push(url);
                }}
              />
            </div>
          </React.Fragment>
        )}
        <br />
      </React.Fragment>
      // </Classroom>
    );
  }
}

export default withWidth()(withStyles(styles)(AssignmentsTable));
