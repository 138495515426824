import {
  AppBar,
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  LinearProgress,
  Slide,
  TextField,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Close, ErrorOutline, WatchLaterOutlined } from "@material-ui/icons";
import Authentication from "controller/Authentication";
import AssignmentController from "Pages/Student/Classroom/Classroom/Assignments/StudentAssignmentController";
import React, { Component } from "react";
import { Clock, Download } from "react-bootstrap-icons";
import styles from "Pages/Teacher/Classroom/Classroom/Assignments/GradeAssignment/GradeAssignmentStyle";
import axios from "axios";
import { toast } from "material-react-toastify";
import DownloadButton from "components/DownloadButton/DownloadButton";
import dayjs from "dayjs";
import ImprovePlanController from "../../ImprovePlan/ImprovePlanController";
import GradeImprovePlanController from "../../ImprovePlan/GradeImprovePlanController";

const controller = new ImprovePlanController();
const gradeController = new GradeImprovePlanController();

class GradeImprovePlanDialog extends Component {
  state = {
    currentScore: 0,
    criterias: [],
    validScore: true,
    loading: false,
    message: "",
  };

  componentWillUnmount() {
    controller.cancelAllTokens();
    gradeController.cancelAllTokens();
  }

  validateScores = () => {
    let validScore = true;
    let { criterias } = this.state;
    for (let index = 0; index < criterias.length; index++) {
      const el = criterias[index];
      if (parseInt(el.value) > parseInt(el.percentage)) {
        validScore = false;
        break;
      }
    }
    this.setState({ validScore });
  };

  handleGrade = async (current) => {
    try {
      this.setState({ loading: true });
      await gradeController.gradeAssignment(
        current.idAssignment,
        current.uid,
        this.state.criterias,
        this.state.message
      );

      toast(`Tarea calificada correctamente`, { type: "success" });

      if (this.props.onGrade)
        this.props.onGrade(current, this.state.currentScore);
      this.resetCriterias();
      document.getElementById("criteria_0").focus();

      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      if (axios.isCancel(error)) return;
      console.log(error);
      toast(`Error al intentar calificar la tarea`, { type: "error" });
    }
  };

  resetCriterias = () => {
    let { criterias } = this.state;
    criterias.forEach((el) => (el.value = 0));
    this.setState({ criterias, currentScore: 0, message: "" });
  };

  handleCriteriaChange = (id, value) => {
    let criterias = [...this.state.criterias];
    let index = criterias.findIndex((el) => el.id === id);
    if (index !== -1) criterias[index].value = value;

    let score = criterias.reduce((acum, value) => {
      let val = acum + (parseFloat(value.value) || 0);
      return parseFloat(val.toFixed(10));
    }, 0);
    this.setState({ criterias, currentScore: score }, () => {
      this.validateScores();
    });
  };

  getCriteria = (id) => {
    let criterias = [...this.state.criterias];
    let index = criterias.findIndex((el) => el.id === id);
    if (index === -1) return 0;
    return criterias[index];
  };

  getOldCriterias = async () => {
    const { data } = this.props;
    let current = data[0] || {
      name: "",
      lastName: "",
      email: "",
      photoURL: "",
      score: null,
    };

    let score = await controller.getUserPlan(
      this.props.idAssignment,
      current.uid
    );

    this.setState({ message: score.message ?? "" });
    let criterias = [...this.state.criterias];
    criterias.forEach((el) => {
      let previous = score.criteria.findIndex((x) => x.id === el.id);
      if (previous !== -1) el.value = score.criteria[previous].score;
      else el.value = 0;
    });
    this.setState({ criterias, currentScore: score.score });
  };

  criteriaToState = async () => {
    const { assignment } = this.props;
    let { criteria } = assignment;

    if (this.state.criterias.length === 0 && criteria && this.props.open) {
      let criterias = criteria.map((el) => {
        return { ...el, value: 0 };
      });
      this.setState({ criterias });
    }
    // await this.getOldCriterias();
  };

  handleClose = () => {
    if (this.state.loading) return;
    this.setState({ criterias: [], currentScore: 0 });
    this.props.onClose();
  };

  hasNewAssignment(current) {
    if (!current.lastScoredURL) return false;
    if (current.lastScoredURL === current.filesURL) return false;
    return true;
  }

  render() {
    const { data, assignment } = this.props;
    const { classes } = this.props;
    let current = data[0] || {
      name: "",
      lastName: "",
      email: "",
      photoURL: "",
      score: null,
    };

    // let { criterias } = this.state;
    let { criteria = [] } = assignment;
    this.criteriaToState();

    return (
      <Dialog
        onEnter={() => this.setState({ message: "" })}
        onEntered={() => document.getElementById("criteria_0").focus()}
        open={this.props.open}
        onClose={this.handleClose}
        fullScreen
        TransitionComponent={Transition}
        // onEnter={this.getOldCriterias}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              onClick={() => this.handleClose()}
              edge="start"
              color="inherit"
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Calificar Tarea
            </Typography>
          </Toolbar>
          {this.state.loading && <LinearProgress color="secondary" />}
        </AppBar>

        <DialogContent>
          <Grid container justify="center">
            <Grid item md={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  margin: "20px",
                }}
              >
                <Hidden smDown>
                  <Avatar
                    src={current.photoURL}
                    style={{ marginRight: 20, width: 96, height: 96 }}
                  ></Avatar>
                </Hidden>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="subtitle2">
                    {Authentication.formatName(current.name, current.lastName)}
                  </Typography>
                  <Typography variant="body1">{current.email}</Typography>
                </div>
                <div style={{ flexGrow: 1 }} />

                <Typography variant="h4" color="textSecondary">
                  {this.state.currentScore} / {assignment.score}
                </Typography>
              </div>

              {dayjs(assignment.dueTime).isBefore(
                dayjs(current.submitTime)
              ) && (
                <React.Fragment>
                  <Chip
                    label={`Entregada ${dayjs(assignment.dueTime).from(
                      dayjs(current.submitTime),
                      true
                    )} tarde`}
                    className={classes.errorChip}
                    variant="outlined"
                    icon={<WatchLaterOutlined className={classes.errorChip} />}
                  />
                </React.Fragment>
              )}
              <div style={{ marginTop: 20 }} />
              <Divider />

              {/* TAREA ANTERIOR */}
              {this.hasNewAssignment(current) && (
                <div style={{ display: "flex", margin: "20px" }}>
                  <Typography
                    variant="subtitle2"
                    style={{ marginRight: "20px" }}
                  >
                    Tarea Calificada
                  </Typography>
                  <div style={{ flexGrow: 1 }} />

                  <Hidden smDown>
                    <DownloadButton
                      endIcon={<Download />}
                      label="Descargar tarea"
                      fileURL={current.lastScoredURL}
                    />
                  </Hidden>
                  <Hidden mdUp>
                    <DownloadButton
                      endIcon={<Download />}
                      label="Descargar"
                      fileURL={current.lastScoredURL}
                    />
                  </Hidden>
                </div>
              )}

              <div style={{ display: "flex", margin: "20px" }}>
                <Typography variant="subtitle2" style={{ marginRight: "20px" }}>
                  {!this.hasNewAssignment(current) ? `Tarea` : `Nueva Tarea`}
                </Typography>
                <div style={{ flexGrow: 1 }} />

                <Hidden smDown>
                  <DownloadButton
                    endIcon={<Download />}
                    label="Descargar tarea"
                    fileURL={current.filesURL}
                  />
                </Hidden>
                <Hidden mdUp>
                  <DownloadButton
                    endIcon={<Download />}
                    label="Descargar"
                    fileURL={current.filesURL}
                  />
                </Hidden>
              </div>

              <br />
              <Divider />

              {criteria.map((el, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "20px",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{ marginRight: "20px" }}
                    >
                      {el.name}
                    </Typography>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      type="number"
                      id={`criteria_${index}`}
                      autoComplete="off"
                      value={this.getCriteria(el.id).value}
                      style={{ width: "150px", fontSize: "2rem" }}
                      onChange={(e) =>
                        this.handleCriteriaChange(el.id, e.target.value)
                      }
                      InputProps={{
                        style: { fontSize: "1.3rem" },
                        endAdornment: (
                          <InputAdornment position="end">
                            {`/${el.percentage}`}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                );
              })}

              <Divider />
              <br />
              <div style={{ margin: 20 }}>
                <TextField
                  variant="outlined"
                  label="Comentarios"
                  multiline
                  fullWidth
                  value={this.state.message}
                  onChange={(e) => this.setState({ message: e.target.value })}
                />
              </div>

              <br />
              <Divider />

              <br />

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "20px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleGrade(current)}
                  disabled={!this.state.validScore || this.state.loading}
                >
                  Calificar y Continuar
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default withStyles(styles)(GradeImprovePlanDialog);
