import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.secondary.main,
  },
});
