import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import styles from "./Classroom-styles";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  GridList,
  GridListTile,
  IconButton,
  Button,
  Select,
  MenuItem,
  Tabs,
  Tab,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

import Skeleton from "@material-ui/lab/Skeleton";
import {
  WebAssetOutlined,
  AdbOutlined,
  DeleteOutline,
  Add as AddIcon,
  Settings,
} from "@material-ui/icons";

import Dashboard from "components/DashboardContainer/Dashboard";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Controller from "./ClassroomController";
import { toast } from "material-react-toastify";
import Axios from "axios";
import Authentication from "controller/Authentication";
import AssignmentsTable from "./Assignments/AssignmentsTable";
import AssignmentCreation from "./Assignments/AssignmentCreation";
import { isMobile } from "react-device-detect";
import Assignment from "./Assignments/Assignment";
import ClassroomManagementCard from "../Management/ClassroomManagementCard";
import routes from "Pages/routes";
import {
  generatePath,
  Link,
  matchPath,
  Route,
  withRouter,
} from "react-router-dom";
import { ProtectedRoute } from "components/ProtectedRoutes/ProtectedRoute";
import ClassroomStudents from "./Students/ClassroomStudents";
import Scores from "./Scores/Scores";
import Delivered from "./Scores/Assignments/Delivered";
import Posts from "./Assignments/Posts/Posts";
import ImprovePlan from "./ImprovePlan/ImprovePlan";
import CreateImprovePlan from "./ImprovePlan/CreateImprovePlan";
import TestCreation from "./Tests/TestCreation";
import ImprovePlanScores from "./Scores/ImprovePlan/ImprovePlanScores";
import AssignmentController from "./Assignments/AssignmentController";
import GetData from "components/Fetching/GetData";
import { periods, getPeriodName, MAX_PERIODS } from "utils/Settings";
import PostData from "components/Fetching/PostData";
import PublishDialog from "./PublishDialog";
import PeriodSummary from "./Summary/PeriodSummary";
import AnswerTest from "Pages/Student/Classroom/Classroom/Tests/AnswerTest";

const controller = new Controller();
const assignmnetController = new AssignmentController();

const theme = createMuiTheme({
  palette: {
    action: {
      disabledBackground: "set color of background here",
      disabled: "set color of text here",
    },
  },
});

class Classroom extends Component {
  state = {
    score: { totalScore: 0, remainingScore: 100 },
    tabIndex: 0,
    loading: false,
    currentPeriod: 0,
    periods: [],
    classroom: {
      id: null,
      name: "",
      imageURL: "",
      sectionName: "",
      currentPeriod: 0,
    },
    error: null,
    openPublishDialog: false,
    loadingPublish: false,
    periodToPublish: -1,
  };

  constructor(props) {
    super(props);
    this.assignmetCreation = React.createRef();
    this.publishRef = React.createRef();
    this.summaryRef = React.createRef();
  }

  matchUrl = (location, path, exact) => {
    return matchPath(location, {
      exact,
      path,
      strict: false,
    });
  };

  setTabIndex = (l) => {
    const tabIndex = this.getTabIndex(l);
    this.setState({ tabIndex });
  };

  getTabIndex = (l) => {
    let rt = routes.teacher;
    if (this.matchUrl(l, rt.classroom, true)) return 0;
    if (this.matchUrl(l, rt.classroomAssignments, false)) return 1;
    if (this.matchUrl(l, rt.improvePlans, false)) return 1;
    if (this.matchUrl(l, rt.createImprovePlan, false)) return 1;
    // if (this.matchUrl(l, rt.createTest, false)) return 2;
    if (this.matchUrl(l, rt.scores, false)) return 2;
    if (this.matchUrl(l, rt.students, true)) return 3;

    return 0;
  };

  handleUserChange = (user) => {
    if (user == null) return;
    this.requestClassroom();
  };

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.setTabIndex(location.pathname);
    });

    this.setState({ periods: periods() });
    Authentication.instance.subscribeToUserChange(this.handleUserChange);
    this.setTabIndex(this.props.location.pathname);
  }

  componentWillUnmount() {
    Authentication.instance.unsubscribeFromUserChange(this.handleUserChange);
    controller.cancelAllTokens();
    assignmnetController.cancelAllTokens();
    this.unlisten();
  }

  requestTotalScore = async () => {
    const response = await assignmnetController.getTotalScore(
      this.props.match.params.id
    );
    return response;
  };

  requestClassroom = async () => {
    try {
      this.setState({ loading: true });
      let response = await controller.getClassroom(this.props.match.params.id);

      if (response) {
        let period = response.currentPeriod;
        if (period >= MAX_PERIODS) period = MAX_PERIODS - 1;
        this.setState({
          classroom: response,
          loading: false,
          currentPeriod: period,
        });
        if (this.props.onGetClassrooom) this.props.onGetClassrooom(response);
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
      if (Axios.isCancel(error)) return;
      this.props.history.replace(routes.errors.classroomNotFound);
      // toast("Error al obtener el salón", { type: "error" });
    }
  };

  onClickPublish = () => {
    this.publishRef.current.post();
  };

  publish = async () => {
    const response = await controller.publish(
      this.props.match.params.id,
      this.state.periodToPublish
    );
    return response;
  };

  parseLink = (link) => {
    let id = this.props.match.params.id;
    return generatePath(link, { id });
  };

  changePeriod = (e) => {
    const newVal = e.target.value;
    const currentPeriod = this.state.classroom.currentPeriod ?? 0;

    if (newVal > currentPeriod) {
      toast.info(`Esta unidad es mayor a la unidad actual`);
      return;
    }
    let old = this.state.currentPeriod;

    this.setState({ currentPeriod: newVal }, () => {
      if (newVal === currentPeriod) return;
      if (old === newVal || old === currentPeriod) return;
      this.summaryRef.current.refresh();
    });
  };

  render() {
    const { classes } = this.props;
    const { classroom, loading } = this.state;
    return (
      <Dashboard history={this.props.history}>
        <Container
          className={isMobile ? classes.containerMobile : classes.container}
        >
          <GetData
            request={this.requestTotalScore}
            onData={(val) => this.setState({ score: val })}
            disableSuccessNotification
            disableErrorNotification
          />

          <PostData
            onLoading={(val) => this.setState({ loadingPublish: val })}
            onData={(data) => {
              window.location.reload();
            }}
            ref={this.publishRef}
            request={this.publish}
            disableSuccessNotification
            errorMessage={`Error al publicar el salón`}
          />

          <main>
            <div
              className={
                isMobile
                  ? classes.mainFeaturedPostMobile
                  : classes.mainFeaturedPost
              }
            >
              {/* Increase the priority of the hero background image */}

              {!loading && (
                <img
                  className={classes.coverPage}
                  src={this.state.classroom.imageURL}
                  alt="background"
                />
              )}
              <div className={classes.overlay} />

              <div className={classes.mainFeaturedPostContent}>
                {loading && (
                  <div className={classes.loadingTextContainer}>
                    <Skeleton
                      width={350}
                      height={80}
                      animation="wave"
                      style={{ maxWidth: "90%" }}
                    />
                    <div style={{ height: "10px" }} />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width={250}
                      height={30}
                      style={{ maxWidth: "90%" }}
                    />
                  </div>
                )}
                <Typography
                  variant={"h3"}
                  color="inherit"
                  gutterBottom
                  unselectable={"on"}
                >
                  {this.state.classroom.name}
                </Typography>

                <Typography
                  variant={"subtitle1"}
                  color="inherit"
                  gutterBottom
                  unselectable={"on"}
                >
                  {this.state.classroom.sectionName}
                </Typography>

                <div className={classes.period}>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={this.state.currentPeriod}
                    className={classes.select}
                    onChange={this.changePeriod}
                    style={{ height: "40px", borderColor: "white" }}
                    classes={{
                      root: classes.whiteColor,
                      icon: classes.whiteColor,
                    }}
                    disableUnderline
                  >
                    {periods().map((el) => {
                      if (el <= this.state.classroom.currentPeriod)
                        return (
                          <MenuItem value={el}>{getPeriodName(el)} </MenuItem>
                        );
                      return <React.Fragment />;
                    })}
                  </Select>

                  {this.state.score.totalScore === 100 &&
                    this.state.currentPeriod ===
                      this.state.classroom.currentPeriod && (
                      <Button
                        variant="contained"
                        size="large"
                        color="default"
                        onClick={() =>
                          this.setState({
                            periodToPublish: -1,
                            openPublishDialog: true,
                          })
                        }
                        classes={{
                          root: classes.rootButton,
                          disabled: classes.buttonDisabled,
                        }}
                      >
                        {"Publicar"}
                      </Button>
                    )}

                  {this.state.currentPeriod !==
                    this.state.classroom.currentPeriod && (
                    <Button
                      variant="contained"
                      size="large"
                      color="default"
                      onClick={() =>
                        this.setState({
                          periodToPublish: this.state.currentPeriod,
                          openPublishDialog: true,
                        })
                      }
                      classes={{
                        root: classes.rootButton,
                        disabled: classes.buttonDisabled,
                      }}
                    >
                      {"Retroceder"}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </main>
        </Container>
        {this.state.currentPeriod === this.state.classroom.currentPeriod ? (
          <Container
            className={isMobile ? classes.containerMobile : classes.container}
          >
            <Tabs
              scrollButtons="on"
              value={this.state.tabIndex}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              aria-label="disabled tabs example"
            >
              <Tab
                label="Inicio"
                to={this.parseLink(routes.teacher.classroom)}
                component={Link}
              />
              <Tab
                label="Tareas"
                to={this.parseLink(routes.teacher.classroomAssignments)}
                component={Link}
              />
              {/* <Tab
                label="Evaluaciones"
                to={this.parseLink(routes.teacher.createTest)}
                component={Link}
              /> */}
              <Tab
                label="Calificar"
                to={this.parseLink(routes.teacher.scores)}
                component={Link}
              />
              <Tab
                label="Alumnos"
                to={this.parseLink(routes.teacher.students)}
                component={Link}
              />
            </Tabs>

            <ProtectedRoute
              idRole="teacher"
              exact
              path={routes.teacher.classroom}
              component={Posts}
            />
            <ProtectedRoute
              idRole="teacher"
              exact
              path={routes.teacher.classroomAssignments}
              component={AssignmentsTable}
            />

            <ProtectedRoute
              idRole="teacher"
              exact
              path={routes.teacher.assignment}
              component={Assignment}
            />

            <ProtectedRoute
              idRole="teacher"
              exact
              path={routes.teacher.createImprovePlan}
              component={CreateImprovePlan}
            />

            <ProtectedRoute
              idRole="teacher"
              exact
              path={routes.teacher.improvePlans}
              component={ImprovePlan}
            />

            <ProtectedRoute
              idRole="teacher"
              exact
              path={routes.teacher.students}
              component={ClassroomStudents}
            />

            <ProtectedRoute
              idRole="teacher"
              exact
              path={routes.teacher.scoresImprove}
              component={ImprovePlanScores}
            />

            <ProtectedRoute
              idRole="teacher"
              exact
              path={routes.teacher.scores}
              component={Scores}
            />

            <ProtectedRoute
              idRole="teacher"
              exact
              path={routes.teacher.scoresAssignment}
              component={Delivered}
            />
            {/* 
            <ProtectedRoute
              idRole="teacher"
              exact
              path={routes.teacher.createTest}
              component={TestCreation}
            /> */}

            {/* {this.props.children} */}
          </Container>
        ) : (
          <PeriodSummary
            ref={this.summaryRef}
            idClassroom={this.props.match.params.id}
            period={this.state.currentPeriod}
          />
        )}
        <PublishDialog
          open={this.state.openPublishDialog}
          onClose={() => this.setState({ openPublishDialog: false })}
          onClick={this.onClickPublish}
          loading={this.state.loadingPublish}
        />
      </Dashboard>
    );
  }
  openDeleteDialog = () => {
    this.setState({ deleteOpen: true });
  };
  handleDeleteDialogClose = () => {
    this.setState({ deleteOpen: false });
  };
}

Classroom.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Classroom));
