import { InputAdornment } from "@material-ui/core";
import {
  Box,
  Button,
  Container,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import PostData from "components/Fetching/PostData";
import dayjs from "dayjs";
import { toast } from "material-react-toastify";
import { nanoid } from "nanoid";
import React, { Component } from "react";
import DefaultErrorMessages from "utils/DefaultErrorMessages";
import AssignmentErrorMessages from "../Assignments/AssignmentErrorMessages";
import TestController from "./TestController";
import TestQuestionMultiple from "./TestQuestionMultiple";

const testController = new TestController();

class TestCreation extends Component {
  state = {
    loading: false,
    score: 0,
    questions: [{ id: "first" }],
    date: dayjs(new Date().setHours(23, 59, 0, 0)).format("YYYY-MM-DDTHH:mm"),
  };

  constructor(props) {
    super(props);
    this.createRef = React.createRef();
  }

  scrollToBottom = () => {
    this.endOfPage.scrollIntoView({ behavior: "smooth" });
  };

  addQuestion = () => {
    let id = nanoid(11);
    let { questions } = this.state;
    questions.push({ id });
    this.setState({ questions }, () => {
      this.scrollToBottom();

      setTimeout(() => {
        document.getElementById(`question-${id}-text`).focus();
      }, 500);
    });
  };

  removeQuestion = (index) => {
    let questions = [...this.state.questions];
    questions.splice(index, 1);
    this.setState({ questions }, () => {
      document.getElementById(`add-question-btn`).focus();
      this.updateQuestion(-1);
    });
  };

  updateQuestion = (index, value) => {
    let questions = [...this.state.questions];

    if (index !== -1) questions[index] = { ...value };

    let score = questions.reduce((acum, value) => {
      let val = acum + (parseFloat(value.score || 0) || 0);
      return parseFloat(val.toFixed(10)) || 0;
    }, 0);

    this.setState({ questions, score });
  };
  onTextChange = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  componentWillUnmount() {
    testController.cancelAllTokens();
  }

  handleCreateClick = () => {
    this.createRef.current.post();
  };

  handleOnPost = (data) => {};
  handlePost = async () => {
    let data = await testController.create(this.props.match.params.id, {
      name: "Test",
      dueTime: new Date(this.state.date),
      questions: this.state.questions,
    });

    return data;
  };

  printError = (error) => {
    console.log(error);
    let message =
      AssignmentErrorMessages(error) ||
      DefaultErrorMessages(error, "Error al intentar crear la evaluación");
    toast(message, { type: "error" });
  };

  render() {
    return (
      <React.Fragment>
        <PostData
          request={this.handlePost}
          onData={(val) => this.handleOnPost()}
          onLoading={(val) => this.setState({ loading: val })}
          onError={(err) => this.printError(err)}
          successMessage="Nueva evaluación ha sido creada"
          ref={this.createRef}
          disableErrorNotification
        />
        <Container maxWidth="md" style={{ textAlign: "left", marginTop: 50 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <TextField
              label="Nombre de la evaluación"
              variant="outlined"
              fullWidth
              autoFocus
              autoComplete="off"
            />
            <br />

            <TextField
              autoComplete="off"
              label="Fecha de entrega"
              variant="outlined"
              type="datetime-local"
              value={this.state.date}
              fullWidth
              onChange={(e) => this.onTextChange("date", e)}
            />
            <br />
            <TextField
              label="Tiempo para responder"
              variant="outlined"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">min</InputAdornment>
                ),
              }}
              autoComplete="off"
            />
            <br />

            <div style={{ width: "100%" }}>
              <Divider />
            </div>
            <br />
            <Typography variant="subtitle1" color="textSecondary">
              Preguntas
            </Typography>
            <br />
            {this.state.questions.map((el, index) => {
              return (
                <React.Fragment key={`question-container-${el.id}`}>
                  <TestQuestionMultiple
                    key={`question-creator-${el.id}`}
                    id={el.id}
                    removeQuestionDisabled={this.state.questions.length <= 1}
                    onRemoveQuestion={() => this.removeQuestion(index)}
                    onUpdateQuestion={(val) => this.updateQuestion(index, val)}
                  />

                  <br />
                </React.Fragment>
              );
            })}

            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.endOfPage = el;
              }}
            ></div>
            <Button
              id="add-question-btn"
              variant="outlined"
              color="primary"
              endIcon={<Add />}
              onClick={this.addQuestion}
            >
              Agregar Pregunta
            </Button>
          </div>

          <br />
          <Divider />
          <br />
          <br />
          <br />
          <TextField
            disabled
            fullWidth
            variant="outlined"
            label="Puntos Totales"
            value={this.state.score}
            inputProps={{ style: { fontSize: "2rem" } }}
          />
          <div style={{ marginTop: 20 }} />

          <Button
            variant="contained"
            color="primary"
            onClick={this.handleCreateClick}
            disabled={this.state.loading}
          >
            Crear Evaluación
          </Button>
          <div style={{ marginTop: 20 }} />
        </Container>
      </React.Fragment>
    );
  }
}

export default TestCreation;
