import axios from "utils/API";
import Axios from "axios";
import dayjs from "dayjs";
import download from "downloadjs";

export default class AssignmentController {
  constructor() {
    this.getAssignmetsSource = Axios.CancelToken.source();
    this.getAssignmentSource = Axios.CancelToken.source();
    this.getStatusSource = Axios.CancelToken.source();
    this.uploadAssignmentSource = Axios.CancelToken.source();
    this.getScoreSource = Axios.CancelToken.source();
    this.getTotalScoreSource = Axios.CancelToken.source();
  }

  getAssignments = async (classroomId) => {
    if (this.getAssignmetsSource) this.getAssignmetsSource.cancel();
    this.getAssignmetsSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/student/classrooms/${classroomId}/assignments`,
      { cancelToken: this.getAssignmetsSource.token }
    );

    return response.data;
  };

  /**
   *
   * @param {String} classroomId
   * @param {Number} assignmentId
   */
  getAssignment = async (classroomId, assignmentId) => {
    if (this.getAssignmetSource) this.getAssignmetSource.cancel();
    this.getAssignmetSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/student/classrooms/${classroomId}/assignments/${assignmentId}`,
      { cancelToken: this.getAssignmetSource.token }
    );
    return response.data;
  };

  getGradeAssignments = async (classroomId) => {
    if (this.getAssignmetsSource) this.getAssignmetsSource.cancel();
    this.getAssignmetsSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/student/classrooms/${classroomId}/grade-assignments`,
      { cancelToken: this.getAssignmetsSource.token }
    );

    let { data } = response;

    return data;
  };

  getTotalScore = async (classroomId) => {
    if (this.getTotalScoreSource) this.getTotalScoreSource.cancel();
    this.getTotalScoreSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/student/classrooms/${classroomId}/total-score`,
      { cancelToken: this.getTotalScoreSource.token }
    );

    let { data } = response;

    return data;
  };

  getAssignmentStatus = async (classroomId, assignmentId) => {
    if (this.getStatusSource) this.getStatusSource.cancel();
    this.getStatusSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/student/classrooms/${classroomId}/assignments/${assignmentId}/status`,
      { cancelToken: this.getStatusSource.token }
    );
    return response.data;
  };

  uploadAssignment = async (idClassroom, idAssignment, fileURL) => {
    if (this.uploadAssignmentSource) this.uploadAssignmentSource.cancel();
    this.uploadAssignmentSource = Axios.CancelToken.source();

    let res = await axios.post(
      `/api/student/classrooms/${idClassroom}/assignments/${idAssignment}`,
      { fileURL },
      { cancelToken: this.uploadAssignmentSource.token }
    );
    return res.data;
  };

  getScore = async (idClassroom, idAssignment) => {
    if (this.getScoreSource) this.getScoreSource.cancel();
    this.getScoreSource = Axios.CancelToken.source();

    let res = await axios.get(
      `/api/student/classrooms/${idClassroom}/assignments/${idAssignment}/score`,
      { cancelToken: this.getScoreSource.token }
    );

    return res.data;
  };

  cancelAllTokens() {
    if (this.getAssignmetsSource) this.getAssignmetsSource.cancel();
    if (this.getAssignmetSource) this.getAssignmetSource.cancel();
    if (this.getStatusSource) this.getStatusSource.cancel();
    if (this.uploadAssignmentSource) this.uploadAssignmentSource.cancel();
    if (this.getScoreSource) this.getScoreSource.cancel();
    if (this.getTotalScoreSource) this.getTotalScoreSource.cancel();
  }
}
