import axios from "utils/API";
import Axios from "axios";

export default class ClassroomManagementController {
  constructor() {
    this.classroomsSource = Axios.CancelToken.source();
    this.createClassroomSource = Axios.CancelToken.source();
  }

  transfer = async (idClassroom, toUid) => {
    if (this.createClassroomSource) this.createClassroomSource.cancel();
    this.createClassroomSource = Axios.CancelToken.source();

    const response = await axios.put(
      `api/teacher/classrooms/${idClassroom}/transfer`,
      { toUid: toUid },
      { cancelToken: this.createClassroomSource.token }
    );
    return response.data;
  };

  /**
   * @param {String} textFilter
   * @param {Number} page Número de página a traer
   */
  getClassrooms = async (textFilter, page = 0) => {
    let filter = textFilter ? `&textFilter=${textFilter}` : "";

    if (this.classroomsSource) this.classroomsSource.cancel();
    this.classroomsSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/teacher/classrooms?page=${page}&pageSize=100${filter}`,
      { cancelToken: this.classroomsSource.token }
    );

    let classrooms = response.data;
    classrooms.sort(this.compare);
    return classrooms;
  };

  compare(a, b) {
    let nameA = a.name.toLowerCase();
    let nameB = b.name.toLowerCase();
    let sectionA = a.sectionName.toLowerCase();
    let sectionB = b.sectionName.toLowerCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    if (sectionA < sectionB) return -1;
    if (sectionA > sectionB) return 1;
    return 0;
  }

  uploadPicture = async (picture, idClassroom) => {
    let formData = new FormData();
    formData.append("portrait", picture);
    const response = await axios({
      method: "POST",
      url: `/files/classrooms/${idClassroom}/portrait-picture`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return response.data;
  };

  /**
   *
   * @param {String} name
   * @param {String} description
   * @param {Number} idSection
   */
  createClassroom = async (name, description, idSection) => {
    if (this.createClassroomSource) this.createClassroomSource.cancel();
    this.createClassroomSource = Axios.CancelToken.source();

    const response = await axios.post(
      `api/teacher/classrooms`,
      {
        name,
        description,
        idSection,
      },
      { cancelToken: this.createClassroomSource.token }
    );
    return response.data;
  };

  editClassroom = async (idClassroom, info) => {
    if (this.createClassroomSource) this.createClassroomSource.cancel();
    this.createClassroomSource = Axios.CancelToken.source();

    const response = await axios.put(
      `api/teacher/classrooms/${idClassroom}`,
      { info },
      { cancelToken: this.createClassroomSource.token }
    );
    return response.data;
  };

  cancelAllTokens() {
    if (this.classroomsSource) this.classroomsSource.cancel();
    if (this.createClassroomSource) this.createClassroomSource.cancel();
  }

  cancelUsersSearch() {}
}
