import { Card } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { Timer } from "@material-ui/icons";
import React, { Component } from "react";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgress);

class CardProgress extends Component {
  state = {};

  render() {
    const {
      variant = "determinate",
      value = 100,
      color = "primary",
    } = this.props;
    return (
      <Card
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {this.props.icon}
          <Divider
            orientation="vertical"
            flexItem
            style={{ marginLeft: 10, marginRight: 10 }}
          />
          <BorderLinearProgress
            variant={variant}
            value={value}
            color={color}
            style={{ flexGrow: 1, borderRadius: 5 }}
          />
        </div>
      </Card>
    );
  }
}

export default CardProgress;
