import axios from "utils/API";
import { beginRequest } from "utils/ApiRequests";
import Axios from "axios";

/**
 * @typedef {Object} ResponseAllUsers
 * @property {Array<import("utils/typedefs").AccountData>} users Usuarios aprobados
 * @property {Array<import("utils/typedefs").AccountData>} pending Usuarios pendientes de aprobación
 */

export default class UserManagementController {
  constructor() {
    this.approvedUsersSource = Axios.CancelToken.source();
    this.pendingUsersSource = Axios.CancelToken.source();
    this.getByRoleSource = Axios.CancelToken.source();
  }

  /**
   * @param {String} textFilter
   * @param {Number} page Número de página a traer
   * @param {Boolean} pending Obtener usuarios pendientes (o aprobados en caso de ser falso)
   * @returns {Promise<Array<import("utils/typedefs").AccountData>>} Arreglo de usuarios
   */
  getUsers = async (textFilter, page = 0, pending = false) => {
    let filter = textFilter ? `&textFilter=${textFilter}` : "";

    if (pending) {
      if (this.pendingUsersSource) this.pendingUsersSource.cancel();
      this.pendingUsersSource = Axios.CancelToken.source();
    } else {
      if (this.approvedUsersSource) this.approvedUsersSource.cancel();
      this.approvedUsersSource = Axios.CancelToken.source();
    }

    const response = await axios.get(
      `api/admin/user-management/users?page=${page}&pageSize=10&approved=${pending ? 0 : 1
      }${filter}`,
      {
        cancelToken: pending
          ? this.pendingUsersSource.token
          : this.approvedUsersSource.token,
      }
    );
    return response.data;
  };

  /**
   *
   * @param {String} idRole
   * @returns {Promise<Array<import("utils/typedefs").AccountData>>} Arreglo de usuarios
   */
  getByRole = async (idRole) => {
    if (this.getByRoleSource) this.getByRoleSource.cancel();
    this.getByRoleSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/admin/user-management/users-by-roles/${idRole}`,
      { cancelToken: this.getByRoleSource.token }
    );

    return response.data;
  };

  /**
   *
   * @param {String} textFilter
   * @returns {Promise<ResponseAllUsers>}
   */
  requestAllUsers = async (textFilter) => {
    let [users, pending] = await Promise.all([
      this.getUsers(textFilter, 0, false),
      this.getUsers(textFilter, 0, true),
    ]);

    return { users, pending };
  };

  cancelAllTokens() {
    this.cancelUsersSearch();
    if (this.getByRoleSource) this.getByRoleSource.cancel();
  }

  cancelUsersSearch() {
    if (this.approvedUsersSource) this.approvedUsersSource.cancel();
    if (this.pendingUsersSource) this.pendingUsersSource.cancel();
  }
}
