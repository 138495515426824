import Axios from "axios";

export default (error) => {
  let errorMessage = "";

  if (error.message === "Network Error") {
    errorMessage = error.message;
  } else if (Axios.isAxiosError(error)) {
    errorMessage = error.response.data.message;
  } else errorMessage = error.message;

  switch (errorMessage) {
    case "Network Error":
      return `Error de conexión`;
    default:
      return null;
  }
};
