export default (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: 300,
    margin: 100,
  },
  //style for font size
  questionText: {
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: "1.5rem",
  },
  answerText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "1.5rem",
  },
});
