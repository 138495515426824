import axios from "utils/API";
import Axios from "axios";
const CancelToken = Axios.CancelToken;
/**
 * @param {import("axios").CancelTokenSource} oldCancelToken
 * @returns {import("axios").CancelTokenSource}
 */

export const beginRequest = async (oldCancelToken = null) => {
  if (oldCancelToken) oldCancelToken.cancel();
  return CancelToken.source();
};
