import React, { Component } from "react";
import styles from "./StudentClassroomManagement-styles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  TextField,
  InputAdornment,
  Dialog,
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Hidden,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import {
  Add,
  MoreVertOutlined,
  Search as SearchIcon,
} from "@material-ui/icons";
import Axios from "axios";
import RoleManagement from "controller/Roles";
import School from "controller/School";
import { DropzoneArea } from "material-ui-dropzone";
import { isMobile } from "react-device-detect";
import Controller from "./StudentClassroomManagementController";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { FetchDataComponent } from "components/Fetching/FetchDataComponent";
import { toast } from "material-react-toastify";
import Authentication from "controller/Authentication";
import ClassroomManagementCard from "./StudentClassroomManagementCard";
import SearchInput from "components/SearchInput/SearchInput";
import { Skeleton } from "@material-ui/lab";
import Dashboard from "components/DashboardContainer/Dashboard";
const controller = new Controller();

function loadingCards(prefix) {
  let style = { width: "100%", height: "100%", minHeight: 275 };
  return (
    <React.Fragment>
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_01"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_02"}>
        <Skeleton variant="rect" style={style} />
      </Grid>{" "}
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_03"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
    </React.Fragment>
  );
}

function textDataRender(text) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <Typography
        variant="caption"
        color="textSecondary"
        style={{
          textAlign: "left",
          fontWeight: "600",
          marginTop: "35px",
          fontSize: "24px",
          height: "75px",
          opacity: "0.5",
        }}
      >
        {text}
      </Typography>
    </div>
  );
}

let cancelToken;

class StudentClassroomManagement extends Component {
  state = {
    roles: [],
    textFilter: "",
    dialogOpen: false,
    addPortraitImage: false,
    classrooms: {
      loading: true,
      data: [],
      error: null,
    },

    dialog: {
      name: "",
      description: "",
      idSection: 0,
    },
  };

  constructor() {
    super();
    this.requestRoles = this.requestRoles.bind(this);
    this.requestSections = this.requestSections.bind(this);
  }

  handleUserChange = (user) => {
    this.setState({ user });
    if (user == null) return;
    this.requestClassrooms();
    this.requestRoles();
    this.requestSections();
  };

  handleDialogOpen = (dialogOpen) => {
    this.setState({ dialogOpen });
  };

  handleSearch = async (text) => {
    this.setState({ textFilter: text }, () => {
      this.requestClassrooms(text);
    });
  };

  handleDialogTextChange = (e, name, callback) => {
    const { value } = e.target;
    this.setState((old) => {
      let { dialog } = old;
      dialog[name] = value;
      return { dialog };
    }, callback);
  };

  handleTextChange = (e, name, callback) => {
    const { value } = e.target;
    this.setState({ [name]: value }, callback);
  };

  componentDidMount() {
    Authentication.instance.subscribeToUserChange(this.handleUserChange);
  }

  async requestRoles() {
    const roles = await RoleManagement.getRoles();
    this.setState({ roles });
  }

  async requestSections() {
    const sections = await School.getSections();
    this.setState({ sections });
  }

  requestClassrooms = async () => {
    this.setState({ classrooms: { ...this.state.classrooms, loading: true } });
    try {
      let response = await controller.getClassrooms(this.state.textFilter);
      this.setState({
        classrooms: { loading: false, data: response, error: null },
      });
    } catch (error) {
      if (Axios.isCancel(error)) return;
      this.setState({ classrooms: { data: [], error, loading: false } });
    }
  };

  componentWillUnmount() {
    Authentication.instance.unsubscribeFromUserChange(this.handleUserChange);
    controller.cancelAllTokens();
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Dashboard history={this.props.history}>
          <main
            className={isMobile ? classes.containerMobile : classes.container}
          >
            <Grid
              container
              justify="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12} md={2} lg={1}>
                <Typography variant="h4" align="left">
                  Salones
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                justify="flex-start"
                alignContent="flex-start"
              >
                <div className={classes.header}>
                  <SearchInput
                    className={classes.searchBar}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    autoFocus={!isMobile}
                    onSearch={this.handleSearch}
                  />
                </div>
              </Grid>
            </Grid>
            <br />
            <br />

            <Grid container spacing={2} className={classes.heroContainer}>
              <FetchDataComponent
                loading={this.state.classrooms.loading}
                data={this.state.classrooms.data}
                error={this.state.classrooms.error}
                customNoDataRenderer={() =>
                  textDataRender("No se encontraron salones")
                }
                customErrorRenderer={() =>
                  textDataRender(
                    "Ocurrio un error al momento de traer la información"
                  )
                }
                loadingRender={() => loadingCards("classrooms")}
              >
                {this.state.classrooms.data.map((el) => (
                  <Grid
                    item
                    xl={3}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    key={el.uid}
                    className={classes.heroContent}
                  >
                    <ClassroomManagementCard
                      classroom={el}
                      history={this.props.history}
                      handleApprove={this.handleApprove}
                      handleOpenApproveMenu={this.handleOpenApproveMenu}
                    />
                  </Grid>
                ))}
              </FetchDataComponent>
            </Grid>

            <br />

            {/* <Pagination count={10} color="primary" />
          <br /> */}
          </main>
        </Dashboard>
      </React.Fragment>
    );
  }
}

StudentClassroomManagement.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(StudentClassroomManagement);
