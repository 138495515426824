import React, { Component } from "react";
import styles from "./PublicHome-styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import CSS from "./PublicHome.css";
import {
  Button,
  Container,
  Divider,
  Grid,
  Hidden,
  Paper,
} from "@material-ui/core";

class PublicHome extends Component {
  state = {};

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div
          className={"background"}
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center top",
            backgroundImage: `url(${require("../../img/Experimental.png")})`,
          }}
        />
        <div className={classes.root}>
          <AppBar
            position="fixed"
            style={{
              background: "transparent",
              boxShadow: "none",
              zIndex: 1000,
            }}
          >
            <Toolbar syle={{ zIndex: 1000 }}>
              <Hidden smDown>
                <Typography
                  variant="subtitle1"
                  className={classes.appbarTitle}
                  style={{ color: "#fff" }}
                >
                  Bachillerato Experimental
                </Typography>
              </Hidden>
              <div className={classes.grow} />
              <Button
                color="secondary"
                style={{ color: "white" }}
                onClick={() => {
                  this.props.history.push("/sign-in");
                }}
              >
                Iniciar Sesión
              </Button>
            </Toolbar>
          </AppBar>

          <main className="container">
            <Grid
              container
              className={classes.heroContainer}
              justify={"center"}
              alignContent="center"
            >
              <Grid item xs={12} md={6} lg={4} className={classes.heroContent}>
                <Typography variant="h4" style={{ color: "#d8d8d8" }}>
                  Bienvenido a
                </Typography>

                <Typography variant="h2" className={classes.heroTitle}>
                  Campus Bachillerato Experimental
                </Typography>
                <br />
                <br />
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    this.props.history.push("/authenticate");
                  }}
                >
                  Entrar al campus
                </Button>
              </Grid>
            </Grid>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

PublicHome.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(PublicHome);
