import axios from "utils/API";
import Axios from "axios";
import Authentication from "controller/Authentication";

export default class GradeAssignmentController {
  constructor() {
    this.gradeSource = Axios.CancelToken.source();
    this.getScoreSource = Axios.CancelToken.source();
  }
  /**
   *
   * @param {*} idStudent
   * @param {*} idClassroom
   * @param {*} idAssignment
   * @returns
   */

  getScore = async (idStudent, idClassroom, idAssignment) => {
    if (this.getScoreSource) this.getScoreSource.cancel();
    this.getScoreSource = Axios.CancelToken.source();

    let res = await axios.get(
      `/api/teacher/classrooms/${idClassroom}/assignments/${idAssignment}/score/${idStudent}`,
      { cancelToken: this.getScoreSource.token }
    );

    return res.data;
  };

  /**
   *
   * @param {String} idStudent
   * @param {Array} scores
   */
  gradeAssignment = async (
    idClassroom,
    idAssignment,
    idStudent,
    scores,
    message
  ) => {
    if (this.classroomSource) this.gradeSource.cancel();
    this.gradeSource = Axios.CancelToken.source();

    const response = await axios.post(
      `api/teacher/classrooms/${idClassroom}/assignments/${idAssignment}/scores/${idStudent}`,
      { scores, message },
      { cancelToken: this.gradeSource.token }
    );
    return response.data;
  };

  compare = (a, b) => {
    if (a.displayName > b.displayName) return 1;
    if (a.displayName < b.displayName) return -1;
    return 0;
  };

  cancelAllTokens() {
    if (this.gradeSource) this.gradeSource.cancel();
    if (this.getScoreSource) this.getScoreSource.cancel();
  }
}
