import React from "react";

const { Typography } = require("@material-ui/core");

export const FetchDataComponent = ({
  loading,
  data,
  error,
  loadingRender,
  children,
  noDataText = "No se pudo encontrar esta información",
  errorText = "Ocurrio un error al momento de tratar de obtener la información",
  customErrorRenderer = null,
  customNoDataRenderer = null,
}) => {
  if (loading) {
    return loadingRender();
  }

  if (error) {
    if (customErrorRenderer) return customErrorRenderer();
    return <Typography variant="caption">{errorText}</Typography>;
  }

  if (!data || data.length == 0) {
    if (customNoDataRenderer) return customNoDataRenderer();
    return <Typography variant="caption">{noDataText}</Typography>;
  }

  return <React.Fragment>{children}</React.Fragment>;
};
