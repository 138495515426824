import React, { Component } from "react";
import styles from "./DynamicNavigationStyles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Divider,
  IconButton,
} from "@material-ui/core";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import clsx from "clsx";
import Authentication from "controller/Authentication";
import { ArrowLeftShort as Back } from "react-bootstrap-icons";

class DynamicNavigation extends Component {
  state = { drawerOpen: false, user: Authentication.emptyUser };

  constructor(props) {
    super(props);
  }

  handleUserChange = (user) => {
    this.setState({ user });
  };

  componentDidMount() {
    Authentication.instance.subscribeToUserChange(this.handleUserChange);
  }

  componentWillUnmount() {
    Authentication.instance.unsubscribeFromUserChange(this.handleUserChange);
  }

  renderDrawer = (props) => {
    const { classes, menuList, header = "Bachillerato Experimental" } = props;
    return (
      <Drawer
        anchor={"left"}
        open={props.open}
        onClose={() => props.toggle(false)}
      >
        {/* <div style={{ padding: "0px" }} onClick={()=>{this.setState()}}>
          <IconButton>
            <Back />
          </IconButton>
        </div> */}
        <div className={classes.toolbar} style={{ padding: "20px" }}>
          <Typography variant="h6">{header}</Typography>
          <Typography
            variant="body1"
            style={{ fontWeight: 600 }}
            color="textSecondary"
          >
            {this.state.user ? this.state.user.role : ""}
          </Typography>
          {Authentication.instance.section && (
            <Typography
              variant="body1"
              style={{ fontWeight: 600 }}
              color="textSecondary"
            >
              {Authentication.instance.section.name}
            </Typography>
          )}
        </div>
        <Divider />

        <div
          className={clsx(classes.list, {
            [classes.fullList]: false,
          })}
        >
          <List>
            {menuList.map((el) => {
              return (
                <ListItem
                  key={el.name}
                  button
                  onClick={() => {
                    if (props.history.location.pathname != el.location)
                      props.history.push(el.location);
                    props.toggle(false);
                  }}
                >
                  <ListItemIcon>{el.icon}</ListItemIcon>
                  <ListItemText>
                    <Typography
                      variant={"body1"}
                      style={{ fontWeight: "bold" }}
                    >
                      {el.name}
                    </Typography>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </div>
      </Drawer>
    );
  };
  renderBottomNavigation(props) {
    const { menuList } = props;
    return (
      <BottomNavigation showLabels className={props.classes.bottomNavigation}>
        {menuList.map((el) => {
          return (
            <BottomNavigationAction
              key={el.name}
              label={el.name}
              icon={el.icon}
            />
          );
        })}
      </BottomNavigation>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderDrawer(this.props)}
        {/* <Hidden smDown>{this.renderDrawer(this.props)}</Hidden>
        <Hidden mdUp>{this.renderBottomNavigation(this.props)}</Hidden> */}
      </React.Fragment>
    );
  }
}

DynamicNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(DynamicNavigation);
