import axios from "utils/API";
import Axios from "axios";
import Authentication from "controller/Authentication";

export default class SystemNotificationsController {
  constructor() {
    this.sendNotificationSource = Axios.CancelToken.source();
  }

  targetToUrl = (target) => {
    switch (target) {
      case "all":
        return "/api/system/notify/all";
      case "students":
        return "/api/system/notify/students";
      case "teachers":
        return "/api/system/notify/teachers";
      case "admins":
        return "/api/system/notify/admins";
      default:
        return null;
    }
  };

  sendNotification = async (notification, target) => {
    const url = this.targetToUrl(target);
    if (url === null) return;

    if (this.sendNotificationSource) this.sendNotificationSource.cancel();
    this.sendNotificationSource = Axios.CancelToken.source();

    const response = await axios.post(
      url,
      { notification },
      { cancelToken: this.sendNotificationSource.token }
    );

    return response.data;
  };

  cancelAllTokens() {
    if (this.sendNotificationSource) this.sendNotificationSource.cancel();
  }
}
