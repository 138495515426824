import {
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import Dashboard from "components/DashboardContainer/Dashboard";
import PostData from "components/Fetching/PostData";
import dayjs from "dayjs";
import React, { Component } from "react";
import Controller from "./SystemAssignmentController";
const controller = new Controller();

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(1),
    },
  },
});

class SystemCreateAssignment extends Component {
  state = {
    assignment: { criteria: [{ name: "Entrega", percentage: 0 }], score: 0 },
    idClassroom: "",
    uid: "",
    name: "",
    description: "",
    loading: false,
    date: dayjs(new Date().setHours(23, 59, 0, 0)).format("YYYY-MM-DDTHH:mm"),
  };

  constructor(props) {
    super(props);
    this.postRef = React.createRef();
  }

  componentWillUnmount() {
    controller.cancelAllTokens();
  }
  onClick = () => {
    this.postRef.current.post();
  };

  addCriteria = () => {
    let assignment = { ...this.state.assignment };
    assignment.criteria.push({ name: "", percentage: 0 });
    const index = assignment.criteria.length - 1;

    this.setState({ assignment }, () => {
      document.getElementById(`criteria-name-${index}`).focus();
    });
  };

  removeCriteria = (index) => {
    let assignment = { ...this.state.assignment };
    assignment.criteria.splice(index, 1);
    this.setState({ assignment }, () => {
      document.getElementById(`add-btn`).focus();
      this.updateCriteria();
    });
  };

  updateCriteria = (e, name, index) => {
    let assignment = { ...this.state.assignment };

    if (e) assignment.criteria[index][name] = e.target.value;
    assignment.score = assignment.criteria.reduce((acum, value) => {
      let val = acum + parseFloat(value.percentage);
      return parseFloat(val.toFixed(10));
    }, 0);
    this.setState({ assignment });
  };
  onTextChange = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  request = async () => {
    const { name, description, assignment, date } = this.state;
    const dueTime = dayjs(date, "YYYY-MM-DD HH:mm");
    const data = await controller.createAssignment(
      this.state.idClassroom,
      {
        name,
        description,
        dueTime,
        criteria: [...assignment.criteria],
      },
      this.state.uid
    );

    return data;
  };
  render() {
    const { classes } = this.props;
    return (
      <Dashboard history={this.props.history}>
        <PostData
          ref={this.postRef}
          onLoading={(loading) => this.setState({ loading })}
          request={this.request}
        />
        <Container maxWidth="md">
          <form className={classes.root} noValidate autoComplete="off">
            <br />
            <Typography variant="h3" align="left">
              Crear Tarea
            </Typography>
            <br />
            <br />
            <TextField
              variant="outlined"
              fullWidth
              label="Id del Salón"
              autoComplete="off"
              value={this.state.idClassroom}
              onChange={(e) => this.onTextChange("idClassroom", e)}
            />
            <TextField
              variant="outlined"
              fullWidth
              label="Id del Profesor"
              autoComplete="off"
              value={this.state.uid}
              onChange={(e) => this.onTextChange("uid", e)}
            />
            <br />
            <Divider />
            <br />
            <TextField
              variant="outlined"
              fullWidth
              label="Nombre de la tarea"
              autoComplete="off"
              value={this.state.name}
              onChange={(e) => this.onTextChange("name", e)}
            />
            <TextField
              variant="outlined"
              fullWidth
              label="Descripción de la tarea"
              autoComplete="off"
              value={this.state.description}
              onChange={(e) => this.onTextChange("description", e)}
            />
            <TextField
              variant="outlined"
              fullWidth
              type="datetime-local"
              label="Fecha de Entrega"
              autoComplete="off"
              value={this.state.date}
              onChange={(e) => this.onTextChange("date", e)}
            />
            <div className={classes.section}>
              <br />
              <Divider />
              <br />
              <div className={classes.form}>
                <Grid container spacing={2}>
                  {this.state.assignment.criteria.map((el, index, arr) => {
                    return (
                      <React.Fragment>
                        <Grid item xs={8}>
                          <TextField
                            label="Nombre"
                            variant="outlined"
                            margin="dense"
                            value={el.name}
                            fullWidth
                            id={`criteria-name-${index}`}
                            onChange={(e) => {
                              this.updateCriteria(e, "name", index);
                            }}
                          />
                        </Grid>
                        <Grid item xs={arr.length > 1 ? 3 : 4}>
                          <TextField
                            label="Punteo"
                            variant="outlined"
                            margin="dense"
                            type="number"
                            value={el.percentage}
                            onChange={(e) => {
                              this.updateCriteria(e, "percentage", index);
                            }}
                            fullWidth
                          />
                        </Grid>
                        {arr.length > 1 && (
                          <Grid item xs={1}>
                            <IconButton
                              onClick={() => {
                                this.removeCriteria(index);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Grid>
                        )}
                      </React.Fragment>
                    );
                  })}
                </Grid>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    id="add-btn"
                    color="primary"
                    onClick={() => {
                      this.addCriteria();
                    }}
                  >
                    Agregar Criterio
                  </Button>
                </div>

                <TextField
                  variant="outlined"
                  fullWidth
                  label="Punteo"
                  margin="dense"
                  type="number"
                  disabled
                  value={this.state.assignment.score}
                  onChange={(e) => {
                    this.updateAssignment(e, "score");
                  }}
                />
              </div>
            </div>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={this.state.loading}
              onClick={this.onClick}
            >
              Crear Tarea
            </Button>
          </form>
        </Container>
      </Dashboard>
    );
  }
}

export default withStyles(styles)(SystemCreateAssignment);
