import axios from "utils/API";
import Axios from "axios";

export default class StudentClassroomManagementController {
  constructor() {
    this.classroomsSource = Axios.CancelToken.source();
  }

  /**
   * @param {String} textFilter
   * @param {Number} page Número de página a traer
   */
  getClassrooms = async (textFilter, page = 0) => {
    let filter = textFilter ? `&textFilter=${textFilter}` : "";

    if (this.classroomsSource) this.classroomsSource.cancel();
    this.classroomsSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/student/classrooms?page=${page}&pageSize=100${filter}`,
      { cancelToken: this.classroomsSource.token }
    );
    return response.data;
  };

  cancelAllTokens() {
    if (this.classroomsSource) this.classroomsSource.cancel();
  }
}
