import React, { Component } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment"; // new

import "moment/locale/es-mx";
import {
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Hidden,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calendar.css";
import Dashboard from "components/DashboardContainer/Dashboard";
import Controller from "./StudentCalendarController";
import { generatePath } from "react-router";
import routes from "Pages/routes";
import axios from "axios";

// import "react-big-calendar/lib/sass/styles";

const localizer = momentLocalizer(moment);
const controller = new Controller();
let allViews = Object.keys(Views).map((k) => Views[k]);

const messages = {
  // new
  allDay: "Todo el día",
  previous: "Anterior",
  next: "Siguiente",
  today: "Fecha Actual",
  month: "Mes",
  week: "Semana",
  day: "Día",
  agenda: "Agenda",
  date: "Fecha",
  time: "Hora",
  event: "Evento",
  showMore: (e) => {
    return "+" + e + " más";
  },
};

class StudentCalendar extends Component {
  state = { events: [], more: [], moreOpen: false, loading: false };

  async componentDidMount() {
    let events = [];
    this.setState({ loading: true, events });
    try {
      let assignments = await controller.getAll();

      events = assignments.map((el) => {
        return {
          id: el.id,
          title: el.name,
          start: el.dueTime,
          end: el.dueTime,
          ...el,
        };
      });
    } catch (error) {
      if (axios.isCancel(error)) return;
    } finally {
      this.setState({ loading: false, events });
    }
  }
  componentWillUnmount() {
    controller.cancelAllTokens();
  }

  pushToURL = (el) => {
    if (el.type === "homework")
      this.props.history.push(
        generatePath(routes.student.assignment, {
          id: el.idClassroom,
          idAssignment: el.id,
        })
      );

    if (el.type === "improve")
      this.props.history.push(
        generatePath(routes.student.improvePlan, {
          id: el.idClassroom,
          idAssignment: el.id,
        })
      );
  };

  desktopCalendar = () => {
    return (
      <div style={{ padding: 50, paddingLeft: 100, paddingRight: 100 }}>
        <Calendar
          views={["month"]}
          messages={messages} // new
          localizer={localizer}
          events={this.state.events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 800 }}
          onShowMore={(e) => {
            this.setState({ more: e, moreOpen: true });
          }}
          onSelectEvent={this.pushToURL}
        />
      </div>
    );
  };

  mobileCalendar = () => {
    return (
      <div style={{ padding: 10 }}>
        <Calendar
          defaultView={"month"}
          views={["month"]}
          messages={messages} // new
          localizer={localizer}
          events={this.state.events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 550 }}
          onShowMore={(e) => {
            this.setState({ more: e, moreOpen: true });
          }}
          onSelectEvent={this.pushToURL}
        />
      </div>
    );
  };

  render() {
    return (
      <Dashboard history={this.props.history}>
        {this.state.loading && <LinearProgress />}
        <Hidden mdUp>{this.mobileCalendar()}</Hidden>
        <Hidden smDown>{this.desktopCalendar()}</Hidden>
        <Dialog open={this.state.moreOpen} onClose={this.handleClose} fullWidth>
          <DialogTitle>Tareas</DialogTitle>
          <DialogContent>
            <List>
              {this.state.more.map((el) => {
                return (
                  <ListItem
                    button
                    id={el.id}
                    key={el.id}
                    onClick={(e) => this.pushToURL(el)}
                  >
                    <ListItemText primary={el.title} />
                    {el.type === "improve" && (
                      <Chip
                        color="primary"
                        label="PM"
                        variant="outlined"
                        style={{ marginRight: 10 }}
                      />
                    )}
                    {el.delivered && <Chip color="primary" label="Entregada" />}
                  </ListItem>
                );
              })}
            </List>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleClose}>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Dashboard>
    );
  }

  handleClose = () => {
    this.setState({ moreOpen: false, more: [] });
  };
}

export default StudentCalendar;

/**
 * { date: 'Date',
        time: 'Time',
        event: 'Event',
        allDay: 'All Day',
        week: 'Week',
        work_week: 'Work Week',
        day: 'Day',
        month: 'Month',
        previous: 'Back',
        next: 'Next',
        yesterday: 'Yesterday',
        tomorrow: 'Tomorrow',
        today: 'Today',
        agenda: 'Agenda',
        noEventsInRange: 'There are no events in this range.',
        showMore: function(e) {
          return '+' + e + ' more'
        }
 */
