import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import {
  ArrowBack,
  ArrowLeft,
  Close,
  FileCopy,
  ThumbUpAltSharp,
  Wallpaper,
  YouTube,
  YoutubeSearchedFor,
} from "@material-ui/icons";
import axios from "axios";
import UploadController from "components/DownloadButton/UploadController";
import { toast } from "material-react-toastify";
import { DropzoneArea } from "material-ui-dropzone";
import React, { Component } from "react";
import { Back } from "react-bootstrap-icons";
import Controller from "./PostsController";

let upload = new UploadController();
let controller = new Controller();

class CreatePostDialog extends Component {
  state = {
    type: "text",
    addingImages: false,
    addingFiles: false,
    addingVideo: false,
    images: [],
    files: [],
    loading: false,
    title: "",
    content: "",
    videoURL: "",
    upload: { progress: 0, uploading: false },
  };

  setType = (type) => {
    let addingImages = false,
      addingFiles = false,
      addingVideo = false;
    if (type === "image") {
      addingImages = true;
    }

    if (type === "video") {
      addingVideo = true;
    }

    if (type === "files") {
      addingFiles = true;
    }

    this.setState({ addingImages, addingVideo, addingFiles, type });
  };

  componentWillUnmount() {
    controller.cancelAllTokens();
    upload.cancelAllTokens();
  }

  onUploadProgress = (progressEvent) => {
    let progress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    let { upload } = this.state;
    upload.progress = progress;
    this.setState({ upload });
  };

  uploadURL = () => {
    let { idClassroom } = this.props;
    // if (this.state.type === "files")
    return `files/v2/classrooms/${idClassroom}/posts`;
    // else if (this.state.type === "image")
    // return `files/classrooms/${idClassroom}/posts/images`;
  };

  onUploadFinish = (data) => {
    let { upload } = this.state;
    upload.progress = 0;
    upload.uploading = false;
    this.setState({ upload });
  };

  onUploadCancel = () => {
    let { upload } = this.state;
    upload.progress = 0;
    upload.uploading = false;
    this.setState({ upload });
  };

  uploadFiles = async () => {
    this.setState({ upload: { uploading: true, progress: 0 } });
    let res = await upload.startUpload(
      this.uploadURL(),
      this.state.files,
      "file",
      this.onUploadProgress,
      this.onUploadFinish
    );
    return res;
  };

  uploadImage = async () => {
    this.setState({ upload: { uploading: true, progress: 0 } });
    let res = await upload.startUpload(
      this.uploadURL(),
      this.state.images,
      "file",
      this.onUploadProgress,
      this.onUploadFinish
    );
    return res;
  };

  createPost = async () => {
    let { idClassroom } = this.props;
    this.setState({ loading: true });
    let post = {
      type: this.state.type,
      title: this.state.title,
      content: this.state.content,
    };

    if (post.type === "image") {
      let result = await this.uploadImage();
      post.url = result.uri;
    }

    if (post.type === "files") {
      let result = await this.uploadFiles();
      post.url = result.uri;
    }

    if (post.type === "video") {
      post.url = this.state.videoURL;
    }

    try {
      await controller.createPost(post, idClassroom);
      this.props.onClose();
      if (this.props.onCreatePost) this.props.onCreatePost(post);
      toast(`Publicación ha sido creada`, { type: "success" });
    } catch (error) {
      if (axios.isCancel(error)) return;
      toast(`Error al crear publicación`, { type: "error" });
    } finally {
      this.setState(
        {
          loading: false,
          images: [],
          files: [],
          title: "",
          content: "",
          videoURL: "",
        },
        () => this.setType("text")
      );
    }
  };

  handleDropzoneImageChange = async (files) => {
    this.setState({ images: files });
  };

  handleDropzoneFilesChange = async (files) => {
    this.setState({ files });
  };

  renderBack = () => {
    return (
      <div style={{ marginLeft: -15, marginBottom: 10 }}>
        <IconButton onClick={() => this.setType("text")}>
          <Close />
        </IconButton>
      </div>
    );
  };

  renderAddImages = () => {
    return (
      <React.Fragment>
        {this.renderBack()}
        <DropzoneArea
          onChange={this.handleDropzoneImageChange}
          dropzoneText="Arrastra una imagen o da click"
          maxFileSize={1024 * 1024 * 20}
          filesLimit={1}
          showAlerts={false}
          acceptedFiles={["image/jpeg", "image/jpg", "image/png"]}
        />
      </React.Fragment>
    );
  };

  renderAddVideo = () => {
    return (
      <React.Fragment>
        {this.renderBack()}

        <TextField
          type="text"
          autoComplete="off"
          variant="outlined"
          label="URL de Youtube"
          placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
          margin="dense"
          fullWidth
          autoFocus
          value={this.state.videoURL}
          onChange={(e) => this.setState({ videoURL: e.target.value })}
        />
      </React.Fragment>
    );
  };

  renderAddFiles = () => {
    return (
      <React.Fragment>
        {this.renderBack()}
        <DropzoneArea
          onChange={this.handleDropzoneFilesChange}
          dropzoneText="Arrastra archivos o da click"
          maxFileSize={1024 * 1024 * 100}
          filesLimit={1}
          showAlerts={false}
        />
      </React.Fragment>
    );
  };

  handleClose = () => {
    if (this.state.loading) {
      return;
    }

    this.props.onClose();
  };

  render() {
    return (
      <Dialog fullWidth open={this.props.open} onClose={this.handleClose}>
        {this.state.upload.uploading && (
          <LinearProgress
            variant="determinate"
            value={this.state.upload.progress}
          />
        )}
        {this.state.loading && !this.state.upload.uploading && (
          <LinearProgress />
        )}
        <DialogTitle>Crear Publicación</DialogTitle>
        <DialogContent>
          <TextField
            type="text"
            autoComplete="off"
            variant="outlined"
            label="Título"
            margin="dense"
            fullWidth
            autoFocus
            value={this.state.title}
            onChange={(e) => this.setState({ title: e.target.value })}
          />

          <div style={{ margin: 5 }} />
          <TextField
            multiline
            type="text"
            autoComplete="off"
            label="Contenido"
            variant="outlined"
            fullWidth
            value={this.state.content}
            onChange={(e) => this.setState({ content: e.target.value })}
          />

          <div style={{ marginTop: 10 }} />
          <Divider />

          <div style={{ marginTop: 10 }}>
            {this.state.type === "text" && (
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    endIcon={<Wallpaper />}
                    onClick={() => this.setType("image")}
                  >
                    Agregar Imagen
                  </Button>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    endIcon={<FileCopy />}
                    onClick={() => this.setType("files")}
                  >
                    Agregar Archivos
                  </Button>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    endIcon={<YouTube />}
                    onClick={() => this.setType("video")}
                  >
                    Agregar Video
                  </Button>
                </Grid>
              </Grid>
            )}

            {this.state.addingImages && this.renderAddImages()}
            {this.state.addingFiles && this.renderAddFiles()}
            {this.state.addingVideo && this.renderAddVideo()}
          </div>
        </DialogContent>
        <br />
        <br />
        <DialogActions>
          <Button
            color="primary"
            disabled={this.state.loading}
            onClick={this.handleClose}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            disabled={this.state.loading}
            onClick={this.createPost}
          >
            Crear Publicación
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CreatePostDialog;
