import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Collapse,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import Authentication from "controller/Authentication";
import React, { Component } from "react";
import clsx from "clsx";

const style = (theme) => ({
  expand: {
    transform: "rotate(0deg)",
    //   marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
});

class SummaryCard extends Component {
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  render() {
    const { summary, classes } = this.props;
    const { expanded } = this.state;
    const el = summary;

    return (
      <Card variant="outlined" style={{ textAlign: "left" }}>
        <CardHeader
          avatar={
            <Avatar style={{ width: 80, height: 80 }} src={el.photoURL} />
          }
          title={
            <React.Fragment>
              <div style={{ display: "flex" }}>
                <Typography variant="subtitle2">
                  {Authentication.formatName(el.name, el.lastName)}
                </Typography>

                {<div style={{ flexGrow: 1 }} />}
                <Typography variant="h5">{`${el.score} de 100`}</Typography>
              </div>
            </React.Fragment>
          }
          subheader={<Typography variant="body1">{el.name}</Typography>}
        />
        <CardActions>
          <Button
            onClick={this.handleExpandClick}
            endIcon={
              <ExpandMore
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
              />
            }
          >
            Más información
          </Button>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {el.assignments.map((el) => {
            let score = el.score ?? 0;
            if (el.improve && (el.improve.score ?? -1) >= 0)
              score = el.improve.score;
            return (
              <React.Fragment>
                <div style={{ display: "flex", padding: 20 }}>
                  <Typography variant="body1">{el.assignmentName}</Typography>
                  <div style={{ flexGrow: 1 }} />
                  <Typography style={{ fontWeight: 600 }}>{`${score ?? 0} / ${
                    el.assignmentScore
                  }`}</Typography>
                </div>
              </React.Fragment>
            );
          })}
        </Collapse>
      </Card>
    );
  }
}

export default withStyles(style)(SummaryCard);
