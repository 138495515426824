import React, { Component } from "react";
import styles from "./UserManagement-styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Hidden,
  Paper,
  Avatar,
  CardHeader,
  IconButton,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  CardActionArea,
  LinearProgress,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { MoreVertOutlined, Search as SearchIcon } from "@material-ui/icons";
import AdminAppbar from "components/Appbar/AdminAppbar";
import AdminDashboard from "components/DashboardContainer/AdminDashboard";
import axios from "utils/API";
import Authentication from "controller/Authentication";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const CardManagementAction = ({ children, onClick }) => {
  return (
    <React.Fragment>
      <Hidden smDown>{children}</Hidden>
      <Hidden mdUp>
        <CardActionArea onClick={onClick}>{children}</CardActionArea>
      </Hidden>
    </React.Fragment>
  );
};

class UserManagementCard extends Component {
  state = {
    menuAnchor: null,
  };

  handleOpenMenu = (e) => {
    if (this.props.hasTransaction) return;
    this.setState({ menuAnchor: e.currentTarget });
  };
  handleCloseMenu = () => {
    this.setState({ menuAnchor: null });
  };

  renderApproveMenu = (props) => {
    return (
      <Menu
        elevation={3}
        id="account-settings"
        anchorEl={this.state.menuAnchor}
        open={Boolean(this.state.menuAnchor) && !this.props.hasTransaction}
        onClose={this.handleCloseMenu}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        getContentAnchorEl={null}
      >
        <MenuItem
          onClick={() => {
            props.handleOpenApproveMenu(props.user);
            this.handleCloseMenu();
          }}
        >
          <Typography variant={"body1"}>Aprobar rol</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            props.handleApprove(props.user, "student");
            this.handleCloseMenu();
          }}
        >
          <Typography variant={"body1"}>Aprobar estudiante</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            props.handleDelete(props.user);
            this.handleCloseMenu();
          }}
        >
          <Typography variant={"body1"}>Eliminar estudiante</Typography>
        </MenuItem>
      </Menu>
    );
  };

  renderEditMenu = (props) => {
    return (
      <Menu
        elevation={3}
        id="account-settings"
        anchorEl={this.state.menuAnchor}
        open={Boolean(this.state.menuAnchor)}
        onClose={this.handleCloseMenu}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        getContentAnchorEl={null}
      >
        <MenuItem
          onClick={() => {
            props.handleOpenApproveMenu(props.user);
            this.handleCloseMenu();
          }}
        >
          <Typography variant={"body1"}>Cambiar rol</Typography>
        </MenuItem>

        {props.user.idRole === "student" && (
          <MenuItem
            onClick={() => {
              props.handleOpenStudentMenu(props.user);
              this.handleCloseMenu();
            }}
          >
            <Typography variant={"body1"}>Establecer sección</Typography>
          </MenuItem>
        )}
      </Menu>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      name = "",
      lastName = "",
      email = "",
      role = "",
      approved = 0,
      idRole = 1,
      photoURL = "",
    } = this.props.user;

    return (
      <React.Fragment>
        <Card elevation={1} style={{ height: "100%" }}>
          {this.props.hasTransaction && <LinearProgress />}
          <CardManagementAction onClick={this.handleOpenMenu}>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="avatar"
                  style={{
                    marginRight: 10,
                    width: 60,
                    height: 60,
                  }}
                  src={photoURL}
                ></Avatar>
              }
              title={
                <Typography
                  variant="subtitle2"
                  align="left"
                  color="textPrimary"
                  style={{}}
                >
                  {Authentication.formatName(name, lastName)}
                </Typography>
              }
              subheader={
                <React.Fragment>
                  {approved == 1 && (
                    <Typography variant="body1" align="left">
                      {role}
                    </Typography>
                  )}
                  <Hidden smDown>
                    <Typography
                      variant="body2"
                      align="left"
                      color="textSecondary"
                    >
                      {email}
                    </Typography>
                  </Hidden>
                </React.Fragment>
              }
              action={
                <Hidden smDown>
                  <IconButton
                    aria-label="settings"
                    onClick={this.handleOpenMenu}
                  >
                    <MoreVertOutlined />
                  </IconButton>
                </Hidden>
              }
            />
          </CardManagementAction>
        </Card>
        {approved == 0
          ? this.renderApproveMenu(this.props)
          : this.renderEditMenu(this.props)}
      </React.Fragment>
    );
  }
}

UserManagementCard.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(UserManagementCard);
