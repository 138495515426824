import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import axios from "utils/API";

export default class Authentication {
  constructor() { }

  static instance = this.instance || new Authentication();

  static initializeApp() {
    this.instance.initialize();
  }

  initialize() {
    onAuthStateChanged(getAuth(), async (user) => {
      await this._bringUserData(user);
    });

    axios.interceptors.request.use(async (config) => {
      const sessionToken = await getAuth().currentUser.getIdToken();
      this.sessionToken = sessionToken;
      config.headers = { Authorization: this.sessionToken };
      return config;
    });

    // firebase.auth().onIdTokenChanged(async (token) => {
    //   if (token == null) {
    //     axios.defaults.headers.common["Authorization"] = null;
    //     return;
    //   }
    //   this.sessionToken = await token.getIdToken();
    //   axios.defaults.headers.common["Authorization"] = this.sessionToken;
    // });
  }

  firebaseUser = null;
  userTemplate = {};
  user = {};
  sessionToken = null;
  section = null;

  static emptyUser = {
    uid: "",
    name: "",
    lastName: "",
    birthdate: "",
    phone: "",
    phoneExt: "",
    email: "",
    address: "",
    photoURL: "",
    searchname: "",
    idRole: "",
    role: "",
    displayName: "",
    isVerified: false,
  };

  /**
   * Refresca la información del usuario con la información que se
   * encuentra en la base de datos
   */
  async refreshUserData() {
    await this._bringUserData(getAuth().currentUser);
  }

  /**
   * Trae la información del usuario de la base de datos personalizada
   * @param {import("firebase").User} firebaseUser
   */
  async _bringUserData(firebaseUser) {
    if (firebaseUser == null) {
      //axios.defaults.headers.common["Authorization"] = null;
      this.user = null;
      this.sessionToken = null;
      this.section = null;
      this.onUserChange(null);
      return;
    }

    this.sessionToken = await firebaseUser.getIdToken();
    //axios.defaults.headers.common["Authorization"] = this.sessionToken;

    const res = await axios.get("/api/account-management/me/account-data");
    this.user = res.data;
    this.user.isVerified = firebaseUser.emailVerified;
    this.user.displayName = Authentication.formatName(
      this.user.name,
      this.user.lastName
    );

    if (this.user.idRole === "student") {
      const res1 = await axios.get("/api/account-management/me/section");
      this.section = res1.data;
    }

    this.onUserChange(this.user);

    //No guardar usuarios no verificados
    if (!this.user.isVerified) {
      this.user = null;
      this.sessionToken = null;
    }
  }

  /**
   * Events when user is changed
   */
  changeUserHandler = [];

  /**
   * @param {User} user
   */
  onUserChange(user) {
    this.changeUserHandler.forEach((handler) => handler(user));
  }

  /**
   *
   * @param {*} handler
   */
  subscribeToUserChange(handler) {
    if (this.user == null || this.user.uid) handler(this.user);
    this.changeUserHandler.push(handler);
  }

  /**
   *
   * @param {*} handler
   */
  unsubscribeFromUserChange(handler) {
    this.changeUserHandler = this.changeUserHandler.filter(
      (h) => h !== handler
    );
  }

  static formatName(name, lastName) {
    // name = name ?? "";
    // lastName = lastName ?? "";
    // let arr = name.split(" ");
    // if (arr.length > 0) name = arr[0];
    // arr = lastName.split(" ");
    // if (arr.length > 0) lastName = arr[0];
    return lastName + ", " + name;
  }
}
