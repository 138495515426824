import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  withStyles,
  withWidth,
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";
import {
  BackupRounded,
  ShowChart,
  ArrowBack,
  Edit,
  Delete,
  Add,
  FileCopy,
} from "@material-ui/icons";
import React, { Component } from "react";
import styles from "./AssignmentStyles";
import Controller from "./AssignmentController";
import Axios from "axios";
import { toast } from "material-react-toastify";
import Classroom from "../Classroom";
import dayjs from "dayjs";
import AssignmentErrorMessages from "./AssignmentErrorMessages";
import DefaultErrorMessages from "utils/DefaultErrorMessages";
import DeleteDialog from "components/Dialogs/DeleteDialog";
import routes from "Pages/routes";
import { generatePath } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { Download } from "react-bootstrap-icons";
import { DropzoneArea } from "material-ui-dropzone";
import GradeAssignment from "./GradeAssignment/GradeAssignment";
import Authentication from "controller/Authentication";
import EditCriteriaDialog from "./EditCriteriaDialog";
import DownloadButton from "components/DownloadButton/DownloadButton";
import UploadButton from "components/DownloadButton/UploadButton";
import axios from "axios";
import CopyAssignmentDialog from "./CopyAssignmentDialog";
import GetData from "components/Fetching/GetData";
import ClassroomController from "../ClassroomController";

const classroomController = new ClassroomController();
const controller = new Controller();

class Assignment extends Component {
  state = {
    files: [],

    editCriterias: {
      data: [],
      loading: false,
      dialogOpen: false,
    },

    assignment: {
      loading: true,
      data: null,
      error: null,
    },
    delivered: [],
    totalStudents: "--",
    uploadProgress: 0,
    temaryDialog: {
      editing: false,
      open: false,
      loading: false,
      title: "Editar Temario",
      btnLbl: "Editar",
    },

    classroom: {},
    emptyAssignment: {
      name: "",
      description: "",
      score: 0,
      originalAssignment: null,
      dueTime: dayjs(new Date(new Date().setHours(24, 0, 0, 0))),
      dueTimeStr: "00:00",
      dueDateStr: dayjs(new Date().setHours(24, 0, 0, 0)).format("YYYY-MM-DD"),
      criteria: [],
      filesURL: null,
    },
    editLoading: false,
    editDialogOpen: false,
    editAssignment: {
      score: 0,
      dueTime: dayjs(new Date(new Date().setHours(24, 0, 0, 0))),
      dueTimeStr: "00:00",
      dueDateStr: dayjs(new Date().setHours(24, 0, 0, 0)).format("YYYY-MM-DD"),
    },

    delete: {
      loading: false,
      dialogOpen: false,
    },

    copyDialogOpen: false,
    gradingDialog: {
      data: [],
      editing: false,
      open: false,
    },
  };

  handleDropzoneChange = async (files) => {
    this.setState({ files: files });
  };
  componentDidMount() {
    this.handleClassroomId(this.props.match.params.id);
  }

  getInfo = async () => {
    try {
      let [total, del] = await Promise.all([
        controller.getTotalStudents(this.props.match.params.id),
        controller.getDeliveredAssignments(
          this.props.match.params.id,
          this.props.match.params.idAssignment
        ),
      ]);
      const delivered = [...del.scored, ...del.pending];

      this.setState({ totalStudents: total.total, delivered });
    } catch (error) {}
  };

  //CRITERIA
  handleEditCriteriaDialogOpen = (val) => {
    let editCriterias = { ...this.state.editCriterias };
    editCriterias.dialogOpen = val;
    if (val) {
      let criterias = [...this.state.assignment.data.criteria];
      editCriterias.data = criterias.map((el) => {
        return { ...el };
      });
    }
    this.setState({ editCriterias });
  };

  updateCriteria = (e, name, index) => {
    let editCriterias = { ...this.state.editCriterias };
    editCriterias.data[index][name] = e.target.value;
    this.setState({ editCriterias });
  };
  addCriteria = () => {
    let editCriterias = { ...this.state.editCriterias };
    editCriterias.data.push({ name: "", percentage: 0 });
    const index = editCriterias.data.length - 1;

    this.setState({ editCriterias }, () => {
      document.getElementById(`criteria-name-${index}`).focus();
    });
  };

  removeCriteria = (index) => {
    let editCriterias = { ...this.state.editCriterias };
    editCriterias.data.splice(index, 1);
    this.setState({ editCriterias }, () => {
      document.getElementById(`add-btn`).focus();
    });
  };
  //

  componentWillUnmount() {
    classroomController.cancelAllTokens();
    controller.cancelAllTokens();
  }
  getAssignment = async (idClassroom, idAssignment) => {
    this.setState({ assignment: { loading: true, data: null, error: null } });
    try {
      let result = await controller.getAssignment(idClassroom, idAssignment);
      this.setState({
        assignment: { loading: false, data: { ...result }, error: null },
        editAssignment: {
          ...this.state.editAssignment,
          score: result.score,
          name: result.name,
          description: result.description,
        },
      });

      this.setDueTime(
        dayjs(result.dueTime).format("YYYY-MM-DD"),
        dayjs(result.dueTime).format("HH:mm")
      );
    } catch (error) {
      if (Axios.isCancel(error)) return;
      let assignment = { loading: false, data: null, error };
      this.setState({ assignment }, () => {});
      toast("Error al obtener la tarea", { type: "error" });
    }
  };

  editAssignment = async () => {
    try {
      this.setState({ editLoading: true });
      await controller.editAssignment(
        this.props.match.params.id,
        this.props.match.params.idAssignment,
        { ...this.state.editAssignment }
      );
      this.setState({ editLoading: false, editDialogOpen: false }, () => {
        this.getAssignment(
          this.props.match.params.id,
          this.props.match.params.idAssignment
        );
      });

      toast(`Tarea actualizada!`, { type: "success" });
    } catch (error) {
      if (Axios.isCancel(error)) return;
      this.setState({ editLoading: false });

      let message =
        AssignmentErrorMessages(error) ||
        DefaultErrorMessages(error, "Error desconocido al crear tarea");
      toast(message, { type: "error" });
    }
  };

  handleDialogOpen = (value) => {
    if (this.state.editLoading) return;

    let currentAssignment = { ...this.state.assignment.data };
    this.setState(
      {
        editDialogOpen: value,
        editAssignment: {
          ...this.state.editAssignment,
          score: currentAssignment.score,
          name: currentAssignment.name,
          description: currentAssignment.description,
        },
      },
      () => {
        this.setDueTime(
          dayjs(currentAssignment.dueTime).format("YYYY-MM-DD"),
          dayjs(currentAssignment.dueTime).format("HH:mm")
        );
      }
    );
  };

  handleClassroomId = (id) => {
    this.getInfo();
    this.getAssignment(id, this.props.match.params.idAssignment);
  };

  setDueTime = (date, time) => {
    let fullDateStr = `${date} ${time}`;
    let d = dayjs(fullDateStr, "YYYY-MM-DD HH:mm");
    let editAssignment = {
      ...this.state.editAssignment,
      dueTime: d,
      dueTimeStr: time,
      dueDateStr: date,
    };

    this.setState({ editAssignment });
  };

  setDate = (e) => {
    let date = e.target.value;
    if (date == "")
      date = dayjs(this.state.editAssignment.dueTime).format("YYYY-MM-DD");
    let newE = { target: { value: date } };
    this.setDueTime(date, this.state.editAssignment.dueTimeStr);
  };

  setTime = (e) => {
    let time = e.target.value;
    if (time == "")
      time = dayjs(this.state.editAssignment.dueTime).format("HH:mm");
    let newE = { target: { value: time } };
    this.setDueTime(this.state.editAssignment.dueDateStr, time);
  };

  updateEditAssignment = (e, name) => {
    let editAssignment = { ...this.state.editAssignment };
    editAssignment[name] = e.target.value;
    this.setState({ editAssignment });
  };

  handleDeleteDialogOpen = () => {
    let d = { ...this.state.delete };
    d.dialogOpen = true;
    this.setState({ delete: d });
  };
  handleDeleteDialogClose = (callback) => {
    let d = { ...this.state.delete };
    d.dialogOpen = false;
    this.setState({ delete: d }, callback);
  };

  push = (link) => {
    this.props.history.push(generatePath(link, { ...this.props.match.params }));
  };

  handleDelete = async () => {
    try {
      this.setState({ delete: { ...this.state.delete, loading: true } });
      await controller.deleteAssignment(
        this.props.match.params.id,
        this.props.match.params.idAssignment
      );
      toast("Tarea eliminada", { type: "success" });
      this.push(routes.teacher.classroomAssignments);
      //this.setState({ delete: { loading: false, dialogOpen: false } });
      //this.props.history.push(routes.teacher.assignment);
    } catch (error) {
      if (Axios.isCancel(error)) return;
      this.setState({ delete: { loading: false, dialogOpen: false } });

      let message =
        AssignmentErrorMessages(error) ||
        DefaultErrorMessages(error, "Error desconocido al eliminar tarea");
      toast(message, { type: "error" });
    }
  };

  getTemaryUploadURL = () => {
    let { id, idAssignment } = this.props.match.params;
    return `files/v2/classrooms/${id}/assignments/temary`;
  };

  onUploadTemaryStart = (url, files, filesName) => {
    let { temaryDialog } = this.state;
    temaryDialog.loading = true;
    this.setState({ temaryDialog });
  };

  onUploadTemaryCancel = (url, files, filesName) => {
    let { temaryDialog } = this.state;
    temaryDialog.loading = false;
    this.setState({ temaryDialog });
  };

  onUploadTemaryFinish = async (data) => {
    let { id, idAssignment } = this.props.match.params;
    const filesURL = data.uri;
    let { temaryDialog } = this.state;
    try {
      temaryDialog.loading = true;
      this.setState({ temaryDialog });
      await controller.editAssignment(id, idAssignment, { filesURL });

      let { assignment } = this.state;
      assignment.data = { ...assignment.data };
      assignment.data.filesURL = filesURL;
      this.setState({ assignment });
      toast("Temario subido correctamente", { type: "success" });
    } catch (error) {
      if (axios.isCancel(error)) return;
      toast(`No se pudo actualizar la tarea con el nuevo temario`, {
        type: "error",
      });
    } finally {
      temaryDialog.open = false;
      temaryDialog.loading = false;
      this.setState({ temaryDialog });
    }
  };

  onUploadTemaryProgress = (uploadProgress) => {
    this.setState({ uploadProgress });
  };

  getClassroom = async () => {
    let data = await classroomController.getClassroom(
      this.props.match.params.id
    );

    return data;
  };

  render() {
    let assignment = this.state.emptyAssignment;
    const { data } = this.state.assignment;
    const { temaryDialog } = this.state;
    const { classroom = {} } = this.state;
    if (data !== null) assignment = { ...data };

    return (
      // <Classroom
      //   history={this.props.history}
      //   tabIndex={1}
      //   match={this.props.match}
      //   onGetClassroomId={this.handleClassroomId}
      // >
      <React.Fragment>
        <GetData
          request={this.getClassroom}
          onData={(classroom) => this.setState({ classroom })}
          disableSuccessNotification
          disableErrorNotification
        />

        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => this.push(routes.teacher.classroomAssignments)}
          >
            <ArrowBack />
          </IconButton>
          <Typography
            style={{ marginLeft: "20px" }}
            variant={"h5"}
            align="left"
          >
            Tareas
          </Typography>
        </div>
        <br />

        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <Card variant="outlined">
              <CardContent>
                <Typography
                  variant="body1"
                  align="left"
                  style={{
                    flexGrow: 1,
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  {this.state.assignment.loading ? (
                    <Skeleton variant="text" />
                  ) : (
                    assignment.name
                  )}
                </Typography>
                <Typography variant="body1" align="left">
                  {this.state.assignment.loading ? (
                    <React.Fragment>
                      <Skeleton variant="text" />
                      <Skeleton variant="text" width={250} />
                    </React.Fragment>
                  ) : (
                    assignment.description
                  )}
                </Typography>
                <br />
                <Typography
                  align="left"
                  variant="subtitle2"
                  color="textSecondary"
                >
                  {this.state.assignment.loading ? (
                    <Skeleton variant="text" width={150} />
                  ) : (
                    dayjs(assignment.dueTime).format("llll")
                  )}
                </Typography>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  disabled={
                    this.state.assignment.loading ||
                    this.state.editLoading ||
                    this.state.assignment.data.period !==
                      classroom.currentPeriod
                  }
                  onClick={() => {
                    this.handleDialogOpen(true);
                  }}
                  color="primary"
                >
                  Editar
                </Button>

                <Divider flexItem orientation="vertical" />
                <Button
                  disabled={
                    this.state.assignment.loading ||
                    this.state.delete.loading ||
                    this.state.assignment.data.period !==
                      classroom.currentPeriod
                  }
                  onClick={() => {
                    this.handleDeleteDialogOpen(true);
                  }}
                  color="primary"
                >
                  Eliminar
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Card variant="outlined" style={{ height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: 10,
                }}
              >
                <Typography
                  variant="h5"
                  align="center"
                  style={{
                    fontSize: "2rem",
                    letterSpacing: "5px",
                  }}
                >
                  {assignment.score}
                </Typography>
                <Typography variant="caption">Punteo</Typography>

                <br />
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Card variant="outlined" style={{ height: "100%" }}>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h5"
                    align="center"
                    style={{
                      fontSize: "2rem",
                      letterSpacing: "5px",
                    }}
                  >
                    {this.state.delivered.length}/{this.state.totalStudents}
                  </Typography>
                  <Typography variant="caption" gutterBottom>
                    Entregas
                  </Typography>
                  <br />
                </div>

                <Divider />
                <br />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => this.push(routes.teacher.scoresAssignment)}
                >
                  Calificar
                </Button>
                <br />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card variant="outlined">
              <CardContent>
                <Typography
                  variant="body1"
                  align="left"
                  style={{
                    flexGrow: 1,
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  Criterios a calificar
                </Typography>

                {this.state.assignment.loading && (
                  <React.Fragment>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </React.Fragment>
                )}
                {assignment.criteria.map((el, index, arr) => {
                  return (
                    <div
                      style={{ display: "flex", marginTop: "10px" }}
                      key={index}
                    >
                      <Typography varaint="body1" align="left">
                        {el.name}
                      </Typography>
                      <div style={{ flexGrow: 1 }} />
                      <Typography
                        varaint="body1"
                        align="left"
                        color="textSecondary"
                        style={{ fontWeight: "bold" }}
                      >
                        {el.percentage}
                      </Typography>
                      {index !== arr.length - 1 && (
                        <Divider style={{ marginTop: "10px" }} />
                      )}
                    </div>
                  );
                })}
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  onClick={() => {
                    this.handleEditCriteriaDialogOpen(true);
                  }}
                  color="primary"
                >
                  Editar Criterios
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card variant="outlined" style={{ height: "100%" }}>
              <CardActions>
                <Typography
                  variant="body1"
                  align="left"
                  style={{
                    marginLeft: 12,
                    flexGrow: 1,
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  {"Temario"}
                </Typography>

                <Divider flexItem orientation="vertical" />
                {assignment.filesURL !== null && (
                  <DownloadButton
                    fileURL={assignment.filesURL}
                    color="primary"
                    variant="text"
                    label="Descargar"
                  />
                )}

                <Divider flexItem orientation="vertical" />
                {assignment.filesURL === null ? (
                  <Button
                    onClick={() => {
                      this.handleTemaryDialogOpen(true, false);
                    }}
                    color="primary"
                  >
                    Agregar
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      this.handleTemaryDialogOpen(true, true);
                    }}
                    color="primary"
                  >
                    Editar
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card variant="outlined" style={{ height: "100%" }}>
              <CardActions>
                <Typography
                  variant="body1"
                  align="left"
                  style={{
                    flexGrow: 1,
                    fontWeight: 600,
                    textTransform: "uppercase",
                    marginLeft: 12,
                  }}
                >
                  Otras acciones
                </Typography>
                <Divider flexItem orientation="vertical" />
                <Button
                  onClick={() => {
                    this.handleCopyDialogOpen(true);
                  }}
                  color="primary"
                  endIcon={<FileCopy />}
                >
                  Copiar a otros salones
                </Button>
                <Divider flexItem orientation="vertical" />

                <Button
                  onClick={() => {
                    this.props.history.push(
                      generatePath(routes.teacher.createImprovePlan, {
                        ...this.props.match.params,
                      }) +
                        `?idAssignment=${this.props.match.params.idAssignment}`
                    );
                  }}
                  color="primary"
                  endIcon={<ShowChart />}
                >
                  Crear plan de Mejora
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <br />
        <DeleteDialog
          open={this.state.delete.dialogOpen}
          loading={this.state.delete.loading}
          onClose={this.handleDeleteDialogClose}
          onDelete={this.handleDelete}
        />

        <Dialog
          open={this.state.editDialogOpen}
          onClose={() => {
            this.handleDialogOpen(false);
          }}
          fullWidth
        >
          {this.state.editLoading && <LinearProgress />}
          <DialogTitle>Editar tarea</DialogTitle>
          <DialogContent>
            <TextField
              variant="outlined"
              fullWidth
              autoFocus
              label="Nombre"
              margin="dense"
              value={this.state.editAssignment.name}
              onChange={(e) => {
                this.updateEditAssignment(e, "name");
              }}
            />
            <TextField
              variant="outlined"
              fullWidth
              multiline
              label="Descripción"
              margin="dense"
              value={this.state.editAssignment.description}
              onChange={(e) => {
                this.updateEditAssignment(e, "description");
              }}
            />
            {/* <TextField
              margin="dense"
              id="edit-score"
              label="Punteo"
              type="number"
              variant="outlined"
              fullWidth
              onChange={(e) => {
                this.updateEditAssignment(e, "score");
              }}
              value={this.state.editAssignment.score}
            /> */}

            <TextField
              label="Fecha de entrega"
              variant="outlined"
              margin="dense"
              fullWidth
              type="date"
              value={this.state.editAssignment.dueDateStr}
              onChange={(e) => {
                this.setDate(e);
              }}
            />
            <TextField
              label="Hora de entrega"
              variant="outlined"
              margin="dense"
              type="time"
              fullWidth
              value={this.state.editAssignment.dueTimeStr}
              onChange={(e) => {
                this.setTime(e);
              }}
            />
          </DialogContent>

          <DialogActions>
            <Button
              disabled={this.state.editLoading}
              color="primary"
              onClick={() => {
                this.handleDialogOpen(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={this.state.editLoading}
              color="primary"
              onClick={() => {
                this.editAssignment();
              }}
            >
              Editar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={temaryDialog.open}
          onClose={() => {
            this.handleTemaryDialogOpen(false);
          }}
          fullWidth
        >
          {temaryDialog.loading && (
            <LinearProgress
              variant="determinate"
              value={this.state.uploadProgress}
            />
          )}

          <DialogTitle>{temaryDialog.title}</DialogTitle>

          <DialogContent>
            <DropzoneArea
              onChange={this.handleDropzoneChange}
              dropzoneText="Arrastra archivos o da click"
              maxFileSize={1024 * 1024 * 100}
              filesLimit={1}
              showAlerts={false}
              useChipsForPreview
            />
          </DialogContent>

          <DialogActions>
            <Button
              disabled={this.state.editLoading}
              color="primary"
              onClick={() => {
                this.handleTemaryDialogOpen(false);
              }}
            >
              Cancelar
            </Button>
            <UploadButton
              variant="text"
              disabled={temaryDialog.loading}
              onUploadStart={this.onUploadTemaryStart}
              onUploadFinish={this.onUploadTemaryFinish}
              onUploadProgress={this.onUploadTemaryProgress}
              onUploadCancel={this.onUploadTemaryCancel}
              label={temaryDialog.btnLbl}
              url={this.getTemaryUploadURL()}
              files={this.state.files}
              filesName={"file"}
              showProgress={false}
            />
          </DialogActions>
        </Dialog>

        <EditCriteriaDialog
          idClassroom={this.props.match.params.id}
          idAssignment={this.props.match.params.idAssignment}
          criterias={this.state.editCriterias.data}
          open={this.state.editCriterias.dialogOpen}
          onClose={() => this.handleEditCriteriaDialogOpen(false)}
          updateCriteria={this.updateCriteria}
          addCriteria={this.addCriteria}
          removeCriteria={this.removeCriteria}
          onEditCriterias={() => {
            this.getAssignment(
              this.props.match.params.id,
              this.props.match.params.idAssignment
            );
          }}
        />

        <CopyAssignmentDialog
          open={this.state.copyDialogOpen}
          onClose={() => this.handleCopyDialogOpen(false)}
          idClassroom={this.props.match.params.id}
          idAssignment={this.props.match.params.idAssignment}
        />
      </React.Fragment>
    );
  }

  handleCopyDialogOpen = (val) => {
    this.setState({ copyDialogOpen: val });
  };

  handleTemaryDialogOpen = (val, editing = false) => {
    let temaryDialog = { ...this.state.temaryDialog };
    if (!val) editing = temaryDialog.editing;
    temaryDialog.editing = editing;
    temaryDialog.title = editing ? "Editar Temario" : "Agregar Temario";
    temaryDialog.btnLbl = editing ? "Editar" : "Agregar";
    temaryDialog.open = val;
    this.setState({
      temaryDialog,
    });
  };
}
export default withWidth()(withStyles(styles)(Assignment));
