import axios from "axios";
import { toast } from "material-react-toastify";
import React, { Component } from "react";
import PostController from "./PostsController";
import CopyDialog from "Pages/Teacher/Classroom/Classroom/CopyDialog";

const controller = new PostController();

class CopyPostDialog extends Component {
  state = {};

  copy = async (classrooms, idClassroom) => {
    try {
      await controller.copyAssignment(
        idClassroom,
        this.props.idPost,
        classrooms
      );

      toast(`Publicación ha sido copiada a otros salones`, { type: "success" });
    } catch (error) {
      if (axios.isCancel(error)) return;
      toast(`Error al copiar una o más publicaciones`, { type: "error" });
    } finally {
    }
  };

  handleClose = () => {
    if (this.state.loading) return;
    if (this.props.onClose) this.props.onClose();
  };

  render() {
    const { open = false } = this.props;

    return (
      <CopyDialog
        open={open}
        onClose={this.props.onClose}
        idClassroom={this.props.idClassroom}
        onCopy={this.copy}
        title={`Copiar Publicación`}
        content={`Elige los salones a los que deseas copiar la publicación`}
      />
    );
  }
}

export default CopyPostDialog;
