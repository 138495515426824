import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@material-ui/core";
import React, { Component } from "react";

class PublishDialog extends Component {
  handleClose = () => {
    if (this.props.loading) return;
    this.props.onClose();
  };

  render() {
    const { open, onClick, loading } = this.props;
    return (
      <Dialog open={open} onClose={this.handleClose}>
        {loading && <LinearProgress color="primary" />}
        <DialogTitle>Publicar Salón</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Al momento de publicar el salón, los punteos{" "}
            <b>no podrán ser modificados</b>, asegúrate de que todos los punteos
            se encuentren en orden
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.handleClose} disabled={loading}>
            Cancelar
          </Button>
          <Button color="primary" onClick={onClick} disabled={loading}>
            Publicar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default PublishDialog;
