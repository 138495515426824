import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  withStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { Component } from "react";
import styles from "./AssignmentStyles";
import Controller from "./AssignmentController";
import axios from "axios";
import { toast } from "material-react-toastify";
import AssignmentErrorMessages from "./AssignmentErrorMessages";
import DefaultErrorMessages from "utils/DefaultErrorMessages";

let controller = new Controller();

class EditCriteriasDialog extends Component {
  state = { loading: false };

  editCriterias = async () => {
    try {
      this.setState({ loading: true });

      await controller.editAssignmentCriterias(
        this.props.idClassroom,
        this.props.idAssignment,
        this.props.criterias
      );

      this.setState({ loading: false });
      this.props.onEditCriterias();
      this.props.onClose();
      toast(`Criterios actualizados exitosamente`, { type: "success" });
    } catch (error) {
      if (axios.isCancel(error)) return;
      console.log(error);
      this.setState({ loading: false });
      let message =
        AssignmentErrorMessages(error) ||
        DefaultErrorMessages(error, "Error desconocido al editar criterios");
      toast(message, { type: "error" });
    }
  };

  handleClose = () => {
    if (this.state.loading) return;
    this.props.onClose();
  };

  render() {
    const { open = false, criterias } = this.props;

    return (
      <React.Fragment>
        <Dialog open={open} onClose={this.handleClose} fullWidth>
          {this.state.loading && <LinearProgress />}

          <DialogTitle>Editar Criterios</DialogTitle>

          <DialogContent>
            <DialogContentText>
              Edita los criterios a calificar
            </DialogContentText>

            <Grid container spacing={2}>
              {criterias.map((el, index, arr) => {
                return (
                  <React.Fragment>
                    <Grid item xs={8}>
                      <TextField
                        label="Nombre"
                        variant="outlined"
                        margin="dense"
                        autoFocus={index === 0}
                        value={el.name}
                        fullWidth
                        id={`criteria-name-${index}`}
                        onChange={(e) => {
                          this.props.updateCriteria(e, "name", index);
                        }}
                      />
                    </Grid>
                    <Grid item xs={arr.length > 1 ? 3 : 4}>
                      <TextField
                        label="Punteo"
                        variant="outlined"
                        margin="dense"
                        type="number"
                        value={el.percentage}
                        onChange={(e) => {
                          this.props.updateCriteria(e, "percentage", index);
                        }}
                        fullWidth
                      />
                    </Grid>
                    {arr.length > 1 && (
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => {
                            this.props.removeCriteria(index);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Grid>
                    )}
                  </React.Fragment>
                );
              })}
            </Grid>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                id="add-btn"
                color="primary"
                onClick={() => {
                  this.props.addCriteria();
                }}
              >
                Agregar Criterio
              </Button>
            </div>
            <br />
            <br />
            <Divider />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={this.state.editLoading}
              color="primary"
              onClick={this.handleClose}
              disabled={this.state.loading}
            >
              Cancelar
            </Button>
            <Button
              disabled={this.state.editLoading}
              color="primary"
              variant="contained"
              disabled={this.state.loading}
              onClick={() => {
                this.editCriterias();
              }}
            >
              Editar
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(EditCriteriasDialog);
