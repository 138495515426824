import { Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

/**
 *
 * @param {String} text
 */
export const textDataRender = (text) => {
  return (
    <Typography
      variant="caption"
      color="textSecondary"
      style={{
        fontWeight: "600",
        marginTop: "35px",
        fontSize: "24px",
        marginLeft: "30px",
        height: "75px",
        opacity: "0.5",
      }}
    >
      {text}
    </Typography>
  );
};

/**
 *
 * @param {String} prefix
 */
export const loadingCards = (prefix) => {
  let style = { width: "100%", height: "100%", minHeight: 110 };
  return (
    <React.Fragment>
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_01"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_02"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
    </React.Fragment>
  );
};
