import React, { Component } from "react";
import styles from "./ClassroomManagement-styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Hidden,
  Paper,
  Avatar,
  CardHeader,
  IconButton,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  CardMedia,
  CardActionArea,
  LinearProgress,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import {
  Delete,
  Edit,
  MoreVertOutlined,
  OpenInNew,
  Search as SearchIcon,
  Send,
} from "@material-ui/icons";
import AdminAppbar from "components/Appbar/AdminAppbar";
import axios from "utils/API";
import Authentication from "controller/Authentication";
import { Dialog } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { Icon } from "@material-ui/core";
import DeleteDialog from "components/Dialogs/DeleteDialog";

class ClassroomManagementCard extends Component {
  state = {
    menuAnchor: null,
    loading: false,
  };

  handleOpenMenu = (e) => {
    this.setState({ menuAnchor: e.currentTarget });
  };
  handleCloseMenu = () => {
    this.setState({ menuAnchor: null });
  };

  renderMenu = (props) => {
    return (
      <Menu
        elevation={3}
        id="account-settings"
        anchorEl={this.state.menuAnchor}
        open={Boolean(this.state.menuAnchor)}
        onClose={this.handleCloseMenu}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        getContentAnchorEl={null}
      >
        <MenuItem
          onClick={() => {
            props.history.push(`/teacher/classrooms/${props.classroom.id}`);

            this.handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <OpenInNew fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Ver" />
        </MenuItem>

        <MenuItem
          onClick={() => {
            props.handleEdit(props.classroom.id, props.classroom);
            this.handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Editar" />
        </MenuItem>

        <MenuItem
          onClick={async () => {
            this.handleCloseMenu();
            this.setState({ loading: true });
            await props.handleTransferDialogOpen(props.classroom.id);
            this.setState({ loading: false });
          }}
        >
          <ListItemIcon>
            <Send fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Transferir" />
        </MenuItem>

        <Divider />
        <MenuItem
          onClick={async () => {
            this.handleCloseMenu();
            this.setState({ loading: true });
            await props.handleArchive(props.classroom.id, props.classroom);
            this.setState({ loading: false });
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Archivar" />
        </MenuItem>
      </Menu>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      id = "",
      name = "Matemáticas",
      sectionName = "No section",
      imageURL = "/images/classroom-templates/template01.png",
    } = this.props.classroom;

    return (
      <React.Fragment>
        <Card variant="outlined">
          {this.state.loading && <LinearProgress />}
          <CardActionArea
            onClick={() => {
              this.props.history.push(`/teacher/classrooms/${id}`);
            }}
          >
            <CardMedia image={imageURL} className={classes.media} />
          </CardActionArea>
          <CardHeader
            title={
              <Typography
                variant="subtitle2"
                align="left"
                color="textPrimary"
                style={{}}
              >
                {name}
              </Typography>
            }
            subheader={
              <React.Fragment>
                <Typography variant="body2" align="left" color="textSecondary">
                  {sectionName}
                </Typography>
              </React.Fragment>
            }
            action={
              <IconButton aria-label="settings" onClick={this.handleOpenMenu}>
                <MoreVertOutlined />
              </IconButton>
            }
          />
        </Card>

        {this.renderMenu(this.props)}

        {/* {approved == 0
          ? this.renderApproveMenu(this.props)
          : this.renderEditMenu(this.props)} */}
      </React.Fragment>
    );
  }
}

ClassroomManagementCard.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(ClassroomManagementCard);
