import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import dayjs from "dayjs";
import { toast } from "material-react-toastify";
import React, { Component } from "react";
import Controller from "./PostsController";

let controller = new Controller();

class CreateMeetingDialog extends Component {
  state = {
    loading: false,
    videoURL: "",
  };

  componentWillUnmount() {
    controller.cancelAllTokens();
  }

  createPost = async () => {
    let { idClassroom } = this.props;
    this.setState({ loading: true });
    let post = {
      type: "meeting",
      title: "Únete a la reunión",
      content: `${dayjs().format("DD/MM/YYYY  HH:mm")}`,
    };

    post.url = this.state.videoURL;

    try {
      await controller.createPost(post, idClassroom);
      this.props.onClose();
      if (this.props.onCreatePost) this.props.onCreatePost(post);
      toast(`Publicación ha sido creada`, { type: "success" });
    } catch (error) {
      if (axios.isCancel(error)) return;
      toast(`Error al crear publicación`, { type: "error" });
    } finally {
      this.setState({
        loading: false,
        videoURL: "",
      });
    }
  };

  renderAddVideo = () => {
    return (
      <React.Fragment>
        <TextField
          type="text"
          autoComplete="off"
          variant="outlined"
          label="Link de la reunión"
          placeholder="https://meet.google.com/afz-xpvd-dpd"
          margin="dense"
          fullWidth
          autoFocus
          value={this.state.videoURL}
          onChange={(e) => this.setState({ videoURL: e.target.value })}
        />
      </React.Fragment>
    );
  };

  handleClose = () => {
    if (this.state.loading) {
      return;
    }

    this.props.onClose();
  };

  render() {
    return (
      <Dialog fullWidth open={this.props.open} onClose={this.handleClose}>
        {this.state.loading && <LinearProgress />}
        <DialogTitle>Crea una reunión</DialogTitle>
        <DialogContent>
          {this.renderAddVideo()}
          <br />
          <div style={{ height: 20 }} />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                href="https://zoom.us/"
                target="_blank"
              >
                Ir a Zoom
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                href="https://meet.google.com/new"
                target="_blank"
              >
                Ir a Meet
              </Button>
            </Grid>
          </Grid>
        </DialogContent>

        <br />
        <br />
        <DialogActions>
          <Button
            color="primary"
            disabled={this.state.loading}
            onClick={this.handleClose}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            disabled={this.state.loading}
            onClick={this.createPost}
          >
            Crear Reunión
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CreateMeetingDialog;
