import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import DownloadButton from "components/DownloadButton/DownloadButton";
import GetData from "components/Fetching/GetData";
import Authentication from "controller/Authentication";
import React, { Component } from "react";
import ClassroomManagementController from "../ClassroomController";
import SummaryCard from "./SummaryCard";

const controller = new ClassroomManagementController();

class PeriodSummary extends Component {
  state = { summary: [], loading: true };

  constructor(props) {
    super(props);
    this.getRef = React.createRef();
  }

  componentWillUnmount() {
    controller.cancelAllTokens();
  }

  requestSummary = async () => {
    const result = await controller.getSummary(
      this.props.idClassroom,
      this.props.period ?? 0
    );
    return result;
  };

  refresh = async () => {
    this.getRef.current.get();
  };

  render() {
    return (
      <React.Fragment>
        <GetData
          ref={this.getRef}
          onLoading={(val) => this.setState({ loading: val })}
          onData={(summary) => this.setState({ summary })}
          request={this.requestSummary}
          disableSuccessNotification
        />

        <Container>
          {this.state.loading && (
            <Grid container spacing={2}>
              {[0, 1, 2].map((el) => {
                return (
                  <Grid item xs={12}>
                    <Skeleton height={186} variant="rect" />
                  </Grid>
                );
              })}
            </Grid>
          )}

          {!this.state.loading && this.state.summary.length > 0 && (
            <div style={{ marginTop: 20, marginBottom: 50, display: "flex" }}>
              <DownloadButton
                fileURL={`${process.env.REACT_APP_API_URL}api/secretary/notes?idClassroom=${this.props.idClassroom}&period=${this.props.period}`}
                color="primary"
                variant="outlined"
                label="Descargar Cuadro de Notas"
              />

              <br />
              <Divider />
            </div>
          )}

          {!this.state.loading && (
            <Grid container spacing={2}>
              {this.state.summary.map((el) => {
                return (
                  <Grid item xs={12}>
                    <SummaryCard summary={el} />
                  </Grid>
                );
              })}
            </Grid>
          )}

          {!this.state.loading && this.state.summary.length === 0 && (
            <React.Fragment>
              <br />
              <Typography variant="h4" color="textSecondary">
                No hubieron entregas de tareas
              </Typography>
            </React.Fragment>
          )}
        </Container>
      </React.Fragment>
    );
  }
}

export default PeriodSummary;
