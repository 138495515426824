import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./SearchStyles";
import { InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";

class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
  }

  doSearch = (evt) => {
    var searchText = evt.target.value; // this is the search text
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.onSearch(searchText);
    }, 300);
  };

  render() {
    let rest = { ...this.props };
    delete rest.onSearch;

    return (
      <TextField
        variant="outlined"
        size="small"
        margin="dense"
        onChange={(e) => {
          this.doSearch(e);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    );
  }
}

export default withStyles(styles)(SearchInput);
