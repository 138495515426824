import React, { Component } from "react";
import styles from "./DeliveredStyles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Hidden,
  Paper,
  Avatar,
  CardHeader,
  IconButton,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  CardActionArea,
  LinearProgress,
  CardContent,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { MoreVertOutlined, Search as SearchIcon } from "@material-ui/icons";
import AdminAppbar from "components/Appbar/AdminAppbar";
import AdminDashboard from "components/DashboardContainer/AdminDashboard";
import axios from "utils/API";
import Authentication from "controller/Authentication";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import dayjs from "dayjs";

const CardManagementAction = ({ children, onClick }) => {
  return (
    <React.Fragment>
      <Hidden smDown>{children}</Hidden>
      <Hidden mdUp>
        <CardActionArea onClick={onClick}>{children}</CardActionArea>
      </Hidden>
    </React.Fragment>
  );
};

class UserManagementCard extends Component {
  state = {
    menuAnchor: null,
  };

  handleOpenMenu = (e) => {
    if (this.props.hasTransaction) return;
    this.setState({ menuAnchor: e.currentTarget });
  };
  handleCloseMenu = () => {
    this.setState({ menuAnchor: null });
  };

  renderMenu = () => {
    return (
      <Menu
        elevation={3}
        id="account-settings"
        anchorEl={this.state.menuAnchor}
        open={Boolean(this.state.menuAnchor) && !this.props.hasTransaction}
        onClose={this.handleCloseMenu}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        getContentAnchorEl={null}
      >
        <MenuItem
          onClick={() => {
            this.props.handleEdit(this.props.deliver);
            this.handleCloseMenu();
          }}
        >
          <Typography variant={"body1"}>
            {this.props.isScored ? `Editar Calificación` : `Calificar`}
          </Typography>
        </MenuItem>
      </Menu>
    );
  };

  render() {
    const { classes } = this.props;
    const el = this.props.deliver;
    let isScored = el.lastScoredURL !== null;

    return (
      <React.Fragment>
        <Card style={{ height: "100%" }} variant="outlined">
          <CardManagementAction onClick={this.handleOpenMenu}>
            <CardHeader
              action={
                <Hidden smDown>
                  {/* {isScored && ( */}
                  <IconButton
                    aria-label="settings"
                    onClick={this.handleOpenMenu}
                  >
                    <MoreVertOutlined />
                  </IconButton>
                  {/* )} */}
                </Hidden>
              }
              avatar={
                <Avatar
                  src={el.photoURL}
                  style={{ width: 64, height: 64, marginRight: 20 }}
                />
              }
              title={
                <div
                  style={{
                    display: "flex",
                    margin: 5,
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="subtitle2" align="left">
                    {Authentication.formatName(
                      el.userName ?? el.name,
                      el.lastName
                    )}
                  </Typography>
                  {isScored && (
                    <Typography variant="subtitle2" align="left">
                      {el.score + " puntos"}
                    </Typography>
                  )}
                  <Typography variant="body1" align="left">
                    {`${dayjs(el.submitTime).format("DD/MM/YYYY")} · 
                      ${dayjs(el.submitTime).format("HH:mm")}`}
                  </Typography>
                </div>
              }
            />
          </CardManagementAction>
        </Card>

        {this.renderMenu()}
        {/* {approved == 0
          ? this.renderApproveMenu(this.props)
          : this.renderEditMenu(this.props)} */}
      </React.Fragment>
    );
  }
}

UserManagementCard.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(UserManagementCard);
