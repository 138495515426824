import axios from "utils/API";
import Axios from "axios";
import dayjs from "dayjs";

export default class AssignmentController {
  constructor() {
    this.createPostSource = Axios.CancelToken.source();
    this.getPostsSource = Axios.CancelToken.source();
    this.removePostSource = Axios.CancelToken.source();
    this.copySource = Axios.CancelToken.source();
    this.pinSource = Axios.CancelToken.source();
  }

  createPost = async (post, idClassroom) => {
    if (this.createPostSource) this.createPostSource.cancel();
    this.createPostSource = Axios.CancelToken.source();
    const response = await axios.post(
      `api/teacher/classrooms/${idClassroom}/posts`,
      { post },
      { cancelToken: this.createPostSource.token }
    );
    return response.data;
  };

  getPosts = async (idClassroom) => {
    if (this.getPostsSource) this.getPostsSource.cancel();
    this.getPostsSource = Axios.CancelToken.source();
    const response = await axios.get(
      `api/teacher/classrooms/${idClassroom}/posts`,
      { cancelToken: this.getPostsSource.token }
    );
    return response.data;
  };

  loadMore = async (idClassroom, page, pageSize) => {
    if (this.getPostsSource) this.getPostsSource.cancel();
    this.getPostsSource = Axios.CancelToken.source();
    const response = await axios.get(
      `api/teacher/classrooms/${idClassroom}/posts?page=${page}&pageSize=${pageSize}`,
      { cancelToken: this.getPostsSource.token }
    );
    return response.data;
  };

  removePost = async (idPost, idClassroom) => {
    if (this.removePostSource) this.removePostSource.cancel();
    this.removePostSource = Axios.CancelToken.source();
    const response = await axios.delete(
      `api/teacher/classrooms/${idClassroom}/posts/${idPost}`,
      { cancelToken: this.removePostSource.token }
    );
    return response.data;
  };

  copyAssignment = async (classroomId, idPost, classrooms) => {
    if (this.copySource) this.copySource.cancel();
    this.copySource = Axios.CancelToken.source();
    const response = await axios.post(
      `api/teacher/classrooms/${classroomId}/posts/${idPost}/copy`,
      { classrooms },
      { cancelToken: this.copySource.token }
    );
    return response.data;
  };

  pin = async (classroomId, idPost, value = true) => {
    if (this.pinSource) this.pinSource.cancel();
    this.pinSource = Axios.CancelToken.source();
    const response = await axios.post(
      `api/teacher/classrooms/${classroomId}/posts/${idPost}/pin`,
      { value },
      { cancelToken: this.pinSource.token }
    );
    return response.data;
  };

  cancelAllTokens() {
    if (this.createPostSource) this.createPostSource.cancel();
    if (this.getPostsSource) this.getPostsSource.cancel();
    if (this.removePostSource) this.removePostSource.cancel();
    if (this.copySource) this.copySource.cancel();
    if (this.pinSource) this.pinSource.cancel();
  }
}
