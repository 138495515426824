import axios from "utils/API";
import { beginRequest } from "utils/ApiRequests";
import Axios from "axios";

/**
 * @typedef {Object} ResponseAllUsers
 * @property {Array<import("utils/typedefs").AccountData>} users Usuarios aprobados
 * @property {Array<import("utils/typedefs").AccountData>} pending Usuarios pendientes de aprobación
 */

export default class SectionManagementController {
  constructor() {
    this.getStudentsSource = Axios.CancelToken.source();
    this.getNonStudentsSource = Axios.CancelToken.source();
  }

  /**
   * @param {Number} idSection
   * @param {String} textFilter
   * @param {Number} page Número de página a traer
   * @returns {Promise<Array<import("utils/typedefs").AccountData>>} Arreglo de usuarios
   */
  getUsers = async (idSection, textFilter, page = 0) => {
    let filter = textFilter ? `&textFilter=${textFilter}` : "";

    if (this.getStudentsSource) this.getStudentsSource.cancel();
    this.getStudentsSource = Axios.CancelToken.source();
    this.assignToSectionSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/admin/sections/${idSection}/users?page=${page}&pageSize=100${filter}`,
      { cancelToken: this.getStudentsSource.token }
    );
    return response.data;
  };

  /**
   * @param {Number} idSection
   * @param {String} textFilter
   * @param {Number} page Número de página a traer
   * @param {Boolean} studentsOnly
   * @returns {Promise<Array<import("utils/typedefs").AccountData>>} Arreglo de usuarios
   */
  getNonUsers = async (
    idSection,
    textFilter,
    page = 0,
    studentsOnly = false
  ) => {
    let filter = textFilter ? `&textFilter=${textFilter}` : "";

    if (this.getNonStudentsSource) this.getNonStudentsSource.cancel();
    this.getNonStudentsSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/admin/sections/${idSection}/non-users?page=${page}&pageSize=100${filter}&onlyStudents=${studentsOnly}`,
      { cancelToken: this.getNonStudentsSource.token }
    );
    return response.data;
  };

  /**
   *
   * @param { Array<import("utils/typedefs")>.AccountData} users
   * @param {Number} idSection
   */
  assignToSection = async (users, idSection) => {
    if (this.assignToSectionSource) this.assignToSectionSource.cancel();
    this.assignToSectionSource = Axios.CancelToken.source();

    let promises = users.map((el) => {
      return axios.put(
        `/api/admin/sections/${idSection}/users/${el.uid}`,
        {},
        { cancelToken: this.assignToSectionSource.token }
      );
    });

    await Promise.all(promises);
  };

  cancelAllTokens() {
    if (this.getStudentsSource) this.getStudentsSource.cancel();
    if (this.getNonStudentsSource) this.getNonStudentsSource.cancel();
    if (this.assignToSectionSource) this.assignToSectionSource.cancel();
  }
}
