import axios from "utils/API";
import Axios from "axios";
import dayjs from "dayjs";

export default class FilesController {
  constructor() {
    this.getSource = Axios.CancelToken.source();
  }

  getURL = async (url) => {
    if (this.getSource) this.getSource.cancel();
    this.getSource = Axios.CancelToken.source();
    const response = await axios.get(url, {
      cancelToken: this.getSource.token,
    });
    return response.data;
  };

  cancelAllTokens() {
    if (this.getSource) this.getSource.cancel();
  }
}
