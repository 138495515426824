import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Hidden,
  isWidthDown,
  Typography,
  withStyles,
  withWidth,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";
import routes from "Pages/routes";
import React, { Component } from "react";
import { generatePath } from "react-router-dom";
import styles from "./StudentScoresStyles";
import axios from "axios";
import { toast } from "material-react-toastify";
import DataTable from "components/Data/Table/DataTable";
import GetData from "components/Fetching/GetData";
import AssignmentController from "../Assignments/StudentAssignmentController";
import { Skeleton } from "@material-ui/lab";
import { FetchDataComponent } from "components/Fetching/FetchDataComponent";
import {
  loadingCards,
  textDataRender,
} from "components/Fetching/fetchingLoaders";

let assignmentsController = new AssignmentController();

class StudentScores extends Component {
  state = {
    totalScore: { total: 0, current: 0 },
    loadingTotalScore: true,
    assignments: {
      loading: true,
      data: [],
      error: null,
    },
  };

  componentDidMount() {
    this.getAssignments(this.props.match.params.id);
  }

  componentWillUnmount() {
    assignmentsController.cancelAllTokens();
  }

  getAssignments = async (id) => {
    try {
      this.setState({ assignments: { data: [], loading: true, error: null } });
      const data = await assignmentsController.getGradeAssignments(id);
      let assignments = { loading: false, data, error: null };
      this.setState({ assignments }, () => {});
    } catch (error) {
      if (axios.isCancel(error)) return;
      let assignments = { loading: false, data: [], error: error };
      this.setState({ assignments }, () => {});
      toast("Error al obtener las tareas", { type: "error" });
    }
  };

  requestTotalScore = async () => {
    const result = await assignmentsController.getTotalScore(
      this.props.match.params.id
    );
    return result;
  };

  getScore = (el) => {
    if (el.improvedScore == null) {
      return el.score || 0;
    }

    return el.improvedScore;
    // if (el.improvedScore > el.score) return el.improvedScore;

    //return el.score || 0;
  };

  render() {
    const { assignments } = this.state;
    return (
      <React.Fragment>
        <GetData
          request={this.requestTotalScore}
          onData={(totalScore) => {
            this.setState({ totalScore });
          }}
          errorMessage={`Error al traer el punto actual`}
          onLoading={(val) => this.setState({ loadingTotalScore: val })}
          disableSuccessNotification
          disableErrorNotification
        />

        <div
          style={{
            width: "100%",
            marginTop: "30px",
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography varaint="subtitle1" color="textSecondary">
            Puntos Actuales
          </Typography>
          {/* <div style={{ flexGrow: 1 }} /> */}
          <Typography variant="h4">
            {this.state.loadingTotalScore ? (
              <Skeleton width={180} height={80} variant="text" />
            ) : (
              `${this.state.totalScore.current || 0} / ${
                this.state.totalScore.total
              }`
            )}
          </Typography>
        </div>
        <div
          style={{
            height: 400,
            width: "100%",
            marginTop: "30px",
            textAlign: "left",
          }}
        >
          <Typography variant="body1" align="left" color="textSecondary">
            Actividades entregadas
          </Typography>
          <br />

          <FetchDataComponent
            loading={assignments.loading}
            data={assignments.data}
            error={assignments.error}
            customNoDataRenderer={() =>
              textDataRender("No hay actividades calificadas")
            }
            customErrorRenderer={() =>
              textDataRender(
                "Ocurrio un error al momento de traer la información"
              )
            }
            loadingRender={() => (
              <React.Fragment>
                <Skeleton variant="rect" height={90} />
                <br />
                <Skeleton variant="rect" height={90} />
                <br />
                <Skeleton variant="rect" height={90} />
                <br />
                <Skeleton variant="rect" height={90} />
              </React.Fragment>
            )}
          >
            {assignments.data.map((el) => {
              return (
                <React.Fragment>
                  <Card
                    variant="outlined"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <CardActionArea
                      onClick={() => {
                        let path = routes.student.assignment;
                        let idAssignment = el.id;
                        if (
                          el.improvedScore !== null &&
                          el.improvedScore > el.score
                        ) {
                          path = routes.student.improvePlan;
                          idAssignment = el.improvedIdAssignment;
                        }

                        this.props.history.push(
                          generatePath(path, {
                            id: this.props.match.params.id,
                            idAssignment,
                          })
                        );
                      }}
                    >
                      <CardContent>
                        <div style={{ display: "flex", marginBottom: 5 }}>
                          <Typography variant="subtitle2">{el.name}</Typography>
                          <Hidden smDown>
                            <Chip
                              style={{ marginLeft: 20 }}
                              label={
                                el.score !== null
                                  ? "Calificada"
                                  : "Pendiente de Calificar"
                              }
                              color="primary"
                              variant={
                                el.score !== null ? "default" : "outlined"
                              }
                            />
                          </Hidden>

                          {el.improvedScore !== null &&
                            el.improvedScore > el.score && (
                              <Chip
                                style={{ marginLeft: 20, marginRight: 20 }}
                                label={"PM"}
                                color="primary"
                                variant="outlined"
                              />
                            )}
                          <div style={{ flexGrow: 1 }} />
                          <Divider orientation="vertical" flexItem />

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              marginLeft: 10,
                              alignItems: "center",
                              minWidth: 100,
                            }}
                          >
                            <Typography variant="h5">
                              {`${this.getScore(el)} / ${el.total}`}
                            </Typography>
                            <Typography variant="caption">Punteo</Typography>
                          </div>
                        </div>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  <br />
                </React.Fragment>
              );
            })}
          </FetchDataComponent>

          {/* <DataTable
            noRowsLabel="No hay tareas para calificar"
            loading={assignments.loading}
            rows={assignments.data}
            columns={this.getColumns()}
            pageSize={5}
            disableMultipleSelection
            disableSelectionOnClick
            disableColumnSelector
            sortModel={[{ field: "dueTime", sort: "desc" }]}
            onRowClick={(target) => {
              this.props.history.push(
                generatePath(routes.teacher.scoresAssignment, {
                  id: this.props.match.params.id,
                  idAssignment: target.row.id,
                })
              );
            }}
          /> */}
        </div>
        <div style={{ height: 50, marginTop: 20 }} />
      </React.Fragment>
    );
  }
}

export default withWidth()(withStyles(styles)(StudentScores));
