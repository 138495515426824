export default {
  admin: {
    userManagement: "/admin/users",
    studentsManagement: "/admin/students",
    sectionsManagement: "/admin/sections",
  },
  teacher: {
    classroomManagement: "/teacher/classrooms",
    classroom: "/teacher/classrooms/:id",
    archivedClassroom: "/teacher/archived",
    classroomAssignments: "/teacher/classrooms/:id/assignments",
    assignment: "/teacher/classrooms/:id/assignments/:idAssignment",
    students: "/teacher/classrooms/:id/students",
    scores: "/teacher/classrooms/:id/scores",
    scoresAssignment:
      "/teacher/classrooms/:id/scores/assignments/:idAssignment",
    posts: "/teacher/classrooms/:id/posts",
    createImprovePlan: "/teacher/classrooms/:id/create/improve",
    improvePlans: "/teacher/classrooms/:id/improve/:idAssignment",
    scoresImprove: "/teacher/classrooms/:id/scores/improve/:idAssignment",
    improvePlan: "/teacher/classrooms/:id/improve/:idAssignment/:idStudent",
    createTest: "/teacher/classrooms/:id/create/test",
  },
  student: {
    calendar: "/student/calendar",
    classroomManagement: "/student/classrooms",
    classroom: "/student/classrooms/:id",
    classroomAssignments: "/student/classrooms/:id/assignments",
    assignment: "/student/classrooms/:id/assignments/:idAssignment",
    posts: "/student/classrooms/:id/posts",
    scores: "/student/classrooms/:id/scores",
    improvePlan: "/student/classrooms/:id/improve/:idAssignment",
  },
  system: {
    notify: "/system/notify",
    createAssignment: "/system/create/assignment",
  },
  secretary: {
    index: "/secretary/",
    notes: "/secretary/notes",
    notesPerClassroom: "/secretary/notes-per-classroom",
    students: "/secretary/students",
  },
  errors: {
    classroomNotFound: "/error/classrooms",
    assignmentNotFound: "/error/assignment",
    improveNotFound: "/error/improve",
  },
};
