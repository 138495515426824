import { Typography, withStyles } from "@material-ui/core";
import { CardActionArea } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { ToggleButton } from "@material-ui/lab";
import React, { Component } from "react";
import styles from "./AnswerStyles";

class AnswerCard extends Component {
  state = {};
  render() {
    const { text = "", score, selected, classes } = this.props;
    return (
      <Card
        variant="outlined"
        className={selected ? classes.selectedCard : classes.card}
      >
        <CardActionArea onClick={this.props.onToggle}>
          <CardContent>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ToggleButton
                selected={selected}
                onChange={() => {
                  //   this.props.onToggle();
                }}
                tabIndex="-1"
                value="check"
              >
                <Check />
              </ToggleButton>
              <Typography
                variant="subtitle1"
                style={{ flexGrow: 1, marginLeft: 20 }}
              >
                {text}
              </Typography>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

export default withStyles(styles)(AnswerCard);
