import {
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Divider,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";

export default function AssignmentScoreCard(props) {
  const { onClick, el } = props;
  return (
    <React.Fragment>
      <Card variant="outlined" style={{ backgroundColor: "transparent" }}>
        <CardActionArea onClick={onClick}>
          <CardContent>
            <div style={{ display: "flex", marginBottom: 5 }}>
              <Typography variant="subtitle2">{el.name}</Typography>
              <div style={{ flexGrow: 1 }} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 10,
                }}
              >
                <Typography variant="h5">
                  {el.scored} / {el.delivered}
                </Typography>
                <Typography variant="caption">Calificados</Typography>
              </div>
              <Divider orientation="vertical" flexItem />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginLeft: 10,
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">{el.score}</Typography>
                <Typography variant="caption">Total</Typography>
              </div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
      <br />
    </React.Fragment>
  );
}
