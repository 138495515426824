import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import React, { Component } from "react";

class DeleteDialog extends Component {
  state = { confirmationText: "" };

  handleClose = () => {
    if (this.props.onClose)
      this.props.onClose(() => {
        this.setState({ confirmationText: "" });
      });
  };

  handleDelete = () => {
    if (this.props.onDelete) this.props.onDelete();
  };

  render() {
    let {
      title = "Eliminar",
      description = "Escribe la palabra eliminar en el campo de texto para continuar",
      confirmationText = "eliminar",
      buttonLabel = "Eliminar",
      loading = false,
    } = this.props;

    let canBeDeleted =
      this.state.confirmationText.toLocaleLowerCase() === confirmationText;
    return (
      <Dialog open={this.props.open} onClose={this.handleClose} fullWidth>
        {loading && <LinearProgress />}
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
          <TextField
            autoFocus
            placeholder={confirmationText}
            variant="outlined"
            fullWidth
            value={this.state.confirmationText}
            onChange={(e) => {
              this.setState({ confirmationText: e.target.value });
            }}
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.handleClose} disabled={loading}>
            Cancelar
          </Button>
          <Button
            color="primary"
            disabled={!canBeDeleted || loading}
            onClick={this.handleDelete}
          >
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DeleteDialog;
