import React, { Component } from "react";
import routes from "Pages/routes";
import Notes from "./StudentNotes/Notes";
import NotesPerClassroom from "./Notes/Notes";
import { Route } from "react-router";
import Dashboard from "components/DashboardContainer/Dashboard";

class SecretaryRoutes extends Component {
  state = {};
  render() {
    return (
      <Dashboard history={this.props.history}>
        <Route path={routes.secretary.notes} exact component={Notes} />
        <Route
          path={routes.secretary.notesPerClassroom}
          exact
          component={NotesPerClassroom}
        />
      </Dashboard>
    );
  }
}

export default SecretaryRoutes;
