import axios from "utils/API";
import Axios from "axios";
import Authentication from "controller/Authentication";

export default class ClassroomManagementController {
  constructor() {
    this.classroomSource = Axios.CancelToken.source();
    this.studentsSource = Axios.CancelToken.source();
    this.archiveSource = Axios.CancelToken.source();
  }

  /**
   *
   * @param {String} id id del salón
   */
  getClassroom = async (id) => {
    if (this.classroomSource) this.classroomSource.cancel();
    this.classroomSource = Axios.CancelToken.source();

    const response = await axios.get(`api/teacher/classrooms/${id}`, {
      cancelToken: this.classroomSource.token,
    });
    return response.data;
  };

  getStudents = async (id) => {
    if (this.studentsSource) this.studentsSource.cancel();
    this.studentsSource = Axios.CancelToken.source();

    const response = await axios.get(`api/teacher/classrooms/${id}/students`, {
      cancelToken: this.studentsSource.token,
    });

    let data = response.data.map((el) => {
      return {
        ...el,
        displayName: Authentication.formatName(el.name, el.lastName),
      };
    });

    data.sort(this.compare);

    return data;
  };

  archive = async (id) => {
    if (this.archiveSource) this.archiveSource.cancel();
    this.archiveSource = Axios.CancelToken.source();

    const response = await axios.post(
      `api/teacher/classrooms/${id}/archive`,
      {},
      {
        cancelToken: this.archiveSource.token,
      }
    );

    return response.data;
  };

  compare = (a, b) => {
    if (a.displayName > b.displayName) return 1;
    if (a.displayName < b.displayName) return -1;
    return 0;
  };

  publish = async (id, periodToPublish) => {
    if (this.archiveSource) this.archiveSource.cancel();
    this.archiveSource = Axios.CancelToken.source();

    const response = await axios.put(
      `api/teacher/classrooms/${id}/publish`,
      { customPeriod: periodToPublish },
      {
        cancelToken: this.archiveSource.token,
      }
    );

    return response.data;
  };

  getSummary = async (id, period = 0) => {
    if (this.archiveSource) this.archiveSource.cancel();
    this.archiveSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/teacher/classrooms/${id}/summary?period=${period}`,
      { cancelToken: this.archiveSource.token }
    );

    return response.data;
  };

  cancelAllTokens() {
    if (this.classroomSource) this.classroomSource.cancel();
    if (this.studentsSource) this.studentsSource.cancel();
    if (this.archiveSource) this.archiveSource.cancel();
  }
}
