import { Badge, Divider, MenuItem, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import HumanizeDuration from "humanize-duration";
import React, { Component } from "react";

class NotificationItem extends Component {
  state = {};

  getTitle = (type) => {
    switch (type) {
      case "new_homework":
        return "Nueva Tarea";
      case "homework_scored":
        return "Tarea Calificada";
      case "new_post":
        return "Nueva Publicación";
      case "system_new_feature":
        return "Nueva Característica";
      case "new_improve_plan":
        return "Plan de Mejora";
      case "improve_plan_scored":
        return "Plan de Mejora Calificado";
      case "new_period":
        return "Nueva Unidad";
      default:
        return "Notificación";
    }
  };

  render() {
    let {
      message = `Se ha creado una nueva tarea del curso "Física 2" para el 9/10/2020`,
      type = "new_homework",
      url = "/student/classroom/70NnJ7jkyV0/assignments/153",
      creationTime = new Date(),
      readStatus = false,
    } = this.props.notification;

    // creationTime = dayjs(creationTime).toDate();
    // let now = new Date();
    // const remainingTime = now.getTime() - creationTime.getTime();

    return (
      <React.Fragment>
        <MenuItem
          style={{
            width: "100%",
            maxWidth: "90vw",
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
          onClick={async () => {
            if (this.props.onClick)
              await this.props.onClick(this.props.notification);

            if (this.props.history.location.pathname != url)
              this.props.history.push(url);
          }}
        >
          {!readStatus && (
            <div
              style={{
                display: "flex",
                width: "100%",
                paddingRight: 20,
                marginTop: 5,
              }}
            >
              <div style={{ flexGrow: 1 }} />
              <Badge badgeContent={4} color="primary" variant="dot" />
            </div>
          )}
          <Typography variant="subtitle2" style={{ paddingRight: 20 }}>
            {this.getTitle(type)}
          </Typography>
          {/* </Badge> */}
          <Typography
            variant="body1"
            style={{
              overflowWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            {message}
          </Typography>

          <div style={{ height: 5 }} />
          <Typography variant="caption">
            {dayjs(creationTime).fromNow()}
            {/* {HumanizeDuration(remainingTime, {
              language: "es",
              units: ["mo", "w", "d", "h", "m"],
              round: true,
            })} */}
          </Typography>
        </MenuItem>
      </React.Fragment>
    );
  }
}

export default NotificationItem;
