import React, { Component } from "react";
import styles from "./Authenticate-styles";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Backdrop, CircularProgress } from "@material-ui/core";
import Authentication from "controller/Authentication";
import routes from "Pages/routes";
import ClipLoader from "react-spinners/DotLoader";
import { BrightnessAltLowFill } from "react-bootstrap-icons";

class Authenticate extends Component {
  state = { user: Authentication.emptyUser };

  constructor(props) {
    super(props);
  }

  handleUserChange = (user) => {
    if (user == null) {
      this.props.history.replace("/sign-in");
      return;
    }

    if (!user.isVerified) {
      this.props.history.replace("/verify");
      return;
    }

    switch (user.idRole) {
      case "admin":
        this.props.history.replace(routes.admin.userManagement);
        break;
      case "teacher":
        this.props.history.replace(routes.teacher.classroomManagement);
        break;
      case "student":
        this.props.history.replace(routes.student.classroomManagement);
        break;
      case "system":
        this.props.history.replace(routes.system.notify);
        break;

      case "secretary":
        this.props.history.replace(routes.secretary.notes);
        break;

      default:
        this.props.history.replace("/settings/account");
        break;
    }
  };

  componentDidMount() {
    Authentication.instance.subscribeToUserChange(this.handleUserChange);
  }

  componentWillUnmount() {
    Authentication.instance.unsubscribeFromUserChange(this.handleUserChange);
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div
          style={{
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            overflow: "hidden",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Backdrop className={classes.backdrop} open={true}> */}
          <ClipLoader
            color={"#4C7AF1"}
            loading={true}
            // css={override}
            size={60}
          />

          {/* <CircularProgress color="inherit" /> */}
          {/* </Backdrop> */}
        </div>
      </React.Fragment>
    );
  }
}

Authenticate.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(Authenticate);
