import React, { Component } from "react";
import styles from "./StudentClassroomManagement-styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Hidden,
  Paper,
  Avatar,
  CardHeader,
  IconButton,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  CardMedia,
  CardActionArea,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { MoreVertOutlined, Search as SearchIcon } from "@material-ui/icons";

class StudentClassroomManagementCard extends Component {
  state = {
    menuAnchor: null,
  };

  handleOpenMenu = (e) => {
    this.setState({ menuAnchor: e.currentTarget });
  };
  handleCloseMenu = () => {
    this.setState({ menuAnchor: null });
  };

  renderMenu = (props) => {
    return (
      <Menu
        elevation={3}
        id="account-settings"
        anchorEl={this.state.menuAnchor}
        open={Boolean(this.state.menuAnchor)}
        onClose={this.handleCloseMenu}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        getContentAnchorEl={null}
      >
        <MenuItem
          onClick={() => {
            this.props.history.push(
              `/student/classrooms/${props.classroom.id}`
            );

            // props.handleOpenApproveMenu(props.user);
            //this.handleCloseMenu();
          }}
        >
          <Typography variant={"body1"}>Ver</Typography>
        </MenuItem>
      </Menu>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      id = "",
      name = "Matemáticas",
      teacherName = "Profesor",
      imageURL = "/images/classroom-templates/template01.png",
    } = this.props.classroom;

    return (
      <React.Fragment>
        <Card variant="outlined">
          <CardActionArea
            onClick={() => {
              this.props.history.push(`/student/classrooms/${id}`);
            }}
          >
            <CardMedia image={imageURL} className={classes.media} />
          </CardActionArea>
          <CardHeader
            title={
              <Typography
                variant="subtitle2"
                align="left"
                color="textPrimary"
                style={{}}
              >
                {name}
              </Typography>
            }
            subheader={
              <React.Fragment>
                <Typography variant="body2" align="left" color="textSecondary">
                  {teacherName}
                </Typography>
              </React.Fragment>
            }
            action={
              <IconButton aria-label="settings" onClick={this.handleOpenMenu}>
                <MoreVertOutlined />
              </IconButton>
            }
          />
        </Card>

        {this.renderMenu(this.props)}
        {/* {approved == 0
          ? this.renderApproveMenu(this.props)
          : this.renderEditMenu(this.props)} */}
      </React.Fragment>
    );
  }
}

StudentClassroomManagementCard.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(StudentClassroomManagementCard);
