import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  formControl: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
});
