import axios from "utils/API";
import Axios from "axios";
import dayjs from "dayjs";

export default class AssignmentController {
  constructor() {
    this.getPostsSource = Axios.CancelToken.source();
  }

  getPosts = async (idClassroom) => {
    if (this.getPostsSource) this.getPostsSource.cancel();
    this.getPostsSource = Axios.CancelToken.source();
    const response = await axios.get(
      `api/student/classrooms/${idClassroom}/posts`,
      { cancelToken: this.getPostsSource.token }
    );
    return response.data;
  };

  loadMore = async (idClassroom, page, pageSize) => {
    if (this.getPostsSource) this.getPostsSource.cancel();
    this.getPostsSource = Axios.CancelToken.source();
    const response = await axios.get(
      `api/student/classrooms/${idClassroom}/posts?page=${page}&pageSize=${pageSize}`,
      { cancelToken: this.getPostsSource.token }
    );
    return response.data;
  };

  cancelAllTokens() {
    if (this.getPostsSource) this.getPostsSource.cancel();
  }
}
