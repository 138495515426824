import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import Dashboard from "components/DashboardContainer/Dashboard";
import Controller from "./SystemNotificationsController";
import { toast } from "material-react-toastify";
import axios from "axios";

let controller = new Controller();

class SystemNotifications extends Component {
  state = {
    message: "",
    url: "/authenticate",
    dialogOpen: false,
    loading: false,
    target: "none",
  };

  send = async () => {
    try {
      this.setState({ loading: true });
      await controller.sendNotification(
        { message: this.state.message, url: this.state.url },
        this.state.target
      );
      toast(`Notificaciones enviadas`, { type: "success" });
    } catch (error) {
      if (axios.isCancel(error)) return;
      console.log(error);
      toast(`Error al enviar una o mas notificaciones`, { type: "error" });
    } finally {
      this.setState({ loading: false, dialogOpen: false });
    }
  };

  handleDialogOpen = (val) => {
    if (this.state.loading) return;
    this.setState({ dialogOpen: val });
  };

  componentWillUnmount() {
    controller.cancelAllTokens();
  }

  render() {
    return (
      <Dashboard history={this.props.history}>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: 800,
              maxWidth: "90vw",
            }}
          >
            <div style={{ height: 50 }} />
            <Typography variant="h4"> Enviar Notificaciones</Typography>
            <div style={{ height: 20 }} />
            <TextField
              autoFocus
              label="Mensaje"
              multiline
              variant="outlined"
              fullWidth
              margin="dense"
              value={this.state.message}
              onChange={(e) => this.setState({ message: e.target.value })}
            />
            <div style={{ height: 5 }} />
            <TextField
              autoFocus
              label="URL"
              variant="outlined"
              fullWidth
              margin="dense"
              value={this.state.url}
              onChange={(e) => this.setState({ url: e.target.value })}
            />

            <div style={{ height: 20 }} />

            <Button
              color="primary"
              variant="contained"
              onClick={(e) => {
                this.setState({ target: "all", dialogOpen: true });
              }}
            >
              Enviar a Todos
            </Button>

            <div style={{ height: 10 }} />
            <Button
              color="primary"
              variant="outlined"
              onClick={(e) => {
                this.setState({ target: "students", dialogOpen: true });
              }}
            >
              Enviar a Estudiantes
            </Button>

            <div style={{ height: 10 }} />
            <Button
              color="primary"
              variant="outlined"
              onClick={(e) => {
                this.setState({ target: "teachers", dialogOpen: true });
              }}
            >
              Enviar a Docentes
            </Button>

            <div style={{ height: 10 }} />
            <Button
              color="primary"
              variant="outlined"
              onClick={(e) => {
                this.setState({ target: "admins", dialogOpen: true });
              }}
            >
              Enviar a Administradores
            </Button>
          </div>
        </div>
        <Dialog
          open={this.state.dialogOpen}
          fullWidth
          onClose={() => {
            this.handleDialogOpen(false);
          }}
        >
          {this.state.loading && <LinearProgress />}
          <DialogTitle>Enviar Notificación</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Deseas enviar esta notificación a <b>{this.state.target}</b>
            </DialogContentText>

            <Typography>{this.state.message}</Typography>
            <Typography>{this.state.url}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={this.state.loading}
              onClick={() => {
                this.handleDialogOpen(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              disabled={this.state.loading}
              onClick={this.send}
            >
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </Dashboard>
    );
  }
}

export default SystemNotifications;
