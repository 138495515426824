import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  appbar: { background: theme.palette.background.default },
  appbarTransparent: {
    background: "transparent",
    boxShadow: "none",
    zIndex: 1000,
  },
  avatar: { margin: 0, width: 36, height: 36 },
  menuAccountSettingsItem: {
    width: 400,
    maxWidth: "90vw",
    justifyContent: "center",
    display: "flex",
  },
  menuAvatar: { margin: 0, width: 120, height: 120 },
});
