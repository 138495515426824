import axios from "axios";
import { toast } from "material-react-toastify";
import React, { Component } from "react";
import AssignmentController from "./AssignmentController";
import CopyDialog from "../CopyDialog";

const assignmentController = new AssignmentController();

class CopyAssignmentDialog extends Component {
  state = {};

  copyAssignment = async (classrooms, idClassroom) => {
    try {
      await assignmentController.copyAssignment(
        idClassroom,
        this.props.idAssignment,
        classrooms
      );

      toast(`Tarea ha sido copiada a otros salones`, { type: "success" });
    } catch (error) {
      if (axios.isCancel(error)) return;
      toast(`Error al copiar una o más tareas`, { type: "error" });
    } finally {
    }
  };

  handleClose = () => {
    if (this.state.loading) return;
    if (this.props.onClose) this.props.onClose();
  };

  render() {
    const { open = false } = this.props;

    return (
      <CopyDialog
        open={open}
        onClose={this.props.onClose}
        idClassroom={this.props.idClassroom}
        onCopy={this.copyAssignment}
        title={`Copiar Tarea`}
        content={`Elige los salones a los que deseas copiar la tarea`}
      />
    );
  }
}

export default CopyAssignmentDialog;
