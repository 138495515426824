import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme) => ({
  container: { padding: 50 },
  containerMobile: { padding: 20 },
  header: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  searchBar: {
    background: "#fff",
    width: 400,
    maxWidth: "100%",
  },
  button: { marginLeft: 10, height: "100%" },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
});
