import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Add, Edit } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import DeleteDialog from "components/Dialogs/DeleteDialog";
import DownloadButton from "components/DownloadButton/DownloadButton";
import GetData from "components/Fetching/GetData";
import PostData from "components/Fetching/PostData";
import Authentication from "controller/Authentication";
import dayjs from "dayjs";
import routes from "Pages/routes";
import React, { Component } from "react";
import { Download } from "react-bootstrap-icons";
import { generatePath } from "react-router";
import EditImprovePlan from "./EditImprovePlan";
import GradeImprovePlan from "./GradeImprovePlan";
import Controller from "./ImprovePlanController";

const controller = new Controller();

class ImprovePlan extends Component {
  state = {
    editDialogOpen: false,
    loading: true,
    deleteLoading: false,
    gradingDialogOpen: false,
    improvePlan: { description: "", dueTime: dayjs(), filesURL: null },
  };

  push = (link) => {
    this.props.history.push(generatePath(link, { ...this.props.match.params }));
  };

  constructor(props) {
    super(props);
    this.deleteRef = React.createRef();
  }

  requestDelete = async () => {
    let { idAssignment, idStudent } = this.props.match.params;
    const data = await controller.deletePlan(idAssignment, idStudent);
    return data;
  };

  handleDelete = () => {
    this.deleteRef.current.post();
  };

  handleDeleteDialogOpen = () => {
    this.setState({ deleteDialogOpen: true });
  };

  handleDeleteDialogClose = () => {
    this.setState({ deleteDialogOpen: false });
  };

  handleEditDialogClose = () => {
    this.setState({ editDialogOpen: false });
  };
  handleEditDialogOpen = () => {
    this.setState({ editDialogOpen: true });
  };

  handleData = (data) => {
    this.setState({ improvePlan: data });
  };

  handleGrade = (criterias, total, message) => {
    const improvePlan = { ...this.state.improvePlan };
    improvePlan.criteria = [...criterias];
    improvePlan.score = total;
    improvePlan.scoredMessage = message;
    improvePlan.lastScoredURL = improvePlan.filesURL;
    this.setState({ improvePlan });
  };

  requestImprovePlan = async () => {
    const { idAssignment, idStudent } = this.props.match.params;
    let data = await controller.getPlan(idAssignment, idStudent);

    return data;
  };

  handleLoading = (val) => {
    this.setState({ loading: val });
  };

  onEdit = ({ message, dueTime }) => {
    let improvePlan = { ...this.state.improvePlan };
    improvePlan.description = message;
    improvePlan.dueTime = dueTime;
    this.setState({ improvePlan }, this.handleEditDialogClose);
  };

  componentWillUnmount() {
    controller.cancelAllTokens();
  }
  render() {
    const { loading, improvePlan } = this.state;
    return (
      <React.Fragment>
        <GetData
          request={this.requestImprovePlan}
          onData={this.handleData}
          // errorMessage={`Error al traer plan de mejora`}
          onLoading={this.handleLoading}
          disableSuccessNotification
          disableErrorNotification
          onError={(error) => {
            this.props.history.replace(
              `${routes.errors.improveNotFound}?id=${this.props.match.params.id}`
            );
          }}
        />

        <PostData
          ref={this.deleteRef}
          request={this.requestDelete}
          onLoading={(val) => this.setState({ deleteLoading: val })}
          errorMessage="Ocurrio un error al momento de eliminar el plan de mejora"
          successMessage="Plan de mejora ha sido eliminado"
          onData={() => {
            this.props.history.push(
              generatePath(routes.teacher.classroomAssignments, {
                id: improvePlan.idClassroom,
                idAssignment: improvePlan.idAssignment,
              })
            );
          }}
        />

        <div
          style={{
            marginTop: "40px",
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* <Card variant="outlined" style={{ padding: 20 }}> */}
              <Typography align="left" variant="h5" gutterBottom>
                Plan de Mejora
              </Typography>
              {/* </Card> */}
              <Divider />
            </Grid>
            <Grid item xs={12} md={8}>
              <Card variant="outlined" style={{ height: "100%" }}>
                <CardContent>
                  <Typography
                    variant="body1"
                    align="left"
                    style={{
                      flexGrow: 1,
                      fontWeight: 600,
                      textTransform: "uppercase",
                    }}
                  >
                    {loading ? <Skeleton /> : improvePlan.name}
                  </Typography>
                  <Typography align="left">
                    {loading ? (
                      <React.Fragment>
                        <Skeleton />
                        <Skeleton />
                      </React.Fragment>
                    ) : (
                      improvePlan.description
                    )}
                  </Typography>
                  <Typography
                    align="left"
                    variant="subtitle2"
                    color="textSecondary"
                  >
                    {loading ? (
                      <Skeleton />
                    ) : (
                      dayjs(improvePlan.dueTime).format("llll")
                    )}
                  </Typography>
                </CardContent>
                <Divider />
                <CardActions>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.props.history.push(
                        generatePath(routes.teacher.assignment, {
                          id: improvePlan.idClassroom,
                          idAssignment: improvePlan.originalAssignment,
                        })
                      )
                    }
                  >
                    Ver Original
                  </Button>
                  <Divider flexItem orientation="vertical" />
                  <Button color="primary" onClick={this.handleEditDialogOpen}>
                    Editar
                  </Button>

                  <Divider flexItem orientation="vertical" />
                  <Button color="primary" onClick={this.handleDeleteDialogOpen}>
                    Eliminar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card variant="outlined" style={{ height: "100%" }}>
                <CardContent>
                  <br />
                  <Typography variant="h4">
                    {loading ? <Skeleton /> : `${improvePlan.score}`}
                  </Typography>
                  <Typography variant="caption">Punteo</Typography>
                  <div style={{ marginTop: 20 }} />
                  <Divider />
                  <br />
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      this.push(routes.teacher.scoresImprove);
                    }}
                    // disabled={!improvePlan.filesURL}
                  >
                    Calificar
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              <Card variant="outlined" style={{ height: "100%" }}>
                <CardContent>
                  <Typography
                    variant="body1"
                    align="left"
                    style={{
                      flexGrow: 1,
                      fontWeight: 600,
                      textTransform: "uppercase",
                    }}
                  >
                    Criterios
                  </Typography>
                  <Typography align="left">
                    {loading ? (
                      <React.Fragment>
                        <Skeleton />
                        <Skeleton />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {improvePlan.criteria.map((el, index, arr) => {
                          return (
                            <div
                              style={{ display: "flex", marginTop: "10px" }}
                              key={index}
                            >
                              <Typography varaint="body1" align="left">
                                {el.name}
                              </Typography>
                              <div style={{ flexGrow: 1 }} />
                              <Typography
                                varaint="body1"
                                align="left"
                                color="textSecondary"
                                style={{ fontWeight: "bold" }}
                              >
                                {el.percentage}
                              </Typography>
                              {index !== arr.length - 1 && (
                                <Divider style={{ marginTop: "10px" }} />
                              )}
                            </div>
                          );
                        })}
                      </React.Fragment>
                    )}
                  </Typography>
                </CardContent>

                <Divider />
                <CardActions>
                  <Button color="primary" onClick={this.handleEditDialogOpen}>
                    Editar Criterios
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={8}>
              <Card variant="outlined" style={{ height: "100%" }}>
                <CardActions>
                  <Typography
                    variant="body1"
                    align="left"
                    style={{
                      marginLeft: 12,
                      flexGrow: 1,
                      fontWeight: 600,
                      textTransform: "uppercase",
                    }}
                  >
                    {"Temario"}
                  </Typography>

                  {improvePlan.filesURL !== null && (
                    <React.Fragment>
                      <Divider flexItem orientation="vertical" />
                      <DownloadButton
                        fileURL={improvePlan.filesURL}
                        color="primary"
                        variant="text"
                        label="Descargar"
                      />
                    </React.Fragment>
                  )}

                  <Divider flexItem orientation="vertical" />

                  {improvePlan.filesURL === null ? (
                    <Button
                      onClick={() => {
                        this.handleTemaryDialogOpen(true, false);
                      }}
                      color="primary"
                    >
                      Agregar
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        this.handleTemaryDialogOpen(true, true);
                      }}
                      color="primary"
                    >
                      Editar
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </div>
        <EditImprovePlan
          open={this.state.editDialogOpen}
          onClose={this.handleEditDialogClose}
          message={this.state.improvePlan.description}
          dueTime={this.state.improvePlan.dueTime}
          idAssignment={this.props.match.params.idAssignment}
          assignment={improvePlan}
          idStudent={this.props.match.params.idStudent}
          onEdit={this.onEdit}
        />

        <DeleteDialog
          open={this.state.deleteDialogOpen}
          loading={this.state.deleteLoading}
          onClose={this.handleDeleteDialogClose}
          onDelete={this.handleDelete}
        />

        <GradeImprovePlan
          // ref={this.gradeRef}
          open={this.state.gradingDialogOpen}
          onClose={() => {
            this.setState({ gradingDialogOpen: false });
          }}
          data={improvePlan}
          // editing={this.state.gradingDialog.editing}
          // assignment={this.state.assignment}
          onGrade={this.handleGrade}
          idAssignment={this.props.match.params.idAssignment}
          idClassroom={this.props.match.params.id}
        />
      </React.Fragment>
    );
  }
}

export default ImprovePlan;
