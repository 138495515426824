import axios from "axios";
import { toast } from "material-react-toastify";
import React, { Component } from "react";

class GetData extends Component {
  state = {
    loading: false,
    data: null,
    error: null,
  };

  get = async () => {
    try {
      this.setState({ loading: true, error: null, data: null });
      if (this.props.onStart) this.props.onStart();
      if (this.props.onLoading) this.props.onLoading(true);
      let data = await this.props.request();
      this.setState({ data });
      if (this.props.onData) this.props.onData(data);
      if (!this.props.disableSuccessNotification) {
        let { successMessage = `Éxito` } = this.props;
        toast(successMessage, { type: "success" });
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        if (this.props.onCancel) this.props.onCancel();
        return;
      }
      this.setState({ error });
      if (this.props.onError) this.props.onError(error);
      if (!this.props.disableErrorNotification) {
        let { errorMessage = `Error` } = this.props;
        toast(errorMessage, { type: "error" });
      }
    } finally {
      this.setState({ loading: false });
      if (this.props.onStart) this.props.onStart();
      if (this.props.onLoading) this.props.onLoading(false);
    }
  };

  async componentDidMount() {
    if (this.props.dontExecuteOnStart) return;
    await this.get();
  }

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

export default GetData;
