import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Hidden,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  withStyles,
  withWidth,
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";
import {
  BackupRounded,
  ArrowBack,
  Edit,
  Delete,
  WatchLaterOutlined,
} from "@material-ui/icons";
import React, { Component } from "react";
import styles from "./StudentAssignmentStyles";
import Controller from "./StudentAssignmentController";
import Axios from "axios";
import { toast } from "material-react-toastify";
import dayjs from "dayjs";
import AssignmentErrorMessages from "Pages/Teacher/Classroom/Classroom/Assignments/AssignmentErrorMessages";
import DefaultErrorMessages from "utils/DefaultErrorMessages";
import DeleteDialog from "components/Dialogs/DeleteDialog";
import routes from "Pages/routes";
import { generatePath } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { DropzoneArea } from "material-ui-dropzone";
import { Download } from "react-bootstrap-icons";
import UploadButton from "components/DownloadButton/UploadButton";
import DownloadButton from "components/DownloadButton/DownloadButton";
import { StyledDropZone } from "react-drop-zone";
import "react-drop-zone/dist/styles.css";
import UploadAssignmentDialog from "./UploadAssignmentDialog";

const controller = new Controller();

class Assignment extends Component {
  state = {
    submitted: {
      loading: true,
      data: null,
    },
    assignment: {
      loading: true,
      data: null,
      error: null,
    },
    emptyAssignment: {
      name: "",
      description: "",
      score: 0,
      originalAssignment: null,
      dueTime: dayjs(new Date(new Date().setHours(24, 0, 0, 0))),
      dueTimeStr: "00:00",
      dueDateStr: dayjs(new Date().setHours(24, 0, 0, 0)).format("YYYY-MM-DD"),
      criteria: [],
    },
    userAssignmentDialogOpen: false,
    uploadProgress: 0,
    score: {
      data: null,
      loading: false,
    },
  };

  async componentDidMount() {
    await this.getAssignment(
      this.props.match.params.id,
      this.props.match.params.idAssignment
    );

    await this.getStatus(
      this.props.match.params.id,
      this.props.match.params.idAssignment
    );
  }

  componentWillUnmount() {
    controller.cancelAllTokens();
  }
  getAssignment = async (idClassroom, idAssignment) => {
    this.setState({ assignment: { loading: true, data: null, error: null } });
    try {
      let result = await controller.getAssignment(idClassroom, idAssignment);
      this.setState({
        assignment: { loading: false, data: { ...result }, error: null },
      });
    } catch (error) {
      if (Axios.isCancel(error)) return;
      let assignment = { loading: false, data: null, error };
      this.setState({ assignment }, () => {});
      this.props.history.replace(
        `${routes.errors.assignmentNotFound}?idClassroom=${idClassroom}`
      );
      // console.log(error);
      // toast("Error al obtener la tarea", { type: "error" });
    }
  };

  openUploadDialog = (value) => {
    this.setState({ userAssignmentDialogOpen: value });
  };

  getStatus = async (idClassroom, idAssignment) => {
    try {
      this.setState({ submitted: { loading: true, data: null } });
      let result = await controller.getAssignmentStatus(
        idClassroom,
        idAssignment
      );
      this.setState({ submitted: { loading: false, data: result } });
      this.getScore();
    } catch (error) {
      if (Axios.isCancel(error)) return;
      this.setState({ submitted: { loading: false, data: null } });
    }
  };

  getScore = async () => {
    let { id, idAssignment } = this.props.match.params;
    try {
      this.setState({ score: { loading: true, data: null } });
      let result = await controller.getScore(id, idAssignment);

      //No se ha calificado
      if (result.criterias.length === 0) {
        let status = this.state.submitted.data;
        if (
          (status.status === "not_submitted" && status.canSubmit) ||
          status.status === "submitted"
        ) {
          result = null;
        }
      }
      this.setState({ score: { loading: false, data: result } });
    } catch (error) {}
  };

  push = (link) => {
    this.props.history.push(generatePath(link, { ...this.props.match.params }));
  };

  handleUpload = async (url) => {
    // this.openUploadDialog(false);
    const { id, idAssignment } = this.props.match.params;
    // setTimeout(() => {
    window.location.reload();
    //}, 1000);

    //await this.getStatus(id, idAssignment);
    //const assignment = { ...this.state.assignment };
    //assignment.data.filesURL = url;
    // this.setState({ assignment });
  };

  render() {
    let assignment = this.state.emptyAssignment;
    const { data } = this.state.assignment;
    let score = this.state.score.data;
    if (data !== null) assignment = { ...data };

    return (
      <React.Fragment>
        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => this.push(routes.student.classroomAssignments)}
          >
            <ArrowBack />
          </IconButton>
          <Typography
            style={{ marginLeft: "20px" }}
            variant={"h5"}
            align="left"
          >
            Tareas
          </Typography>
        </div>
        <br />

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Card variant="outlined">
              <CardContent>
                <Typography
                  variant="body1"
                  align="left"
                  style={{
                    flexGrow: 1,
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  {this.state.assignment.loading ? (
                    <Skeleton variant="text" />
                  ) : (
                    assignment.name
                  )}

                  {!this.state.assignment.loading &&
                    this.state.submitted.loading && (
                      <Chip style={{ marginLeft: 20, width: 75 }} label="">
                        <Skeleton />
                      </Chip>
                    )}
                  {/* 
                  {!this.state.assignment.loading &&
                    assignment.hasImprove !== null && (
                      <Chip
                        label="Plan Mejora"
                        color="primary"
                        style={{ marginLeft: 10 }}
                        variant="outlined"
                      />
                    )} */}

                  {!this.state.assignment.loading &&
                    this.state.submitted.data &&
                    this.state.submitted.data.status === "submitted" && (
                      <Chip
                        label="Entregada"
                        color="primary"
                        style={{ marginLeft: 10 }}
                        variant="outlined"
                      />
                    )}

                  {!this.state.assignment.loading &&
                    this.state.submitted.data &&
                    this.state.submitted.data.status === "submitted" &&
                    dayjs(assignment.dueTime).isBefore(
                      dayjs(this.state.submitted.data.submitted.submitTime)
                    ) && (
                      <React.Fragment>
                        <Chip
                          style={{ marginLeft: 10 }}
                          label={`Entregada ${dayjs(assignment.dueTime).from(
                            dayjs(
                              this.state.submitted.data.submitted.submitTime
                            ),
                            true
                          )} tarde`}
                          // className={classes.errorChip}
                          color="primary"
                          variant="outlined"
                          icon={<WatchLaterOutlined />}
                        />
                      </React.Fragment>
                    )}

                  {!this.state.assignment.loading &&
                    !this.state.submitted.loading &&
                    !this.state.score.loading &&
                    this.state.score.data &&
                    this.state.score.data.criterias.length > 0 && (
                      <Chip
                        label={"Calificada"}
                        color="primary"
                        style={{ marginLeft: 10 }}
                      />
                    )}
                </Typography>
                <Typography
                  varaint="body1"
                  align="left"
                  style={{ marginTop: 20 }}
                >
                  {this.state.assignment.loading ? (
                    <React.Fragment>
                      <Skeleton variant="text" />
                      <Skeleton variant="text" width={250} />
                    </React.Fragment>
                  ) : (
                    assignment.description
                  )}
                </Typography>
                <br />
                {/* <Typography
                  varaint="body1"
                  align="left"
                  color="textSecondary"
                  style={{ fontWeight: "bold" }}
                >
                  {this.state.assignment.loading ? (
                    <Skeleton variant="text" width={150} />
                  ) : (
                    `${assignment.score} puntos`
                  )}
                </Typography> */}
                <Typography
                  align="left"
                  variant="subtitle2"
                  color="textSecondary"
                >
                  {this.state.assignment.loading ? (
                    <Skeleton variant="text" width={150} />
                  ) : (
                    `${dayjs(assignment.dueTime).format("llll")}`
                  )}
                </Typography>
              </CardContent>
              {!this.state.assignment.loading &&
                (this.state.assignment.data.hasImprove !== null ||
                  assignment.filesURL !== null) && <Divider />}
              <CardActions>
                {!this.state.assignment.loading &&
                  this.state.assignment.data.hasImprove !== null && (
                    <React.Fragment>
                      <Button
                        onClick={() => {
                          const { id, idAssignment } = this.props.match.params;
                          const url = generatePath(routes.student.improvePlan, {
                            id: id,
                            idAssignment: assignment.hasImprove,
                          });
                          this.props.history.push(url);
                        }}
                        color="primary"
                      >
                        Plan de Mejora
                      </Button>
                      <Divider orientation="vertical" flexItem />
                    </React.Fragment>
                  )}

                {!this.state.assignment.loading &&
                  assignment.filesURL !== null && (
                    <React.Fragment>
                      <DownloadButton
                        variant="text"
                        fileURL={assignment.filesURL}
                        color="primary"
                        label="Temario"
                      />
                    </React.Fragment>
                  )}
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card variant="outlined" style={{ height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {(this.state.assignment.loading ||
                  this.state.score.loading ||
                  this.state.submitted.loading) && (
                  <Skeleton width={150} variant="text" height={100} />
                )}
                <Typography
                  variant="subtitle1"
                  align="center"
                  style={{
                    fontSize: "2rem",
                    letterSpacing: "5px",
                  }}
                >
                  {!this.state.score.loading &&
                    !this.state.submitted.loading &&
                    this.state.assignment.data &&
                    ` ${
                      this.state.score.data ? this.state.score.data.total : "--"
                    } / ${this.state.assignment.data.score}`}
                </Typography>
                <Typography variant="caption">Calificación</Typography>
              </div>
            </Card>
          </Grid>

          {this.state.score.data && this.state.score.data.criterias.length > 0 && (
            <Grid item xs={12} md={8}>
              <Card variant="outlined">
                <CardContent>
                  <Typography
                    variant="body1"
                    align="left"
                    style={{
                      flexGrow: 1,
                      fontWeight: 600,
                      textTransform: "uppercase",
                    }}
                  >
                    Calificación
                  </Typography>
                  <br />
                  {this.state.score.data.message && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="body1" align="left">
                        {this.state.score.data.message ||
                          `No hay ningún comentario`}
                      </Typography>
                    </div>
                  )}
                  <br />
                  <Divider />
                  {score.criterias.map((el, index, arr) => {
                    return (
                      <div
                        style={{ display: "flex", marginTop: "10px" }}
                        key={index}
                      >
                        <Typography varaint="body1" align="left">
                          {el.name}
                        </Typography>
                        <div style={{ flexGrow: 1 }} />
                        <Typography
                          varaint="body1"
                          align="left"
                          color="textSecondary"
                          style={{ fontWeight: "bold" }}
                        >
                          {el.score || 0} / {el.percentage}
                        </Typography>
                        {index !== arr.length - 1 && (
                          <Divider style={{ marginTop: "10px" }} />
                        )}
                      </div>
                    );
                  })}
                </CardContent>

                <Divider />
                {this.state.score.data.lastScoredURL && (
                  <CardActions>
                    <DownloadButton
                      variant="text"
                      fileURL={this.state.score.data.lastScoredURL}
                      color="primary"
                      label="Descargar Tarea"
                    />
                  </CardActions>
                )}
              </Card>
            </Grid>
          )}

          <Grid item xs={12} md={8}>
            <Card variant="outlined">
              <CardContent>
                <Typography
                  variant="body1"
                  align="left"
                  style={{
                    flexGrow: 1,
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  {this.state.assignment.loading ? <Skeleton /> : `Criterios`}
                </Typography>

                {this.state.assignment.loading && (
                  <React.Fragment>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </React.Fragment>
                )}
                {assignment.criteria.map((el, index, arr) => {
                  return (
                    <div
                      style={{ display: "flex", marginTop: "10px" }}
                      key={index}
                    >
                      <Typography varaint="body1" align="left">
                        {el.name}
                      </Typography>
                      <div style={{ flexGrow: 1 }} />
                      <Typography
                        varaint="body1"
                        align="left"
                        color="textSecondary"
                        style={{ fontWeight: "bold" }}
                      >
                        {el.percentage}
                      </Typography>
                      {index !== arr.length - 1 && (
                        <Divider style={{ marginTop: "10px" }} />
                      )}
                    </div>
                  );
                })}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <br />
        <Typography variant="subtitle1" align="left">
          Entregas
        </Typography>
        {this.state.submitted.loading && (
          <React.Fragment>
            <Skeleton variant="rect" height={50} width={200} />
            <br />
            <Skeleton variant="rect" height={100} width={"100%"} />
            <br />
          </React.Fragment>
        )}
        <div
          style={{ display: "flex", marginTop: "20px", marginBottom: "20px" }}
        >
          {!this.state.submitted.loading && (
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => this.openUploadDialog(true)}
              // disabled={!this.state.submitted.data.canSubmit}
            >
              {this.state.submitted.data &&
              this.state.submitted.data.status === "submitted"
                ? `Reemplazar Tarea`
                : `Subir Tarea`}
            </Button>
          )}
        </div>
        <br />
        {!this.state.submitted.loading &&
          this.state.submitted.data.status === "submitted" && (
            <Card
              variant="outlined"
              style={{
                padding: "30px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle2">
                  {dayjs(this.state.submitted.data.submitted.submitTime).format(
                    "DD/MM/YYYY  ·  HH:mm"
                  ) || "ERROR"}
                </Typography>
                <div style={{ flexGrow: 1 }} />
                <Hidden smDown>
                  <DownloadButton
                    color={"primary"}
                    endIcon={<Download />}
                    fileURL={this.state.submitted.data.submitted.fileURL}
                    label="Descargar"
                    variant="text"
                  />
                </Hidden>
              </div>
              <Hidden mdUp>
                <div>
                  <DownloadButton
                    color={"primary"}
                    endIcon={<Download />}
                    fileURL={this.state.submitted.data.submitted.fileURL}
                    label="Descargar"
                    variant="text"
                  />
                </div>
              </Hidden>
            </Card>
          )}
        <br />
        <br />

        <UploadAssignmentDialog
          open={this.state.userAssignmentDialogOpen}
          idAssignment={this.props.match.params.idAssignment}
          idClassroom={this.props.match.params.id}
          onClose={() => this.openUploadDialog(false)}
          onUploadFinish={this.handleUpload}
        />

        {/* </Classroom> */}
      </React.Fragment>
    );
  }
}
export default withWidth()(withStyles(styles)(Assignment));
