import axios from "utils/API";
import Axios from "axios";

export default class NotificationsController {
  constructor() {
    this.getAllSource = Axios.CancelToken.source();
    this.readAllSource = Axios.CancelToken.source();
    this.readSource = Axios.CancelToken.source();
    this.getUnreadSource = Axios.CancelToken.source();
  }

  getNotifications = async () => {
    if (this.getAllSource) this.getAllSource.cancel();
    this.getAllSource = Axios.CancelToken.source();

    const response = await axios.get(`api/notifications/`, {
      cancelToken: this.getAllSource.token,
    });
    return response.data;
  };

  getUnread = async () => {
    if (this.getUnreadSource) this.getUnreadSource.cancel();
    this.getUnreadSource = Axios.CancelToken.source();

    const response = await axios.get(`api/unread-notifications/`, {
      cancelToken: this.getUnreadSource.token,
    });
    return response.data.total;
  };

  read = async (id) => {
    if (this.readSource) this.readSource.cancel();
    this.readSource = Axios.CancelToken.source();

    const response = await axios.post(
      `api/notifications/${id}/read`,
      {},
      { cancelToken: this.readSource.token }
    );
    return response.data;
  };

  readAll = async () => {
    if (this.readAllSource) this.readAllSource.cancel();
    this.readAllSource = Axios.CancelToken.source();

    const response = await axios.post(
      `api/all-notifications/read`,
      {},
      { cancelToken: this.readAllSource.token }
    );
    return response.data;
  };

  cancelAllTokens() {
    if (this.getAllSource) this.getAllSource.cancel();
    if (this.readAllSource) this.readAllSource.cancel();
    if (this.readSource) this.readSource.cancel();
    if (this.getUnreadSource) this.getUnreadSource.cancel();
  }
}
