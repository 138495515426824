import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { BackupRounded, ArrowBack, Close, Delete } from "@material-ui/icons";
import React, { Component } from "react";
import styles from "./AssignmentCreationStyles";
import Controller from "./AssignmentController";
import { toast } from "material-react-toastify";
import dayjs from "dayjs";
import Axios from "axios";
import AssignmentErrorMessages from "./AssignmentErrorMessages";
import DefaultErrorMessages from "utils/DefaultErrorMessages";
import { generatePath } from "react-router-dom";
import { DropzoneArea } from "material-ui-dropzone";
import UploadButton from "components/DownloadButton/UploadButton";
import UploadController from "components/DownloadButton/UploadController";

const upload = new UploadController();
const controller = new Controller();

class AssignmentCreation extends Component {
  state = {
    files: [],
    creating: false,
    assignment: {
      name: "",
      description: "",
      score: 0,
      originalAssignment: null,
      dueTime: dayjs(new Date(new Date().setHours(23, 59, 0, 0))),
      dueTimeStr: "23:59",
      dueDateStr: dayjs(new Date().setHours(23, 59, 0, 0)).format("YYYY-MM-DD"),
      criteria: [{ name: "Entrega", percentage: 0 }],
    },

    upload: {
      uploading: false,
      progress: 0,
    },
  };

  handleDropzoneChange = async (files) => {
    this.setState({ files: files });
  };

  componentDidMount() {}

  componentWillUnmount() {
    controller.cancelAllTokens();
    upload.cancelAllTokens();
  }

  getTemaryUploadURL = (idAssignment) => {
    let { id } = this.props.match.params;
    return `files/v2/classrooms/${id}/assignments/temary`;
  };

  onUploadProgress = (progressEvent) => {
    let progress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    let { upload } = this.state;
    upload.progress = progress;
    this.setState({ upload });
  };

  onUploadFinish = (data) => {
    let { upload } = this.state;
    upload.progress = 0;
    upload.uploading = false;
    this.setState({ upload });
  };

  onUploadCancel = () => {
    let { upload } = this.state;
    upload.progress = 0;
    upload.uploading = false;
    this.setState({ upload });
  };

  createAssignment = async () => {
    try {
      this.setState({ creating: true });

      const response = await controller.createAssignment(
        this.props.match.params.id,
        { ...this.state.assignment }
      );

      let id = response.insertId;

      //Si hay archivos
      if (this.state.files.length > 0) {
        toast(`Tarea ha sido agregada`, { type: "success" });
        toast(`Subiendo archivos a la tarea`, { type: "dark", delay: 500 });

        this.setState({ upload: { uploading: true, progress: 0 } });

        let f = await upload.startUpload(
          this.getTemaryUploadURL(id),
          this.state.files,
          "file",
          this.onUploadProgress,
          this.onUploadFinish
        );
        let filesURL = f.uri;
        await controller.editAssignment(this.props.match.params.id, id, {
          filesURL,
        });

        this.onUploadFinish(f);
      }

      if (this.props.onAdd) this.props.onAdd();
      this.props.onBack();
      this.setState({ creating: false });
      toast(`Tarea creada exitosamente!`, { type: "success" });
    } catch (error) {
      this.setState({ creating: false });
      if (Axios.isCancel(error)) {
        this.onUploadCancel();
        return;
      }
      if (this.state.upload.uploading) error = new Error("on_uploading_files");
      this.onUploadFinish();

      console.log(error);
      let message =
        AssignmentErrorMessages(error) ||
        DefaultErrorMessages(error, "Error desconocido al crear tarea");
      toast(message, { type: "error" });
    }
  };

  addCriteria = () => {
    let assignment = { ...this.state.assignment };
    assignment.criteria.push({ name: "", percentage: 0 });
    const index = assignment.criteria.length - 1;

    this.setState({ assignment }, () => {
      document.getElementById(`criteria-name-${index}`).focus();
    });
  };

  removeCriteria = (index) => {
    let assignment = { ...this.state.assignment };
    assignment.criteria.splice(index, 1);
    this.setState({ assignment }, () => {
      document.getElementById(`add-btn`).focus();
      this.updateCriteria();
    });
  };

  setDueTime = (date, time) => {
    let fullDateStr = `${date} ${time}`;
    let d = dayjs(fullDateStr, "YYYY-MM-DD HH:mm");
    let assignment = {
      ...this.state.assignment,
      dueTime: d,
      dueTimeStr: time,
      dueDateStr: date,
    };

    this.setState({ assignment });
  };

  setDate = (e) => {
    let date = e.target.value;
    if (date == "")
      date = dayjs(this.state.assignment.dueTime).format("YYYY-MM-DD");
    let newE = { target: { value: date } };
    this.setDueTime(date, this.state.assignment.dueTimeStr);
  };
  setTime = (e) => {
    let time = e.target.value;
    if (time == "") time = dayjs(this.state.assignment.dueTime).format("HH:mm");
    let newE = { target: { value: time } };
    this.setDueTime(this.state.assignment.dueDateStr, time);
  };

  updateAssignment = (e, name) => {
    let assignment = { ...this.state.assignment };
    assignment[name] = e.target.value;
    this.setState({ assignment });
  };

  updateCriteria = (e, name, index) => {
    let assignment = { ...this.state.assignment };

    if (e) assignment.criteria[index][name] = e.target.value;
    assignment.score = assignment.criteria.reduce((acum, value) => {
      let val = acum + parseFloat(value.percentage);
      return parseFloat(val.toFixed(10));
    }, 0);
    this.setState({ assignment });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton onClick={this.props.onBack}>
            <ArrowBack />
          </IconButton>
          <Typography
            style={{ marginLeft: "20px" }}
            variant={"h5"}
            align="left"
          >
            Tareas
          </Typography>
        </div>
        <div>
          <div className={classes.section}>
            <div className={classes.sectionHeader}>
              <Typography variant={"subtitle2"} align="left">
                Información
              </Typography>
            </div>
            <div className={classes.form}>
              <TextField
                variant="outlined"
                fullWidth
                autoFocus
                label="Nombre"
                margin="dense"
                value={this.state.assignment.name}
                onChange={(e) => {
                  this.updateAssignment(e, "name");
                }}
              />
              <TextField
                variant="outlined"
                fullWidth
                multiline
                label="Descripción"
                margin="dense"
                value={this.state.assignment.description}
                onChange={(e) => {
                  this.updateAssignment(e, "description");
                }}
              />
            </div>
          </div>

          <div className={classes.section}>
            <div className={classes.sectionHeader}>
              <Typography variant={"subtitle2"} align="left">
                Archivos
              </Typography>
            </div>

            <div className={classes.form}>
              <DropzoneArea
                onChange={this.handleDropzoneChange}
                dropzoneText="Arrastra archivos o da click"
                maxFileSize={1024 * 1024 * 100}
                filesLimit={1}
                showAlerts={false}
                //                acceptedFiles={["image/jpeg", "image/jpg", "image/png"]}
                useChipsForPreview
              />
            </div>
          </div>
          <div className={classes.section}>
            <div className={classes.sectionHeader}>
              <Typography variant="subtitle2" align="left">
                Entrega
              </Typography>
            </div>
            <div className={classes.form}>
              <TextField
                label="Fecha de entrega"
                variant="outlined"
                margin="dense"
                fullWidth
                type="date"
                value={this.state.assignment.dueDateStr}
                onChange={(e) => {
                  this.setDate(e);
                }}
              />
              <TextField
                label="Hora de entrega"
                variant="outlined"
                margin="dense"
                type="time"
                fullWidth
                value={this.state.assignment.dueTimeStr}
                onChange={(e) => {
                  this.setTime(e);
                }}
              />
            </div>
          </div>

          <div className={classes.section}>
            <div className={classes.sectionHeader}>
              <Typography variant="subtitle2" align="left">
                Criterios a calificar
              </Typography>
            </div>
            <div className={classes.form}>
              <Grid container spacing={2}>
                {this.state.assignment.criteria.map((el, index, arr) => {
                  return (
                    <React.Fragment>
                      <Grid item xs={8}>
                        <TextField
                          label="Nombre"
                          variant="outlined"
                          margin="dense"
                          value={el.name}
                          fullWidth
                          id={`criteria-name-${index}`}
                          onChange={(e) => {
                            this.updateCriteria(e, "name", index);
                          }}
                        />
                      </Grid>
                      <Grid item xs={arr.length > 1 ? 3 : 4}>
                        <TextField
                          label="Punteo"
                          variant="outlined"
                          margin="dense"
                          type="number"
                          value={el.percentage}
                          onChange={(e) => {
                            this.updateCriteria(e, "percentage", index);
                          }}
                          fullWidth
                        />
                      </Grid>
                      {arr.length > 1 && (
                        <Grid item xs={1}>
                          <IconButton
                            onClick={() => {
                              this.removeCriteria(index);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      )}
                    </React.Fragment>
                  );
                })}
              </Grid>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  id="add-btn"
                  color="primary"
                  onClick={() => {
                    this.addCriteria();
                  }}
                >
                  Agregar Criterio
                </Button>
              </div>

              <TextField
                variant="outlined"
                fullWidth
                label="Punteo"
                margin="dense"
                type="number"
                disabled
                value={this.state.assignment.score}
                onChange={(e) => {
                  this.updateAssignment(e, "score");
                }}
              />

              <br />
              {this.state.upload.uploading && (
                <React.Fragment>
                  <Typography variant={"caption"} align="left">
                    Subiendo archivos
                  </Typography>

                  <LinearProgress
                    variant="determinate"
                    value={this.state.upload.progress}
                  />
                </React.Fragment>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "30px",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginRight: "20px" }}
                  onClick={this.createAssignment}
                  disabled={this.state.creating}
                >
                  Crear Tarea
                </Button>
                <Button
                  color="primary"
                  disabled={this.state.creating}
                  onClick={this.props.onBack}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AssignmentCreation);
