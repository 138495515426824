import React, { Component } from "react";
import styles from "./SignUpForm-styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import GoogleButton from "react-google-button";
import {
  SignUp,
  OpenGoogleSignIn,
} from "Pages/Login/Authentication/authentication";

import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  TextField,
} from "@material-ui/core";

import validator from "validator";

const passwordValidator = require("password-validator");

var schema = new passwordValidator();

schema
  .is()
  .min(8)
  .is()
  .max(100)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits(2)
  .has()
  .not()
  .spaces();

class SignUpForm extends Component {
  constructor(props) {
    super();
    this.login = this.login.bind(this);
  }

  state = {
    loadingLogin: false,
    nameHelperText: "",
    lastNameHelperText: "",
    passwordHelperText: "",
    confirmPasswordHelperText: "",
    emailHelperText: "",
  };

  async login(
    e,
    name,
    lastName,
    email,
    password,
    confirmPassword,
    handleStart,
    handleCompletion,
    handleError
  ) {
    e.preventDefault();

    //Validate
    if (!this.validateForm(name, lastName, email, password, confirmPassword))
      return;

    handleStart();
    this.setState({ loadingLogin: true });

    try {
      await SignUp(name, lastName, email, password);
      handleCompletion();
      this.setState({ loadingLogin: false });
    } catch (e) {
      if (e == `Cancel`) return;
      this.setState({ loadingLogin: false, email: "", password: "" });
      handleError(e);
    }
  }

  handleTextChange = (e, str) => {
    this.setState({ [str + "HelperText"]: "" });
    this.props.handleTextChange(e, str);
  };

  /**
   * Acciones de validación
   * @param {*} valid
   * @param {*} message
   * @param {*} ref
   * @param {*} stateName
   */
  validateInput(valid, message, ref, stateName) {
    if (!valid) {
      ref.current.focus();
      this.setState({ [stateName]: message });
      return;
    }
    this.setState({ [stateName]: "" });
  }

  /**
   *
   * @param {String} name
   * @param {String} lastName
   * @param {String} email
   * @param {String} password
   * @param {String} confirmPassword
   */
  validateForm(name, lastName, email, password, confirmPassword) {
    console.log(name);

    //Validar Nombre
    let isInvalid = name.length < 1 || name.length > 150;

    this.validateInput(
      !isInvalid,
      "Nombre no puede estar vacío",
      this.props.nameInputRef,
      "nameHelperText"
    );

    if (isInvalid) return false;

    //Validar apellido
    isInvalid = lastName.length < 1 || lastName.length > 150;

    this.validateInput(
      !isInvalid,
      "Apellido no puede estar vacío",
      this.props.lastNameInputRef,
      "lastNameHelperText"
    );

    if (isInvalid) return false;

    //Validar correo
    isInvalid = !validator.isEmail(email);
    this.validateInput(
      !isInvalid,
      "Correo inválido",
      this.props.emailInputRef,
      "emailHelperText"
    );

    if (isInvalid) return false;

    //Validar contraseña

    isInvalid = !schema.validate(password);
    this.validateInput(
      !isInvalid,
      "Contraseña insegura",
      this.props.passwordInputRef,
      "passwordHelperText"
    );

    if (isInvalid) return false;

    //Validar confirmar contraseña

    isInvalid = password !== confirmPassword;
    this.validateInput(
      !isInvalid,
      "Contraseñas no coinciden",
      this.props.confirmPasswordInputRef,
      "confirmPasswordHelperText"
    );

    if (isInvalid) return false;

    return true;
  }

  render() {
    const { classes, handleStart } = this.props;
    const { loadingLogin } = this.state;
    const {
      email,
      password,
      name,
      confirmPassword,
      lastName,
      disabled = false,
    } = this.props;
    const buttonDisabled = disabled || loadingLogin;

    return (
      <React.Fragment>
        <Typography variant="h4" color="textSecondary">
          Crear Cuenta
        </Typography>
        <br />

        <form className="form-container" onSubmit={() => false}>
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              autoFocus
              autoComplete="given-name"
              color="primary"
              variant="filled"
              type="text"
              label={"Nombres"}
              size={"small"}
              value={name}
              inputRef={this.props.nameInputRef}
              onChange={(e) => this.handleTextChange(e, "name")}
              inputProps={{ maxLength: 150 }}
              required
              helperText={this.state.nameHelperText}
              error={this.state.nameHelperText !== ""}
              //error
            />
          </FormControl>

          <FormControl className={classes.formControl} fullWidth>
            <TextField
              autoComplete="family-name"
              color="primary"
              variant="filled"
              type="text"
              label={"Apellidos"}
              size={"small"}
              value={lastName}
              inputRef={this.props.lastNameInputRef}
              onChange={(e) => this.handleTextChange(e, "lastName")}
              inputProps={{ maxLength: 150 }}
              required
              helperText={this.state.lastNameHelperText}
              error={this.state.lastNameHelperText !== ""}
            />
          </FormControl>
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              autoComplete="email"
              color="primary"
              variant="filled"
              type="text"
              label={"Correo"}
              size={"small"}
              value={email}
              inputRef={this.props.emailInputRef}
              onChange={(e) => this.handleTextChange(e, "email")}
              required
              helperText={this.state.emailHelperText}
              error={this.state.emailHelperText !== ""}
            />
          </FormControl>
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              variant="filled"
              color="primary"
              autoComplete="new-password"
              type="password"
              label={"Contraseña"}
              size={"small"}
              value={password}
              inputRef={this.props.passwordInputRef}
              onChange={(e) => this.handleTextChange(e, "password")}
              required
              helperText={this.state.passwordHelperText}
              error={this.state.passwordHelperText !== ""}
            />
          </FormControl>
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              variant="filled"
              color="primary"
              autoComplete="new-password"
              type="password"
              label={"Confirmar Contraseña"}
              size={"small"}
              value={confirmPassword}
              inputRef={this.props.confirmPasswordInputRef}
              onChange={(e) => this.handleTextChange(e, "confirmPassword")}
              helperText={this.state.confirmPasswordHelperText}
              error={this.state.confirmPasswordHelperText !== ""}
              required
            />
          </FormControl>

          <FormControl className={classes.formControl} fullWidth>
            <Button
              type="submit"
              variant="contained"
              color={"primary"}
              onClick={(e) =>
                this.login(
                  e,
                  name,
                  lastName,
                  email,
                  password,
                  confirmPassword,
                  this.props.handleStart,
                  this.props.handleCompletion,
                  this.props.handleError
                )
              }
              disabled={buttonDisabled}
              className={classes.button}
            >
              Crear Cuenta
            </Button>

            {loadingLogin && (
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
                color="primary"
              />
            )}
          </FormControl>
        </form>
        <br />
        <br />
        <Divider />
        <br />
        {/* <Typography variant="body2">Otras formas de iniciar sesión</Typography>
        <div className="google-login-container">
          <GoogleButton
            disabled={buttonDisabled}
            type="dark"
            label="Inicia sesión con Google"
            onClick={() => {
              OpenGoogleSignIn();
            }}
          />
        </div> */}
      </React.Fragment>
    );
  }
}

SignUpForm.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(SignUpForm);
