import {
  Avatar,
  Card,
  CardHeader,
  Grid,
  Hidden,
  Typography,
  withStyles,
  withWidth,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import axios from "axios";
import { FetchDataComponent } from "components/Fetching/FetchDataComponent";
import Authentication from "controller/Authentication";
import { toast } from "material-react-toastify";
import React, { Component } from "react";
import Controller from "../ClassroomController";
import styles from "./ClassroomStudentsStyles";
import PhoneNumber from "awesome-phonenumber";

const controller = new Controller();

function textDataRender(text) {
  return (
    <Typography
      variant="caption"
      color="textSecondary"
      style={{
        fontWeight: "600",
        marginTop: "35px",
        fontSize: "24px",
        marginLeft: "30px",
        height: "75px",
        opacity: "0.5",
      }}
    >
      {text}
    </Typography>
  );
}

function loadingCards(prefix) {
  let style = { width: "100%", maxHeight: "275px", height: "108px" };
  return (
    <React.Fragment>
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_01"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_02"}>
        <Skeleton variant="rect" style={style} />
      </Grid>{" "}
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_03"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
    </React.Fragment>
  );
}

class ClassroomStudents extends Component {
  state = { students: { data: [], loading: false, error: null } };

  getStudents = async () => {
    const id = this.props.match.params.id;
    try {
      let students = { ...this.state.students };
      students.loading = true;
      this.setState({ students });

      const data = await controller.getStudents(id);
      students.data = data;
      students.loading = false;
      this.setState({ students });
    } catch (error) {
      if (axios.isCancel(error)) return;
      this.setState({ students: { data: [], loading: false, error } });
      toast("Error al obtener los estudiantes", { type: "error" });
    }
  };

  componentDidMount() {
    this.getStudents();
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div style={{ marginTop: 75 }} />
        <Grid container spacing={2} className={classes.heroContainer}>
          <FetchDataComponent
            loading={this.state.students.loading}
            data={this.state.students.data}
            error={this.state.students.error}
            customNoDataRenderer={() =>
              textDataRender("Aún no hay estudiantes")
            }
            loadingRender={() => loadingCards("student")}
          >
            <React.Fragment>
              {this.state.students.data.map((el) => {
                return (
                  <Grid
                    item
                    xl={3}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    key={el.uid}
                    className={classes.heroContent}
                  >
                    <Card variant="outlined" style={{ height: "100%" }}>
                      <CardHeader
                        avatar={
                          <Avatar
                            aria-label="avatar"
                            style={{
                              marginRight: 10,
                              width: 60,
                              height: 60,
                            }}
                            src={el.photoURL}
                          ></Avatar>
                        }
                        title={
                          <Typography
                            variant="subtitle2"
                            align="left"
                            color="textPrimary"
                            style={{}}
                          >
                            {Authentication.formatName(el.name, el.lastName)}
                          </Typography>
                        }
                        subheader={
                          <React.Fragment>
                            <Hidden smDown>
                              <Typography
                                variant="body1"
                                align="left"
                                color="textSecondary"
                              >
                                {el.email}
                              </Typography>
                            </Hidden>
                            <Hidden smDown>
                              <Typography
                                variant="body1"
                                align="left"
                                color="textSecondary"
                              >
                                {new PhoneNumber(
                                  `+${el.phoneExt}${el.phone}`
                                ).getNumber("national")}
                              </Typography>
                            </Hidden>
                          </React.Fragment>
                        }
                      />
                    </Card>

                    {/* <UserManagementCard
                      user={el}
                      hasTransaction={this.state.transactions.includes(el.uid)}
                      handleDelete={this.deleteUser}
                      handleApprove={this.handleApprove}
                      handleOpenApproveMenu={this.handleOpenApproveMenu}
                      handleOpenStudentMenu={this.handleOpenStudentMenu}
                    /> */}
                  </Grid>
                );
              })}
            </React.Fragment>
          </FetchDataComponent>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withWidth()(withStyles(styles)(ClassroomStudents));
