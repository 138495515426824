import axios from "utils/API";
import Axios from "axios";

export default class SecretaryClassroomController {
  constructor() {
    this.classroomsSource = Axios.CancelToken.source();
    this.studentsSource = Axios.CancelToken.source();
  }

  /**
   * @param {String} textFilter
   * @param {Number} page Número de página a traer
   */
  getClassrooms = async (textFilter, idSection = null) => {
    let filter = textFilter ? `&textFilter=${textFilter}` : "";
    let idSectionQuery = idSection ? `&idSection=${idSection.idSection}` : "";

    if (this.classroomsSource) this.classroomsSource.cancel();
    this.classroomsSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/secretary/classrooms?pageSize=100${filter}${idSectionQuery}`,
      { cancelToken: this.classroomsSource.token }
    );

    let classrooms = response.data;

    classrooms.sort(this.compare);
    return classrooms;
  };

  getStudents = async (textFilter, idSection, page = 0) => {
    let filter = textFilter ? `&textFilter=${textFilter}` : "";

    if (this.studentsSource) this.studentsSource.cancel();
    this.studentsSource = Axios.CancelToken.source();

    const response = await axios.get(
      `api/secretary/students/by-section/${idSection}?page=${page}&pageSize=300${filter}`,
      { cancelToken: this.studentsSource.token }
    );
    return response.data;
  };

  compare(a, b) {
    let nameA = a.name.toLowerCase();
    let nameB = b.name.toLowerCase();
    // let sectionA = a.sectionName.toLowerCase();
    // let sectionB = b.sectionName.toLowerCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    // if (sectionA < sectionB) return -1;
    // if (sectionA > sectionB) return 1;
    return 0;
  }

  cancelAllTokens() {
    if (this.classroomsSource) this.classroomsSource.cancel();
    if (this.studentsSource) this.studentsSource.cancel();
  }

  cancelUsersSearch() {}
}
