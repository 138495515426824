import { Button, Divider, Grid } from "@material-ui/core";
import { Add, Camera, VideoCall } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import axios from "axios";
import { FetchDataComponent } from "components/Fetching/FetchDataComponent";
import {
  loadingCards,
  textDataRender,
} from "components/Fetching/fetchingLoaders";
import { toast } from "material-react-toastify";
import React, { Component } from "react";
import PostCard from "./StudentPostCard";

import Controller from "./StudentPostController";

let controller = new Controller();
const pageSize = 20;

function renderLoading() {
  return (
    <React.Fragment>
      <Skeleton
        variant="rect"
        height={110}
        width={"100%"}
        style={{ maxWidth: 800, marginBottom: 15 }}
      />
      <Skeleton
        variant="rect"
        height={400}
        width={"100%"}
        style={{ maxWidth: 800, marginBottom: 15 }}
      />
      <Skeleton
        variant="rect"
        height={150}
        width={"100%"}
        style={{ maxWidth: 800, marginBottom: 15 }}
      />
      <Skeleton
        variant="rect"
        height={110}
        width={"100%"}
        style={{ maxWidth: 800, marginBottom: 15 }}
      />
      <Skeleton
        variant="rect"
        height={150}
        width={"100%"}
        style={{ maxWidth: 800, marginBottom: 15 }}
      />
    </React.Fragment>
  );
}

class Posts extends Component {
  state = {
    createPostOpen: false,
    createMeetingOpen: false,
    posts: { loading: true, data: [], error: null },
    currentPage: 0,
    reachedMax: false,
    loadingMore: false,
  };

  async componentDidMount() {
    await this.getPosts();
  }

  componentWillUnmount() {
    controller.cancelAllTokens();
  }

  getPosts = async () => {
    let posts = { ...this.state.posts };
    posts.loading = true;
    posts.data = [];
    this.setState({ posts, currentPage: 0 });
    let reachedMax = false;
    try {
      let data = await controller.getPosts(this.props.match.params.id);
      if (data.length < pageSize) reachedMax = true;
      posts.data = data;
    } catch (error) {
      if (axios.isCancel(error)) return;
      posts.error = error;
    } finally {
      posts.loading = false;
      this.setState({ posts, reachedMax });
    }
  };

  requestMore = async () => {
    let page = this.state.currentPage;

    try {
      this.setState({ loadingMore: true });
      let data = await controller.loadMore(
        this.props.match.params.id,
        page + 1,
        pageSize
      );
      let reachedMax = data.length < pageSize;
      let posts = { ...this.state.posts };
      posts.data.push(data);
      this.setState({
        posts,
        reachedMax,
        currentPage: page + 1,
        loadingMore: false,
      });
    } catch (error) {}
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            marginTop: 50,
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <FetchDataComponent
            loading={this.state.posts.loading}
            data={this.state.posts.data}
            error={this.state.posts.error}
            customNoDataRenderer={() => textDataRender("No hay publicaciones")}
            customErrorRenderer={() =>
              textDataRender(
                "Ocurrio un error al momento de traer la información"
              )
            }
            loadingRender={() => renderLoading()}
          >
            {this.state.posts.data.map((el, index) => {
              return (
                <React.Fragment>
                  <PostCard post={el} onDelete={this.handleDeletePost} />
                  <br />
                </React.Fragment>
              );
            })}
          </FetchDataComponent>

          {this.state.loadingMore && renderLoading()}
          {!this.state.posts.loading && !this.state.reachedMax && (
            <Button
              color="primary"
              onClick={this.requestMore}
              disabled={this.state.loadingMore}
            >
              Ver Más
            </Button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Posts;
