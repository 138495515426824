import React, { Component } from "react";
import styles from "./Login-styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import LoginForm from "./LoginForm/LoginForm";
import SignupForm from "./SignUpForm/SignUpForm";
import { Grid } from "@material-ui/core";
import { TabPanel, TabContext } from "@material-ui/lab";
import Authentication from "controller/Authentication";
import { toast } from "material-react-toastify";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

class Login extends Component {
  state = {
    email: "",
    password: "",
    name: "",
    lastName: "",
    redirectResult: false,
    loading: false,
    confirmPassword: "",
    tabIndex: 0,
    activeStep: 0,
  };

  constructor(props) {
    super(props);
    this.emailInput = React.createRef();
    this.passwordInput = React.createRef();

    this.emailSignInput = React.createRef();
    this.passwordSignInput = React.createRef();
    this.nameSignInput = React.createRef();
    this.lastNameSignInput = React.createRef();
    this.confirmPasswordSignInput = React.createRef();

    this.handleLogin = this.handleLogin.bind(this);
    this.handleLoginError = this.handleLoginError.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
  }

  handleUserChanage = (user) => {
    if (user == null) return;
    this.props.history.push("/authenticate");
  };

  componentDidMount() {
    Authentication.instance.subscribeToUserChange(this.handleUserChanage);
  }
  componentWillUnmount() {
    Authentication.instance.unsubscribeFromUserChange(this.handleUserChanage);
  }

  handleStart() {
    this.setState({ loading: true });
  }
  handleLogin = (loginData, accountInfo) => {};
  handleSignUp() {}

  handleLoginError(err) {
    this.setState({ loading: false });
    toast("Error al iniciar sesión", {
      type: "error",
    });
  }

  handleTextChange = (e, name) => {
    const { value } = e.target;
    this.setState({ [name]: value }, () => {});
  };

  handleTabChange = (e, tabIndex) => {
    if (!this.state.redirectResult || this.state.loading) return;
    this.setState({
      tabIndex,
      email: "",
      password: "",
      confirmPassword: "",
      lastName: "",
      name: "",
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div
          className={"background"}
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center top",
            backgroundImage: `url(${require("../../img/Experimental.png")})`,
          }}
        />
        <div className={classes.root}>
          <main className="container">
            <Grid
              container
              className={classes.heroContainer}
              justify={"flex-end"}
              alignContent="center"
            >
              <Grid item xs={12} md={6} lg={6} className={classes.heroContent}>
                <AppBar
                  position="static"
                  color="default"
                  style={{ boxShadow: "none" }}
                >
                  <Tabs
                    onChange={this.handleTabChange}
                    value={this.state.tabIndex}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab label="Iniciar Sesión" {...a11yProps(0)} />
                    <Tab label="Crear Cuenta" {...a11yProps(0)} />
                  </Tabs>
                </AppBar>
                <br />
                <br />

                <TabContext value={this.state.tabIndex.toString()}>
                  <TabPanel value={"0"}>
                    <LoginForm
                      emailInputRef={this.emailInput}
                      passwordInputRef={this.passwordInput}
                      handleStart={this.handleStart}
                      handleCompletion={this.handleLogin}
                      handleError={this.handleLoginError}
                      handleTextChange={this.handleTextChange}
                      email={this.state.email}
                      password={this.state.password}
                      onRedirectResult={(result) => {
                        let hasUser = result != null && result.user != null;
                        this.setState({
                          redirectResult: true,
                          loading: hasUser,
                        });
                      }}
                    />
                  </TabPanel>
                  <TabPanel value={"1"}>
                    <SignupForm
                      emailInputRef={this.emailSignInput}
                      passwordInputRef={this.passwordSignInput}
                      confirmPasswordInputRef={this.confirmPasswordSignInput}
                      nameInputRef={this.nameSignInput}
                      lastNameInputRef={this.lastNameSignInput}
                      handleStart={this.handleStart}
                      handleCompletion={this.handleSignUp}
                      handleError={this.handleLoginError}
                      handleTextChange={this.handleTextChange}
                      email={this.state.email}
                      password={this.state.password}
                      name={this.state.name}
                      lastName={this.state.lastName}
                      confirmPassword={this.state.confirmPassword}
                    />
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(Login);
