export default (theme) => ({
  containerMobile: {
    maxWidth: "100%",
  },
  container: {
    maxWidth: "80%",
  },
  section: {
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    marginBottom: 10,
    alignItems: "center",
  },
  sectionHeader: {
    marginBottom: 10,
    maxWidth: "100%",
    width: "800px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    width: "800px",
  },
});
