import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { Close, ThreeSixty } from "@material-ui/icons";
import axios from "axios";
import UploadButton from "components/DownloadButton/UploadButton";
import FilePreview from "components/FilePreview/FilePreview";
import { toast } from "material-react-toastify";
import { DropzoneArea } from "material-ui-dropzone";
import React, { Component } from "react";
import { Upload } from "react-bootstrap-icons";
import Controller from "./StudentAssignmentController";

const controller = new Controller();

class UploadAssignmentDialog extends Component {
  state = {
    loading: false,
    progress: 0,
    files: [],
    previewOpen: false,
    previewFile: null,
    previewName: "",
  };

  handlePreviewOpen = (file) => {
    this.setState({
      previewFile: file,
      previewOpen: true,
      previewName: file.name,
    });
  };

  handlePreviewClose = (file) => {
    this.setState({ previewOpen: false });
  };
  componentWillUnmount() {
    controller.cancelAllTokens();
  }

  handleFileChange = (e) => {
    const { maxFiles = 1 } = this.props;
    let files = e.target.files;
    let filesArr = Array.prototype.slice.call(files);
    this.setState({
      files: [...filesArr, ...this.state.files].slice(0, maxFiles),
    });
  };

  removeFile = (file) => {
    let files = [...this.state.files];
    files = files.filter((el) => el !== file);
    this.setState({ files });
  };

  handleClose = () => {
    if (this.state.loading) return;
    if (this.props.onClose) this.props.onClose();
    this.setState({ files: [] });
  };

  getTemaryUploadURL = () => {
    let { idAssignment } = this.props;
    return `/files/v2/assignments/${idAssignment}`;
  };

  onUploadTemaryStart = (url, files, filesName) => {
    this.setState({ loading: true, progress: 0 });
  };

  onUploadError = (error, fd) => {
    this.setState({ loading: false, progress: 0 });
  };

  onUploadTemaryCancel = (url, files, filesName) => {};

  onUploadTemaryFinish = async (data) => {
    let { idClassroom, idAssignment } = this.props;
    let id = idClassroom;

    const filesURL = data.uri;

    try {
      if (this.props.overrideDefaultFinish) {
        await this.props.overrideDefaultFinish(filesURL);
      } else {
        await controller.uploadAssignment(id, idAssignment, filesURL);
      }
      toast("Tarea subida correctamente", { type: "success" });
      this.setState({ loading: false }, this.handleClose);

      if (this.props.onUploadFinish) this.props.onUploadFinish(filesURL);
    } catch (error) {
      if (axios.isCancel(error)) return;
      toast(`Error al subir la tarea`, { type: "error" });
    } finally {
      this.setState({
        userAssignment: { dialogOpen: false, loading: false, files: [] },
      });
    }
  };

  onUploadTemaryProgress = (uploadProgress) => {
    this.setState({ progress: uploadProgress });
  };

  render() {
    const { maxFiles = 1 } = this.props;

    return (
      <React.Fragment>
        <Dialog fullWidth {...this.props} onClose={this.handleClose}>
          {this.state.loading && (
            <LinearProgress value={this.state.progress} variant="determinate" />
          )}

          <DialogTitle>Subir nueva tarea</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Agrega los archivos de la tarea
            </DialogContentText>
            <Divider />
            {this.state.loading ? (
              <div
                style={{
                  width: "100%",
                  height: "250px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <React.Fragment>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  {this.state.files.map((el, index) => {
                    return (
                      <div style={{ display: "flex" }}>
                        <Typography
                          variant="body1"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            this.handlePreviewOpen(el);
                          }}
                        >
                          {el.name}
                        </Typography>
                        <div style={{ flexGrow: 1 }} />
                        <IconButton onClick={() => this.removeFile(el)}>
                          <Close />
                        </IconButton>
                      </div>
                    );
                  })}
                </div>

                <input
                  style={{ display: "none" }}
                  id="button-file"
                  multiple={maxFiles > 1}
                  type="file"
                  onChange={this.handleFileChange}
                />
                <label htmlFor="button-file">
                  <Button
                    variant="contained"
                    component="span"
                    fullWidth
                    color="primary"
                    endIcon={<Upload />}
                  >
                    Agregar Archivo
                  </Button>
                </label>
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              // disabled={this.state.userAssignment.loading}
              color="primary"
              onClick={this.handleClose}
            >
              Cancelar
            </Button>
            <UploadButton
              variant="text"
              disabled={this.state.loading || this.state.files.length === 0}
              onUploadStart={this.onUploadTemaryStart}
              onUploadFinish={this.onUploadTemaryFinish}
              onUploadProgress={this.onUploadTemaryProgress}
              onUploadCancel={this.onUploadTemaryCancel}
              onUploadError={this.onUploadError}
              label={"Subir"}
              url={this.getTemaryUploadURL()}
              files={this.state.files}
              showProgress={false}
            />
          </DialogActions>
        </Dialog>

        <FilePreview
          open={this.state.previewOpen}
          onClose={this.handlePreviewClose}
          file={this.state.previewFile}
          name={this.state.previewName}
          type={this.state.previewName}
        />
      </React.Fragment>
    );
  }
}

export default UploadAssignmentDialog;
