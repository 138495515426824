import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  appbarTransparent: {
    background: "transparent",
    boxShadow: "none",
    zIndex: 1000,
  },

  heroContainer: {
    height: "100vh",
  },
  heroContent: {
    background: theme.palette.background.default,
    height: "100vh",
  },
  heroTitle: {
    color: "white",
    // fontSize: "50px",
  },
  formControl: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
});
