import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import PostData from "components/Fetching/PostData";
import dayjs from "dayjs";
import React, { Component, createRef } from "react";
import Controller from "./ImprovePlanController";

const controller = new Controller();

class EditImprovePlan extends Component {
  state = {
    loading: false,
    message: "",
    dueTime: dayjs(new Date(new Date().setHours(23, 59, 0, 0))),
    dueTimeStr: "23:59",
    dueDateStr: dayjs(new Date().setHours(23, 59, 0, 0)).format("YYYY-MM-DD"),
  };

  constructor(props) {
    super(props);
    this.postRef = React.createRef();
  }

  componentWillUnmount() {
    controller.cancelAllTokens();
  }

  handleDialogEnter = () => {
    const { dueTime, message } = this.props;
    const dueDateStr = dayjs(dueTime).format("YYYY-MM-DD");
    const dueTimeStr = dayjs(dueTime).format("HH:mm");
    this.setState({
      message,
      dueTime,
      dueTimeStr,
      dueDateStr,
    });
  };

  setDate = (e) => {
    let date = e.target.value;
    if (date == "") date = dayjs(this.state.dueTime).format("YYYY-MM-DD");
    this.setDueTime(date, this.state.dueTimeStr);
  };

  setTime = (e) => {
    let time = e.target.value;
    if (time == "") time = dayjs(this.state.dueTime).format("HH:mm");
    this.setDueTime(this.state.dueDateStr, time);
  };

  setDueTime = (date, time) => {
    let fullDateStr = `${date} ${time}`;
    let d = dayjs(fullDateStr, "YYYY-MM-DD HH:mm");
    this.setState({ dueTime: d, dueTimeStr: time, dueDateStr: date });
  };

  handleClose = () => {
    if (this.state.loading) return;
    if (this.props.onClose) this.props.onClose();
  };

  editImprovePlan = async () => {
    const { message, dueTime } = this.state;
    const data = await controller.editAssignment(
      { description: message, dueTime },
      this.props.assignment
    );
    if (this.props.onEdit) this.props.onEdit({ message, dueTime });
    return data;
  };

  onLoad = (val) => {
    this.setState({ loading: val });
  };

  onClick = () => {
    this.postRef.current.post();
  };

  render() {
    const { open } = this.props;

    return (
      <React.Fragment>
        <PostData
          ref={this.postRef}
          request={this.editImprovePlan}
          onLoading={this.onLoad}
          errorMessage="Error al editar plan de mejora"
          successMessage="Plan de mejora ha sido editado"
        />
        <Dialog
          fullWidth
          open={open}
          onClose={this.handleClose}
          onEnter={this.handleDialogEnter}
        >
          {this.state.loading && <LinearProgress />}
          <DialogTitle>Editar Plan de Mejora</DialogTitle>
          <DialogContent>
            <DialogContentText>Ingresa la nueva información</DialogContentText>
            <TextField
              autoFocus
              fullWidth
              label="Descripción"
              multiline
              value={this.state.message}
              onChange={(e) => this.setState({ message: e.target.value })}
              variant="outlined"
            />
            <div style={{ marginTop: 20 }} />
            <TextField
              type="date"
              fullWidth
              value={this.state.dueDateStr}
              onChange={(e) => this.setDate(e)}
              margin="dense"
              label="Fecha de entrega"
              variant="outlined"
            />
            <div style={{ marginTop: 20 }} />
            <TextField
              type="time"
              fullWidth
              value={this.state.dueTimeStr}
              onChange={(e) => this.setTime(e)}
              margin="dense"
              label="Hora de entrega"
              variant="outlined"
            />
            <div style={{ marginTop: 20 }} />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={this.state.loading}
              onClick={this.handleClose}
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              disabled={this.state.loading}
              onClick={this.onClick}
            >
              Editar
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default EditImprovePlan;
