import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  isWidthDown,
  Typography,
  withStyles,
  withWidth,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";
import routes from "Pages/routes";
import React, { Component } from "react";
import { generatePath } from "react-router-dom";
import styles from "./ScoresStyles";
import AssignmentController from "Pages/Teacher/Classroom/Classroom/Assignments/AssignmentController";
import axios from "axios";
import { toast } from "material-react-toastify";
import DataTable from "components/Data/Table/DataTable";
import GetData from "components/Fetching/GetData";
import { Skeleton } from "@material-ui/lab";
import { textDataRender } from "components/Fetching/fetchingLoaders";
import { FetchDataComponent } from "components/Fetching/FetchDataComponent";
import ImprovePlanController from "../ImprovePlan/ImprovePlanController";
import { ImageRounded } from "@material-ui/icons";
import AssignmentScoreCard from "./Cards/AssignmentScoreCard";
import ImprovePlanScoreCard from "./Cards/ImprovePlanScoreCard";

let assignmentsController = new AssignmentController();
let improveController = new ImprovePlanController();

function parseDate(params) {
  return dayjs(params.getValue("dueTime")).format("DD/MM/YYYY");
}

function loadingCards() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} md={6} xl={4}>
        <Skeleton variant="rect" height={90} />
      </Grid>
      <Grid item xs={12} sm={4} md={6} xl={4}>
        <Skeleton variant="rect" height={90} />
      </Grid>{" "}
      <Grid item xs={12} sm={4} md={6} xl={4}>
        <Skeleton variant="rect" height={90} />
      </Grid>{" "}
      <Grid item xs={12} sm={4} md={6} xl={4}>
        <Skeleton variant="rect" height={90} />
      </Grid>
    </Grid>
  );
}

function loadingRender() {
  return (
    <React.Fragment>
      <Skeleton variant="rect" height={90} />
      <br />
      <Skeleton variant="rect" height={90} />
      <br />
      <Skeleton variant="rect" height={90} />
    </React.Fragment>
  );
}

class Scores extends Component {
  state = {
    totalScore: 0,
    loadingTotalScore: false,
    improvePlans: [],
    improvePlanError: null,
    loadingImprovePlans: true,
    pendingAssignments: [],
    pendingImprove: [],
    assignments: {
      loading: true,
      data: [],
      error: null,
    },
  };

  componentDidMount() {
    this.getAssignments(this.props.match.params.id);
  }

  componentWillUnmount() {
    improveController.cancelAllTokens();
    assignmentsController.cancelAllTokens();
  }

  getAssignments = async (id) => {
    try {
      this.setState({ assignments: { data: [], loading: true, error: null } });
      const data = await assignmentsController.getGradeAssignments(id);
      let assignments = { loading: false, data, error: null };
      let pendingAssignments = assignments.data.filter((el) => {
        return el.scored < el.delivered;
      });
      this.setState({ assignments, pendingAssignments }, () => {});
    } catch (error) {
      if (axios.isCancel(error)) return;
      let assignments = { loading: false, data: [], error: error };
      this.setState({ assignments }, () => {});
      toast("Error al obtener las tareas", { type: "error" });
    }
  };

  requestImprovePlans = async () => {
    const result = await improveController.getImproveAssignmentsToScore(
      this.props.match.params.id
    );
    let pendingImprove = result.filter((el) => {
      return el.scored < el.delivered;
    });
    this.setState({ pendingImprove, improvePlans: result });
    return result;
  };

  requestTotalScore = async () => {
    const result = await assignmentsController.getTotalScore(
      this.props.match.params.id
    );
    return result;
  };

  render() {
    const {
      assignments,
      improvePlans,
      loadingImprovePlans,
      improvePlanError,
      pendingAssignments,
      pendingImprove,
    } = this.state;

    return (
      <React.Fragment>
        <GetData
          request={this.requestTotalScore}
          onData={({ totalScore }) => {
            this.setState({ totalScore });
          }}
          errorMessage={`Error al traer los puntos actuales`}
          onLoading={(val) => this.setState({ loadingTotalScore: val })}
          disableSuccessNotification
          disableErrorNotification
        />

        <GetData
          request={this.requestImprovePlans}
          onData={(improvePlans) => this.setState({ improvePlans })}
          onError={(improvePlanError) => this.setState({ improvePlanError })}
          onLoading={(val) => this.setState({ loadingImprovePlans: val })}
          disableSuccessNotification
          disableErrorNotification
        />

        <div
          style={{
            width: "100%",
            marginTop: "30px",
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography varaint="subtitle1" color="textSecondary">
            Puntos Actuales
          </Typography>
          {/* <div style={{ flexGrow: 1 }} /> */}
          <Typography variant="h4">
            {this.state.loadingTotalScore ? (
              <Skeleton width={80} height={80} variant="text" />
            ) : (
              `${this.state.totalScore}`
            )}
          </Typography>
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "30px",
            textAlign: "left",
          }}
        >
          <Typography variant="body1" align="left" color="textSecondary">
            Pendientes de Calificar
          </Typography>

          <br />
          <FetchDataComponent
            loading={assignments.loading || loadingImprovePlans}
            data={[...pendingAssignments, ...pendingImprove]}
            error={assignments.error}
            customNoDataRenderer={() =>
              textDataRender("No hay pendientes para calificar")
            }
            customErrorRenderer={() =>
              textDataRender(
                "Ocurrio un error al momento de traer la información"
              )
            }
            loadingRender={loadingRender}
          >
            {pendingImprove.map((el) => {
              return (
                <ImprovePlanScoreCard
                  el={el}
                  onClick={() => {
                    this.props.history.push(
                      generatePath(routes.teacher.scoresImprove, {
                        id: this.props.match.params.id,
                        idAssignment: el.id,
                        idStudent: el.uid,
                      })
                    );
                  }}
                />
              );
            })}

            {pendingAssignments.map((el) => {
              if (el.scored >= el.delivered) return <React.Fragment />;
              return (
                <AssignmentScoreCard
                  el={el}
                  onClick={() => {
                    this.props.history.push(
                      generatePath(routes.teacher.scoresAssignment, {
                        id: this.props.match.params.id,
                        idAssignment: el.id,
                      })
                    );
                  }}
                />
              );
            })}
          </FetchDataComponent>

          <br />
          <Divider />
          <br />

          <Typography variant="body1" align="left" color="textSecondary">
            Actividades Calificadas
          </Typography>
          <br />

          <FetchDataComponent
            loading={assignments.loading}
            data={assignments.data}
            error={assignments.error}
            customNoDataRenderer={() =>
              textDataRender("No hay actividades para calificar")
            }
            customErrorRenderer={() =>
              textDataRender(
                "Ocurrio un error al momento de traer la información"
              )
            }
            loadingRender={loadingRender}
          >
            {assignments.data.map((el) => {
              if (el.scored < el.delivered || el.scored === 0)
                return <React.Fragment />;
              return (
                <AssignmentScoreCard
                  el={el}
                  onClick={() => {
                    this.props.history.push(
                      generatePath(routes.teacher.scoresAssignment, {
                        id: this.props.match.params.id,
                        idAssignment: el.id,
                      })
                    );
                  }}
                />
              );
            })}
          </FetchDataComponent>

          <Typography variant="body1" align="left" color="textSecondary">
            Planes de Mejora Calificados
          </Typography>

          <br />
          <FetchDataComponent
            loading={loadingImprovePlans}
            data={improvePlans}
            error={improvePlanError}
            customNoDataRenderer={() =>
              textDataRender("No hay planes de mejora para calificar")
            }
            customErrorRenderer={() =>
              textDataRender(
                "Ocurrio un error al momento de traer la información"
              )
            }
            loadingRender={loadingCards}
          >
            <Grid container spacing={2}>
              {improvePlans.map((el) => {
                if (el.delivered !== el.scored) return <React.Fragment />;
                return (
                  <Grid item xs={12} sm={12} md={6} xl={4}>
                    <ImprovePlanScoreCard
                      el={el}
                      onClick={() => {
                        this.props.history.push(
                          generatePath(routes.teacher.scoresImprove, {
                            id: this.props.match.params.id,
                            idAssignment: el.id,
                            idStudent: el.uid,
                          })
                        );
                      }}
                    />{" "}
                  </Grid>
                );
              })}
            </Grid>
          </FetchDataComponent>

          <br />
        </div>
        <div style={{ height: 50, marginTop: 20 }} />
      </React.Fragment>
    );
  }
}

export default withWidth()(withStyles(styles)(Scores));
