import React, { Component } from "react";
import styles from "./ClassroomManagement-styles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  TextField,
  InputAdornment,
  Dialog,
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Hidden,
  IconButton,
  CircularProgress,
  LinearProgress,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import {
  Add,
  MoreVertOutlined,
  Search as SearchIcon,
} from "@material-ui/icons";
import AdminDashboard from "components/DashboardContainer/AdminDashboard";
import Axios from "axios";
import RoleManagement from "controller/Roles";
import School from "controller/School";
import { DropzoneArea } from "material-ui-dropzone";
import { isMobile } from "react-device-detect";
import Controller from "./ClassroomManagementController";
import ClassroomController from "../Classroom/ClassroomController";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { FetchDataComponent } from "components/Fetching/FetchDataComponent";
import { toast } from "material-react-toastify";
import Authentication from "controller/Authentication";
import ClassroomManagementCard from "./ClassroomManagementCard";
import SearchInput from "components/SearchInput/SearchInput";
import { Skeleton } from "@material-ui/lab";
import Dashboard from "components/DashboardContainer/Dashboard";
import { ArrowReturnLeft, TelephoneMinusFill } from "react-bootstrap-icons";
import TransferClassroomDialog from "../Classroom/TransferClassroomDialog";
import DeleteDialog from "components/Dialogs/DeleteDialog";

const controller = new Controller();
const classroomController = new ClassroomController();

function loadingCards(prefix) {
  let style = { width: "100%", height: "100%", minHeight: 275 };
  return (
    <React.Fragment>
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_01"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_02"}>
        <Skeleton variant="rect" style={style} />
      </Grid>{" "}
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_03"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
    </React.Fragment>
  );
}

function textDataRender(text) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <Typography
        variant="caption"
        color="textSecondary"
        style={{
          textAlign: "left",
          fontWeight: "600",
          marginTop: "35px",
          fontSize: "24px",
          height: "75px",
          opacity: "0.5",
        }}
      >
        {text}
      </Typography>{" "}
    </div>
  );
}

let cancelToken;

class ClassroomManagement extends Component {
  state = {
    formIsValid: false,
    loading: false,
    files: [],
    roles: [],
    textFilter: "",
    dialogOpen: false,
    addPortraitImage: false,
    classrooms: {
      loading: true,
      data: [],
      error: null,
    },

    dialog: {
      name: "",
      description: ".",
      idSection: 0,
    },
    sections: [],
    autocompleteVal: null,
    dialogTitle: "Crear salón de clases",
    dialogActionButtonLabel: "Guardar",
    editingClassroomId: null,
    transferDialogOpen: false,
    archiveDialogOpen: false,
  };

  constructor() {
    super();
    this.requestRoles = this.requestRoles.bind(this);
    this.requestSections = this.requestSections.bind(this);
  }

  handleUserChange = (user) => {
    this.setState({ user });
    if (user == null) return;
    this.requestClassrooms();
    this.requestRoles();
    this.requestSections();
  };

  handleDialogOpen = (dialogOpen) => {
    if (this.state.loading) return;
    this.setState({ dialogOpen });
  };

  handleSearch = async (text) => {
    this.setState({ textFilter: text }, () => {
      this.requestClassrooms(text);
    });
  };

  handleDialogTextChange = (e, name, callback) => {
    const { value } = e.target;
    this.setState(
      (old) => {
        let { dialog } = old;
        dialog[name] = value;

        return { dialog };
      },
      () => {
        this.validateForm();
        if (callback) callback();
      }
    );
  };

  handleTextChange = (e, name, callback) => {
    const { value } = e.target;
    this.setState({ [name]: value }, callback);
  };

  componentDidMount() {
    Authentication.instance.subscribeToUserChange(this.handleUserChange);
  }

  async requestRoles() {
    const roles = await RoleManagement.getRoles();
    this.setState({ roles });
  }

  async requestSections() {
    const sections = await School.getSections();
    this.setState({ sections });
  }

  requestClassrooms = async () => {
    this.setState({ classrooms: { ...this.state.classrooms, loading: true } });
    try {
      let response = await controller.getClassrooms(this.state.textFilter);
      this.setState({
        classrooms: { loading: false, data: response, error: null },
      });
    } catch (error) {
      if (Axios.isCancel(error)) return;
      this.setState({
        classrooms: { data: [], error, loading: false },
      });
    }
  };

  handleArchiveDialogOpen = async (idClassroom) => {
    this.setState({ archiveDialogOpen: true, editingClassroomId: idClassroom });
  };

  handleArchive = async (idClassroom, classroom) => {
    try {
      await classroomController.archive(idClassroom);
      let classrooms = { ...this.state.classrooms };
      let data = [...classrooms.data];
      classrooms.data = data.filter((el) => el.id !== idClassroom);
      this.setState({ classrooms });

      toast(`Salón ha sido archivado`, { type: "success" });
    } catch (error) {
      toast(`Error al archivar salón`, { type: "error" });
    }
  };

  handleDropzoneChange = async (files) => {
    this.setState({ files: files });
  };

  uploadPortraitPicture = async (classroomId) => {
    if (this.state.files.length === 0) return;
    let response = await controller.uploadPicture(
      this.state.files[0],
      classroomId
    );
    return response;
  };

  createClassroom = async () => {
    let { name, description, idSection } = this.state.dialog;
    this.setState({ loading: true });
    try {
      let response = await controller.createClassroom(
        name,
        description,
        idSection
      );

      //Actualizar imagen
      if (this.state.files.length > 0) {
        let res = await this.uploadPortraitPicture(response.id);
        let imageURL = res.uri;
        let info = { imageURL };
        await controller.editClassroom(response.id, info);
      }

      this.setState({ dialogOpen: false, loading: false });

      await this.requestClassrooms("");

      toast(`Salón ${name} creado exitosamente`, { type: "success" });
    } catch (error) {
      if (Axios.isCancel(error)) return;

      this.setState({ dialogOpen: false, loading: false });
      toast("Error al crear salón", { type: "error" });
    }
  };

  editClassroom = async () => {
    let info = this.state.dialog;

    this.setState({ loading: true });
    try {
      //Actualizar imagen
      if (this.state.files.length > 0) {
        let res = await this.uploadPortraitPicture(
          this.state.editingClassroomId
        );
        let imageURL = res.uri;
        info["imageURL"] = imageURL;
      }

      await controller.editClassroom(this.state.editingClassroomId, info);
      this.setState({
        dialogOpen: false,
        editingClassroomId: null,
        loading: false,
      });
      await this.requestClassrooms("");

      toast(`Salón ${info.name} editado exitosamente`, { type: "success" });
    } catch (error) {
      if (Axios.isCancel(error)) return;
      toast("Error al editar salón", { type: "error" });

      this.setState({
        dialogOpen: false,
        editingClassroomId: null,
        loading: false,
      });
    }
  };

  createOrEditClassroom = async () => {
    if (this.state.editingClassroomId == null) await this.createClassroom();
    else await this.editClassroom();
  };

  handleCreateNewOpen = () => {
    this.setState(
      {
        dialog: {
          name: "",
          description: ".",
          idSection: this.state.sections[0].idSection,
        },
        dialogTitle: "Crear salón de clases",
        dialogActionButtonLabel: "Guardar",
        autocompleteVal: this.state.sections[0],
        editingClassroomId: null,
      },
      this.validateForm
    );
    this.handleDialogOpen(true);
  };

  handleEdit = (idClassroom, classroom) => {
    let section = this.state.sections.find(
      (el) => el.idSection === classroom.idSection
    );

    this.setState(
      {
        dialog: {
          name: classroom.name,
          description: classroom.description,
          idSection: classroom.idSection,
        },
        dialogTitle: "Editar salón de clases",
        dialogActionButtonLabel: "Editar",
        autocompleteVal: section,
        editingClassroomId: idClassroom,
      },
      this.validateForm
    );
    this.handleDialogOpen(true);
  };

  onTransfer = (idClassroom) => {
    let classrooms = { ...this.state.classrooms };
    classrooms.data = classrooms.data.filter((x) => x.id !== idClassroom);
    this.setState({ classrooms });
  };

  handleTransferDialogOpen = (idClassroom) => {
    this.setState({
      transferDialogOpen: true,
      editingClassroomId: idClassroom,
    });
  };

  handleTransferDialogClose = () => {
    this.setState({ transferDialogOpen: false });
  };

  componentWillUnmount() {
    Authentication.instance.unsubscribeFromUserChange(this.handleUserChange);
    controller.cancelAllTokens();
  }

  validateForm = () => {
    let val = true;
    if (
      this.state.autocompleteVal == null ||
      this.state.dialog.name.length == 0 ||
      this.state.dialog.description.length == 0
    )
      val = false;
    this.setState({ formIsValid: val });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Dashboard history={this.props.history}>
          <main
            className={isMobile ? classes.containerMobile : classes.container}
          >
            <Grid container justify="flex-start" alignItems="flex-start">
              <Grid item xs={12} md={2} lg={1}>
                <Typography variant="h4" align="left">
                  Salones
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                justify="flex-start"
                alignContent="flex-start"
              >
                <div className={classes.header}>
                  <SearchInput
                    className={classes.searchBar}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    autoFocus={!isMobile}
                    onSearch={this.handleSearch}
                  />
                  <Hidden smUp>
                    <IconButton
                      color="primary"
                      className={classes.button}
                      onClick={() => {
                        this.handleDialogOpen(true);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Hidden>
                  <Hidden xsDown>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="medium"
                      startIcon={<Add />}
                      className={classes.button}
                      onClick={() => {
                        this.handleCreateNewOpen(true);
                      }}
                    >
                      Nuevo
                    </Button>
                  </Hidden>
                </div>
              </Grid>
            </Grid>
            <br />
            <br />

            <Grid container spacing={2} className={classes.heroContainer}>
              <FetchDataComponent
                loading={this.state.classrooms.loading}
                data={this.state.classrooms.data}
                error={this.state.classrooms.error}
                customNoDataRenderer={() =>
                  textDataRender("No se encontraron salones")
                }
                customErrorRenderer={() =>
                  textDataRender(
                    "Ocurrio un error al momento de traer la información"
                  )
                }
                loadingRender={() => loadingCards("classrooms")}
              >
                {this.state.classrooms.data.map((el) => (
                  <Grid
                    item
                    xl={3}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    key={el.uid}
                    className={classes.heroContent}
                  >
                    <ClassroomManagementCard
                      classroom={el}
                      history={this.props.history}
                      handleApprove={this.handleApprove}
                      handleOpenApproveMenu={this.handleOpenApproveMenu}
                      handleEdit={this.handleEdit}
                      handleArchive={this.handleArchiveDialogOpen}
                      handleTransferDialogOpen={this.handleTransferDialogOpen}
                    />
                  </Grid>
                ))}
              </FetchDataComponent>
            </Grid>

            <br />

            {/* <Pagination count={10} color="primary" />
          <br /> */}
          </main>
          <Dialog
            open={this.state.dialogOpen}
            // open={true}
            // fullScreen
            maxWidth={"sm"}
            fullWidth
            onClose={() => this.handleDialogOpen(false)}
          >
            {this.state.loading && <LinearProgress />}

            <DialogTitle>
              <Typography variant="subtitle2">
                {this.state.dialogTitle}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <form noValidate autoComplete="off">
                <FormLabel component="legend">Imagen de portada</FormLabel>

                <React.Fragment>
                  <div style={{ height: "10px" }} />
                  <DropzoneArea
                    onChange={this.handleDropzoneChange}
                    dropzoneText="Arrastra una imagen para la portada o da click"
                    maxFileSize={1024 * 1024 * 20}
                    filesLimit={1}
                    showAlerts={false}
                    acceptedFiles={["image/jpeg", "image/jpg", "image/png"]}
                    // useChipsForPreview
                  />
                  <div style={{ height: "10px" }} />
                </React.Fragment>

                <TextField
                  label="Nombre"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  autoFocus
                  onChange={(e) => this.handleDialogTextChange(e, "name")}
                  value={this.state.dialog.name}
                  inputProps={{ maxLength: 60 }}
                />
                {/* <Hidden>
                  <TextField
                    label="Descripción"
                    variant="outlined"
                    margin="dense"
                    // onChange={(e) =>
                    //   this.handleDialogTextChange(e, "description")
                    // }
                    value={"."}
                    // value={this.state.dialog.description}
                    fullWidth
                    multiline
                    inputProps={{ maxLength: 255 }}
                  />
                </Hidden> */}
                <Autocomplete
                  id="combo-box-section"
                  value={this.state.autocompleteVal}
                  options={this.state.sections}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, val) => {
                    this.setState({ autocompleteVal: val });
                    this.setState((old) => {
                      let { dialog } = old;
                      if (val !== null) dialog.idSection = val.idSection;
                      return { dialog };
                    }, this.validateForm);
                  }}
                  style={{ marginTop: "10px" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Sección" variant="outlined" />
                  )}
                />
              </form>
            </DialogContent>

            <DialogActions>
              <Button
                disabled={this.state.loading}
                onClick={() => this.handleDialogOpen(false)}
                color={"primary"}
              >
                Cancelar
              </Button>
              <Button
                onClick={() => this.createOrEditClassroom()}
                color={"primary"}
                disabled={!this.state.formIsValid || this.state.loading}
              >
                {this.state.dialogActionButtonLabel}
              </Button>
            </DialogActions>
          </Dialog>

          <TransferClassroomDialog
            onTransfer={this.onTransfer}
            open={this.state.transferDialogOpen}
            onClose={this.handleTransferDialogClose}
            idClassroom={this.state.editingClassroomId}
          />

          <DeleteDialog
            open={this.state.archiveDialogOpen}
            onClose={() => this.setState({ archiveDialogOpen: false })}
            onDelete={() => {
              this.setState({ archiveDialogOpen: false });
              this.handleArchive(this.state.editingClassroomId);
            }}
            title={`Archivar`}
            description={`¿Deseas archivar el salón?`}
            confirmationText={`archivar`}
            buttonLabel={`Archivar`}
          />
        </Dashboard>
      </React.Fragment>
    );
  }
}

ClassroomManagement.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(ClassroomManagement);
