import {
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  TextField,
  withStyles,
} from "@material-ui/core";
import { HideColMenuItem } from "@material-ui/data-grid";
import { Delete } from "@material-ui/icons";
import React, { Component } from "react";
import { Trash } from "react-bootstrap-icons";
import styles from "./TestStyle";
import CheckIcon from "@material-ui/icons/Check";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { CardContent } from "@material-ui/core";
import { CardActions } from "@material-ui/core";

class TestQuestionMultiple extends Component {
  state = {
    question: "",
    score: 0,
    answers: [{ id: 0, text: "", valid: false }],
  };

  getQuestion = () => {
    return {
      id: this.props.id,
      question: this.state.question,
      score: this.state.score,
      answers: [...this.state.answers],
    };
  };

  addAnswer = () => {
    let { id = "empty" } = this.props;
    const { answers } = this.state;
    const index = answers.length;
    let answer = { id: index, text: "", valid: false };
    answers.push(answer);
    this.setState({ answers }, () => {
      this.props.onUpdateQuestion(this.getQuestion());
      document.getElementById(`answer-${id}-${index}`).focus();
    });
  };

  removeAnswer = (index) => {
    let { id = "empty" } = this.props;

    let answers = [...this.state.answers];
    answers.splice(index, 1);
    this.setState({ answers }, () => {
      this.props.onUpdateQuestion(this.getQuestion());
      document.getElementById(`add-answer-${id}-btn`).focus();
    });
  };

  setAnswerText = (index, text) => {
    const { answers } = this.state;
    answers[index].text = text;
    this.setState({ answers }, () => {
      this.props.onUpdateQuestion(this.getQuestion());
    });
  };

  setAnswerValid = (index, value) => {
    const { answers } = this.state;
    answers[index].valid = value;
    this.setState({ answers }, () => {
      this.props.onUpdateQuestion(this.getQuestion());
    });
  };

  render() {
    let { id = "empty" } = this.props;
    const { classes } = this.props;
    return (
      <Card
        style={{
          // backgroundColor: "transparent",
          width: "100%",
          padding: 30,
          textAlign: "left",
        }}
        variant="outlined"
      >
        <CardContent>
          {" "}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} md={10}>
              <TextField
                label="Pregunta"
                id={`question-${id}-text`}
                fullWidth
                variant="outlined"
                value={this.state.question}
                onChange={(e) =>
                  this.setState({ question: e.target.value }, () => {
                    this.props.onUpdateQuestion(this.getQuestion());
                  })
                }
                multiline
                InputProps={{
                  classes: {
                    input: classes.questionText,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <TextField
                label="Puntos"
                fullWidth
                variant="outlined"
                type="number"
                value={this.state.score}
                onChange={(e) =>
                  this.setState({ score: e.target.value }, () => {
                    this.props.onUpdateQuestion(this.getQuestion());
                  })
                }
                // value={el.score}
                // onChange={(e) => this.set(index, e.target.value)}
                InputProps={{
                  classes: {
                    input: classes.questionText,
                  },
                }}
              />
            </Grid>
          </Grid>
          <div style={{ marginTop: 20 }} />
          <Divider />
          <div style={{ marginTop: 30 }} />
          {this.state.answers.map((el, index) => {
            return (
              <div key={`answers-container-${id}-${index}`}>
                <Grid container spacing={2}>
                  <Grid item md={10} sm={8} xs={12}>
                    <TextField
                      id={`answer-${id}-${index}`}
                      label="Respuesta"
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      multiline
                      value={el.text}
                      onChange={(e) =>
                        this.setAnswerText(index, e.target.value)
                      }
                      InputProps={{
                        classes: {
                          input: classes.answerText,
                        },
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    md={1}
                    sm={2}
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ToggleButton
                      value="check"
                      selected={el.valid}
                      onChange={() => {
                        this.setAnswerValid(index, !el.valid);
                      }}
                    >
                      <CheckIcon />
                    </ToggleButton>
                  </Grid>

                  {this.state.answers.length > 1 && (
                    <Grid
                      item
                      md={1}
                      sm={2}
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ToggleButton
                        selected={false}
                        color="primary"
                        onChange={() => {
                          this.removeAnswer(index);
                        }}
                      >
                        <Delete />
                      </ToggleButton>
                    </Grid>
                  )}
                </Grid>
                <Hidden mdUp>
                  <br />
                  <Divider />
                  <br />
                </Hidden>
              </div>
            );
          })}
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            onClick={this.addAnswer}
            id={`add-answer-${id}-btn`}
          >
            Agregar Respuesta
          </Button>
          <Divider orientation="vertical" flexItem />
          <Button
            color="primary"
            onClick={() => this.props.onRemoveQuestion?.()}
            disabled={this.props.removeQuestionDisabled}
          >
            Eliminar Pregunta
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(TestQuestionMultiple);
