import { Button, CircularProgress, LinearProgress } from "@material-ui/core";
import React, { Component } from "react";
import downloadjs from "downloadjs";
import axios from "utils/API";
import Axios from "axios";
import { toast } from "material-react-toastify";
import * as rax from "retry-axios";

import DefaultErrorMessages from "utils/DefaultErrorMessages";
import UploadErrorMessages from "./UploadErrorMessages";

let cancelSource = Axios.CancelToken.source();

axios.defaults.raxConfig = {
  instance: axios,
};

const interceptorId = rax.attach(axios);

class UploadButton extends Component {
  state = {
    downloading: false,
    progress: 0,
    totalLength: 0,
  };

  componentWillUnmount() {
    cancelSource.cancel();
  }

  onUploadCancel = () => {
    toast(`Subida cancelada`, { type: "error" });
    this.setState({ progress: 0, loading: false });
    if (this.props.onUploadCancel) this.props.onUploadCancel();
  };

  startUpload = async (href, files, filesName = "files") => {
    if (href === null || files === null || files.length === 0) return;
    let fd = null;
    try {
      if (this.props.onUploadStart)
        this.props.onUploadStart(href, files, filesName);
      this.setState({ downloading: true, progress: 0 });

      if (cancelSource) cancelSource.cancel();
      cancelSource = Axios.CancelToken.source();

      let formData = new FormData();
      files.forEach((file) => {
        formData.append(filesName, file);
      });

      fd = formData;

      const response = await axios({
        raxConfig: {
          retry: 3,
          noResponseRetries: 5,
          retryDelay: 100,
          httpMethodsToRetry: ["POST"],
        },
        method: "POST",
        url: href,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
        onUploadProgress: this.onUploadProgress,
        cancelToken: cancelSource.token,
      });
      this.onUploadFinish(response.data);
    } catch (error) {
      if (Axios.isCancel(error)) {
        this.onUploadCancel();
        return;
      }

      if (this.props.onUploadError) this.props.onUploadError(error, fd);

      if (!this.props.disableErrorNotification) {
        let message =
          UploadErrorMessages(error) ||
          DefaultErrorMessages(error, "Error al subir archivo");
        toast.error(message);
      }
    } finally {
      this.setState({ downloading: false, progress: 0 });
    }
  };

  onUploadFinish = (data) => {
    if (this.props.onUploadFinish) this.props.onUploadFinish(data);
  };

  onUploadProgress = (progressEvent) => {
    let progress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    if (this.props.onUploadProgress) this.props.onUploadProgress(progress);
    this.setState({ progress });
  };

  render() {
    const {
      label = "Subir",
      labelDownloading = "Subiendo",
      url = null,
      color = "primary",
      filesName = "files",
      files = null,
      showProgress = true,
      disabled = false,
    } = this.props;

    let rest = { ...this.props };

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Button
          variant="outlined"
          color={color}
          {...rest}
          disabled={this.state.downloading || disabled}
          onClick={() => this.startUpload(url, files, filesName)}
        >
          {this.state.downloading ? labelDownloading : label}
        </Button>
        {this.state.downloading && showProgress && (
          <div style={{ width: "100%", marginTop: 10 }}>
            <LinearProgress
              variant="determinate"
              value={this.state.progress}
              color={color}
            />
          </div>
        )}
      </div>
    );
  }
}

export default UploadButton;
