import {
  AppBar,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import styles from "./FilePreviewStyles";
// import "./PDFPreview.css";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import pdfFile from "./sample.pdf";
import { Download } from "react-bootstrap-icons";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
};

class PDFPreview extends Component {
  state = {
    numPages: null,
    file: pdfFile,
  };

  onDocumentLoadSucces = ({ numPages }) => {
    this.setState({ numPages });
  };

  download = () => {
    var anchor = document.createElement("a");
    anchor.href = this.props.file;
    anchor.target = "_blank";
    anchor.download = this.props.title;

    anchor.click();
  };

  render() {
    const { classes } = this.props;
    const { file, open = true, onClose, title = "Vista Previa" } = this.props;
    const { numPages } = this.state;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              onClick={onClose}
              edge="start"
              color="inherit"
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          </Toolbar>
          <Divider />
          <Toolbar>
            <Button
              endIcon={<Download />}
              className={classes.textBtn}
              onClick={this.download}
            >
              Descargar
            </Button>
          </Toolbar>
        </AppBar>

        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
            // maxWidth: "90vw",
          }}
        >
          <Document
            file={file}
            onLoadSuccess={this.onDocumentLoadSucces}
            options={options}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <React.Fragment>
                <Card elevation={2}>
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                </Card>
                <br />
              </React.Fragment>
            ))}
          </Document>
        </DialogContent>
      </Dialog>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default withStyles(styles)(PDFPreview);
