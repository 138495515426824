import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme) => ({
  container: {
    padding: 50,
  },
  containerMobile: { padding: 20 },

  header: {
    display: "flex",
    justifyContent: "start",
  },
  searchBar: {
    marginLeft: 30,
    background: "#fff",
    width: 400,
    maxWidth: "100%",
  },
});
