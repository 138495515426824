import axios from "axios";
import { toast } from "material-react-toastify";
import React, { Component } from "react";

class PostData extends Component {
  state = {};

  post = async () => {
    try {
      if (this.props.onStart) this.props.onStart();
      if (this.props.onLoading) this.props.onLoading(true);
      let data = await this.props.request();
      if (this.props.onData) this.props.onData(data);
      if (!this.props.disableSuccessNotification) {
        let { successMessage = `Éxito` } = this.props;
        toast(successMessage, { type: "success" });
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        if (this.props.onCancel) this.props.onCancel();
        return;
      }

      if (this.props.onError) this.props.onError(error);
      if (!this.props.disableErrorNotification) {
        let { errorMessage = `Error` } = this.props;
        toast(errorMessage, { type: "error" });
      }
      console.log(error);
    } finally {
      if (this.props.onLoading) this.props.onLoading(false);
    }
  };

  render() {
    return <React.Fragment />;
  }
}

export default PostData;
