import axios from "utils/API";
import Axios from "axios";

export default class StudentClassroomController {
  constructor() {
    this.classroomSource = Axios.CancelToken.source();
  }

  /**
   *
   * @param {String} id id del salón
   */
  getClassroom = async (id) => {
    if (this.classroomSource) this.classroomSource.cancel();
    this.classroomSource = Axios.CancelToken.source();

    const response = await axios.get(`api/student/classrooms/${id}`, {
      cancelToken: this.classroomSource.token,
    });
    return response.data;
  };

  cancelAllTokens() {
    if (this.classroomSource) this.classroomSource.cancel();
  }
}
