import Axios from "axios";
export default (error) => {
  let errorMessage = "";

  if (Axios.isAxiosError(error)) {
    errorMessage = error.response.data.message;
  } else errorMessage = error.message;

  if (errorMessage.startsWith("assignment_total_score"))
    return `Puntaje de la tarea no puede ser mayor a  ${
      errorMessage.match(/\[(.*?)\]/)[1]
    } `;

  switch (errorMessage) {
    case "criteria_total_score":
      return `Los criterios deben de sumar 100`;

    case "before_date":
      return `La fecha ingresada debe de ser mayor a la fecha actual`;
    case "invalid_period":
      return `La tarea pertenece a una Unidad diferente a la actual`;
    case "on_uploading_files":
      return `Error al subir los archivos de la tarea, la tarea ha sido agregada sin archivos`;
    case "invalid_student":
      return `Estudiante no tiene acceso`;
    case "due_date_has_passed":
      return `La fecha de entrega ya ha pasado`;
    case "invalid_score":
      return `El punteo ingresado es inválido`;
    default:
      return null;
  }
};
