import axios from "utils/API";
import { getAuth, getRedirectResult, signInWithRedirect, GoogleAuthProvider, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import Auth from "controller/Authentication";

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });

function CurrentUser() {
  return getAuth().currentUser;
}

function OpenGoogleSignIn() {
  signInWithRedirect(getAuth(), googleProvider);
}

async function GetGoogleRedirectResult() {
  const result = await getRedirectResult(getAuth());
  if (result == null || result.user == null) return null;
  let token = result.user.getIdToken(true);
  return { token: token, user: result.user };
}

async function SignIn(email, password) {
  if (getAuth().currentUser !== null) {
    await getAuth().signOut();
    Auth.instance.refreshUserData();
  }

  const result = await signInWithEmailAndPassword(getAuth(), email, password);

  return result.user;
}

async function SignUp(name, lastname, email, password) {
  const result = await createUserWithEmailAndPassword(getAuth(), email, password);

  await sendEmailVerification(result.user);
  await updateProfile(result.user, { displayName: `${name} ${lastname}` });
  let token = await result.user.getIdToken();
  await axios.put(
    "/api/account-management/me/account-data",
    { name: name, lastName: lastname },
    { headers: { Authorization: token } }
  );
  await Auth.instance.refreshUserData();

  return result.user;
}

async function GetToken() {
  const result = await getAuth().currentUser.getIdToken(true);
  return result;
}

/**
 *
 */
class Authentication {
  static instance = Authentication.instance || new Authentication();

  constructor() { }

  static initializeApp() {
    this.instance.initialize();
  }

  initialize() {
    onAuthStateChanged(async (user) => {
      this.firebaseUser = user;
      await this._bringUserData();
    });
  }

  authStateChangedHandlers = [];

  onAuthStateChanged(user) {
    this.authStateChangedHandlers.forEach((el) => el(user));
  }

  /**
   *
   * @param {*} handler
   */
  subuscribeToAuthStateChanged(handler) {
    handler(this.user);
    this.authStateChangedHandlers.push(handler);
  }

  /**
   *
   * @param {*} handler
   */
  unsubscribeFromAuthStateChanged(handler) {
    this.authStateChangedHandlers = this.authStateChangedHandlers.filter(
      (observer) => observer !== handler
    );
  }

  firebaseUser = null;
  user = null;

  emptyUser = {
    photoURL: "",
    displayName: "",
    email: "",
    role: "",
    name: "",
    lastName: "",
    phoneNumber: "",
    countryCode: "",
  };

  async _bringUserData() {
    if (this.firebaseUser == null) {
      this.user = null;
      this.onAuthStateChanged(this.user);
      return this.user;
    }

    this.user = this.firebaseUser;
    this.onAuthStateChanged(this.user);
  }
}

export {
  OpenGoogleSignIn,
  GetGoogleRedirectResult,
  CurrentUser,
  GetToken,
  SignUp,
  SignIn,
  Authentication,
};
