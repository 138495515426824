import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import Authentication from "controller/Authentication";
import { useState } from "react";
import { useEffect } from "react";
import ClipLoader from "react-spinners/DotLoader";

export const ProtectedRoute = ({ component: Component, idRole, ...rest }) => {
  const [user, setUser] = useState(() => Authentication.emptyUser);

  const [authorization, setAuthorization] = useState(() => {
    return { loading: true, authorized: false };
  });

  function renderEmpty() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            overflow: "hidden",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ClipLoader color={"#4C7AF1"} loading={true} size={60} />
        </div>
      </React.Fragment>
    );
  }

  function handleUserChange(user) {
    setUser(user);
    let authorized = user != null && (!idRole || user.idRole === idRole);
    let verified = false;
    if (user) {
      verified = user.isVerified;
    }
    setAuthorization({ loading: false, authorized, verified });
  }

  useEffect(() => {
    Authentication.instance.subscribeToUserChange(handleUserChange);
    return () => {
      Authentication.instance.unsubscribeFromUserChange(handleUserChange);
    };
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authorization.loading) return renderEmpty();
        if (!authorization.authorized) {
          return (
            <Redirect
              to={{ pathname: "/sign-in", state: { from: props.location } }}
            />
          );
        }
        if (!authorization.verified) {
          return (
            <Redirect
              to={{
                pathname: "/verify",
                state: { from: props.location },
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};
