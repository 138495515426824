import React, { Component } from "react";
import styles from "./DeliveredStyles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Hidden,
  Paper,
  Avatar,
  CardHeader,
  IconButton,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  CardActionArea,
  LinearProgress,
  CardContent,
  CardActions,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import {
  MoreVertOutlined,
  Search as SearchIcon,
  SupervisorAccountTwoTone,
} from "@material-ui/icons";
import AdminAppbar from "components/Appbar/AdminAppbar";
import AdminDashboard from "components/DashboardContainer/AdminDashboard";
import axios from "utils/API";
import Authentication from "controller/Authentication";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import dayjs from "dayjs";
import PostData from "components/Fetching/PostData";
import AssignmentController from "../../Assignments/AssignmentController";
const controller = new AssignmentController();

const CardManagementAction = ({ children, onClick }) => {
  return (
    <React.Fragment>
      <Hidden smDown>{children}</Hidden>
      <Hidden mdUp>
        <CardActionArea onClick={onClick}>{children}</CardActionArea>
      </Hidden>
    </React.Fragment>
  );
};

class NotDeliveredCard extends Component {
  state = {
    loading: false,
    error: null,
  };

  constructor(props) {
    super(props);
    this.createAssignmentRef = React.createRef();
  }

  request = async () => {
    const data = await this.props.request(this.props.user.uid);
    return data;
  };

  componentWillUnmount() {
    controller.cancelAllTokens();
  }

  onClick = () => {
    this.createAssignmentRef.current.post();
  };

  handleData = (data) => {
    if (this.props.onData) this.props.onData(data);
  };

  render() {
    const { classes } = this.props;
    const el = this.props.user;

    return (
      <React.Fragment>
        <PostData
          ref={this.createAssignmentRef}
          onLoading={(loading) => this.setState({ loading })}
          request={this.request}
          onData={this.handleData}
          successMessage={`Tarea creada exitosamente`}
          onError={(error) => this.setState({ error })}
          errorMessage={`Error al crear tarea`}
        />

        <Card
          style={{ height: "100%" }}
          variant="outlined"
          style={{ background: "transparent" }}
        >
          {this.state.loading && <LinearProgress color="primary" />}
          <CardManagementAction>
            <CardHeader
              avatar={
                <Avatar
                  src={el.photoURL}
                  style={{ width: 64, height: 64, marginRight: 0 }}
                />
              }
              title={
                <div
                  style={{
                    display: "flex",
                    margin: 5,
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    align="left"
                    color="textSecondary"
                  >
                    {Authentication.formatName(el.name, el.lastName)}
                  </Typography>
                </div>
              }
            />
          </CardManagementAction>
          <CardActions>
            <Button
              color="primary"
              disabled={this.state.loading}
              onClick={this.onClick}
            >
              Agregar Entrega
            </Button>
          </CardActions>
        </Card>
      </React.Fragment>
    );
  }
}

NotDeliveredCard.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(NotDeliveredCard);
