import { DialogTitle } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { LinearProgress } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import GetData from "components/Fetching/GetData";
import UserManagementController from "Pages/UserManagment/UserManagementController";
import ClipLoader from "react-spinners/PulseLoader";
import React, { Component } from "react";
import Authentication from "controller/Authentication";
import { Typography } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import ClassroomManagementController from "../Management/ClassroomManagementController";
import PostData from "components/Fetching/PostData";

const userController = new UserManagementController();
const classroomController = new ClassroomManagementController();

class TransferClassroomDialog extends Component {
  state = {
    loading: false,
    loadingUsers: false,
    users: [],
    currentUser: null,
    myUid: null,
  };

  constructor(props) {
    super(props);
    this.teacherRef = React.createRef();
    this.transferRef = React.createRef();
  }

  handleGetUser = (user) => {
    if (user == null) return;
    this.setState({ myUid: user.uid });
    this.teacherRef.current.get();
  };

  componentDidMount() {
    Authentication.instance.subscribeToUserChange(this.handleGetUser);
  }

  componentWillUnmount() {
    Authentication.instance.unsubscribeFromUserChange(this.handleGetUser);
    userController.cancelAllTokens();
    classroomController.cancelAllTokens();
  }

  getTeachers = async () => {
    let result = await userController.getByRole("teacher");
    result = result.filter((x) => x.uid !== this.state.myUid);
    return result;
  };

  handleClose = () => {
    if (this.state.loading) return;
    this.props.onClose();
  };

  onGetTeachers = (users) => {
    let currentUser = users?.[0];
    this.setState({ users, currentUser });
  };

  handleTransferClick = () => {
    this.transferRef.current.post();
  };

  handlePost = async () => {
    const data = await classroomController.transfer(
      this.props.idClassroom,
      this.state.currentUser.uid
    );
    return data;
  };

  handleOnPost = async () => {
    this.props.onTransfer(this.props.idClassroom);
    this.setState({ loading: false }, this.handleClose);
  };

  render() {
    const { loading } = this.state;
    const { open, idClassroom } = this.props;

    return (
      <React.Fragment>
        <PostData
          request={this.handlePost}
          onData={(val) => this.handleOnPost()}
          onLoading={(val) => this.setState({ loading: true })}
          errorMessage="Error al intentar transferir el salón"
          successMessage="Salón transferido exitósamente"
          ref={this.transferRef}
        />

        <GetData
          ref={this.teacherRef}
          request={this.getTeachers}
          onData={this.onGetTeachers}
          onLoading={(val) => this.setState({ loadingUsers: val })}
          onError={(err) => console.log(err)}
          errorMessage="Error al obtener los docentes"
          dontExecuteOnStart={true}
          disableSuccessNotification
        />

        <Dialog open={open} onClose={this.handleClose} fullWidth>
          {this.state.loading && <LinearProgress />}
          <DialogTitle>Transferir Salón</DialogTitle>

          <DialogContent>
            <Autocomplete
              id="combo-box-assignment"
              value={this.state.currentUser}
              options={this.state.users}
              getOptionLabel={(option) =>
                Authentication.formatName(option.name, option.lastName)
              }
              // disabled={this.state.assignmentsLoading}
              loading={this.state.loadingUsers}
              onChange={(e, val) => {
                this.setState({ currentUser: val });
              }}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  fullWidth
                  {...params}
                  label="Docente"
                  variant="outlined"
                  // margin="dense"
                  InputProps={{
                    ...params.InputProps,

                    startAdornment: (
                      <React.Fragment>
                        {this.state.currentUser != null && (
                          <React.Fragment>
                            <Avatar
                              src={this.state.currentUser.photoURL}
                              style={{ width: 30, height: 30 }}
                            />
                            <div style={{ width: 20 }} />
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ),

                    endAdornment: (
                      <React.Fragment>
                        {this.state.loadingUsers ? (
                          <ClipLoader
                            color={"#4C7AF1"}
                            loading={true}
                            size={6}
                          />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color={"primary"}
              disabled={loading}
              onClick={this.handleClose}
            >
              Cancelar
            </Button>
            <Button
              color={"primary"}
              disabled={loading || this.state.currentUser == null}
              onClick={this.handleTransferClick}
            >
              Transferir
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default TransferClassroomDialog;
