import { Divider, withStyles } from "@material-ui/core";
import { LinearProgress } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { CardActionArea } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Container } from "@material-ui/core";
import {
  Check,
  QuestionAnswer,
  QuestionAnswerOutlined,
  Timer,
} from "@material-ui/icons";
import { ToggleButton } from "@material-ui/lab";
import CardProgress from "components/Progress/CardProgress";
import dayjs from "dayjs";
import { toast } from "material-react-toastify";
import routes from "Pages/routes";
import React, { Component } from "react";
import AnswerCard from "./AnswerCard";

class AnswerTest extends Component {
  state = {
    startTime: 0,
    timerValue: 100,
    answerTime: 3600,
    questionsValue: 0,
    answeredQuestions: 0,
    questions: [
      {
        index: 0,
        id: "fsadfas",
        text: "Primer pregunta que uno se hace",
        answered: false,
        answers: [
          { id: "0", text: "Primera respuesta", selected: false },
          { id: "1", text: "Segunda Respuesta", selected: false },
          { id: "2", text: "Tercer respuesta", selected: false },
        ],
      },
      {
        index: 1,
        id: "fsadfas1",
        text: "Segunda pregunta que uno se hace",
        answered: false,
        answers: [
          {
            id: "a0",
            text: "Primera respuesta / Segunda Pregunta",
            selected: false,
          },
          {
            id: "a1",
            text: "Segunda Respuesta / Segunda Pregunta",
            selected: false,
          },
          {
            id: "a2",
            text: "Tercer respuesta / Segunda Pregunta",
            selected: false,
          },
        ],
      },
    ],

    currentQuestion: null,
  };

  sortQuestions = (cb) => {
    let { questions } = this.state;
    questions.sort((x, y) => x.index - y.index);
    this.setState({ questions }, cb);
  };

  getCurrentQuestion = () => {
    let { questions } = this.state;
    let answeredQuestions = 0;
    let currentIndex = 0;
    for (let index = 0; index < questions.length; index++) {
      if (!questions[index].answered) {
        answeredQuestions = index;
        currentIndex = index;
        break;
      }
    }
    this.setState({
      answeredQuestions,
      currentQuestion: questions[currentIndex],
    });
  };

  answerQuestion = async () => {
    //Mandar a la base de datos las respuestas

    let { questions } = this.state;
    let questionIndex = questions.findIndex(
      (el) => el.id === this.state.currentQuestion.id
    );
    questions[questionIndex].answered = true;
    this.setState({ questions }, () => this.nextQuestion());
  };

  nextQuestion = async () => {
    let { currentQuestion, questions, answeredQuestions } = this.state;
    let nextQuestionIndex = currentQuestion.index + 1;
    if (nextQuestionIndex < questions.length) {
      answeredQuestions += 1;
      this.setState({
        currentQuestion: questions[nextQuestionIndex],
        answeredQuestions,
      });
    } else {
      answeredQuestions += 1;
      this.setState({ answeredQuestions });
      toast.success(`Evaluación finalizada`);
      setTimeout(
        () => this.props.history.replace(routes.teacher.classroomManagement),
        500
      );
    }
  };

  updateTimer() {
    let answerTime = this.state.answerTime;
    let startTime = dayjs(this.state.startTime);
    let currentTime = dayjs(new Date());
    let seconds = currentTime.diff(startTime, "seconds");
    let timerValue = (1 - seconds / answerTime) * 100;
    this.setState({ timerValue });

    if (timerValue <= 0) {
      toast.error(`Tiempo agotado`);
      setTimeout(() => {
        this.props.history.replace(routes.teacher.classroomManagement);
      }, 1500);
      return;
    }

    setTimeout(() => {
      this.updateTimer();
    }, 500);
  }

  componentDidMount() {
    this.setState({ startTime: new Date() }, () => {
      this.updateTimer();
    });

    this.sortQuestions(() => {
      this.getCurrentQuestion();
    });
  }

  toggleAnswer = (idQuestion, idAnswer, selected) => {
    let { currentQuestion, questions } = this.state;
    let questionIndex = questions.findIndex((el) => el.id === idQuestion);
    let answerIndex = questions[questionIndex].answers.findIndex(
      (el) => el.id === idAnswer
    );

    questions[questionIndex].answers[answerIndex].selected = selected;
    currentQuestion = { ...questions[questionIndex] };
    this.setState({ questionIndex, currentQuestion });
  };

  render() {
    const { currentQuestion = null } = this.state;
    return (
      <Container style={{ marginTop: 50, textAlign: "left" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CardProgress
              icon={<QuestionAnswerOutlined />}
              value={
                (100 * this.state.answeredQuestions) /
                this.state.questions.length
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardProgress
              icon={<Timer />}
              color="secondary"
              value={this.state.timerValue}
            />
          </Grid>
        </Grid>
        <br />
        <Divider />
        <br />
        <Typography variant="h3" color="textSecondary" align="center">
          {currentQuestion?.text || ""}
        </Typography>
        <br />
        {currentQuestion != null &&
          currentQuestion.answers.map((el, index) => {
            return (
              <div
                key={`answer-container-${index}-${currentQuestion.id}`}
                style={{ marginTop: 10 }}
              >
                <AnswerCard
                  onToggle={() => {
                    this.toggleAnswer(currentQuestion.id, el.id, !el.selected);
                  }}
                  selected={true}
                  key={`answer-${index}-${currentQuestion.id}`}
                  text={el.text}
                  selected={el.selected}
                />
              </div>
            );
          })}
        <br />
        <Button
          disabled={this.state.timerValue <= 0}
          color="primary"
          variant={"outlined"}
          style={{ fontSize: "1.6rem" }}
          onClick={() => this.answerQuestion()}
        >
          Responder
        </Button>
        <br />
        <br />
      </Container>
    );
  }
}

export default AnswerTest;
