import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core";
import { WatchLaterOutlined } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import DownloadButton from "components/DownloadButton/DownloadButton";
import GetData from "components/Fetching/GetData";
import PostData from "components/Fetching/PostData";
import Authentication from "controller/Authentication";
import dayjs from "dayjs";
import routes from "Pages/routes";
import React, { Component } from "react";
import { Download } from "react-bootstrap-icons";
import { generatePath } from "react-router";
import UploadAssignmentDialog from "../Assignments/UploadAssignmentDialog";
import Controller from "./StudentImproveController";

const controller = new Controller();

class StudentImprovePlan extends Component {
  state = {
    editDialogOpen: false,
    loading: true,
    deleteLoading: false,

    userAssignmentDialogOpen: false,
    improvePlan: { message: "", dueTime: dayjs(), criteria: [] },
  };

  push = (link) => {
    this.props.history.push(generatePath(link, { ...this.props.match.params }));
  };

  constructor(props) {
    super(props);
    this.deleteRef = React.createRef();
  }

  requestDelete = async () => {
    let { idAssignment, idStudent } = this.props.match.params;
    const data = await controller.deletePlan(idAssignment, idStudent);
    return data;
  };

  handleDelete = () => {
    this.deleteRef.current.post();
  };

  handleDeleteDialogOpen = () => {
    this.setState({ deleteDialogOpen: true });
  };

  handleDeleteDialogClose = () => {
    this.setState({ deleteDialogOpen: false });
  };

  handleEditDialogClose = () => {
    this.setState({ editDialogOpen: false });
  };
  handleEditDialogOpen = () => {
    this.setState({ editDialogOpen: true });
  };

  handleData = (data) => {
    this.setState({ improvePlan: data });
  };

  requestImprovePlan = async () => {
    const { idAssignment } = this.props.match.params;
    let data = await controller.getPlan(idAssignment);

    return data;
  };

  openUploadDialog = (value) => {
    this.setState({ userAssignmentDialogOpen: value });
  };

  handleLoading = (val) => {
    this.setState({ loading: val });
  };

  onEdit = ({ message, dueTime }) => {
    let improvePlan = { ...this.state.improvePlan };
    improvePlan.message = message;
    improvePlan.dueTime = dueTime;
    this.setState({ improvePlan }, this.handleEditDialogClose);
  };

  componentWillUnmount() {
    controller.cancelAllTokens();
  }

  handleUpload = async (url) => {
    let improvePlan = { ...this.state.improvePlan };
    improvePlan.filesURL = url;
    improvePlan.submitTime = new Date();
    this.setState({ improvePlan });
  };

  overrideDefaultFinish = async (url) => {
    const { improvePlan } = this.state;
    await controller.upload(improvePlan.id, url);
  };

  render() {
    const { loading, improvePlan } = this.state;
    return (
      <React.Fragment>
        <GetData
          request={this.requestImprovePlan}
          onData={this.handleData}
          // errorMessage={`Error al traer plan de mejora`}
          onLoading={this.handleLoading}
          disableSuccessNotification
          disableErrorNotification
          onError={(error) => {
            console.log(error);
            // this.props.history.replace(
            //   `${routes.errors.improveNotFound}?id=${this.props.pematch.params.id}`
            // );
          }}
        />

        <PostData
          ref={this.deleteRef}
          request={this.requestDelete}
          onLoading={(val) => this.setState({ deleteLoading: val })}
          errorMessage="Ocurrio un error al momento de eliminar el plan de mejora"
          successMessage="Plan de mejora ha sido eliminado"
          onData={() => {
            this.props.history.push(
              generatePath(routes.teacher.classroomAssignments, {
                id: improvePlan.idClassroom,
                idAssignment: improvePlan.idAssignment,
              })
            );
          }}
        />

        <div
          style={{
            marginTop: "40px",
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography align="left" variant="h5" gutterBottom>
                Plan de Mejora
              </Typography>

              <Divider />
            </Grid>
            <Grid item xs={12} md={8}>
              <Card variant="outlined" style={{ height: "100%" }}>
                <CardContent>
                  <Typography
                    variant="body1"
                    align="left"
                    style={{
                      flexGrow: 1,
                      fontWeight: 600,
                      textTransform: "uppercase",
                    }}
                  >
                    {loading ? <Skeleton /> : improvePlan.name}

                    {loading && (
                      <Chip style={{ marginLeft: 20, width: 75 }} label="">
                        <Skeleton />
                      </Chip>
                    )}

                    {!loading && improvePlan.filesURL && (
                      <Chip
                        label="Entregada"
                        color="primary"
                        style={{ marginLeft: 20 }}
                        variant="outlined"
                      />
                    )}

                    {!loading &&
                      improvePlan.submitTime &&
                      dayjs(improvePlan.dueTime).isBefore(
                        dayjs(improvePlan.submitTime)
                      ) && (
                        <React.Fragment>
                          <Chip
                            style={{ marginLeft: 10 }}
                            label={`Entregada ${dayjs(improvePlan.dueTime).from(
                              dayjs(improvePlan.submitTime),
                              true
                            )} tarde`}
                            // className={classes.errorChip}
                            color="primary"
                            variant="outlined"
                            icon={<WatchLaterOutlined />}
                          />
                        </React.Fragment>
                      )}

                    {!loading && improvePlan.lastScoredURL && (
                      <Chip
                        label={"Calificada"}
                        color="primary"
                        style={{ marginLeft: 10 }}
                      />
                    )}
                  </Typography>

                  <Typography align="left">
                    {loading ? (
                      <React.Fragment>
                        <Skeleton />
                        <Skeleton />
                      </React.Fragment>
                    ) : (
                      improvePlan.description
                    )}
                  </Typography>

                  <Typography
                    align="left"
                    variant="subtitle2"
                    color="textSecondary"
                  >
                    {loading ? (
                      <Skeleton />
                    ) : (
                      dayjs(improvePlan.dueTime).format("llll")
                    )}
                  </Typography>
                </CardContent>
                <Divider />
                <CardActions>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.props.history.push(
                        generatePath(routes.student.assignment, {
                          id: improvePlan.idClassroom,
                          idAssignment: improvePlan.originalAssignment,
                        })
                      )
                    }
                  >
                    Ver Original
                  </Button>

                  {improvePlan.assignmentFilesURL && (
                    <React.Fragment>
                      <Divider flexItem orientation="vertical" />
                      <DownloadButton
                        variant="text"
                        fileURL={improvePlan.assignmentFilesURL}
                        color="primary"
                        label="Temario"
                      />
                    </React.Fragment>
                  )}
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                variant="outlined"
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardContent>
                  {loading ? (
                    <Skeleton width={150} height={80} />
                  ) : (
                    <div>
                      <Typography variant="h4">
                        {improvePlan.lastScoredURL ? improvePlan.score : "--"} /{" "}
                        {improvePlan.assignmentScore}
                      </Typography>
                      <Typography variant="caption">Calificación</Typography>
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
            {!loading && improvePlan.lastScoredURL && (
              <Grid item xs={12} md={8}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography
                      variant="body1"
                      align="left"
                      style={{
                        flexGrow: 1,
                        fontWeight: 600,
                        textTransform: "uppercase",
                      }}
                    >
                      Calificación
                    </Typography>
                    <br />
                    {improvePlan.scoredMessage && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="body1" align="left">
                          {improvePlan.scoredMessage ||
                            `No hay ningún comentario`}
                        </Typography>
                      </div>
                    )}
                    <br />
                    <Divider />
                    {improvePlan.criteria.map((el, index, arr) => {
                      return (
                        <div
                          style={{ display: "flex", marginTop: "10px" }}
                          key={index}
                        >
                          <Typography varaint="body1" align="left">
                            {el.name}
                          </Typography>
                          <div style={{ flexGrow: 1 }} />
                          <Typography
                            varaint="body1"
                            align="left"
                            color="textSecondary"
                            style={{ fontWeight: "bold" }}
                          >
                            {el.score || 0} / {el.percentage}
                          </Typography>
                          {index !== arr.length - 1 && (
                            <Divider style={{ marginTop: "10px" }} />
                          )}
                        </div>
                      );
                    })}
                  </CardContent>

                  {improvePlan.lastScoredURL && (
                    <CardActions>
                      <DownloadButton
                        variant="text"
                        fileURL={improvePlan.lastScoredURL}
                        color="primary"
                        endIcon={<Download />}
                        label="Descargar Tarea"
                      />
                    </CardActions>
                  )}
                </Card>
              </Grid>
            )}
            <Grid item xs={12} md={8}>
              <Card variant="outlined" style={{ height: "100%" }}>
                <CardContent>
                  <Typography
                    variant="body1"
                    align="left"
                    style={{
                      flexGrow: 1,
                      fontWeight: 600,
                      textTransform: "uppercase",
                    }}
                  >
                    Criterios
                  </Typography>
                  <Typography align="left">
                    {loading ? (
                      <React.Fragment>
                        <Skeleton />
                        <Skeleton />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {improvePlan.criteria.map((el, index, arr) => {
                          return (
                            <div
                              style={{ display: "flex", marginTop: "10px" }}
                              key={index}
                            >
                              <Typography varaint="body1" align="left">
                                {el.name}
                              </Typography>
                              <div style={{ flexGrow: 1 }} />
                              <Typography
                                varaint="body1"
                                align="left"
                                color="textSecondary"
                                style={{ fontWeight: "bold" }}
                              >
                                {el.percentage}
                              </Typography>
                              {index !== arr.length - 1 && (
                                <Divider style={{ marginTop: "10px" }} />
                              )}
                            </div>
                          );
                        })}
                      </React.Fragment>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
        <Divider />

        <br />
        <Typography
          variant="body1"
          align="left"
          style={{
            flexGrow: 1,
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        >
          Entrega
        </Typography>

        <div
          style={{ display: "flex", marginTop: "20px", marginBottom: "20px" }}
        >
          {!loading && (
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => this.openUploadDialog(true)}
              // disabled={!this.state.submitted.data.canSubmit}
            >
              {improvePlan.filesURL != null
                ? `Reemplazar Tarea`
                : `Subir Tarea`}
            </Button>
          )}
        </div>

        <br />
        {!loading && improvePlan.filesURL && (
          <Card
            variant="outlined"
            style={{
              padding: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle2">
                {dayjs(improvePlan.submitTime).format("DD/MM/YYYY  ·  HH:mm") ||
                  "ERROR"}
              </Typography>
              <div style={{ flexGrow: 1 }} />
              <Hidden smDown>
                <DownloadButton
                  color={"primary"}
                  endIcon={<Download />}
                  fileURL={improvePlan.filesURL}
                  label="Descargar"
                  variant="text"
                />
              </Hidden>
            </div>
            <Hidden mdUp>
              <div>
                <DownloadButton
                  color={"primary"}
                  endIcon={<Download />}
                  fileURL={improvePlan.filesURL}
                  label="Descargar"
                  variant="text"
                />
              </div>
            </Hidden>
          </Card>
        )}
        <br />
        <br />
        <UploadAssignmentDialog
          open={this.state.userAssignmentDialogOpen}
          idAssignment={this.props.match.params.idAssignment}
          idClassroom={this.props.match.params.id}
          onClose={() => this.openUploadDialog(false)}
          onUploadFinish={this.handleUpload}
          overrideDefaultFinish={this.overrideDefaultFinish}
        />
      </React.Fragment>
    );
  }
}

export default StudentImprovePlan;
